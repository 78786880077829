
import {BaseComponent} from "../../views/_subviews/BaseComponent";
import React, {CSSProperties} from "react";
import style from "../../assets/jss/material-dashboard-pro-react/components/wizardStyle";

export default class WizardNavigation  extends BaseComponent {

    render() {
        return this.props.steps.map((prop: any, key: any) => {
            return (
                <li key={key} style={{...style.steps as CSSProperties, width: this.props.width }}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href="#" style={{...style.stepsAnchor as CSSProperties}}
                       onClick={e => {
                           e.preventDefault();
                           this.props.onChange(key);
                       }}>{prop.name}</a>
                </li>
            );
        });
    }
}
