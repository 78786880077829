
import React from "react";

import {BaseComponent} from "../_subviews/BaseComponent";

import {PrivacyPopupContentEN} from "../_subviews/agreements/PrivacyPopupContentEN";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import {TermsPopupContentEN} from "../_subviews/agreements/TermsPopupContentEN";
import {Routing} from "../../services/Routing";

export default class TermsPage extends BaseComponent {

    render() {
        const service = Routing.instance.pathContains("service");
        const privacy = Routing.instance.pathContains("privacy");
        return (
            <GridContainer justifyContent="center">
                <GridItem xs={12} sm={12} md={8}>
                    <Card>
                        <CardBody>
                            {privacy && <PrivacyPopupContentEN/>}
                            {service && <TermsPopupContentEN/>}
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}
