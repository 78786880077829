import React from "react";
import { Translation } from "../../core/service/Translation";
import { DateEnumeration, DateUtils } from "../../core/utils/DateUtils";
import { ViewUtils } from "../../utils/ViewUtils";
import { BaseComponent } from "../_subviews/BaseComponent";
import ReleaseNoteComponent from "./ReleaseNoteComponent";

export default class ReleaseNotesPage extends BaseComponent {
    
    readonly NOTES = [
        {
            version: "0.1.0",
            date: DateUtils.construct(1, DateEnumeration.June, 2021),
            bullets: [
                "Initial release"
            ]
        },
        {
            version: "0.2.3",
            date: DateUtils.construct(30, DateEnumeration.October, 2021),
            bullets: [
                "Enabled Facebook chat",
                "Added service agreement for caterers",
                "Fixed language parameter manipulation in route calculation",
                "Fixed blank screen after registration"
            ]
        }
    ];

    render() {
        return ViewUtils.createCardTemplate("comments", Translation.find("release-notes"),
            <div className={"container"}>
                {this.NOTES.reverse().map((note: any) => <ReleaseNoteComponent content={note} />)}
            </div>
        );
    }
}