import React from "react";
import EtiqPageCollapse from "../subviews/EtiqPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class Italian extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <EtiqPageCollapse title={"Italian"}>
                    <li>Italian dishes such as antipasti, pasta dishes, pizzas. It is important to follow the original Italian production methods and ingredients</li>
                </EtiqPageCollapse>
            );
        }

        return (
            <EtiqPageCollapse title={"Itaalia"}>
                <li>Itaaliapärased road nagu antipasti, pastatoidud, pizzad. Oluline on siinkohal järgida Itaalia algupäraseid valmistusviise ja tooraineid</li>
            </EtiqPageCollapse>
        );
    }
}