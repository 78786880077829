
import React from "react";

import InputAdornment from "@mui/material/InputAdornment";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Face from "@mui/icons-material/Face";
import Email from "@mui/icons-material/Email";
import {Business, Lock} from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";

import Button from "../../../components/CustomButtons/Button.js";
import CustomInput from "../../../components/CustomInput/CustomInput.js";

import {BaseComponent} from "../../_subviews/BaseComponent";

import TermsPopup from "../../_subviews/agreements/TermsPopup";
import {Translation} from "../../../core/service/Translation";
import AuthenticationBox from "./AuthenticationBox.js";
import { ViewUtils } from "../../../utils/ViewUtils.js";

export default class RegistrationBox extends BaseComponent {

    constructor(props: any) {
        super(props);
        this.state = {
            agreeToTerms:false,
            isCaterer: false,
            cardAnimation: "cardHidden",
            showTerms: false,
            requireTermsAccept: false
        }
    }

    render() {
        if (!this.props.isVisible) {
            return null;
        }
        return (
            <div>
                <TermsPopup isOpen={this.state.showTerms} onClose={() => {
                    this.setState({showTerms: false})
                }}/>
                <AuthenticationBox
                    body={this.createContent()}
                    footer={
                        <div>
                            <Button  color="primary" onClick={() => this.onRegisterClick()}>
                                {Translation.find("get-started")}
                            </Button>
                            <span className={"space-port"}> </span>
                            <Button color="info" className={"boring-button"}  onClick={this.props.showLogin}>
                                {Translation.find("login")}
                            </Button>
                        </div>
                    }
                />
            </div>
        );
    }

    onRegisterClick() {
        if (this.state.agreeToTerms) {
            const name = ViewUtils.getInputValueById("name");
            const email = ViewUtils.getInputValueById("email");
            const password = ViewUtils.getInputValueById("password");
            const regNumber = ViewUtils.getInputValueById("regNumber");
            this.props.onRegisterClick(name, email, password, regNumber);

            this.setState({requireTermsAccept: false});
        } else {
            this.setState({requireTermsAccept: true});
        }
    }

    createContent() {
        const error = this.props.error;
        return (
            <div>
                {this.createInput("name", <Face/>, error.name)}
                {this.createInput("email", <Email/>, error.email)}
                {this.createInput("password", <Lock/>, error.password, {type: "password"})}
                {this.state.isCaterer && this.createInput("regNumber", <Business/>, error.regNumber)}

                {this.createCatererCheckbox()}
                {this.createTermsCheckbox()}
            </div>
        );
    }

    createInput(id: string, icon: any, error: boolean, inputProps?: any) {
        if (!inputProps) {
            inputProps = {};
       }
       inputProps.autoComplete = "off";
        return <CustomInput
            //@ts-ignore 
            id={id}
            error={error}
            labelText={Translation.find(id)}
            formControlProps={{fullWidth: true}}
            inputProps={{startAdornment: this.createInputAdornment(icon), ...inputProps}}
        />;
    }

    createInputAdornment(icon: any) {
        return <InputAdornment position="start">{icon}</InputAdornment>
    }

    handleCatererToggle(e: any) {
        this.setState({isCaterer: e.target.checked});
    }

    createCatererCheckbox() {
        const text = Translation.find("i-am-caterer-checkbox");
        const content = this.createCheckbox(text, (e: any) => this.handleCatererToggle(e));
        return <Tooltip className={"forced-smaller-text-size-2"} title={Translation.find("caterer-checkbox-hint")}>{content}</Tooltip>
    }

    handleTermsToggle(e: any) {
        this.setState({agreeToTerms: e.target.checked});
    };

    onTermsLabelClick(e: any) {
        e.preventDefault();
        this.setState({showTerms: true})
    }

    createTermsCheckbox() {
        const style = this.state.requireTermsAccept ? {color: "red"} : {};
        const label = <span onClick={this.onTermsLabelClick.bind(this)} style={style} className={"forced-smaller-text-size"}>
            {Translation.find("agree-to-terms")}
        </span>;
        return this.createCheckbox(label, (e: any) => this.handleTermsToggle(e));
    }

    createCheckbox(label: any, action: any) {
        return (
            <FormControlLabel control={<Checkbox tabIndex={-1} onClick={action}/>}label={label}/>
        );
    }
}
