import React from "react";
import EtiqPageCollapse from "../subviews/EtiqPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class Foodtruck extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <EtiqPageCollapse title={"Foodtruck"}>
                    <li>Option to order a food truck (or a stand) that offers street food</li>
                </EtiqPageCollapse>
            );
        }

        return (
            <EtiqPageCollapse title={"Foodtruck"}>
                <li>Soov tellida spetsiaalselt tänavatoitu valmistav auto või muu sarnane vahend</li>
            </EtiqPageCollapse>
        );
    }
}
