import defaultAvatar from "../../assets/img/icon-avatar-default.png";
import {Networking} from "../Networking";

export class CurrentUser {

    public static instance = new CurrentUser();

    private data: any;

    // Event handler that should be attached when user does not exist, to await data load
    public loaded: any;

    public set(data: any) {
        this.data = data;
        if (this.loaded) {
            this.loaded();
        }
    }

    public get(): any {
        return this.data;
    }

    public displayName() {
        if (!this.exists()) {
            return "";
        }

        if (this.isCaterer()) {
            const business = this.data.officialBusinessName;

            if (!business || business?.trim() === "") {
                return this.name();
            }
            return business;
        }
        return this.data.name;
    }

    public findCateringOptions() {
        if (!this.data) {
            return null;
        }
        const result: { [index: string]: any } = {};

        Object.keys(this.data).forEach((item: string) => {
            if (item.indexOf("multi_") !== -1) {
                const value = this.data[item];
                result[item] = value ? value.split(",") : [];
            }
        });
        return result;
    }

    public updateCateringOptions(data: any) {
        Object.keys(data).forEach((item: string) => {
           this.data[item] = data[item];
        });
    }

    public getId(): number {
        return this.data.id;
    }

    public name(): string {
        return this.data?.name;
    }

    public avatarUrl() {
        return this.avatarUrlOf(this.data);
    }

    public avatarUrlOf(user: any) {
        if (!user?.avatarUrl) {
            return defaultAvatar;
        }

        return Networking.instance.basePath() + user.avatarUrl;
    }

    public exists(): boolean {
        return this.data !== undefined && this.data.error === undefined;
    }

    public isCaterer(): boolean {
        if (!this.exists()) {
            return false
        }
        return !!this.data.isCaterer
    }

    public isAdmin(): boolean {
        return !!this.data.isAdmin
    }

}
