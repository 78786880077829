
import React from "react";

// @mui/material components
import "../../assets/css/landing.css";
import {BaseComponent} from "../_subviews/BaseComponent";
import {Networking} from "../../services/Networking";
import {LocalStorage} from "../../services/LocalStorage";
import {Routing} from "../../services/Routing";
import { Translation } from "../../core/service/Translation";

const buffet = require('../../assets/img/allicater/foodz.png');

export default class VerificationPage extends BaseComponent {

    constructor(props: any) {
        super(props);
        this.state = {verified: false};
    }

    componentDidMount = async () => {

        const id = Routing.instance.getUrlParameter("id");
        if (!id) {
            console.log("No verification key. Nothing to do");
            return;
        }
        const response = await Networking.instance.verify(id);
        if (response.sessionId) {
            LocalStorage.instance.setSessionToken(response.sessionId);
            console.log("Setting session token:", response.sessionId);
            this.setState({verified: true});
            setTimeout(() => {
                console.log("You will be redirected in a second...");
                this.props.verified();
            }, 1000);

        }
    };

    render() {
        return (
            <div>
                <div className={"container"}>
                    <img src={buffet} className={"img-responsive rounded about-sec"} alt={"."}/>
                    <h5 style={{width: "100%", textAlign: "center"}}>{
                        Translation.find("verified-redirecting")}
                    </h5>
                </div>
            </div>
        )
    }
}
