import React from "react";
import EtiqPageCollapse from "../subviews/EtiqPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class Long extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <EtiqPageCollapse title={"Long Table"}>
                    <li>Client wants to place all guests sitting behind long tables placed in a line as possible</li>
                </EtiqPageCollapse>
            );
        }

        return (
            <EtiqPageCollapse title={"Pikk laud"}>
                <li>Klient soovib kõik oma külalised asetada istuma võimalikult ühtses joones asetatud pikkade laudade taha</li>
            </EtiqPageCollapse>
        );
    }
}