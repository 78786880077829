import React from "react";
import {Translation} from "../../core/service/Translation";

export class BaseComponent extends React.Component {

    reload() {
        this.forceUpdate();
    }

    translate(source) {
        if (typeof source === "string") {
            return Translation.find(source);
        }
        return source.map(item => Translation.find(item));
    }

    alertSuccess(body, redirect) {
        this.props.alert(Translation.find("success-header"), this.translate(body), redirect);
    }

}
