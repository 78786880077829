import React from "react";
import EtiqPageCollapse from "../subviews/EtiqPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class Chinese extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <EtiqPageCollapse title={"Chinese"}>
                    <li>Heavily seasoned and often spiced mixed dishes. Lots of  different sauces (soy, fish, etc.) are used to give the main dishes interesting nuances. The choice of ingredients is basically everything that grows above and below ground</li>
                </EtiqPageCollapse>
            );
        }

        return (
            <EtiqPageCollapse title={"Hiina"}>
                <li>Ohtralt maitsestatud ja tihtipeale ka vürtsitatud seguroad. Kasutatakse palju erinevaid kastmeid (soja, kalaõli jne), andmaks põhiroogadele huvitavaid nüansse. Toorainevalik on põhimõtteliselt kõik, mis maa peal ja all kasvab. </li>
            </EtiqPageCollapse>
        );
    }
}