
import React from "react";

import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardIcon from "../../../components/Card/CardIcon";
import {Styles} from "../../../styles/Styles";
import CardBody from "../../../components/Card/CardBody";

import {BaseComponent} from "../BaseComponent";
import ACCardIcon from "./ACCardIcon";

export default class ACCard extends BaseComponent {

    render() {
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="default">
                            <CardIcon color={this.props.color ? this.props.color : "info"}>
                                <ACCardIcon name={this.props.icon}/>
                            </CardIcon>
                            <h4 style={Styles.cardIconTitle}>{this.props.title}</h4>
                        </CardHeader>
                        <CardBody>
                            {this.props.children}
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}
