
import {createBrowserHistory} from "history";
import {CurrentUser} from "./dto/CurrentUser";

const HISTORY = createBrowserHistory();

export enum RouteEnumeration {
    Dashboard = "/#/",
    ParamStart = "?",
    Menu = "menu",
    Requests = "requests",
    OfferHistory = "offer/history",
    OfferList = "offer/list",
    Offer = "offer?",
    EventCreate = "event/create",
    Invoice = "invoice?",
    InvoiceList = "invoice/list",
    EventHistory = "event/history",
    Feedback = "feedback",
    Profile = "profile",
    Settings = "settings",
    Rate = "rate?",
    Contact = "contact",
    Admin = "admin",
    PaymentResult = "payment/result",
    ReleaseNotes = "notes/release"
}

export class Routing {

    static Enumeration = RouteEnumeration;

    public static instance = new Routing();

    public history() {
        return HISTORY;
    }

    public to(route: string) {
        HISTORY.push(route);
    }

    public isAt(route: RouteEnumeration): boolean {
        if (route === RouteEnumeration.Dashboard && !this.getPath()) {
            return true;
        }
        
        return this.pathStartsWith(route);
    }

    public clear() {
        HISTORY.replace(Routing.Enumeration.Dashboard);
    }

    public calculate(props: any) {
        const result: any = [];

        if (!CurrentUser.instance.exists()) {
            return result;
        }

        const routes = Routing.Enumeration;
        result.push(this.create(props.t("dashboard"), routes.Dashboard, "dashboard"));

        if (CurrentUser.instance.isCaterer()) {
            result.push(this.create(props.t("menu"), routes.Menu, "my-menu"));
            result.push(this.create(props.t("requests"), routes.Requests, "party"));
            result.push(this.create(props.t("offer-history"), routes.OfferHistory, "offer-history"));
        } else {
            result.push(this.create(props.t("my-offers"), routes.OfferList, "my-offers"));
            result.push(this.create(props.t("create-event"), routes.EventCreate, "party"));
        }

        result.push(this.create(props.t("invoices"), routes.InvoiceList, "invoice"));
        result.push(this.create(props.t("past-gigs"), routes.EventHistory, "past-gigs"));
        result.push(this.create(props.t("feedback"), routes.Feedback, "comments"));

        if (CurrentUser.instance.isAdmin()) {
            result.push(this.create(props.t("admin"), routes.Admin, "settings"));
        }
        return result;
    }


    public isAtRoot() {
        return !this.getPath();
    }
    public isAtResetPasswordPage() {
        return this.pathStartsWith("reset");
    }
    public isAtVerificationPage() {
        return this.pathStartsWith("verify");
    }
    public isAtPaymentRedirectPage() {
        return this.pathStartsWith("payment/redirect");
    }
    public isAtTermsPage() {
        return this.pathStartsWith("terms");
    }
    public isAtHelpPage() {
        return this.pathStartsWith("help");
    }
    public getPath() {
        let clean = window.location.href.replace("://", "").replace("?lang=et", "").replace("?lang=en", "");
        return clean.split("#/")[1];
    }

    public pathContains(endpoint: string) {
        const path = this.getPath();
        if (!path) {
            return false;
        }
        return path.includes(endpoint);
    }

    public pathStartsWith(page: string) {
        const path = this.getPath();
        if (!path) {
            return false;
        }
        return path.startsWith(page);
    }

    static redirectTo(path: string) {
        window.location.href = path;
    }

    public getHash(key: string): boolean {
        let hash = window.location.hash.substr(1);
        if (!hash) {
            return false;
        }
        const split = hash.split("?");
        if (split.length > 1) {
            hash = split[0];
        }
        hash = hash.replaceAll("/", "");
        return hash === key;
    }

    public getUrlParameter(key: string) {
        var params = window.location.hash;
        if (!params) {
            params = window.location.href
            if (!params) {
                return;
            }
        }
        
        const split = params.split("?");
        if (split.length < 2) {
            return;
        }

        let search = split[1];

        let result = null;
        let tmp = [];
        search
            .split("&")
            .forEach(function (item) {
                tmp = item.split("=");
                if (tmp[0] === key) result = decodeURIComponent(tmp[1]);
            });
        return result;
    }

    public getUrlNumberParameter(key: string) {
        const value = this.getUrlParameter(key);
        if (!value) {
            return undefined;
        }
        return parseInt(value);
    }

    private create(title: string, route: string, icon: any) {
        return {name: title, path: route, icon: icon, layout: "/"}
    }
}
