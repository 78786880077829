
import React, {CSSProperties} from "react";
import {BaseComponent} from "../BaseComponent";

export default class ACCardIcon extends BaseComponent {

    get style(): CSSProperties {
        return {width: "100%", height: "100%", objectFit: "contain"}
    }

    render() {
        const source = require("../../../assets/img/allicater/icon/" + this.props.name + ".svg");
        return <img style={this.style} className={"largerCat"} src={source}  alt={"."}/>;
    }
}
