import React from "react";

import EventDetailContainer from "./EventDetailContainer";
import BasePopup from "./_base/BasePopup";
import {DateUtils} from "../../core/utils/DateUtils";
import {CurrentUser} from "../../services/dto/CurrentUser";
import {BaseComponent} from "./BaseComponent";
import { Translation } from "../../core/service/Translation";

export default class EventDetailPopup extends BaseComponent {

    render() {
        if (!this.props.event) {
            return null;
        }
        const event = this.props.event;
        const title = Translation.find("event-details") + " (id: " + event.id + ")";

        let extra = undefined;
        const hasNoOffers = event.offers === undefined || event.offers.length === 0;
        const myId = CurrentUser.instance.getId();
        const isMyEvent = myId === event.creatorId;
        let myOffer = event.offers?.find((offer: any) => offer.catererId === myId);

        if (!myOffer && event.offer && event.offer.catererId === myId) {
            myOffer = event.offer;
        }
        if (hasNoOffers && isMyEvent && DateUtils.isUpcoming(event.date)) {
            extra = {
                onClick: () => {
                    this.props.editClick(event)
                }, color: "warning", text: Translation.find("edit")
            };
        } else if (myOffer) {
            extra = {
                onClick: () => {
                    this.props.viewOfferClick(event, myOffer)
                }, color: "warning", text: Translation.find("view-offer")
            };
        } else if (!isMyEvent && !myOffer && DateUtils.isUpcoming(event.date)) {
            extra = {
                onClick: () => {
                    this.props.makeOfferClick(event)
                }, color: "warning", text: Translation.find("make-offer")
            };
        }
        return (
            <BasePopup isOpen={!!this.props.event} title={title} onClose={this.props.onClose} extraAction={extra}>
                <EventDetailContainer event={event}/>
            </BasePopup>
        );
    }
}
