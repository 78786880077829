
import React from "react";
import HelpPageCollapse from "../subviews/HelpPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class EventHistory extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <HelpPageCollapse title={"Past events"}>
                    <li>Under Past events, the caterer sees the events they have catered and additional information</li>
                </HelpPageCollapse>
            );
        }

        return (
            <HelpPageCollapse title={"Toimunud üritused"}>
                <li>Toimunud ürituste all näeb caterer oma sooritatud üritusi ning lisainfot</li>
            </HelpPageCollapse>
        );
    }
}
