import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import GridItem from "../../../components/Grid/GridItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import {MenuCategories} from "../../../model/constants/MenuCategories";
import MenuItem from "@mui/material/MenuItem";
import GridContainer from "../../../components/Grid/GridContainer";
import {ViewUtils} from "../../../utils/ViewUtils";
import DialogActions from "@mui/material/DialogActions";
import Button from "../../../components/CustomButtons/Button";
import React from "react";
import {withTranslation} from "react-i18next";
import withStyles from "@mui/styles/withStyles";

import customSelectStyle from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "../../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import ImageUpload from "../../../components/CustomUpload/ImageUpload";
import {BaseComponent} from "../../_subviews/BaseComponent";

const style = {
    infoText: {
        fontWeight: "400",
        margin: "10px 0 30px",
        textAlign: "center"
    },
    ...customSelectStyle,
    ...customCheckboxRadioSwitch
};

class MenuItemPopup extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            category: undefined
        };
    }

    handleCategoryChange(e) {
        this.setState({category: e.target.value});
    }

    render() {
        this.inputs = [];
        this.checkboxes = [];
        this.imageBase64 = undefined;
        return (
            <div>
                <Dialog
                    open={this.props.show}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {this.translate(this.props.item ? "update-menu-item" : "add-menu-item")}
                    </DialogTitle>
                    <DialogContent>
                        <div>
                            <GridItem xs={12} sm={6} md={5} lg={12} style={{marginLeft: "10px", marginRight: "10px"}}>
                                {this.createCategorySelect()}
                            </GridItem>
                            <GridContainer>
                                {this.createGridItem("name", this.props.item?.name, 12)}
                            </GridContainer>
                            <GridContainer>
                                {this.createGridItem("quantity", this.props.item?.quantity, 4)}
                                {this.createGridItem("unit", this.props.item?.unit, 4)}
                                {this.createGridItem("price", this.props.item?.price, 4)}
                            </GridContainer>
                            <GridContainer>
                                {this.createGridItem("description", this.props.item?.description, 12,
                                    { multiline: true, rows: 3 })}
                            </GridContainer>
                            <GridContainer>
                                {this.createCheckbox("vegan", this.props.item?.vegan)}
                                {this.createCheckbox("gluten-free", this.props.item?.gluten)}
                                {this.createCheckbox("sugar-free", this.props.item?.sugar)}
                                {this.createCheckbox("dairy-free", this.props.item?.dairy)}
                                {this.createCheckbox("nut-free", this.props.item?.nut)}
                                {this.createCheckbox("local", this.props.item?.local)}
                            </GridContainer>
                            <GridItem xs={12} sm={6} md={5} lg={12} style={
                                { margin: "10px", marginTop: "20px", display: "flex" }
                            }>
                                <ImageUpload
                                    addButtonProps={{ color: "warning", text: this.props.t("add-image") }}
                                    changeButtonProps={{ color: "rose", text: this.props.t("change") }}
                                    removeButtonProps={{ color: "danger", text: this.props.t("remove") }}
                                    style={{margin: "20px auto", maxWidth: "300px" }}
                                    imagePicked={(file, base64) => {
                                        this.imageFile = file;
                                        this.imageBase64 = base64;
                                    }}
                                    imageError={(code) => {
                                        if (code === ImageUpload.TOO_LARGE) {
                                            this.props.alert(
                                                this.translate("upload-error"),
                                                this.translate("image-too-large") + " 1MB");
                                        }
                                    }}
                                />
                            </GridItem>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            this.setState({ show: false, category: undefined });
                            this.props.onCancel();
                        }} color="danger">{this.props.t("cancel")}</Button>
                        <Button onClick={() => {
                            this.props.onAdd(this.collectData(), this.props.item?.id);
                        }} color="success" autoFocus>
                            {this.props.t(this.props.item ? "update" : "add")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    collectData() {
        const result = {};
        this.inputs.forEach(id => {
           result[id] = ViewUtils.getInputValueById(id);
        });

        this.checkboxes.forEach(id => {
            const split = id.split("-");
            const formatted = split.length > 1 ? split[0] : id;
            result[formatted] = ViewUtils.getCheckboxValueById(id) ? "true" : "false";
        });

        result.imageBase64 = this.imageBase64;
        result.categoryId = this.categoryId();

        return result;
    }

    categoryId() {
        return this.state.category ?? this.props.category?.id;
    }

    createCategorySelect() {
        const classes = this.props.classes;
        return (
            <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                    {this.props.t("menu-category")}
                </InputLabel>
                <Select
                    MenuProps={{className: classes.selectMenu}}
                    classes={{select: classes.select}}
                    value={this.categoryId()}
                    onChange={(e) => this.handleCategoryChange(e)}
                    inputProps={{name: "simpleSelect", id: "simple-select"}}
                >
                    {MenuCategories.list.items.map((item, index) => {
                        return <MenuItem
                            key={index}
                            classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                            value={item.id}>
                            {this.props.t(item.tx)}
                        </MenuItem>
                    })}
                </Select>
            </FormControl>
        );
    }

    createGridItem(id, text, width, props) {
        if (!props) {
            props = { defaultValue: text ? text : "" };
        } else {
            props.defaultValue = text ? text : "";
        }
        this.inputs.push(id);
        return ViewUtils.createGridItem(this, id, width, props);
    }

    createCheckbox(id, checked) {
        this.checkboxes.push(id);
        return ViewUtils.createCheckbox(this.props, id, 4, () => {}, checked === 1);
    }
}

export default withTranslation()(withStyles(style)(MenuItemPopup));
