import React from "react";

import {BaseComponent} from "../BaseComponent";

import {withStyles} from "@mui/styles";
import style from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import GridItem from "../../../components/Grid/GridItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

class ACMultiSelect extends BaseComponent {

    onSelectChange(e) {
        this.props.optionSelected(e);
    }

    render() {
        const {hidden, id, index, text, options, selectedOptions, multiple} = this.props;
        if (hidden) {
            return null;
        }
        return (
            <GridItem key={index} xs={12} sm={6} md={5} lg={4} style={{marginLeft: "10px", marginRight: "10px", fontFamily: '"Open Sans"'}}>
                <FormControl fullWidth className={this.props.classes.selectFormControl}>
                    <InputLabel style={{fontFamily: '"Open Sans"'}} htmlFor={id} className={this.props.classes.selectLabel}>{text}</InputLabel>
                    <Select
                        key={index}
                        multiple={multiple}
                        value={selectedOptions}
                        onChange={(e) => this.onSelectChange(e)}
                        MenuProps={{className: this.props.classes.selectMenu}}
                        classes={{select: this.props.classes.select}}
                        inputProps={{name: "multipleSelect", id: id}}
                        style={{fontFamily: '"Open Sans"'}}
                    >
                        {options.map((item, subindex) => {
                            return this.createItem(item, subindex);
                        })}
                    </Select>
                </FormControl>
            </GridItem>
        );
    }

    createItem(item, index) {
        const classes = {
            root: this.props.classes.selectMenuItem,
            selected: this.props.classes.selectMenuItemSelectedMultiple
        };
        return <MenuItem style={{fontFamily: '"Open Sans"'}} key={index} classes={classes} value={item.id}>{item.text}</MenuItem>
    }
}

//@ts-ignore
export default withStyles(style)(ACMultiSelect);
