import React from "react";
import EtiqPageCollapse from "../subviews/EtiqPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class Vegan extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <EtiqPageCollapse title={"Vegan"}>
                    <li>Any food in which only plant ingredients have been used</li>
                </EtiqPageCollapse>
            );
        }

        return (
            <EtiqPageCollapse title={"Vegan"}>
                <li>Igasugune toit, mille valmistamiseks on kasutatud ainult taimseid osiseid</li>
            </EtiqPageCollapse>
        );
    }
}