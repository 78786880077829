
import React from "react";

import GridItem from "../../../components/Grid/GridItem";
import GridContainer from "../../../components/Grid/GridContainer";
import {BaseComponent} from "../../_subviews/BaseComponent";

import { Translation } from "../../../core/service/Translation";
import { ViewUtils } from "../../../utils/ViewUtils";

import "../../../assets/css/landing.css";
import logo from "../../../assets/img/allicater/allicater_logo_alt.svg";
import { Routing } from "../../../services/Routing";

class PaymentResultPage extends BaseComponent {

    render() {
        const success = Routing.instance.getUrlParameter("success")! === "true";
        const message = Translation.find(success ? "payment-successful" : "payment-failed");
        return ViewUtils.createCardTemplate("comments", Translation.find("invoice"),
            <div className={"container"}>
                <GridContainer container>
                    <GridItem xs={12} sm={12} md={6}>
                        <h3>{message}</h3>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                        <img src={logo} alt="..."/>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }

}


export default PaymentResultPage;
