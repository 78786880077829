import {Translation} from "../../service/Translation";

export enum County {
    All,
    Harju,
    Hiiu,
    IdaViru,
    J6geva,
    J2rva,
    L22ne,
    L22neViru,
    P6lva,
    P2rnu,
    Rapla,
    Saare,
    Tartu,
    Valga,
    Viljandi,
    V6ru
}

export default class CateringRegion {

    public static LIST: CateringRegion[] = [];

    id?: County;
    text?: string;

    constructor(id: County) {
        this.id = id;
        this.text = Translation.county(id);
    }

    public static load() {
        for (let item in County) {
            if (!isNaN(Number(item))) {
                this.LIST.push(new CateringRegion(parseInt(item) as County));
            }
        }
        // console.log(this.LIST);
    }
}
