import {Networking} from "../../Networking";
import {CurrentUser} from "../../dto/CurrentUser";
import Event from "../../../model/Event";
import MenuItem from "../../../model/MenuItem";

export default class EventHistoryData {

    data?: {events: any[]};

    async load(callback?: any): Promise<any> {

        if (this.data) {
            return this.data;
        }
        let events = (await Networking.instance.fetchEvents({})).events;

        events = Event.mapToPastEvents(events);

        // if (events.length === 0) {
        //     this.data = { events: events };
        //     callback(this.data);
        //     return;
        // }

        this.data = { events: events };
        callback(this.data);

        if (!!events || events.length === 0) {
            return;
        }
        const ids = Event.mapToUniqueEventIds(events);
        const offers = (await Networking.instance.fetchOffers({ eventIds: ids })).offers;

        let userIds;
        if (CurrentUser.instance.isCaterer()) {
            userIds = events.map((event: any) => event.creatorId);
        } else {
            userIds = offers.map((offer: any) => offer.catererId);
        }

        const users = (await Networking.instance.fetchUsers({ userIds: userIds })).users;

        let ratings = (await Networking.instance.fetchRatings({ recipientIds: userIds })).ratings;
        // Remove pending ratings, where score has not been given and defaults to -1
        ratings = ratings.filter((rating: any) => rating.score !== -1);

        users.forEach((user: any) => {
            user.ratings = ratings.filter((rating: any) => rating.recipientId === user.id);
        });

        const menuItems: any[] = [];
        offers.forEach((offer: any) => {
            offer.menuItems = JSON.parse(offer.menuItems);
            offer.menuItems.forEach((item: any) => { menuItems.push(item); });
            offer.caterer = users.find((user: any) => user.id === offer.catererId);
        });

        const items = await MenuItem.parseOfferDataAndDownload(menuItems);

        offers.forEach((offer: any) => {
            offer.menuItems.forEach((item: any) => {
                const downloaded = items.find((existing: any) => existing.id === item.id);
                if (downloaded) {
                    item.data = downloaded;
                }
            });
            const event = events.find((event: any) => event.id === offer.eventId);
            if (event) {
                event.offer = offer;
                event.creator = users.find((user: any) => user.id === event.creatorId);
            }
        });

        this.data = { events: events };
        return this.data;
    }
}
