import React from "react";
import {ViewUtils} from "../../utils/ViewUtils";
import GridItem from "../../components/Grid/GridItem";

import logo from "../../assets/img/allicater/allicater_logo_alt.svg";
import GridContainer from "../../components/Grid/GridContainer";
import Button from "../../components/CustomButtons/Button";
import {Networking} from "../../services/Networking";
import {Translation} from "../../core/service/Translation";
import {BaseComponent} from "../_subviews/BaseComponent";

export default class FeedbackPage extends BaseComponent {

    FEEDBACK_INPUT_ID = "feedback";

    constructor(props: any) {
        super(props);
        this.state = {disabled: true};
    }
    render() {
        const inputProps = {
            multiline: true, rows: 5, onChange: (e: any) => {
                this.setState({disabled: e.target.value?.length === 0});
            }
        };
        return ViewUtils.createCardTemplate("comments", Translation.find("feedback"),
            <div className={"container"}>
                <GridContainer container>
                    <GridItem xs={12} sm={12} md={6}>
                        <h3>{Translation.find("feedback-intro")}</h3>
                        <h5>{Translation.find("feedback-intro-2")}</h5>
                        {ViewUtils.createGridItem(this, this.FEEDBACK_INPUT_ID, 12, inputProps)}
                        <Button color={"primary"} onClick={this.onButtonPress.bind(this)}
                                disabled={this.state.disabled}>
                            {Translation.find("give")}
                        </Button>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                        <img src={logo} alt="..."/>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }

    async onButtonPress() {
        const message = ViewUtils.getInputValueById(this.FEEDBACK_INPUT_ID);
        const result = await Networking.instance.giveFeedback(message);

        if (result.error) {
            this.props.alert(Translation.find("feedback-give-error"), result.error);
        } else {
            this.props.alert(Translation.find("success-header"), Translation.find("feedback-give-success-content"), "#/");
        }
    }
}

