
import React from "react";

import BasePopup from "../_base/BasePopup";
import {BaseComponent} from "../BaseComponent";
import {TermsPopupContentEN} from "./TermsPopupContentEN";
import {TermsPopupContentET} from "./TermsPopupContentET";

import {LocalStorage} from "../../../services/LocalStorage";

export default class TermsPopup extends BaseComponent {

    render() {
        const english = LocalStorage.instance.settings.language === "en";
        return (
            <BasePopup isOpen={this.props.isOpen} title={""} onClose={this.props.onClose}>
                {english ? <TermsPopupContentEN/> : <TermsPopupContentET/>}
            </BasePopup>
        );
    }
}
