import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import {LocalStorage} from "./services/LocalStorage";

import en from "./core/translations/en.json";
import et from "./core/translations/et.json";

i18n.use(initReactI18next).init({
    interpolation: { escapeValue: false },
    lng: LocalStorage.instance.settings.language,
    resources: { en: {translation: en}, et: {translation: et} },
    ns: ['translation'],
    defaultNS: 'translation',
    fallbackLng: 'en',
    preload: ['en', 'et'],
    react: { useSuspense: false, wait: true },
    keySeparator: false
}, () => {
    console.log("Translation load success");
});

export default i18n;
