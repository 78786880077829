
import React from "react";

import {Email, Lock} from "@mui/icons-material";

import Button from "../../../components/CustomButtons/Button.js";

import AuthenticationBox from "./AuthenticationBox";

import {Translation} from "../../../core/service/Translation";
import {Styles} from "../../../styles/Styles";
import { ViewUtils } from "../../../utils/ViewUtils.js";
import { BaseComponent } from "../../_subviews/BaseComponent.js";

export default class LoginBox extends BaseComponent {

    linkStyle(): any {
        return {...Styles.link, width: "100%", textAlign: "center"};
    }

    render() {
        
        if (!this.props.isVisible) {
            return null;
        }

        return(
            <AuthenticationBox 
                body={
                    <div>
                        {ViewUtils.createCustomInput("email", Translation.find("email"), "email", <Email/>)}
                        {ViewUtils.createCustomInput("password", Translation.find("password"), "password", <Lock/>)}
                        <div style={this.linkStyle()} onClick={this.props.onPasswordForget}>
                            {Translation.find("forgot-password")}
                        </div>
                    </div>
                }
                footer={
                    <div>
                        <Button color="primary" onClick={() => {
                            const email = ViewUtils.getInputValueById("email");
                            const password = ViewUtils.getInputValueById("password");
                            this.props.onLogin(email, password);
                        }}>
                            {Translation.find("login")}
                        </Button>
                        <span className={"space-port"}> </span>
                        <Button color="info" className={"boring-button"}  onClick={this.props.onShowRegister}>
                            {Translation.find("sign-up")}
                        </Button>
                    </div>
                }
            />
        );
    }

}
