import React from "react";

import ReactTable from "../../components/ReactTable/ReactTable.js";

import {DateUtils} from "../../core/utils/DateUtils";
import {ViewUtils} from "../../utils/ViewUtils";

import EventDetailPopup from "../_subviews/EventDetailPopup";
import LocalData from "../../services/state/LocalData";
import { BaseComponent } from "../_subviews/BaseComponent";
import { Translation } from "../../core/service/Translation";
import {CurrentUser} from "../../services/dto/CurrentUser";

export default class RequestsPage extends BaseComponent {

    constructor(props: any) {
        super(props);
        this.state = {
            events: [],
            showDescription: false
        }
    }

    componentDidMount = async () => {
        if (CurrentUser.instance.exists()) {
            await this.loadData();
        } else {
            CurrentUser.instance.loaded = async() => {
                await this.loadData();
            }
        }
    };

    async loadData() {
        const data = await LocalData.requests.load();
        this.setState(data);
    }

    render() {
        return ViewUtils.createCardTemplate("party", Translation.find("requests"),
            <div>
                <EventDetailPopup
                    event={this.state.selectedEvent}
                    viewOfferClick={(event: any, offer: any) => {
                        this.props.viewOffer(event, offer);
                    }}
                    makeOfferClick={(event: any) => {
                        this.props.makeOffer(event);
                    }}
                    onClose={() => {
                        this.setState({selectedEvent: undefined})
                    }}/>
                <ReactTable
                    columns={this.columns()}
                    data={this.parseData()}
                />
            </div>
        );
    }

    columns() {
        const result = [];
        if (!ViewUtils.isMobile()) {
            result.push(this.createColumnHeader("#", "id"));
        }

        result.push(this.createColumnHeader(Translation.find("location"), "location"));
        result.push(this.createColumnHeader(Translation.find("date"), "humanReadableDate"));

        if (!ViewUtils.isMobile()) {
            result.push(this.createColumnHeader(Translation.find("budget"), "humanReadableBudget"));
            result.push(this.createColumnHeader(Translation.find("people-attending"), "attendees"));
        }

        result.push(this.createColumnHeader(Translation.find("action"), "action"));

        return result;
    }

    createColumnHeader(header: string, accessor: string) {
        return { Header: header, accessor: accessor };
    }

    parseData() {
        const result: any[] = [];
        if (!this.state.events) {
            return result;
        }

        for (let i = 0; i < this.state.events.length; i++) {
            const event = this.state.events[i];

            event.humanReadableDate = DateUtils.msToLongDateString(event.date);
            event.humanReadableBudget = event.budget + "€";

            const action = () => this.setState({selectedEvent: event});
            event.action = ViewUtils.listActionButton(Translation.find("view-details"), action);
            result.push(event);
        }

        return result;
    }


}
