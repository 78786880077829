
import React from "react";

import GridItem from "../../../components/Grid/GridItem";
import {BaseComponent} from "../../_subviews/BaseComponent";
import {ViewUtils} from "../../../utils/ViewUtils";

import RatingIcon from "../../../assets/img/allicater/icon/reviews.svg";

import {Translation} from "../../../core/service/Translation";
import {CurrentUser} from "../../../services/dto/CurrentUser";

import Rating from "../../../model/Rating";

export class RatingBoxEntry extends BaseComponent {
    render () {
        return (
            <GridItem>
                <p style={{marginBottom: "3px"}}>{this.props.title + ": "}<strong>{this.props.value}</strong></p>
            </GridItem>
        );
    }
}

export default class RatingBox extends BaseComponent {

    addIfExists(rating: any, key: string) {
        let result = 0;
        if (rating[key] !== -1 && rating[key] !== null) {
            result = rating[key];
        }
        return result;
    }

    dividedByAndFixed(total: number, divider: number) {
        return (total / divider).toFixed(1);
    }

    header() {
        if (!CurrentUser.instance.exists()) {
            return "";
        }
        return Translation.find((CurrentUser.instance.isCaterer() ? "customer" : "caterer") + "-score");
    }

    render() {
        const rating = {overall: "-", cleanliness: "-", service: "-", food: "-", score: "-"};
        const ratings = this.props.ratings;

        if (ratings && ratings.length > 0) {

            if (CurrentUser.instance.isCaterer()) {
                let score = 0;
                ratings.forEach((rating: Rating) => {
                    score += this.addIfExists(rating, "score");
                });
                rating.score = this.dividedByAndFixed(score, ratings.length);
            } else {
                let cleanliness = 0;
                let service = 0;
                let food = 0;
                ratings.forEach((rating: Rating) => {
                    cleanliness += this.addIfExists(rating, "cleanliness");
                    service += this.addIfExists(rating, "service");
                    food += this.addIfExists(rating, "food");
                });
                rating.cleanliness = this.dividedByAndFixed(cleanliness, ratings.length);
                rating.service = this.dividedByAndFixed(service, ratings.length);
                rating.food = this.dividedByAndFixed(food, ratings.length);
                rating.overall = this.dividedByAndFixed(cleanliness + service + food, ratings.length * 3);
            }
        }

        const icon = <img src={RatingIcon} className={"squery"} alt={"."}/>;
        return (
            ViewUtils.createCard(icon, this.header(), this.isEmpty(rating) ? this.emptyRatings() : this.ratingEntries(rating))
        );
    }

    emptyRatings() {
        return Translation.find("no-ratings-to-display");
    }

    ratingEntries(rating: any) {
        if (CurrentUser.instance.isCaterer()) {
            return (
                <div style={{width: "94%", margin: "2%", marginTop: 0}}>
                    <RatingBoxEntry title={Translation.find("overall-score")} value={rating.score}/>
                </div>
            );    
        }
        
        return (
            <div style={{width: "94%", margin: "2%", marginTop: 0}}>
                <RatingBoxEntry title={Translation.find("overall-score")} value={rating.overall}/>
                <RatingBoxEntry title={Translation.find("cleanliness-score")} value={rating.cleanliness}/>
                <RatingBoxEntry title={Translation.find("service-score")} value={rating.service}/>
                <RatingBoxEntry title={Translation.find("food-score")} value={rating.food}/>
            </div>
        );
    }

    isEmpty(rating: any): boolean {
        if (CurrentUser.instance.isCaterer()) {
            return this.isValueEmpty(rating, "score");
        }
        return this.isValueEmpty(rating, "overall");
    }
    isValueEmpty(object: any, key: string): boolean {
        return object[key] === "-" || object[key] === "0.0"
    }
}
