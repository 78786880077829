import React from "react";
import EtiqPageCollapse from "../subviews/EtiqPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class Fine extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <EtiqPageCollapse title={"Fine Dining"}>
                    <li>Food with a finer and more detailed finish, designed to provide taste pleasures and eye beauty, not a quick belly filling.</li>
                </EtiqPageCollapse>
            );
        }

        return (
            <EtiqPageCollapse title={"Peen"}>
                <li>Peenema ja detailsema viimistlusega toit, mille eesmärk on pakkuda maitsenaudinguid ja silma ilu, mitte kiiret kõhutäit. Niinimetatud fine dining </li>
            </EtiqPageCollapse>
        );
    }
}