import React from "react";

import CardBody from "../../../components/Card/CardBody";

import {BaseComponent} from "../../_subviews/BaseComponent";
import MenuCategoryButton from "./MenuCategoryButton";
import {Styles} from "../../../styles/Styles";

import {MenuCategories} from "../../../model/constants/MenuCategories";
import LocalData from "../../../services/state/LocalData";
import {Translation} from "../../../core/service/Translation";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardIcon from "../../../components/Card/CardIcon";

import MenuIcon from "../../../assets/img/allicater/icon/categories.svg"

export default class MenuCategoryContainer extends BaseComponent {


    render() {
        return (
            <Card profile>
                <CardHeader color="primary" icon>
                    <CardIcon color="info"><img src={MenuIcon} className={"squery"} alt={"."} /></CardIcon>
                    <h4 style={Styles.cardIconTitle} className={"text-left"}>
                        {Translation.find(MenuCategories.list.tx)}
                    </h4>
                </CardHeader>
                <CardBody profile>

                    {MenuCategories.list.items.map((item, index) => {
                        return <MenuCategoryButton
                            key={index}
                            item={item}
                            badge={LocalData.menu.findMenuItemsFor(item.id).length}
                            onClick={() => this.props.onCategoryClick(item)}
                            className={"text-left"}
                        />;
                    })}
                </CardBody>
            </Card>);
    }
}
