
import React from "react";

import cx from "classnames";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import {BaseComponent} from "../../views/_subviews/BaseComponent";
import {ViewUtils} from "../../utils/ViewUtils";

import withStyles from "@mui/styles/withStyles";
import sidebarStyle from "../../assets/jss/material-dashboard-pro-react/components/sidebarStyle";
import List from "@mui/material/List";

class SidebarTabs extends BaseComponent {

    // verifies if routeName is the one active (in browser input)
    activeRoute = routeName => {
        return window.location.href.indexOf(routeName) > -1 ? "active" : "";
    };

    createLinks(routes) {
        const {classes, color} = this.props;
        return routes?.map((prop, key) => {
            if (prop.redirect) {
                return null;
            }

            const innerNavLinkClasses = classes.collapseItemLink + " " + cx({
                [" " + classes[color]]: this.activeRoute(prop.path)
            });

            const navLinkClasses = classes.itemLink + " " + cx({[" " + classes[color]]: this.activeRoute(prop.path)});
            const itemText = classes.itemText + " " + cx({
                [classes.itemTextMini]: this.props.miniActive
            });
            // console.log("active", prop, this.props.miniActive);
            const collapseItemText = classes.collapseItemText + " " + cx({
                [classes.collapseItemTextMini]: this.props.miniActive
            });
            const itemIcon = classes.itemIcon;
            return (
                <ListItem key={key} className={classes.item + " " + classes.collapseItem}>
                    <div
                        className={navLinkClasses + " " + innerNavLinkClasses}
                        style={{cursor: "pointer"}}
                        onClick={e => {this.props.onRedirect(prop.path);}}
                    >
                        <div className={itemIcon}>{ViewUtils.createIcon(prop.icon)}</div>
                        <ListItemText
                            primary={prop.name}
                            disableTypography={true}
                            className={itemText + " " + collapseItemText}
                            style={{lineHeight: '2em'}}
                        />
                    </div>
                </ListItem>
            );
        });
    };

    render() {
        return <List className={this.props.classes.list}>{this.createLinks(this.props.routes)}</List>
    }
}

export default withStyles(sidebarStyle)(SidebarTabs);
