import ACPDFDocument, {ACPDFOptions, ACPDFTextAlignment} from './wrapper/ACPDFDocument';
import PDFInvoiceData from './model/PDFInvoiceData';

import Coordinate from '../model/Coordinate';
import {InvoiceType} from '../model/enum/InvoiceType';

import FileUtils from '../utils/FileUtils';
import {DateUtils} from '../utils/DateUtils';

import Invoice from '../../model/Invoice';

import {Translation} from "../service/Translation";
import Banking from '../service/Banking';
import {MathUtils} from "../utils/MathUtils";
import {StringUtils} from "../../utils/StringUtils";

export class PDFGenerator {
    
    public static readonly instance = new PDFGenerator()

    private document!: ACPDFDocument;
    private invoice!: PDFInvoiceData;
    
    private padding = {
        small: 8,
        default: 15,
        large: 25
    };
        
    async initialize() {
        this.document = await ACPDFDocument.create()
    }

    async createInvoice(invoice: Invoice, compactMode: boolean) {

        this.invoice = PDFInvoiceData.parse(invoice, compactMode);
        
        const url = "https://dashboard.allicater.com/static/logo192.png";
        let buffer = undefined;
        if (typeof fetch === "undefined") {
            const fetch = require("node-fetch");
            const response = await fetch(url);
            buffer = await response.buffer();
        } else {
            const response = await fetch(url);
            buffer = await response.arrayBuffer();
        }
        
        const image = await this.document.internal().embedPng(buffer);
        const size = 80;

        this.document.draw(image, {
            x: this.document.size().width - (size + this.padding.default + this.padding.small),
            y: this.padding.default + this.padding.small,
            width: size,
            height: size
        });
        
        let x = this.padding.default;
        let y = 3 * this.padding.default;

        y += this.header(x, y);

        y += this.contactInfo(x, y);

        y = this.itemTable(x, y);
        
        this.bankInfo(y, invoice);
    }

    header(x: number, y: number,): number {

        let height = 25;
        
        let text = Translation.find("invoice") + " #" + this.invoice.id;
        this.document.draw(text, {x: x, y: y, size: this.document.font().size.large});
        
        y += height;
        height = 20;

        text = Translation.find("create-date") + ": " + DateUtils.msToLongDateString(this.invoice.releaseDate);
        this.document.draw(text, {x: x, y: y});

        y += height;
        text = Translation.find("due-date") + ": " + DateUtils.msToLongDateString(this.invoice.dueDate);
        this.document.draw(text, {x: x, y: y});

        y += height;
        
        return y;
    }

    contactInfo(x: number, y: number): number {

        const pageWidth = this.document.size().width;
        const boxWidth = pageWidth / 3;

        let header = Translation.find("invoice-to");
        let recipientBoxHeight = this.contactInfoRow(x, y, this.invoice.recipient, header);

        header = Translation.find("invoice-source");
        let senderBoxHeight = this.contactInfoRow(pageWidth - (boxWidth + this.padding.default), y, this.invoice.sender, header);

        const highest = (recipientBoxHeight > senderBoxHeight) ? recipientBoxHeight : senderBoxHeight;
        return highest;
    }

    totalWidth(): number {
        return (this.document.size().width - 2 * this.padding.default);
    }

    drawSeparator(y: number) {
        this.document.line(
            new Coordinate(this.padding.default, y), 
            new Coordinate(this.totalWidth(), y)
        );
    }

    options(x: number, y: number, width: number, index: number, font?: any): ACPDFOptions {
        const options: ACPDFOptions = {x: x + index * width, y: y, width: width, font: font};
        if (index >= 4) {
            options.alignment = ACPDFTextAlignment.Right;
        }
        return options;
    }

    drawRow(row: any[], x: number, y: number, width: number, font?: any) {
        row.forEach((key: string, index: number) => {
            const options = this.options(x, y, width, index, font);
            this.document.draw(key.toString(), options);
        });
    }

    itemTable(x: number, y: number): number {
        
        const height = 20;
        const keys = Translation.findList(["#", "item", "", "", "item-price", "amount", "price"]);
        const width = (this.totalWidth() - this.padding.default) / keys.length;

        this.drawRow(keys, x, y, width, this.document.font().bold);

        this.drawSeparator(y + this.padding.small);        

        y += height + this.padding.small;

        if (this.invoice.type === InvoiceType.Prepayment) {
            const row = [1, Translation.find("invoice-prepayment"), "", "", 1, 1, this.invoice.prepaid.total];
            this.drawRow(row, x, y, width);
            y += height;
        } else {
            if (this.invoice.compactMode) {
                // const row = [item.id, item.data.name, "", "", item.data.price, item.count, item.data.price * item.count];
                const row = [1, Translation.find("minimal-services-ambiguous-title"), "", "", 1, 1, MathUtils.round(this.invoice.price.total + this.invoice.prepaid.total)]
                this.drawRow(row, x, y, width);
                y += height;
            } else {
                this.invoice.menuItems.forEach((item: any) => {
                    const row = [item.id, StringUtils.truncate(item.data.name, 40), "", "", item.data.price, item.count, MathUtils.round(item.data.price * item.count)];
                    this.drawRow(row, x, y, width);
                    y += height;
                });
            }
            
        }
        
        if (this.invoice.type === InvoiceType.Full) {
            const row = ["X", Translation.find("invoice-prepayment"), "", "", 1, 1, -this.invoice.prepaid.total];
            this.drawRow(row, x, y, width);
            y += height;
        }

        if (this.invoice.type === InvoiceType.Allicater) {
            const row = ["X", Translation.find("allicater-fee"), "", "", 
                this.invoice.feePercentage + "%", 1, MathUtils.round(-this.invoice.feeAmount)
            ];
            this.drawRow(row, x, y, width);
            y += height;
        }

        this.drawSeparator(y - this.padding.small);
        
        y += height;
        
        const summary = [
            ["", "", "", "", "", Translation.find("total-without-vat"), this.invoice.price.withoutVat],
            ["", "", "", "", "", Translation.find("vat"), this.invoice.price.vat],
            ["", "", "", "", "", Translation.find("invoice-total"), this.invoice.price.total]
        ];

        summary.forEach((row: any[]) => {
            this.drawRow(row, x, y, width);
            y += height;
        });
        
        this.drawSeparator(y - this.padding.small);

        return y;
    }

    bankInfo(y: number, invoice: Invoice): number {
        let rows;
        if (this.invoice.type === InvoiceType.Allicater) {
            if (invoice.caterer.iban === null) {
                rows = [ {name: "iban", iban: "undefined"} ]
            } else {
                let bankCode = invoice.caterer.iban.slice(4, 6);
                let bankName = Banking.BANK_CODES[bankCode];

                if (bankName === undefined) {
                    bankName = "iban";
                }

                rows = [{name: bankName, iban: invoice.caterer.iban}]
            }
        } else {
            rows = Banking.ACCOUNTS;
        }
        y += this.padding.default;

        rows.forEach((row: any) => {
            let x = this.document.size().width - 300;
            this.document.draw(row.name, {x: x, y: y, font: this.document.font().bold});
            x += 100;
            this.document.draw(row.iban, {x: x, y: y});
            y += 20;
        })
        
        return y;
    }

    contactInfoRow(x: number, y: number, person: any, header: string): number {
        
        const keys = ["name", "email", "officialBusinessName", "regNumber", "vatNumber"];
        
        let rowHeight = 20;
        let localY = y;
        
        this.document.draw(header, {x: x, y: y, font: this.document.font().bold});
        y += rowHeight;

        keys.forEach((key: string) => {
            const value = person[key];
            if (value) {
                this.document.draw(value, {x: x, y: y});
                y += rowHeight;
            }
        });
        return localY;
    }

    async asDataUri(): Promise<any> {
        return await this.document?.internal().saveAsBase64({ dataUri: true });
    }

    async download() {
        const bytes = await this.document?.internal().save();
        FileUtils.download([bytes], "file.pdf");
    }
}
