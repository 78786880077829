import {Networking} from "../../Networking";

export default class MenuData {

    data?: any[];

    async load(callback?: any, force?: boolean): Promise<any> {

        if (this.data && !force) {
            return this.data;
        }
        const response = await Networking.instance.fetchMenu({});
        this.data = response.items;
        return this.data;
    }

    public findMenuItemsFor(categoryId: number) {
        if (!this.data) {
            return [];
        }
        return this.data.filter((item: any) => item.categoryId === categoryId);
    }
}
