
import React from "react";

import SweetAlert from "react-bootstrap-sweetalert";

import styles from "../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import {withStyles} from "@mui/styles";
import {BaseComponent} from "../BaseComponent";

class ACNotifier extends BaseComponent {

    constructor(props) {
        super(props);
        this.classes = props.classes;
    }

    render() {
        if (!this.props.titleKey) {
            return null;
        }

        return (
            <SweetAlert
                style={{display: "block", marginTop: "-100px"}}
                title={this.props.titleKey}
                onConfirm={() => this.props?.confirmed()}
                onCancel={() => this.props?.cancelled()}
                confirmBtnText={this.props.confirmText}
                cancelBtnText={this.props.cancelText}
                confirmBtnCssClass={this.classes.button + " " + this.classes.success}
                cancelBtnCssClass={this.classes.button + " " + this.classes.warn}
                showCancel={!!this.props.cancelled}
            >
                {this.props.contentKey}
            </SweetAlert>
        );
    }
}

export default withStyles(styles)(ACNotifier);
