
import React from "react";

//@ts-ignore
import ChatBox from 'react-chat-plugin';

import {BaseComponent} from "../../_subviews/BaseComponent";
import Offer from "../../../model/Offer";
import {CurrentUser} from "../../../services/dto/CurrentUser";

export default class ChatWindow extends BaseComponent {

    render() {
        if (!this.props.messages || this.props.messages.length === 0) {
            return null;
        }
        return <ChatBox
            messages={this.props.messages ?? []}
            userId={1}
            onSendMessage={(e: any) => this.props.onMessageSend(e)}
            width={'500px'}
            height={'500px'}
            disableInput={this.disableInput()}
        />;
    }

    disableInput() {
        if (!Offer.isPending(this.props.offer)) {
            return true;
        }
        return CurrentUser.instance.isCaterer();
    }
}
