import React from "react";
import EtiqPageCollapse from "../subviews/EtiqPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class Coffee extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <EtiqPageCollapse title={"Coffe"}>
                    <li>A lighter break for meetings with a few snacks or cookies with hot and cold drinks</li>
                </EtiqPageCollapse>
            );
        }

        return (
            <EtiqPageCollapse title={"Kohvipaus"}>
                <li>soojade ja külmade jookidega väheste suupistete või küpsistega kergem vahepaus koosolekutele</li>
            </EtiqPageCollapse>
        );
    }
}