import React from "react";
import {CardContent, Collapse} from '@mui/material';

import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import CardHeader from "../../../components/Card/CardHeader";
import GridItem from "../../../components/Grid/GridItem";
import GridContainer from "../../../components/Grid/GridContainer";

import {BaseComponent} from "../../_subviews/BaseComponent";
import {Styles} from "../../../styles/Styles";

export default class CollapseBlock extends BaseComponent {

    constructor(props: any) {
        super(props);
        this.state = {expanded: false};
    }

    render() {
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card className={"z-margin"}>
                        <CardHeader color="default" onClick={() => {
                            this.setState({expanded: !this.state.expanded})
                        }}>
                            <h5 style={{...Styles.cardTitle}}>{this.props.title}</h5>
                        </CardHeader>

                        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                            <CardBody>
                                <CardContent style={{paddingTop: "0px"}}>
                                    {this.props.content}
                                </CardContent>
                            </CardBody>
                        </Collapse>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}

