
import React from "react";
import HelpPageCollapse from "../subviews/HelpPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class Dashboard extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <HelpPageCollapse title={"Dashboard"}>
                    <li> The Dashboard has a selection of different quick links and widgets for a better overview </li>
                </HelpPageCollapse>
            );
        }

        return (
            <HelpPageCollapse title={"Töölaud"}>
                <li>Töölauale on koondatud valik erinevatest kiirlinkidest, mis suunavad rakenduse erinevatesse osadesse</li>
            </HelpPageCollapse>
        );
    }
}
