
import React from "react";
import HelpPageCollapse from "../subviews/HelpPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class FinishProfileCaterer extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <HelpPageCollapse title={"Finishing profile (caterer)"}>
                    <li> The profile is divided into three sections:
                        <ol>
                            <li> Company details </li>
                            <li> Catering type and services </li>
                            <li> Logo </li>
                        </ol>
                    </li>
                    <li> A company profile includes information such as company name, official business name,
                        phone number, contact email address, city where the company is located, the service area
                        within which you are ready to provide catering, minimum and maximum number of people
                        who you can provide catering, and minimum catering price. Company registry code,
                        VAT number (if available) and bank account.
                        You can also add a brief description of your services. </li>
                    <li> Under the Catering type, you should first select the category in which you operate.
                        You can also select several. For example, if a restaurant has the ability
                        to make outdoor catering or there is a foodtruck. You can choose what events you are
                        willing to attend, the types of food offered, the types of service, different
                        drinks and more (such as dishes, glasses, tables, chairs, sheets, etc.) </li>
                    <li> It's worth noting that customers are brought together by catering providers based on
                        algorithms that take into account the data and offers entered to avoid requests
                        that are not appropriate for the caterer. For example, if a customer wants sushi for catering,
                        but you're a German restaurant, no such request will be sent to your business. </li>
                </HelpPageCollapse>
            );
        }

        return (
            <HelpPageCollapse title={"Profiili lõpetamine (cateringi pakkuja)"}>
                <li>Profiil on jagatud kolmeks osaks:
                    <ol>
                        <li>Ettevõtte andmed</li>
                        <li>Cateringi tüüp ja teenused</li>
                        <li>Logo</li>
                    </ol>
                </li>
                <li>Ettevõtte profiil sisaldab andmeid nagu ettevõtte nimi, ametlik ärinimi, telefoninumber,
                    kontakt e-posti aadress, linna kus ettevõte asub, teeninduspiirkonda mille piires olete
                    valmis cateringi pakkuma, minimaalset ja maksimaalset inimeste arvu kelle suudetakse cateringi
                    pakkuda ning minimaalset cateringi hinda. Ettevõtte registrikoodi, käibemaksukohuslase
                    numbrit (kui see on olemas) ja pangakontot. Samuti saab lisada lühikirjelduse oma teenustest.</li>
                <li>Catering tüübi all tuleks esmalt valida millises kategoorias tegutsetakse.
                    Valida võib ka mitu. Näiteks kui restoranil on võimekus teha väljas cateringe või olemas foodtruck.
                    Saab valida millistel üritustel ollakse valmis osalema, pakutava toidu tüüpe, teeninduse tüüpe,
                    erinevaid jooke ja muud (nagu näiteks nõud, klaasid, lauad, toolid, linad jm)</li>
                <li>Tasub tähele panna, et kliendid viiakse cateringi pakkujatega kokku algoritmide alusel,
                    mis arvestavad sisestatud andmeid ja pakkumisi, et vältida soove, mis catererile ei sobi.
                    Näiteks kui klient soovib cateringiks sushit, aga olete Saksa restoran,
                    siis sellist päringut teie ettevõttele ei saadeta.</li>
            </HelpPageCollapse>
        );
    }
}
