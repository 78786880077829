import React from "react";

import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import GridItem from "../../components/Grid/GridItem";

import styles from "../../assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import {Styles} from "../../styles/Styles";

import {BaseComponent} from "../_subviews/BaseComponent";
import CateringTypeSelector from "../_subviews/CateringTypeSelector";
import {Translation} from "../../core/service/Translation";

export default class ProfileCateringTypesComponent extends BaseComponent {

    render() {
        if (!this.props.isVisible) {
            return null;
        }
        return (
            <GridItem xs={12} sm={12} md={8}>
                <Card>
                    <CardHeader color="info" icon>
                        <CardIcon color="info">
                            <img src={require("../../assets/img/allicater/icon/catering-type.svg")} alt={"."}/>
                        </CardIcon>
                        <h4 style={Styles.cardIconTitle}>
                            {Translation.find("type-of-business")} - <small>{Translation.find("edit-what-offer")}</small>
                        </h4>
                    </CardHeader>
                    <CardBody>
                        <CateringTypeSelector/>
                        <Button color="primary" style={styles.updateProfileButton} type={"submit"} onClick={() => {
                            this.props.onSave();
                        }}>
                            {Translation.find("save")}
                        </Button>
                    </CardBody>
                </Card>
            </GridItem>
        );
    }
}
