import React from "react";
import EtiqPageCollapse from "../subviews/EtiqPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class Full extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <EtiqPageCollapse title={"Full Service"}>
                    <li>Guests sit in their seats and the waiters serve drinks and all meals to each guest separately. You can choose partial full service, full service or protocol service</li>
                </EtiqPageCollapse>
            );
        }

        return (
            <EtiqPageCollapse title={"Täisteenindus"}>
                <li>Külalised istuvad oma kohtadel ning teenindajad serveerivad igale külalisele eraldi nii joogid kui ka kõik toidukäigud. Võimalik valida osaline täisteenindus, täisteenindus või protokollteenindus</li>
            </EtiqPageCollapse>
        );
    }
}