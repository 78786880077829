import {Networking} from "../../Networking";
import InvoiceData from "./InvoiceData";
import Invoice from "../../../model/Invoice";
import User from "../../../model/User";

export default class AdminData {

    data?: {
        invoices?: Invoice[],
        users?: User[]
    };

    async load(callback?: any): Promise<any> {

        if (this.data) {
            return this.data;
        }
        const invoiceResponse = await Networking.instance.fetchAdmin({invoices: true});
        const invoices = invoiceResponse.invoices;

        invoices.forEach((invoice: Invoice) => {
            InvoiceData.unpackJsonDataFor(invoice);
        });

        const userResponse = await Networking.instance.fetchAdmin({users: true});
        const users = userResponse.users;

        this.data = {
            invoices: invoices,
            users: users
        };

        return this.data;
    }
}
