
import React from "react";

import BasePopup from "../_base/BasePopup";
import {BaseComponent} from "../BaseComponent";
import {PrivacyPopupContentEN} from "./PrivacyPopupContentEN";
import {PrivacyPopupContentET} from "./PrivacyPopupContentET";

import {LocalStorage} from "../../../services/LocalStorage";

export default class PrivacyPopup extends BaseComponent {

    render() {
        const english = LocalStorage.instance.settings.language === "en";
        return (
            <BasePopup isOpen={this.props.isOpen} title={""} onClose={this.props.onClose}>
                {english ? <PrivacyPopupContentEN/> : <PrivacyPopupContentET/>}
            </BasePopup>
        );
    }
}
