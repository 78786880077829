
import React, {CSSProperties} from "react";

import EventDetailContainer from "../../_subviews/EventDetailContainer";
import {BaseComponent} from "../../_subviews/BaseComponent";

import {NewEvent} from "../../../services/NewEvent";
import {Translation} from "../../../core/service/Translation";

const style = {
    infoText: {
        margin: "10px 0 30px",
        textAlign: "center"
    } as CSSProperties
};

export default class Step3 extends BaseComponent {

    onChange(e: any) {
        if (this.props.callback) {
            this.props.callback({type: "save", tab: "additional", data: e.target.value});
        }
    }

    render() {
        return (
            <div>
                <h4 style={style.infoText}>{Translation.find("event-overview-info-subheader")}</h4>
                <EventDetailContainer event={NewEvent.instance.findData()} />
            </div>
        );
    }
}
