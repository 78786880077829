import {LocalStorage} from "./LocalStorage";

export enum ApiEndpoints {
    User = "user",
    Event = "event",
    Offer = "offer",
    Invoice = "invoice",
    Menu = "menu",
    Feedback = "feedback",
    Message = "message",
    Rating = "rating",
    Payment = "payment",
    Admin = "admin"
}

class PaymentNetworking {

    networking: Networking;
    constructor(networking: Networking) {
        this.networking = networking;
    }

    public async fetchSwed(data: any) {
        const response = await this.fetch("swed/pay", data);
        return await response.json();
    }
    public async fetch(endpoint: string, data: any, json = false) {
        return await this.networking.fetchWithHeaders(ApiEndpoints.Payment, endpoint, data);
    }
}

export class Networking {
    static instance = new Networking();

    LOCAL_PATH = "http://localhost:8070/";
    LIVE_PATH = "https://api.allicater.com/";
    STAGING_PATH = "https://staging-api.allicater.com/";

    isLocal() {
        const origin = window.location.origin;
        return origin.includes("3000")
    }

    basePath(): string {

        const origin = window.location.origin;
        if (this.isLocal()) {
            // return this.LOCAL_PATH;
            // return this.LIVE_PATH;
            return this.STAGING_PATH;
        } else if (origin.includes("staging")) {
            return this.STAGING_PATH;
        }

        return this.LIVE_PATH;
    }

    sessionId = LocalStorage.instance.getSessionToken();

    payment: PaymentNetworking;

    constructor() {
        this.payment = new PaymentNetworking(this);
    }

    /**
     * Requests
     */
    public async logIn(email: string, password: string) {
        return await this.post(ApiEndpoints.User, "log-in", {"email": email, "password": password});
    }

    public async register(name: string, email: string, password: string, regNumber: string) {
        const data = {"name": name, "email": email, "password": password, "regNumber": regNumber};
        return await this.post(ApiEndpoints.User, "register", data);
    }

    public async resetPassword(email: string) {
        return await this.post(ApiEndpoints.User, "reset-password", {email: email});
    }
    public async updatePassword(verificationKey: string, password: string) {
        const data = {verificationKey: verificationKey, password: password};
        return await this.post(ApiEndpoints.User, "update-password", data);
    }

    public async verify(id: number) {
        return await this.post(ApiEndpoints.User, "verify", {verificationKey: id});
    }

    public async updateEvent(data: {}) {
        return await this.post(ApiEndpoints.Event, "update", data);
    }

    public async createEvent(data: {}) {
        return await this.post(ApiEndpoints.Event, "create", data);
    }
    public async deleteEvent(data: {}) {
        return await this.post(ApiEndpoints.Event, "delete", data);
    }

    public async addMenuItem(data: {}) {
        return await this.post(ApiEndpoints.Menu, "add-item", data);
    }

    public async updateMenuItem(id: number, data: any) {
        data.id = id;
        return await this.post(ApiEndpoints.Menu, "update-item", data);
    }

    public async deleteMenuItem(id: number) {
        const data = { itemId: id };
        return await this.post(ApiEndpoints.Menu, "delete-item", data);
    }

    public async fetchMenu(data: any) {
        const response = await this.fetchWithHeaders(ApiEndpoints.Menu, "find", data);
        return await response.json();
    }

    public async fetchEvents(data?: any) {
        let endpoint = "list";
        if (data) {
            endpoint = "find";
        }
        const response = await this.fetchWithHeaders(ApiEndpoints.Event, endpoint, data);
        return await response.json();
    }

    public async fetchUserInfo() {
        const response = await this.fetchWithHeaders(ApiEndpoints.User, "me");
        return await response.json();
    }

    public async fetchInvoices(data: any) {
        const response = await this.fetchWithHeaders(ApiEndpoints.Invoice, "find", data);
        return await response.json();
    }

    public async fetchUsers(data: any) {
        const response = await this.fetchWithHeaders(ApiEndpoints.User, "find", data);
        return await response.json();
    }

    public async fetchRatings(data: any) {
        const response = await this.fetchWithHeaders(ApiEndpoints.Rating, "find", data);
        return await response.json();
    }

    public async giveRating(data: any) {
        return await this.post(ApiEndpoints.Rating, "give", data);
    }

    public async fetchOffers(data: any) {
        const response = await this.fetchWithHeaders(ApiEndpoints.Offer, "find", data);
        return await response.json();
    }

    public async fetchMenuItems(data: any) {
        const response = await this.fetchWithHeaders(ApiEndpoints.Menu, "find", data);
        return await response.json();
    }

    public async fetchMessages(data: any) {
        const response = await this.fetchWithHeaders(ApiEndpoints.Message, "find", data);
        return await response.json();
    }

    async fetchAdmin(data: any) {
        const response = await this.fetchWithHeaders(ApiEndpoints.Admin, "find", data);
        return await response.json();
    }

    public async updateProfile(data: {}) {
        return await this.post(ApiEndpoints.User, "finish-profile", data);
    }

    public async makeOffer(data: any) {
        return await this.post(ApiEndpoints.Offer, "make", data);
    }
    public async updateOffer(data: any) {
        return await this.post(ApiEndpoints.Offer, "update", data);
    }
    public async acceptOffer(data: any) {
        return await this.post(ApiEndpoints.Offer, "accept", data);
    }
    public async rejectOffer(data: any) {
        return await this.post(ApiEndpoints.Offer, "reject", data);
    }
    public async sendMessage(data: any) {
        return await this.post(ApiEndpoints.Message, "send", data);
    }
    public async giveFeedback(message: string) {
        return await this.post(ApiEndpoints.Feedback, "give", { message: message });
    }
    public async emailPdf(invoiceId: number, compact: boolean) {
        const data = {invoiceId: invoiceId, compact: compact, lng: LocalStorage.instance.settings.language};
        return await this.fetchWithHeaders(ApiEndpoints.Invoice, "email", data);
    }

    /**
     * Utils
     */
    private async post(enumeration: ApiEndpoints, method: string, content: any) {
        const options = { method: "POST", body: JSON.stringify(content), headers: this.getDefaultHeaders() };
        const response = await fetch(this.getUrl(enumeration, method), options);

        return await response.json();
    }

    public async fetchWithHeaders(enumeration: ApiEndpoints, endpoint: string, data: any = undefined) {
        let params;
        if (data) {
            params = "?" + new URLSearchParams(data);
        } else {
            params = "";
        }
        return await fetch(this.getUrl(enumeration, endpoint) + params, { headers: this.getDefaultHeaders() });
    }

    private getUrl(enumeration: ApiEndpoints, endpoint: string): string {
        return this.basePath() + enumeration + "/" + endpoint;
    }

    private getDefaultHeaders() {
        const result = new Headers();
        result.set("Content-Type", "application/json");
        result.set("Accept", "application/json, text/plain, */*");
        result.set(LocalStorage.instance.TOKEN_KEY, LocalStorage.instance.getSessionToken());

        return result;
    }

    public refreshToken() {
        this.sessionId = LocalStorage.instance.getSessionToken();
    }
}
