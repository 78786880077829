import {Networking} from "../../Networking";
import {CurrentUser} from "../../dto/CurrentUser";
import Event from "../../../model/Event";

export default class RequestsData {

    data?: {events: any[]};

    async load(callback?: any): Promise<any> {

        if (this.data) {
            return this.data;
        }
        const response = await Networking.instance.fetchEvents();
        let events = Event.filterUpcoming(response.events);

        const ids = events.map((item: any) => item.id);

        const offerResponse = await Networking.instance.fetchOffers({"eventIds": ids});
        events.forEach((event: any) => {
            event.offers = offerResponse.offers.filter((offer: any) => offer.eventId === event.id);
        });

        events = Event.toActiveRequests(events);
        events = Event.filterByCategory(events, CurrentUser.instance.get());

        this.data = {events: events};
        return this.data;
    }
}
