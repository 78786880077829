
import React from "react";

import ReactTable from "../../components/ReactTable/ReactTable";
import {BaseComponent} from "../_subviews/BaseComponent";

import {Translation} from "../../core/service/Translation";

import {ViewUtils} from "../../utils/ViewUtils";
import { Networking } from "../../services/Networking";

import Event from "../../model/Event";
import { DateUtils } from "../../core/utils/DateUtils";

export default class PreviousEventList extends BaseComponent {

    constructor(props: any) {
        super(props);
        this.state = {
            events: []
        }
    }

    columns() {
        return [
            ViewUtils.createColumnHeader("#", "id"),
            ViewUtils.createColumnHeader(Translation.find("location"), "location"),
            ViewUtils.createColumnHeader(Translation.find("date"), "humanReadableDate"),
            ViewUtils.createColumnHeader(Translation.find("date"), "humanReadableBudget"),
            ViewUtils.createColumnHeader(Translation.find("action"), "action"),
        ];
    }

    parseData() {
        const result = this.state.events;

        result.forEach((event: any) => {
            event.humanReadableDate = DateUtils.msToLongDateString(event.date);
            event.humanReadableBudget = event.budget + "€ / " + event.attendees;
            const action = () => this.setState({ selectedEvent: event });
            event.action = ViewUtils.listActionButton(Translation.find("use"), action);
        });

        return result;
    }

    async componentDidMount() {

        let events = (await Networking.instance.fetchEvents({})).events;
        events = Event.mapToPastEvents(events);
        this.setState({events: events});
    }
    render() {

        if (!this.props.visible) {
            return null;
        }

        const table = <ReactTable columns={this.columns()} data={this.parseData()}/>
        return ViewUtils.createCardTemplate("past-gigs", Translation.find("pick-event-fill-data"), table);
        
    }
}