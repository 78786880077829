
import React from "react";
import HelpPageCollapse from "../subviews/HelpPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class OfferView extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <HelpPageCollapse title={"View and accept offers"}>
                    <li> Offers for your event will appear on your dashboard and also accessible from the My Offers menu </li>
                    <li> Offers can be accepted, rejected, or the caterer can be asked to make changes </li>
                </HelpPageCollapse>
            );
        }

        return (
            <HelpPageCollapse title={"Pakkumiste vaatamine ja valimine"}>
                <li>Teie üritusele tehtud pakkumised ilmuvad töölauale ja ligipääsetavad ka Minu pakkumised menüüst</li>
                <li>Pakkumisi saab vastu võtta, tagasi lükata või paluda catereril nendes muudatusi teha</li>
            </HelpPageCollapse>
        );
    }
}
