
import React from "react";

import {FiberManualRecord} from "@mui/icons-material";
import {ViewUtils} from "../../utils/ViewUtils";
import {LocalStorage} from "../../services/LocalStorage";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

import styles from "../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import i18n from "i18next";

import { BaseComponent } from "../_subviews/BaseComponent";
import { Translation } from "../../core/service/Translation";

export default class SettingsPage extends BaseComponent {

    constructor(props: any) {
        super(props);
        this.state = {
            selectedValue: LocalStorage.instance.settings.language
        };
    }

    async handleChange(e: any) {
        const language = e.target.value;
        LocalStorage.instance.updateLanguage(language);
        await i18n.changeLanguage(language);
        this.setState({ selectedValue: language });
        this.props.languageUpdate();
    }

    render() {
        return ViewUtils.createCardTemplate("settings", Translation.find("settings"), <div>
            <h5>{Translation.find("language")}</h5>
            {this.createContent("Eesti keel", "et")}
            {this.createContent("English", "en")}
        </div>);
    }

    createContent(label: string, value: string) {
        const style = {...styles.label, ...styles.labelRoot, fontWeight: 500};
        return <div>
            <FormControlLabel control={this.radio(value)} style={style} label={label}/>
        </div>;
    }

    radio(value: any) {
        return <Radio
            value={value}
            onChange={this.handleChange.bind(this)}
            checked={this.state.selectedValue === value}
            style={{...styles.radio, ...styles.radioRoot}}
            icon={<FiberManualRecord style={styles.radioUnchecked}/>}
            checkedIcon={<FiberManualRecord style={styles.radioChecked}/>}
        />
    }

}
