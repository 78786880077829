import React from "react";
import classNames from "classnames";

// @mui/material components
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Grow from "@mui/material/Grow";
import Hidden from "@mui/material/Hidden";
import Popper from "@mui/material/Popper";
import Divider from "@mui/material/Divider";

// @mui/icons-material
import Person from "@mui/icons-material/Person";

// core components
import Button from "../CustomButtons/Button.js";

import { withStyles } from '@mui/styles';
import styles from "../../assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";

import { Translation } from "../../core/service/Translation";

class AdminNavbarLinks extends React.Component {

    constructor(props) {
        super(props);
        this.classes = props.classes;

        this.dropdownItem = classNames(this.classes.dropdownItem, this.classes.primaryHover);
        this.state = {
            openNotification: null,
            openProfile: null
        }

    }

    handleClickNotification = event => {
        if (this.state.openNotification && this.state.openNotification.contains(event.target)) {
            this.setState({ openNotification: null });
        } else {
            this.setState({ openNotification: event.currentTarget });
        }
    };
    handleCloseNotification = () => {
        this.setState({ openNotification: null });
    };

    handleClickProfile = event => {
        if (this.state.openProfile && this.state.openProfile.contains(event.target)) {
            this.setState({ openProfile: null });
        } else {
            this.setState({ openProfile: event.currentTarget });
        }
    };
    handleCloseProfile = () => {
        this.setState({ openProfile: null })
    };

    render() {
        const classes = this.classes;
        const managerClasses = classNames({ [classes.managerClasses]: true });

        return (
            <div>
                <Hidden mdDown>
                    <div className={managerClasses}>
                        <Button
                            color="transparent"
                            aria-label="Person"
                            justIcon
                            aria-owns={this.state.openProfile ? "profile-menu-list" : null}
                            aria-haspopup="true"
                            onClick={this.handleClickProfile}
                            className={classes.buttonLink}
                            muiClasses={{ label: "" }}
                        >
                            <Person className={classes.headerLinksSvg + " " + classes.links} />
                            <Hidden mdUp implementation="css">
                                <span onClick={this.handleClickProfile} className={classes.linkText}>{"Profile"}</span>
                            </Hidden>
                        </Button>
                        {this.createUserPopper(classes)}
                    </div>
                </Hidden>
            </div>
        );
    }

    createNotificationPopper(classes) {
        return (
            <Popper
                open={Boolean(this.state.openNotification)}
                anchorEl={this.state.openNotification}
                transition
                disablePortal
                placement="bottom"
                className={classNames({
                    [classes.popperClose]: !this.state.openNotification,
                    [classes.popperResponsive]: true,
                    [classes.popperNav]: true
                })}
            >
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps} id="notification-menu-list" style={{ transformOrigin: "0 0 0" }}>
                        <Paper className={classes.dropdown}>
                            <ClickAwayListener onClickAway={this.handleCloseNotification}>
                                <MenuList role="menu">
                                    {/*{this.createNotificationContent("Mike John responded to your email")}*/}
                                    {/*{this.createNotificationContent("You have 5 new tasks")}*/}
                                    {/*{this.createNotificationContent("You're now friend with Andrew")}*/}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>);
    }

    createUserPopper(classes) {
        return (
            <Popper
                open={Boolean(this.state.openProfile)}
                anchorEl={this.state.openProfile}
                transition
                disablePortal
                placement="bottom"
                className={classNames({
                    [classes.popperClose]: !this.state.openProfile,
                    [classes.popperResponsive]: true,
                    [classes.popperNav]: true
                })}
            >
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps} id="profile-menu-list" style={{ transformOrigin: "0 0 0" }}>
                        <Paper className={classes.dropdown}>
                            <ClickAwayListener onClickAway={this.handleCloseProfile}>
                                <MenuList role="menu">
                                    {this.createUserContent(Translation.find("profile"), this.props.profileClick)}
                                    {this.createUserContent(Translation.find("settings"), this.props.settingsClick)}
                                    <Divider />
                                    {this.createUserContent(Translation.find("log-out"), this.props.logoutClick)}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        );
    }

    createNotificationContent(text) {
        return <MenuItem onClick={this.handleCloseNotification} className={this.dropdownItem}>{text}</MenuItem>
    }

    createUserContent(text, action) {
        return <MenuItem onClick={() => {
            this.setState({ openProfile: null });
            action();
        }} className={this.dropdownItem}>{text}</MenuItem>
    }
}

export default withStyles(styles)(AdminNavbarLinks);

