import {CateringEnums} from "../model/constants/CateringEnums";
import {DateUtils} from "../core/utils/DateUtils";
import {ViewUtils} from "../utils/ViewUtils";
import {LocalStorage} from "./LocalStorage";

export class NewEvent {

    public static instance = new NewEvent();

    basicInfoFieldIds: string[] = [];

    public findBasicInfo() {
        const result: any = {};

        this.basicInfoFieldIds.forEach(id => {
            const element = document.getElementById(id);
            if (element) {
                // @ts-ignore
                const input = element.value;
                const number = Number(input);
                let value = number ? number : input;
                let key = id;
                // TODO date & time unification

                if (key === "time") {
                    return;
                } else if (key === "date") {
                    // Turn DATE field value to milliseconds
                    value = DateUtils.parseDateFieldValue(value);
                    // Add converted TIME field value
                    // @ts-ignore
                    value += DateUtils.parseTimeFieldValue(ViewUtils.getInputValueById("time"));
                }
                key = NewEvent.remapSpecialCases(key);
                result[key] = value;
            }

        });
        return result;
    }

    public getDateFieldValue() {
        return this.findStoredValueForId("date");
    }
    
    public static remapSpecialCases(input: string) {
        if (input === "how-many-children") {
            return "children";
        } else if (input === "people-attending") {
            return "attendees";
        } else if (input === "additional-info") {
            return "additionalInfo";
        }
        return input;
    }

    public static unmapSpecialCases(input: string) {
        if (input === "children") {
            return "how-many-children";
        } else if (input === "attendees") {
            return  "people-attending";
        } else if (input === "additionalInfo") {
            return  "additional-info"
        }
        return input;
    }

    public findCateringInfo() {
        const result: any = {};

        CateringEnums.subtypes.forEach(type => {
            result[type.id] = type.selectedValues.toString();
        });

        result[CateringEnums.MULTI_CATEGORY_ID] = CateringEnums.checkedBoxIndexes().toString();
        return result;
    }

    public findAdditionalInfo() {
        const result: any = {};
        const element = document.getElementById("additional-info");
        if (element) {
            // @ts-ignore
            const input = element.value;
            result["additionalInfo"] = input;
        }

        return result;
    }

    public findData() {
        return { ...this.findBasicInfo(), ...this.findCateringInfo(), ...this.findAdditionalInfo() }
    }

    findStoredValueForId(id: string) {
        const data = LocalStorage.instance.loadEventData();
        if (data) {
            return JSON.parse(data)[NewEvent.remapSpecialCases(id)];
        }
    }

    findStoredCateringInfo() {
        const data = LocalStorage.instance.loadEventData();
        const result: any = {};

        if (data) {
            const json = JSON.parse(data);
            result[CateringEnums.MULTI_CATEGORY_ID] = CateringEnums.explode(json[CateringEnums.MULTI_CATEGORY_ID]);

            CateringEnums.subTypeIds().forEach(id => {
                result[id] = CateringEnums.explode(json[id]);
            })
        }

        return result;
    }

    public saveData(data?: any) {
        LocalStorage.instance.updateEventData(data ?? this.findData());
    }

    public clearData() {
        LocalStorage.instance.updateEventData(null);
    }
}
