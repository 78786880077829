import React from "react";
import EtiqPageCollapse from "../subviews/EtiqPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class Catering extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <EtiqPageCollapse title={"Catering"}>
                    <li>Option to do an event outside the caterers premises</li>
                </EtiqPageCollapse>
            );
        }

        return (
            <EtiqPageCollapse title={"Catering"}>
                <li>Soov teha üritus väljaspool toitlustusettevõtte ruume</li>
            </EtiqPageCollapse>
        );
    }
}
