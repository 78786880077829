import React from "react";

import Button from "../../../components/CustomButtons/Button";
import {BaseComponent} from "../../_subviews/BaseComponent";

import {ViewUtils} from "../../../utils/ViewUtils";
import {StringUtils} from "../../../utils/StringUtils";
import {Translation} from "../../../core/service/Translation";
import AuthenticationBox from "./AuthenticationBox";
import { Email } from "@mui/icons-material";

export default class PasswordReset extends BaseComponent {

    constructor(props: any) {
        super(props);
        this.state = {
            disabled: true,
            text: "",
            error: false
        };
    }

    inputProps() {
        return {
            onChange: (e: any) => {
                const text = e.target.value;
                this.setState({disabled: StringUtils.isNullOrEmpty(text), text: text,});
            }
        };
    }

    onClick(e: any) {
        if (!StringUtils.isValidEmail(this.state.text)) {
            this.setState({error: true});
        } else {
            this.setState({error: false});
            this.props.onSubmit(this.state.text);
        }
    }

    render() {

        if (!this.props.visible) {
            return null;
        }
        return (
            <AuthenticationBox 
                body={
                    <div>
                        {ViewUtils.createCustomInput("email", "Email", "email", <Email/>, this.inputProps())}
                    </div>
                }
                footer={
                    <div>
                        <Button color={"primary"} onClick={this.onClick.bind(this)} disabled={this.state.disabled}>
                            {Translation.find("reset")}
                        </Button>
                        <span className={"space-port"}> </span>
                        <Button color="info" className={"boring-button"}  onClick={this.props.showLogin}>
                                {Translation.find("login")}
                        </Button>
                    </div>
                }
            />
        );
    }
}
