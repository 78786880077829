
export default class FileUtils {
    
    public static download(bytes: any, name: string) {
        var a = document.createElement("a");
        document.body.appendChild(a);
        //@ts-ignore
        a.style = "display: none";
        const blob = new Blob(bytes, {type: "octet/stream"});
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = name;
        a.click();
        window.URL.revokeObjectURL(url);
    }
}