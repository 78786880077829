import AVATAR_URL from "../assets/img/allicater/allicater_logo.svg";
import {CurrentUser} from "../services/dto/CurrentUser";
import { OfferState } from "../core/model/enum/OfferState";
import Offer from "./Offer";
import User from "./User";

export default class Message {

    static ALLICATER_ID = 2;
    static USER_ID = 1;

    static HEADER_TEXT = "comments";

    public offerId: number = -1;
    public catererId: number = -1;
    public customerId: number = -1;

    public timestamp: string = "";
    public text: string = "";

    public isSentByCustomer: boolean = false;

    private static notification(text: string) {
        return {text: text, type: 'notification'};
    }
    private static text(text: string) {
        return {author: {id: this.ALLICATER_ID, avatarUrl: AVATAR_URL}, text: text, type: 'text'};
    }

    static initial(data: any, translate: any): any[] {
        return [
            this.notification(translate(this.HEADER_TEXT)),
            this.text(translate(data.isCaterer ? "caterer-starting-message" : "customer-starting-message"))
        ];
    }

    static catererInfo(offer: Offer, caterer: User, translate: any): any {
        if (offer.status !== OfferState.Accepted) {
            return {};
        }
        let text = `${translate("accepted-offer-caterer-info-share-header")}\n\n`;
        text += `${translate("name")}: ${caterer.name ?? "–"}\n`;
        text += `${translate("phone-number")}: ${caterer.phone ?? "–"}\n`;
        text += `${translate("email")}: ${caterer.email ?? "–"}\n`;
        return this.text(text);
    }

    public static create(message: any, creator?: any): any {
        return {
            author: {
                username: message.isSentByCustomer ? creator.name : "",
                id: message.isSentByCustomer ? this.USER_ID : this.ALLICATER_ID,
                avatarUrl: message.isSentByCustomer ? CurrentUser.instance.avatarUrlOf(creator) : AVATAR_URL,
            },
            text: message.text,
            type: 'text',
        }
    }

    public static createAll(json: any, creator: any): any[] {
        const result: any[] = [];

        json.forEach((child: any) => {
            result.push(this.create(child, creator));
        });
        return result;
    }
}
