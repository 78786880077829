import React from "react";

import Table from "../../../components/Table/Table";
import withStyles from "@mui/styles/withStyles";
import style from "../../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";

import {BaseComponent} from "../BaseComponent";

class ACTable extends BaseComponent {
    render() {
        const {classes} = this.props;

        return (
            <Table
                striped
                tableHead={this.props.head}
                tableData={this.props.rows}
                rowHints={this.props.rowHints}
                customCellClasses={[classes.center, classes.right, classes.right]}
                customClassesForCells={[0, 5, 6]}
                customHeadCellClasses={[classes.center, classes.right, classes.right]}
                customHeadClassesForCells={[0, 5, 6]}
            />
        );
    }
}

//@ts-ignore
export default withStyles(style)(ACTable);
