
import {Networking} from "../services/Networking";

import defaultMenuItem from "../assets/img/allicater/icon/no-food-image.svg";
import {InvoicePrice} from "../core/model/InvoicePrice";
import { JSONUtils } from "../core/utils/JSONUtils";

export default class MenuItem {
    
    public catererId: number = -1;

    public categoryId: number = -1;

    public name: string = "";
    public description: string = "";

    public imageUrl: string = "";

    public dairy: boolean = false;
    public gluten: boolean = false;
    public local: boolean = false;
    public nut: boolean = false;
    public sugar: boolean = false;
    public vegan: boolean = false;

    public price: number = -1;
    public quantity: number = -1;
    public unit: number = -1;

    public vat: number = -1;

    public  static defaultImage() {
        return defaultMenuItem;
    }

    public static avatar(item: any) {
        if (!item.imageUrl) {
            return defaultMenuItem;
        }
        return Networking.instance.basePath() + item.imageUrl;
    }

    public static async parseOfferDataAndDownload(items: string[]) {
        const ids: number[] = [];
        items.forEach((item: any) => {
            const id = item.id;
            if (ids.indexOf(id) === -1 && !isNaN(id)) {
                ids.push(id);
            }
        });

        const result = await Networking.instance.fetchMenuItems({ids: ids});
        return result.items;
    }

    static calculatePrice(menuItems: any, vat: boolean, prepayment: boolean): InvoicePrice {

        let result = new InvoicePrice();

        if (!menuItems) {
            return result;
        }
        menuItems.forEach((item: any) => {
            if (item.data) {
                result.add(item);
            }
        });
        if (prepayment) {
            result.toPrepayment();
        }
        result.calculateVat(vat);
        result.round();

        return result;
    }

    static findOfferedItemsOfData(data: any, json?: string) {
        const result: any[] = [];
        if (!json) {
            return result;
        }
        
        const items = JSON.parse(JSONUtils.escape(json));

        items.forEach((item: any) => {
            const existing = data.find((e: any) => e.id === item.id);
            if (existing) {
                existing.count = item.count;
                result.push(existing);
            } else {
                console.log("Cannot find existing item. The caterer (you?) must've deleted it")
            }

        });
        return result;
    }

    static findDescription(item: any): string {
        if (item.description) {
            return item.description;
        }
        return "";
    }

}
