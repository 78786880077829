
import React from "react";

import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";

import MenuItemPopup from "./subviews/MenuItemPopup";

import MenuItemContainer from "./subviews/MenuItemContainer";
import MenuCategoryContainer from "./subviews/MenuCategoryContainer";

import LocalData from "../../services/state/LocalData";
import {MenuCategories} from "../../model/constants/MenuCategories";
import {Networking} from "../../services/Networking";
import MenuItemDeletePopup from "./subviews/MenuItemDeletePopup";
import {Translation} from "../../core/service/Translation";
import {BaseComponent} from "../_subviews/BaseComponent";
import {StringUtils} from "../../utils/StringUtils";

export default class MenuPage extends BaseComponent {

    constructor(props: any) {
        super(props);
        this.state = {
            activeCategory: MenuCategories.list.items[0],
            showPopup: false,
            item: undefined
        };
    }

    async reload(force = false) {
        await LocalData.menu.load(() => {}, force);
        this.forceUpdate();
    }

    componentDidMount = async () => {
        await this.reload();
    };

    showMenuMenuCategory(item: any) {
        this.setState({ activeCategory: item });
    }

    onEditItemClick(item: any) {
        this.setState({ showPopup: true, item: item });
    }

    onDeleteItemClick(item: any) {
        this.setState({ itemToDelete: item});
    }

    closePopup() {
        this.setState({ showPopup: false });
    }

    async addItem(data: any, id: number) {

        data.price = StringUtils.replaceCommas(data.price);
        data.quantity =StringUtils.replaceCommas(data.quantity);
        data.unit = StringUtils.replaceCommas(data.unit);

        let result;
        if (id) {
            result = await Networking.instance.updateMenuItem(id, data);
        } else {
            result = await Networking.instance.addMenuItem(data);
        }

        if (result.status === 0) {
            let message = Translation.find("menu-item-add-success-content");
            if (id) {
                message = Translation.find("menu-item-update-success-content")
            }
            this.setState({showPopup: false}, async () => {
                await this.reload(true);
                this.props.alert(Translation.find("success-header"), message);
            });
        }

        LocalData.offer.clear();
    }

    async deleteItem() {
        await Networking.instance.deleteMenuItem(this.state.itemToDelete.id);
        this.setState({itemToDelete: undefined});
        await this.reload(true);
    }

    render() {
        return (
            <div>
                <MenuItemDeletePopup
                    item={this.state.itemToDelete}
                    confirmed={this.deleteItem.bind(this)}
                    cancelled={() => {
                        this.setState({itemToDelete: undefined})
                    }}
                />
                <MenuItemPopup
                    //@ts-ignore
                    show={this.state.showPopup}
                    item={this.state.item}
                    alert={this.props.alert}
                    category={this.state.activeCategory}
                    onCancel={this.closePopup.bind(this)}
                    onAdd={this.addItem.bind(this)}
                />
                <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                        <MenuCategoryContainer
                            onCategoryClick={(item: any) => this.showMenuMenuCategory(item)}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={8}>
                        <MenuItemContainer
                            activeCategory={this.state.activeCategory}
                            onEditItemClick={this.onEditItemClick.bind(this)}
                            onDeleteItemClick={this.onDeleteItemClick.bind(this)}
                            onAddClick={() => {
                                this.setState({showPopup: true, item: null});
                            }}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}
