import {whiteColor} from "../assets/jss/material-dashboard-pro-react";
import {CSSProperties} from "react";

export class Styles {

    static whiteColor = "#FFF";
    static blackColor = "#000";

    static primaryColor = ["#9c27b0", "#ab47bc", "#8e24aa", "#af2cc5", "#7b1fa2"];
    static successColor = [
        "#4caf50",
        "#66bb6a",
        "#43a047",
        "#5cb860",
        "#388e3c",
        "#d0e9c6",
        "#dff0d8"
    ];
    static warningColor = [
        "#ff9800",
        "#ffa726",
        "#fb8c00",
        "#ffa21a",
        "#f57c00",
        "#faf2cc",
        "#fcf8e3"
    ];
    static grayColor = [
        "#999",
        "#777",
        "#3C4858",
        "#AAAAAA",
        "#D2D2D2",
        "#DDD",
        "#555555",
        "#333",
        "#eee",
        "#ccc",
        "#e4e4e4",
        "#E5E5E5",
        "#f9f9f9",
        "#f5f5f5",
        "#495057",
        "#e7e7e7",
        "#212121",
        "#c8c8c8",
        "#505050",
        "#888"
    ];
    static hexToRgb(input: string) {
        input = input + "";
        input = input.replace("#", "");
        let hexRegex = /[0-9A-Fa-f]/g;
        if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
            throw new Error("input is not a valid hex color.");
        }
        if (input.length === 3) {
            let first = input[0];
            let second = input[1];
            let last = input[2];
            input = first + first + second + second + last + last;
        }
        input = input.toUpperCase();
        let first = input[0] + input[1];
        let second = input[2] + input[3];
        let last = input[4] + input[5];
        return (
            parseInt(first, 16) +
            ", " +
            parseInt(second, 16) +
            ", " +
            parseInt(last, 16)
        );
    }

    static title = {
        color: Styles.grayColor[2],
        textDecoration: "none",
        fontWeight: 300,
        marginTop: "30px",
        marginBottom: "25px",
        minHeight: "32px",
        fontFamily: "'Open Sans', 'Helvetica', 'Arial', sans-serif",
        "& small": {
            color: Styles.grayColor[1],
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    };

    static cardTitle = {
        ...Styles.title,
        marginTop: "0",
        marginBottom: "3px",
        minHeight: "auto",
        "& a": {
            ...Styles.title,
            marginTop: ".625rem",
            marginBottom: "0.75rem",
            minHeight: "auto"
        }
    };

    static cardIconTitle: CSSProperties = {
        ...Styles.cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    };

    static checkboxAndRadioHorizontal = {
        position: "relative",
        display: "block",
        "&:first-child": {
            marginTop: "10px"
        },
        "&:not(:first-child)": {
            marginTop: "-14px"
        },
        marginTop: "0",
        marginBottom: "0"
    };

    static button = {
        minHeight: "auto",
        minWidth: "auto",
        backgroundColor: Styles.grayColor[0],
        color: Styles.whiteColor,
        boxShadow: "0 2px 2px 0 rgba(" + Styles.hexToRgb(Styles.grayColor[0]) + ", 0.14), 0 3px 1px -2px rgba(" +
            Styles.hexToRgb(Styles.grayColor[0]) + ", 0.2), 0 1px 5px 0 rgba(" + Styles.hexToRgb(Styles.grayColor[0]) + ", 0.12)",
        border: "none",
        borderRadius: "3px",
        position: "relative",
        padding: "12px 30px",
        margin: ".3125rem 1px",
        fontSize: "12px",
        fontWeight: "400",
        textTransform: "uppercase",
        letterSpacing: "0",
        willChange: "box-shadow, transform",
        transition: "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
        lineHeight: "1.42857143",
        textAlign: "center",
        whiteSpace: "nowrap",
        verticalAlign: "middle",
        touchAction: "manipulation",
        cursor: "pointer"
    };

    static successButton = {
        ...Styles.button,
        backgroundColor: Styles.successColor[0],
        boxShadow:
            "0 2px 2px 0 rgba(" +
            Styles.hexToRgb(Styles.successColor[0]) +
        ", 0.14), 0 3px 1px -2px rgba(" +
            Styles.hexToRgb(Styles.successColor[0]) +
        ", 0.2), 0 1px 5px 0 rgba(" +
            Styles.hexToRgb(Styles.successColor[0]) +
        ", 0.12)",
        "&:hover,&:focus": {
            backgroundColor: Styles.successColor[0],
            boxShadow:
                "0 14px 26px -12px rgba(" +
                Styles.hexToRgb(Styles.successColor[0]) +
            ", 0.42), 0 4px 23px 0px rgba(" +
                Styles.hexToRgb(Styles.blackColor) +
            ", 0.12), 0 8px 10px -5px rgba(" +
                Styles.hexToRgb(Styles.successColor[0]) +
            ", 0.2)"
        }
    };

    static warningButton = {
        ...Styles.button,
        backgroundColor: Styles.warningColor[0],
        boxShadow:
            "0 2px 2px 0 rgba(" +
            Styles.hexToRgb(Styles.warningColor[0]) +
        ", 0.14), 0 3px 1px -2px rgba(" +
            Styles.hexToRgb(Styles.warningColor[0]) +
        ", 0.2), 0 1px 5px 0 rgba(" +
            Styles.hexToRgb(Styles.warningColor[0]) +
        ", 0.12)",
        "&:hover,&:focus": {
            backgroundColor: Styles.warningColor[0],
            boxShadow:
                "0 14px 26px -12px rgba(" +
                Styles.hexToRgb(Styles.warningColor[0]) +
            ", 0.42), 0 4px 23px 0px rgba(" +
                Styles.hexToRgb(Styles.blackColor) +
            ", 0.12), 0 8px 10px -5px rgba(" +
                Styles.hexToRgb(Styles.warningColor[0]) +
            ", 0.2)"
        }
    };

    static checkedIcon = {
        width: "20px",
        height: "20px",
        border: "1px solid rgba(" + Styles.hexToRgb(Styles.blackColor) + ", .54)",
        borderRadius: "3px"
    };

    static uncheckedIcon = {
        width: "0px",
        height: "0px",
        padding: "9px",
        border: "1px solid rgba(" + Styles.hexToRgb(Styles.blackColor) + ", .54)",
        borderRadius: "3px"
    };

    static checkRoot = {
        padding: "14px",
        "&:hover": {
            backgroundColor: "unset"
        }
    };

    static checked = {
        color: Styles.primaryColor[0] + "!important"
    };

    static label = {
        cursor: "pointer",
        paddingLeft: "0",
        color: Styles.grayColor[3],
        fontSize: "14px !important",
        lineHeight: "1.428571429",
        fontWeight: "400",
        display: "inline-flex",
        transition: "0.3s ease all",
        letterSpacing: "unset"
    };

    static inputAdornmentIcon = {
        color: Styles.grayColor[6]
    };

    static customButton = {
        "&,&:focus,&:hover": {
            color: whiteColor
        },
        marginLeft: "5px",
        marginRight: "5px"
    };

    static avatar: CSSProperties = {
        height: "100%",
        objectFit: "cover"
    };

    static link: CSSProperties = {
        color: '#37b48f',
        textDecoration: "underline",
        cursor: "pointer"
    }
}
