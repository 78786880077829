
import React from "react";
import classNames from "classnames";
import Button from "@mui/material/Button";

import styles from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import withStyles from "@mui/styles/withStyles";

class RegularButton extends React.Component {

	render() {
		const {
			classes,
			color,
			round,
			children,
			fullWidth,
			disabled,
			simple,
			size,
			block,
			link,
			justIcon,
			className,
			muiClasses,
			...rest
		} = this.props;

		const btnClasses = classNames({
			[classes.button]: true,
			[classes[size]]: size,
			[classes[color]]: color,
			[classes.round]: round,
			[classes.fullWidth]: fullWidth,
			[classes.disabled]: disabled,
			[classes.simple]: simple,
			[classes.block]: block,
			[classes.link]: link,
			[classes.justIcon]: justIcon,
			[className]: className
		});
		
		return (
			<Button {...rest} classes={muiClasses} className={btnClasses}>
				{children}
			</Button>
		);
	}
}

export default withStyles(styles)(RegularButton);
