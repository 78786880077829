
import React from "react";

import Button from "@mui/material/Button";

import GridItem from "../../../components/Grid/GridItem";
import GridContainer from "../../../components/Grid/GridContainer";
import { cardTitle } from "../../../assets/jss/material-dashboard-pro-react";

import swedIcon from "../../../assets/img/allicater/swedbank.png";
// import sebIcon from "../../../assets/img/allicater/seb.png";
// import stripeIcon from "../../../assets/img/allicater/stripe.png";

import {BaseComponent} from "../../_subviews/BaseComponent";

import {Translation} from "../../../core/service/Translation";

export default class PaymentActions extends BaseComponent {

    headerStyle() {
        return {cardTitle, textAlign: "center", marginBottom: "20px"};
    }
    buttonStyle(width: string, icon: any, color: string) {
        return {
            height: "40px",
            width: width,
            backgroundImage: "url('" + icon + "')",
            backgroundColor: color,
            marginLeft: "10px",
            marginRight: "10px",
            backgroundSize: "contain",
        }
    }

    render() {

        if (!this.props.isVisible) {
            return null;
        }

        return (
            <GridContainer justifyContent="center" style={{marginTop: "50px"}}>
                <GridItem xs={12} sm={12} md={7}>
                    <h4
                        //@ts-ignore
                        style={this.headerStyle()}>
                        {Translation.find("pay-with")}
                    </h4>
                    <GridContainer justifyContent="center">
                    {this.createButton("113px", swedIcon, "transparent", this.props.pay.swed)}
                    {/*{this.createButton("110px", sebIcon, "transparent", this.props.pay.seb)}*/}
                    {/*{this.createButton("85px", stripeIcon, "#32325d", this.props.pay.stripe)}*/}
                    </GridContainer>
                </GridItem>

            </GridContainer>
        )
    }

    createButton(width: string, icon: any, color: string, action: any) {
        return <Button style={this.buttonStyle(width, icon, color)} onClick={() => action()}/>
    }
}
