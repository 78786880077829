import React from "react";
import EtiqPageCollapse from "../subviews/EtiqPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class Thai extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <EtiqPageCollapse title={"Thai"}>
                    <li>A cuisine that is gently and carefully prepared and uses a variety of ingredients that are quite aromatic. As a rule, treated with fresh herbs and plenty of spices</li>
                </EtiqPageCollapse>
            );
        }

        return (
            <EtiqPageCollapse title={"Tai"}>
                <li>Õrnalt ja ettevaatlikult prepareeritud ning küpsetautd erinevaid koostisosi kasutav köök, mis on küllalkit aromaatsed. Reeglina on töödeldud värskete ürtide ja ohtrate vürtsidega. </li>
            </EtiqPageCollapse>
        );
    }
}