
import React from "react";

import { PDFGenerator } from "../../core/pdf-generator/PDFGenerator";
import { BaseComponent } from "../_subviews/BaseComponent";

export class InvoicePDFView extends BaseComponent {

    TOOLBAR_HEIGHT = 52;
    A4SIZE = { width: 210, height: 297 };
    MULTIPLIER = 2.2;
    computeSize(): {width: string, height: string} {
        return {
            width: this.A4SIZE.width * this.MULTIPLIER + "px", 
            height: (this.A4SIZE.height * this.MULTIPLIER + this.TOOLBAR_HEIGHT) + "px"
        };
    }

    constructor(props: any) {
        super(props);
        this.state = {
            base64: undefined,
            compact: false
        };
    }

    async componentDidMount() {

        await PDFGenerator.instance.initialize();
        await PDFGenerator.instance.createInvoice(this.props.invoice, this.props.compact);
        
        this.setState({
            base64: await PDFGenerator.instance.asDataUri(),
            compact: this.props.compact
        }) ;
    }

    render() {
        if (!this.state.base64) {
            return null;
        }

        if (this.props.compact !== this.state.compact) {
            this.componentDidMount();
        }
        
        const style = {margin: "0 auto", display: "flex", ...this.computeSize()};
        return <iframe src={this.state.base64} style={style} title={"invoice-pdf-frame"}></iframe>;
    }
}
