
import React, {CSSProperties} from "react";
import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";

import {BaseComponent} from "../../views/_subviews/BaseComponent";
import {DateUtils} from "../../core/utils/DateUtils";

export default class FooterCopyright extends BaseComponent {

    container(): CSSProperties {
        return {margin: "0", fontSize: "14px", float: "right", padding: "15px"}
    }

    anchor(): CSSProperties {
        return {color: primaryColor[0], textDecoration: "none", backgroundColor: "transparent"};
    }

    render() {
        return (
            <p style={this.container()}>&copy; {DateUtils.currentYear()}{" "}
                <a href="https://allicater.com" style={this.anchor()} target="_blank" rel={"noopener noreferrer"}>
                    {"Post Innovations"}
                </a>
            </p>
        );
    }
}
