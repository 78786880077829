
import React, {CSSProperties} from "react";
import Card from "../Card/Card.js";

import {BaseComponent} from "../../views/_subviews/BaseComponent";
import WizardFooter from "./WizardFooter";
import WizardNavigation from "./WizardNavigation";

import style from "../../assets/jss/material-dashboard-pro-react/components/wizardStyle.js";

export default class Wizard extends BaseComponent {

    constructor(props: any) {
        super(props);

        this.state = {
            currentStep: 0,
            color: this.props.color,
            nextButton: this.props.steps.length > 1,
            previousButton: false,
            finishButton: this.props.steps.length === 1,
            width: 100 / 3 + "%",
            movingTabStyle: {
                transition: "transform 0s"
            },
            allStates: {}
        };

        this.navigationStepChange = this.navigationStepChange.bind(this);
        this.refreshAnimation = this.refreshAnimation.bind(this);
        this.previousButtonClick = this.previousButtonClick.bind(this);
        this.previousButtonClick = this.previousButtonClick.bind(this);
        this.finishButtonClick = this.finishButtonClick.bind(this);
        this.updateWidth = this.updateWidth.bind(this);
    }

    wizard: any;

    componentDidMount() {
        this.refreshAnimation(0);
        window.addEventListener("resize", this.updateWidth);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWidth);
    }

    updateWidth() {
        this.refreshAnimation(this.state.currentStep);
    }

    navigationStepChange(key: any) {

        return;
        // if (this.props.steps) {
        //     this.setState({
        //         currentStep: key,
        //         nextButton: this.props.steps.length > key + 1,
        //         previousButton: key > 0,
        //         finishButton: this.props.steps.length === key + 1
        //     });
        //     this.refreshAnimation(key);
        // }
    }

    nextButtonClick(e: any) {
        const stepId = this.props.steps[this.state.currentStep].id;
        // @ts-ignore
        this.props.nextButtonClick(this[stepId]);
    }

    moveNext() {
        const key = this.state.currentStep + 1;
        this.setState({
            currentStep: key,
            nextButton: this.props.steps.length > key + 1,
            previousButton: key > 0,
            finishButton: this.props.steps.length === key + 1
        });
        this.refreshAnimation(key);
    }

    previousButtonClick() {
        const key = this.state.currentStep - 1;
        if (key >= 0) {
            this.setState({
                currentStep: key,
                nextButton: this.props.steps.length > key + 1,
                previousButton: key > 0,
                finishButton: this.props.steps.length === key + 1
            });
            this.refreshAnimation(key);
        }
    }

    finishButtonClick() {
        this.props.finishButtonClick();
    }

    refreshAnimation(index: number) {
        const total = this.props.steps.length;
        let li_width = 100 / total;
        const total_steps = this.props.steps.length;
        let move_distance = this.wizard.children[0].offsetWidth / total_steps;
        let index_temp = index;
        let vertical_level = 0;

        const mobile_device = window.innerWidth < 600 && total > 3;

        if (mobile_device) {
            move_distance = this.wizard.children[0].offsetWidth / 2;
            index_temp = index % 2;
            li_width = 50;
        }

        this.setState({ width: li_width + "%" });

        const step_width = move_distance;
        move_distance = move_distance * index_temp;

        const current = index + 1;

        if (current === 1 || (mobile_device && index % 2 === 0)) {
            move_distance -= 8;
        } else if (current === total_steps || (mobile_device && index % 2 === 1)) {
            move_distance += 8;
        }

        if (mobile_device) {
            vertical_level = parseInt((index / 2).toString(), 10);
            vertical_level = vertical_level * 38;
        }
        const movingTabStyle = {
            width: step_width,
            transform: "translate3d(" + move_distance + "px, " + vertical_level + "px, 0)",
            transition: "all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)"
        };
        this.setState({ movingTabStyle: movingTabStyle });
    }

    render() {
        const {title, subtitle, steps} = this.props;

        return (
            <div style={style.wizardContainer} ref={ref => this.wizard = ref}>
                {/*@ts-ignore*/}
                <Card style={style.card as CSSProperties}>
                    <div style={style.wizardHeader as CSSProperties}>
                        <h3 style={style.title}>{title}</h3>
                        <h5 style={style.subtitle}>{subtitle}</h5>
                    </div>
                    <div style={style.wizardNavigation as CSSProperties}>
                        <ul style={style.nav}>
                            <WizardNavigation
                                steps={this.props.steps}
                                width={this.state.width}
                                onChange={this.navigationStepChange.bind(this)}
                            />
                        </ul>
                        <div style={{...style.movingTab, ...this.state.movingTabStyle, ...style.primary}}>
                            {steps[this.state.currentStep].name}
                        </div>
                    </div>
                    <div style={style.content}>
                        {this.createContent()}
                    </div>

                    <WizardFooter
                        previous={{
                            visible: this.state.previousButton,
                            text: this.props.previousButtonText,
                            onClick: this.previousButtonClick.bind(this)
                        }}
                        next={{
                            visible: this.state.nextButton,
                            text: this.props.nextButtonText,
                            onClick: this.nextButtonClick.bind(this)
                        }}
                        finish={{
                            visible: this.state.finishButton,
                            text: this.props.finishButtonText,
                            onClick: this.finishButtonClick.bind(this)
                        }}
                    />
                </Card>
            </div>
        );
    }

    createContent() {
        return this.props.steps.map((prop: any, key: any) => {
            return (
                <div key={key}
                     style={(this.state.currentStep === key) ? style.stepContentActive : style.stepContent}>
                    <prop.component
                        ref={(node: any) => {
                            // @ts-ignore
                            return this[prop.id] = node;
                        }}
                        allStates={this.state.allStates}
                        callback={prop.callback}
                    />
                </div>
            );
        })
    }
}
