
import React from "react";

import Pagination from '@mui/material/Pagination';

import GridItem from "../../../components/Grid/GridItem";
import Table from "../../../components/Table/Table";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import CardIcon from "../../../components/Card/CardIcon";

import Invoice from "../../../model/Invoice";

import {BaseComponent} from "../../_subviews/BaseComponent";

import {Styles} from "../../../styles/Styles";

import {DateUtils} from "../../../core/utils/DateUtils";
import {ViewUtils} from "../../../utils/ViewUtils";

import {Translation} from "../../../core/service/Translation";
import Offer from "../../../model/Offer";
import InvoiceData from "../../../services/state/sub/InvoiceData";
import { InvoicePrice } from "../../../core/model/InvoicePrice";
import { InvoiceType } from "../../../core/model/enum/InvoiceType";
import { JSONUtils } from "../../../core/utils/JSONUtils";
import User from "../../../model/User";

export default class DashboardTable extends BaseComponent {

    static readonly ITEMS_PER_PAGE = 5;

    constructor(props: any) {
        super(props);

        this.state = {
            page: 1
        }
    }

    count() {
        if (!this.props.data) {
            return 0;
        }
        return Math.ceil(this.props.data.length / DashboardTable.ITEMS_PER_PAGE);
    }

    render() {
        return (
            <GridItem xs={12} sm={12} md={4}>
                <Card>
                    <CardHeader color={"default"}>
                    <CardIcon color="info">
                        {ViewUtils.widgetIcon(this.props.icon ?? "event-accepted-tentatively")}
                        </CardIcon>
                        <h4 style={Styles.cardIconTitle}>{this.props.title}</h4>
                    </CardHeader>
                    <CardBody>
                        <Table
                            // @ts-ignore
                            hover
                            tableHeaderColor="primary"
                            tableHead={this.parseRowHeaders()}
                            tableData={this.parseRows()}
                            onRowClick={(e: any) => this.props.onRowClick(e, this.props.type) }
                        />
                        <Pagination
                            style={{marginTop: "30px"}}
                            count={this.count()}
                            color="standard"
                            onChange={(e: any, page: number) => {
                                this.setState({page: page});
                            }}
                        />
                    </CardBody>
                </Card>
            </GridItem>
        );
    }

    parseRowHeaders() {
        if (this.props.type === "event") {
            return Translation.findList(["ID", "date", "budget-persons"]);
        }
        if (this.props.type === "offer") {
            return Translation.findList(["ID", "date", "status"]);
        }
        if (this.props.type === "invoice") {
            return Translation.findList(["ID", "due-date", "total"]);
        }
        if (this.props.type === "review") {
            return Translation.findList(["ID", "date", "user"]);
        }
    }

    parseRows() {
        const result: any[] = [];
        const start = (this.state.page - 1) * DashboardTable.ITEMS_PER_PAGE;
        const count = start + DashboardTable.ITEMS_PER_PAGE;

        for (let i = start; i < count; i++) {
            const item = this.props.data[i];

            if (!item) {
                break;
            }
            if (this.props.type === "event") {
                const date = DateUtils.msToLongDateString(item.date);
                const budget = <div>{item.budget + "€ / " + item.attendees}</div>;
                item.offer = Offer.findAcceptedOfferForEvent(item);
                result.push([item.id, date, budget /*, ViewUtils.statusForOffer(item.offer)*/]);
            }
            if (this.props.type === "offer") {
                const date = DateUtils.msToLongDateString(item.event.date);
                result.push([item.id, date, ViewUtils.statusForOffer(item)]);
            }
            if (this.props.type === "invoice") {
                const date = DateUtils.msToLongDateString(item.dueDate);

                const users = JSONUtils.safeParse(item.userJson);
                let vat = true;
                if (users.caterer) {
                    vat = users.caterer.hasVatNumber;
                }
                let prepaidAmount = 0;
                if (!Invoice.isPrepayment(item) && item.type !== InvoiceType.Allicater) {
                    prepaidAmount = InvoicePrice.fromPrepaidAmount(item.prepaidAmount, vat).total;
                }
                let sum = InvoiceData.calculatePrice(item, vat) - prepaidAmount;
                result.push([item.id, date, sum + "€"]);
            }
            if (this.props.type === "review") {
                const date = DateUtils.msToLongDateString(item.offer?.event?.date);
                result.push([item.id, date, User.displayName(item.recipient)]);
            }

        }
        return result;
    }
}
