import React from "react";
import {ViewUtils} from "../../../utils/ViewUtils";
import ReactTable from "../../../components/ReactTable/ReactTable";
import {Translation} from "../../../core/service/Translation";
import User from "../../../model/User";
import { CateringEnums } from "../../../model/constants/CateringEnums";

export default class UserList extends React.Component<any, any> {

    columns() {
        const result = [];
        result.push(ViewUtils.createColumnHeader("#", "id"));
        result.push(ViewUtils.createColumnHeader("Name", "name"));
        result.push(ViewUtils.createColumnHeader("Email", "email"));
        result.push(ViewUtils.createColumnHeader("Type", "type"));
        result.push(ViewUtils.createColumnHeader("Categories", "categories"));
        result.push(ViewUtils.createColumnHeader("Action", "action"));
        return result;
    }

    rows() {
        const result: any[] = [];
        if (!this.props.data) {
            return result;
        }
        this.props.data.forEach((user: User) => {
            const item: any = {};
            item.id = user.id;
            item.name = user.name;
            item.email = user.email;
            item.type = user.isCaterer ? "caterer" : "client";
            item.categories = user.isCaterer ? CateringEnums.typesFromList(user.multi_category) : "-";
            item.action = ViewUtils.listActionButton(Translation.find("hijack"), () => {
                this.props.hijack(user);
            });
            result.push(item);
        });

        return result;
    }

    render() {
        return ViewUtils.createCardTemplate(
            "invoice",
            Translation.find(this.props.title),
            <ReactTable columns={this.columns()} data={this.rows()}/>,
            this.props.color
        );
    }
}
