
import React from "react";

import {BaseComponent} from "./BaseComponent";


export default class BetaLabel extends BaseComponent {
    render() {
        return <div
            style={{
                backgroundColor: "red",
                transform: "rotate(-45deg)",
                position: "fixed",
                top: 15,
                left: -23,
                zIndex: 5000,
                fontWeight: 800,
                color: "white",
                width: "100px",
                textAlign: "center"
            }}
        >{"BETA"}</div>
    }
}
