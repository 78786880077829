
import React from "react";

import GridItem from "../../../components/Grid/GridItem";
import {BaseComponent} from "../../_subviews/BaseComponent";

import PartyIcon from "../../../assets/img/allicater/icon/party.svg";

import {CateringEnums} from "../../../model/constants/CateringEnums";
import {DateUtils} from "../../../core/utils/DateUtils";
import {ViewUtils} from "../../../utils/ViewUtils";
import {Translation} from "../../../core/service/Translation";

export default class EventInfoBox extends BaseComponent {

    render() {
        const event = this.props.event;
        if (!event) {
            return null;
        }
        return (
            ViewUtils.createCard(<img src={PartyIcon} className={"squery"} alt={"."} />, Translation.find("event-info") ,
                    <div style={{width: "94%", margin: "2%", marginTop: 0}}>
                        <GridItem>
                            <div style={{display: "flex"}}>
                                {this.item("location", event.location)}
                                {this.item("date", DateUtils.msToDateWithTime(event.date))}
                            </div>
                        </GridItem>
                        <GridItem>
                            <div style={{display: "flex"}}>
                                {this.item("budget", event.budget + "€")}
                                {this.item("people-attending", event.attendees)}
                            </div>
                        </GridItem>
                        <GridItem>
                            {this.item("categories", this.mapCheckboxes())}
                        </GridItem>
                        <GridItem>
                            {this.item("description", event.additionalInfo)}
                        </GridItem>
                    </div>
            )

        );
    }

    mapCheckboxes() {
        const translated = this.translate(CateringEnums.grabSelectedSubtypes(this.props.event));
        return translated.join(", ");
    }

    item(id: string, text: string) {
        return (
            <div>
                <span>{Translation.find(id)}</span>
                <span style={{marginRight: "1px"}}>: </span>
                <strong>{text}</strong>
                <span style={{marginRight: "10px"}}/>
            </div>
        );
    }
}
