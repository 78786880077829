import React from "react";
import EtiqPageCollapse from "../subviews/EtiqPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class Opening extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <EtiqPageCollapse title={"Opening Event"}>
                    <li>A reception before a larger event or a so-called ribbon-cutting event, which offers everything from wine with snacks to a rich buffet</li>
                </EtiqPageCollapse>
            );
        }

        return (
            <EtiqPageCollapse title={"Avamisüritus"}>
                <li>Suuremale üritusele eelnev vastuvõtt; ka nö lindilõikamisüritus, kus pakutakse alates veini pakkumisest koos suupistetega kuni rikkaliku buffeni</li>
            </EtiqPageCollapse>
        );
    }
}