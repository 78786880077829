import {Networking} from "../../Networking";
import MenuItem from "../../../model/MenuItem";

export default class OfferHistoryData {

    data?: {offers: any[]};

    menu?: any;

    async load(callback?: any): Promise<any> {

        // if (this.data) {
        //     return this.data;
        // }

        const response = await Networking.instance.fetchOffers({mine: true});
        const offers = response.offers;
        callback({ offers: offers });

        const eventIds = offers.map((offer: any) => offer.eventId);
        const events = (await Networking.instance.fetchEvents({eventIds: eventIds})).events;

        const userIds = events.map((event: any) => event.creatorId);
        const users = (await Networking.instance.fetchUsers({userIds: userIds})).users;

        const ratings = (await Networking.instance.fetchRatings({recipientIds: userIds})).ratings;

        events.forEach((event: any) => {
            event.creator = users.find((user: any) => user.id === event.creatorId);
        });

        users.forEach((user: any) => {
            user.rating = ratings.find((rating: any) => rating.recipientId === user.id);
        });

        offers.forEach((offer: any) => {
            const event = events.find((event: any) => event.id === offer.eventId);
            offer.event = event;
            event.offer = offer;
        });
        callback({ offers: offers });

        const menuResponse = await Networking.instance.fetchMenu({});
        this.menu = menuResponse.items;

        this.data = { offers: offers };
        return this.data;
    }


    public findPriceForOfferedItems(json: string): number {
        if (!this.menu) {
            return -1;
        }
        const items = this.findOfferedItems(json);
        let result = 0;
        items.forEach((item: any) => {
            result += item.price * item.count;
        });
        return result;
    }

    public findOfferedItems(json: string) {
        return MenuItem.findOfferedItemsOfData(this.menu, json);
    }

}
