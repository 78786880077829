
import {County} from "../model/enum/CateringRegion";

export class Translation {

    private static _function: any;

    public static initialize(_func: any) {
        this._function = _func;
    }

    static find(key?: string) {
        if (!key) {
            return key;
        }
        return this._function(key)
    }

    static findList(keys?: string[]) {
        if (!keys) {
            return [];
        }
        return keys.map(item => this._function(item));
    }
    static error(code: number) {
        return this._function("error-" + code);
    }

    static county(county: County): string {
        return this._function("county-enum." + County[county]);
    }
}
