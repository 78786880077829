import React from "react";
import EtiqPageCollapse from "../subviews/EtiqPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class Bar extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <EtiqPageCollapse title={"Bar"}>
                    <li>Option to order drinks and/or bar services. Also different types of degustations and trainings.</li>
                </EtiqPageCollapse>
            );
        }

        return (
            <EtiqPageCollapse title={"Baar"}>
                <li>Soov tellida ainult joogiga seotud lahendus k.a. veinikoolitused, õllekoolitused, kokteilikoolitused jms</li>
            </EtiqPageCollapse>
        );
    }
}
