
import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import MessengerCustomerChat from 'react-messenger-customer-chat';

import App from './App';
import { Networking } from './services/Networking';

import { ThemeProvider } from "@mui/styles";

import { createTheme, responsiveFontSizes, StyledEngineProvider } from '@mui/material/styles';
let theme = createTheme();
theme = responsiveFontSizes(theme);

ReactDOM.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <App/>
        {!Networking.instance.isLocal() && <MessengerCustomerChat
            pageId="100287648229589" // from: https://www.facebook.com/allicaterer/about/?ref=page_internal
            appId="197346684662795" // from: https://developers.facebook.com/apps/197346684662795/dashboard/
        />}
        </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
