
import React from "react";
import HelpPageCollapse from "../subviews/HelpPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class Reviews extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <HelpPageCollapse title={"Review"}>
                    <li> Each event subscriber can rate the event. The evaluation is divided into three parts:
                        cleanliness, quality of service and quality of food, etc. </li>
                    <li> Each event host can evaluate the event's host </li>
                </HelpPageCollapse>
            );
        }

        return (
            <HelpPageCollapse title={"Hindamine"}>
                <li>Iga ürituse tellija saab toimunud üritust hinnata. Hindamine toimub kolmes osa:
                    puhtus, teenuse kvaliteet ja pakutava toidu vms kvaliteet</li>
                <li>Iga ürituse läbiviija (caterer) saab hinnata ürituse tellijat</li>
            </HelpPageCollapse>
        );
    }
}
