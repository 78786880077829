
import React from "react";

import Checkbox from "@mui/material/Checkbox";
import {Check} from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";
import Icon from "@mui/material/Icon";
import FormControlLabel from "@mui/material/FormControlLabel";

import GridItem from "../components/Grid/GridItem";
import Card from "../components/Card/Card";
import CardHeader from "../components/Card/CardHeader";
import CardIcon from "../components/Card/CardIcon";
import CardBody from "../components/Card/CardBody";
import GridContainer from "../components/Grid/GridContainer";
import CustomInput from "../components/CustomInput/CustomInput";
import Table from "../components/Table/Table";
import Button from "../components/CustomButtons/Button";

import {Styles} from "../styles/Styles";
import {FuckingRandomUtils} from "./FuckingRandomUtils";
import Offer from "../model/Offer";

import {DateUtils} from "../core/utils/DateUtils";
import {Translation} from "../core/service/Translation";


export class ViewUtils {

    static getInputValueById(id) {
        return document.getElementById(id)?.value;
    }

    static getCheckboxValueById(id) {
        return document.getElementById(id).checked;
    }

    static divider() {
        return <div style={{
            backgroundColor: "lightgray",
            height: "1px",
            width: "100%",
            marginTop: "15px",
            marginBottom: "15px"
        }}/>
    }

    static createCardTemplate(iconName, title, content, color) {
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="default">
                            <CardIcon color={color ? color : "info"}>
                                {this.createIcon(iconName)}
                            </CardIcon>
                            <h4 style={Styles.cardIconTitle}>{title}</h4>
                        </CardHeader>
                        <CardBody>
                            {content}
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }

    static createIcon(name) {
        const source = require("../assets/img/allicater/icon/" + name + ".svg");
        return (<img style={{width: "100%", height: "100%", objectFit: "contain"}} className={"largerCat"} src={source}
                     alt={"."}/>);
    }

    static widgetIcon(name) {
        const source = require("../assets/img/allicater/icon/" + name + ".svg");
        return (<img src={source} className={"widget-icons"} alt={"."}/>);
    }

    static menuIcon(name) {
        const source = require("../assets/img/allicater/icon/" + name + ".svg");
        return (<img src={source} className={"ssquery float-left"} alt={"."}/>);
    }

    static createColumnHeader(header, accessor) {
        return {Header: header, accessor: accessor};
    }

    static createCard(icon, title, body) {
        return (
            <Card>
                <CardHeader color="primary" icon>
                    <CardIcon color="info">{icon}</CardIcon>
                    <h4 style={Styles.cardIconTitle}>{title}</h4>
                </CardHeader>
                <CardBody>{body}</CardBody>
            </Card>
        );
    }

    static createGridItem(parent, id, width, inputProps, error = false) {
        return (
            <GridItem xs={12} sm={12} md={width}>
                <CustomInput
                    error={error}
                    id={id}
                    innerRef={node => (parent[id] = node)}
                    labelText={Translation.find(id)}
                    formControlProps={{fullWidth: true}}
                    inputProps={inputProps}
                />
            </GridItem>
        );
    }

    static createCheckbox(props, id, width, event, checked) {
        return (
            <GridItem xs={12} sm={12} md={width}>
                <FormControlLabel
                    key={FuckingRandomUtils.generateId()}
                    control={
                        <Checkbox
                            id={id}
                            key={id}
                            tabIndex={-1}
                            onChange={event}
                            defaultChecked={checked}
                            checkedIcon={<Check className={props.classes.checkedIcon}/>}
                            icon={<Check className={props.classes.uncheckedIcon}/>}
                            classes={{checked: props.classes.checked, root: props.classes.checkRoot}}
                        />
                    }
                    classes={{label: props.classes.label, root: props.classes.labelRoot}}
                    label={props.t(id)}
                    style={{fontFamily: '"Open Sans"', fontWeight: "600"}}
                />
            </GridItem>
        );
    }

    static createSimpleTable(props, title, head, body) {
        const icon = require("../assets/img/allicater/icon/feedback.svg");
        return (
            <GridItem xs={12} sm={12} md={4}>
                <Card>
                    <CardHeader color="primary" icon>
                        <CardIcon color="info"><img src={icon} className={"widget-icons"} alt={"."}/></CardIcon>
                        <h4 className={"widgets"}>{props.t(title)}</h4>
                    </CardHeader>
                    <CardBody>
                        <Table
                            hover
                            tableHeaderColor="primary"
                            tableHead={head}
                            tableData={body}
                        />
                    </CardBody>
                </Card>
            </GridItem>
        );
    }

    static strong(text, isStrong) {
        if (!isStrong) {
            return text;
        }

        return <strong>{text}</strong>;
    }

    static isMobile() {
        return window.innerWidth <= 760;
    }

    static listActionButton(text, action) {
        return (
            <div className="actions-right">
                <Button size="sm" color={"primary"} onClick={action}>{text}</Button>
            </div>
        );
    }

    static statusForOffer(offer) {
        if (Offer.isAccepted(offer)) {
            return this.spanAndTranslate("tag4 tag4-t", "accepted");
        }
        if (Offer.isRejected(offer)) {
            return this.spanAndTranslate("tag1 tag1-t", "rejected");
        }
        if (DateUtils.isPast(offer?.event?.date)) {
            return this.spanAndTranslate("tag2 tag2-t", "expired");
        }
        return this.spanAndTranslate("tag3 tag3-t", "pending");
    }

    static spanAndTranslate(tag, text) {
        return (
            <span className={"badge badge-pill " + tag} style={{paddingLeft: "3px", paddingRight: "3px"}}>
                {Translation.find(text)}
            </span>
        );
    }

    static createCustomInput(id, label, type, icon, inputProps) {
        if (!inputProps) {
             inputProps = {};
        }
        inputProps.autoComplete = "off";
        return (
            <CustomInput
                id={id}
                labelText={label}
                formControlProps={{fullWidth: true}}
                inputProps={{
                    endAdornment: (<InputAdornment position="end"><Icon>{icon}</Icon></InputAdornment>),
                    type: type,
                    ...inputProps
                }}
            />
        );
    }

}
