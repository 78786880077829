import React from "react";

import {ViewUtils} from "../../utils/ViewUtils";
import GridContainer from "../../components/Grid/GridContainer";
import EventOfferTable from "./subviews/EventOfferTable";
import LocalData from "../../services/state/LocalData";
import { BaseComponent } from "../_subviews/BaseComponent";
import {Translation} from "../../core/service/Translation";

export default class OfferListPage extends BaseComponent {

    constructor(props: any) {
        super(props);
        this.state = {
            events: []
        };
    }

    componentDidMount = async () => {
        const data = await LocalData.offerList.load((state: any) => {
            this.setState(state);
        });
        this.setState(data);
    };

    render() {
        return <div>
            {ViewUtils.createCardTemplate("my-offers", Translation.find("my-offers"), null)}
            <GridContainer>
                {this.state.events.map((event: any, index: any) => {
                    return <EventOfferTable key={index} event={event} viewDetails={this.props.viewDetails} />
                })}
            </GridContainer>
        </div>
    }
}
