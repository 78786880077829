
import React from "react";
import HelpPageCollapse from "../subviews/HelpPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class OfferMake extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <HelpPageCollapse title={"Making offers"}>
                    <li> Select an event of interest from the dashboard or from the menu to the left </li>
                    <li> A new window will open with the details of the event and a Make Offer button </li>
                    <li> When creating an offer, you'll see three different sections:
                        <ol>
                            <li> Top is the description of the event</li>
                            <li> You can select products from your menu on the left and add them to the offer </li>
                            <li> The offer itself is on the right </li>
                        </ol>
                    </li>
                    <li> If all goes well, press the Make Offer button and the offer will be sent to the customer
                        (who will receive an email and see the offer on their dashboard and on the offer page) </li>
                </HelpPageCollapse>
            );
        }

        return (
            <HelpPageCollapse title={"Pakkumise koostamine"}>
                <li>Valige endale huvi pakkuv üritus töölaualt või ürituste soovide alt vasakul asuvast menüüst</li>
                <li>Avaneb uus aken ürituse detailidega ja nupp Tee Pakkumine</li>
                <li>Pakkumise tegemisel näeb kolme erinevat sektsiooni:
                    <ol>
                        <li>Kõige üleval ürituse soovi sisu</li>
                        <li>Vasakult saab valida oma menüüs olevaid tooteid ja neid pakkumise lisada</li>
                        <li>Pakkumine ise asub paremal pool</li>
                    </ol>
                </li>
                <li>Kui kõik tundub hea, siis vajutage nuppu Tee Pakkumine ja pakkumine saadetakse kliendile
                    (ta saab vastava e-kirja ning näeb pakkumist oma töölaual ja tehtud pakkumiste lehel)</li>
            </HelpPageCollapse>
        );
    }
}
