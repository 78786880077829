
export class StringUtils {
    static escape(input: any): string {
        if (!input) {
            return input;
        }
        return input.replace("\\t", "");
    }

    public static isNullOrEmpty(input: string) {
        return !input || input.length === 0;
    }

    public static isAnyNullOrEmpty(inputs: string[]) {
        for (let i = 0; i < inputs.length; i++) {
            if (StringUtils.isNullOrEmpty(inputs[i])) {
                return true;
            }
        }
        return false;
    }

    public static isValidEmail(email: string) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    public static replaceCommas(input: string) {
        return input.replace(",", ".");
    }

    public static truncate(input: string, length: number) {
        return (input.length > length) ? input.substr(0, length - 1) + '...' : input;
      };
}
