
export class CateringEnums {

    static MULTI_CATEGORY_ID = "multi_category";

    public static checkboxes = [
        { index: 0, id: "type-rest",      isChecked: false, selects: [ 0, 1, 2, 3, 5, 6 ]  },
        { index: 1, id: "type-catering",  isChecked: false, selects: [ 0, 1, 2, 3, 4, 5 ]  },
        { index: 2, id: "type-bar",       isChecked: false, selects: [ 0, 1, 4, 5] },
        { index: 3, id: "type-foodtruck", isChecked: false, selects: [ 1, 5 ] },
        { index: 4, id: "type-workshop", isChecked: false, selects: [ 0, 5 ] }
    ];

    public static checkedBoxes() {
       return CateringEnums.checkboxes.filter(box => box.isChecked);
    }

    public static checkedBoxIndexes() {
        const result = CateringEnums.checkedBoxes();
        return result?.map(box => box.index);
    }

    public static checkedBoxesEnum() {
        return this.checkedBoxes().map(box => { return box.index }).toString();
    }

    public static textByIndex(index?: any) {
        if (typeof index === "string") {
            index = parseInt(index);
        }
        const box = this.checkboxes.find(box => box.index === index);
        if (!box) {
            return;
        }
        return box.id;
    }

    public static availableSelects() {
        let result: number[] = [];
        const boxes = CateringEnums.checkedBoxes();
        boxes.forEach(box => {
            box.selects.forEach(select => {
               if (result.indexOf(select) === -1) {
                   result.push(select);
               }
            });
        });
        return result;
    }

    public static hideSelect(select: any) {
        return CateringEnums.availableSelects().indexOf(select.index)  === -1
    }

    public static clear() {
        this.checkboxes.forEach((box: any) => {
            box.isChecked = false;
        });
        this.subtypes.forEach((type: any) => {
            type.selectedValues = [];
        });

    }

    public static updateSelection(data: any) {

        this.subtypes.forEach((type: any) => {
            type.selectedValues = data[type.id];
        });

        const categories = data[CateringEnums.MULTI_CATEGORY_ID];
        if (!categories) {
            return;
        }

        const checkboxes = categories.map((item: string) => { return Number(item)});
        checkboxes.forEach((index: number) => {
           const box = this.checkboxes.find((box: any) => { return box.index === index });
           if (box) {
               box.isChecked = true;
           }
        });
    }

    public static findSubtypeByKey(key: string): any | undefined {
        return this.subtypes.find(type => type.id === key);
    }

    public static findItemTextBySubtype(subtype: any, index: any): string {
        if (typeof index === "string") {
            index = parseInt(index);
        }
        const item = subtype.items.find((item: any) => item.id === index);
        if (!item) {
            return "";
        }
        return item.tx_class;
    }

    public static subtypes = [
        {
            id: "multi_event",
            index: 0,
            tx: "event",
            items: [
                { id:  0, text: "", tx_class: "birthday" },
                { id:  1, text: "", tx_class: "wedding" },
                { id:  2, text: "", tx_class: "gathering" },
                { id:  3, text: "", tx_class: "reception" },
                { id:  4, text: "", tx_class: "conference" },
                { id:  5, text: "", tx_class: "childrens-party" },
                { id:  6, text: "", tx_class: "office-party" },
                { id:  7, text: "", tx_class: "bar-mitzvah" },
                { id:  8, text: "", tx_class: "tourism-group" },
                { id:  9, text: "", tx_class: "wine-workshop" },
                { id: 10, text: "", tx_class: "other" },
            ],
            selectedValues: []
        },
        {
            id: "multi_cuisine",
            index: 1,
            tx: "cuisine-type",
            items: [
                { id: 0, text: "", tx_class: "estonian" },
                { id: 1, text: "", tx_class: "fine-dining" },
                { id: 2, text: "", tx_class: "vegan" },
                { id: 3, text: "", tx_class: "italian" },
                { id: 4, text: "", tx_class: "asian" },
                { id: 12, text: "", tx_class: "arabic"},
                { id: 5, text: "", tx_class: "japanese" },
                { id: 6, text: "", tx_class: "chinese" },
                { id: 7, text: "", tx_class: "thai" },
                { id: 8, text: "", tx_class: "fast-food" },
                { id: 9, text: "", tx_class: "mexican" },
                { id: 10, text: "", tx_class: "beer-table" },
                { id: 11, text: "", tx_class: "rustic" },
            ],
            selectedValues: []
        },
        {
            id: "multi_service",
            index: 2,
            tx: "service-style",
            items: [
                { id: 0, text: "", tx_class: "buffet" },
                { id: 1, text: "", tx_class: "stand-up-event" },
                { id: 2, text: "", tx_class: "full-service" },
                { id: 3, text: "", tx_class: "service-on-tray" },
                { id: 4, text: "", tx_class: "corporate-meeting" },
                { id: 5, text: "", tx_class: "coffee-break" },
            ],
            selectedValues: []
        },
        {
            id: "multi_seating",
            index: 3,
            tx: "seating-style",
            items: [
                { id: 0, text: "", tx_class: "long-table" },
                { id: 1, text: "", tx_class: "separate-tables" }
            ],
            selectedValues: []
        },
        {
            id: "multi_rentals",
            index: 4,
            tx: "dishes-rentals",
            items: [
                { id: 0, text: "", tx_class: "wine-glasses" },
                { id: 1, text: "", tx_class: "coffee-sets" },
                { id: 2, text: "", tx_class: "plates" },
                { id: 3, text: "", tx_class: "service-dishes" },
                { id: 4, text: "", tx_class: "cuttlery" },
                { id: 5, text: "", tx_class: "tables" },
                { id: 6, text: "", tx_class: "chairs" },
                { id: 7, text: "", tx_class: "table-cloth" },
                { id: 8, text: "", tx_class: "fabric-napkins" },
                { id: 9, text: "", tx_class: "extra-furniture" },
                { id: 10, text: "", tx_class: "sound-system" },
                { id: 11, text: "", tx_class: "party-lights" },
                { id: 12, text: "", tx_class: "tents" },
            ],
            selectedValues: []
        },
        {
            id: "multi_drink",
            index: 5,
            tx: "drinks",
            items: [
                { id: 0, text: "", tx_class: "wines" },
                { id: 1, text: "", tx_class: "beers" },
                { id: 2, text: "", tx_class: "spirits" },
                { id: 3, text: "", tx_class: "hot-drinks" },
                { id: 4, text: "", tx_class: "soft-drinks" },
                { id: 5, text: "", tx_class: "cocktails" },
                { id: 6, text: "", tx_class: "craft-beer" },
                { id: 7, text: "", tx_class: "strong-alcoholic-beverage" },
                { id: 8, text: "", tx_class: "alcoholic-beverage" },
                { id: 9, text: "", tx_class: "non-alcoholic-beverage" },
            ],
            selectedValues: []
        },
        {
            id: "multi_other",
            index: 6,
            tx: "additional-benefits",
            items: [
                { id: 0, text: "", tx_class: "free-parking" },
                { id: 1, text: "", tx_class: "pets-allowed" },
                { id: 2, text: "", tx_class: "childrens-corner" },
                { id: 3, text: "", tx_class: "presentation-tools" },
                { id: 4, text: "", tx_class: "sound-system" },
            ],
            selectedValues: []
        }
    ];

    public static subTypeIds() {
        return this.subtypes.map(type => type.id);
    }

    public static fill(data: any) {
        const categories = data[this.MULTI_CATEGORY_ID];
        categories?.forEach((category: any) => {
            const box = this.checkboxes.find(box => box.index === parseInt(category));
            if (box) {
                box.isChecked = true;
            }
        });

        this.subtypes.forEach((type: any) => {
            const saved = data[type.id];
            if (saved) {
                type.selectedValues = saved;
            }

        });
    }

    public static grabSelectedSubtypes(event: any) {
        const result: string[] = [];
        this.subtypes.forEach((type: any) => {
            const existing = event[type.id];
            if (existing) {
                const indices = existing.split(",");
                indices.forEach((index: any) => {
                    result.push(this.findItemTextBySubtype(type, index))
                })
            }
        });

        return result;
    }

    public static typesFromList(values: string): string {
        let numbers = this.explode(values)
        let result = "";

        numbers.forEach((number: number) => {
            const box = this.checkboxes.find((box: any) => box.index === number);
            result += box?.id + ", ";
        });
        return result;

    }
    public static explode(values: string) {
        if (!values) {
            return [];
        }

        const split = values.split(",");
        return split.map(number => parseInt(number));
    }

}
