
import React from "react";
import HelpPageCollapse from "../subviews/HelpPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class EventCreate extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <HelpPageCollapse title={"Create event"}>
                    <li> Select Create Event from the menu </li>
                    <li> Fill out event information. It's important to fill it as detailed
                        as possible because it gives the caterer a better overview of special wishes </li>
                    <li> Adding an event is divided into three parts: event info, catering info, and confirmation </li>
                </HelpPageCollapse>
            );
        }

        return (
            <HelpPageCollapse title={"Ürituse loomine"}>
                <li>Valige menüüst Loo Üritus</li>
                <li>Täida ürituse kohta käiv info. Tähits on ka lisainfo osa,
                    mis annnab catereri parema ülevaate erisoovidest</li>
                <li>Ürituse lisamine on jagatud kolmeks osaks: ürituse info, catering info ja kinnitamine</li>
            </HelpPageCollapse>
        );
    }
}
