
import React from "react";

import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";
import CardFooter from "../../../components/Card/CardFooter.js";

import {withStyles} from "@mui/styles";
import style from "../../../assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { BaseComponent } from "../../_subviews/BaseComponent.js";

class AuthenticationBox extends BaseComponent {
    
    constructor(props) {
        super(props);
        this.state = {
            cardAnimation: "cardHidden"
        };
    }
    
    componentDidMount() {
        setTimeout(() => {
            this.setState({
                cardAnimation: ""
            })
        }, 300);
    }

    render() {
        const {classes, body, footer} = this.props;
        
        return (
            <GridContainer justifyContent="center" style={{marginTop: "50px"}} className={"login-boxes"}>
                <GridItem xs={10} sm={10} md={10}>
                    <form>
                        <Card login className={classes[this.state.cardAnimation]}>
                            <CardBody>
                                {body}
                            </CardBody>
                            <CardFooter className={classes.justifyContentCenter}>
                                {footer}
                            </CardFooter>
                        </Card>
                    </form>
                </GridItem>
            </GridContainer>
        );
    }
}

export default withStyles(style)(AuthenticationBox);