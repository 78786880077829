
import React, {CSSProperties} from "react";

import CateringTypeSelector from "../../_subviews/CateringTypeSelector";

import {Translation} from "../../../core/service/Translation";
import {BaseComponent} from "../../_subviews/BaseComponent";
import {ViewUtils} from "../../../utils/ViewUtils";
import {NewEvent} from "../../../services/NewEvent";
import GridContainer from "../../../components/Grid/GridContainer";

const style = {
    infoText: {
        margin: "10px 0 30px",
        textAlign: "center"
    } as CSSProperties
};

export default class Step2 extends BaseComponent {

    isComplete() {
        return true;
    }

    handleToggle() {
        this.forceUpdate();
    }

    sendState() {
        return this.state;
    }

    onChange(e: any) {
        if (this.props.callback) {
            this.props.callback({type: "save", tab: "catering", data: e.target.value});
        }
    }

    render() {
        return (
            <div>
                <h4 style={style.infoText}>{Translation.find("event-catering-info-subheader")}</h4>
                <CateringTypeSelector callback={this.props.callback}/>
                <h4 style={{...style.infoText, marginTop: "40px"}}>
                    {Translation.find("additional-info-subheader")}
                </h4>
                <GridContainer style={{marginTop: "30px", marginBottom: "30px", justifyContent: "center"}}>
                    {ViewUtils.createGridItem(this, "additional-info", 10, {
                        id: "additional-info",
                        multiline: true,
                        rows: 5,
                        placeholder: Translation.find("extra-comments-description"),
                        defaultValue: NewEvent.instance.findStoredValueForId("additional-info"),
                        onChange: this.onChange.bind(this)
                    })}
                </GridContainer>
            </div>
        );
    }
}
