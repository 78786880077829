import React from "react";
import EtiqPageCollapse from "../subviews/EtiqPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class Business extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <EtiqPageCollapse title={"Business"}>
                    <li>Event with a pre-agreed menu and protocol that requires the waiters to go unnoticed</li>
                </EtiqPageCollapse>
            );
        }

        return (
            <EtiqPageCollapse title={"Ärikohtumine"}>
                <li>Eelnevalt kokkulepitud menüü ja protokolliga üritus, mis eeldab teenindajate poolt jääda märkamatuks</li>
            </EtiqPageCollapse>
        );
    }
}