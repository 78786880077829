
import React from "react";

// @ts-ignore
import { ImagePicker } from 'react-file-picker'

import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardAvatar from "../../components/Card/CardAvatar.js";

import {Styles} from "../../styles/Styles";

import {BaseComponent} from "../_subviews/BaseComponent";
import {ImageUtils} from "../../utils/ImageUtils";

import {Translation} from "../../core/service/Translation";

export default class ProfileAvatarComponent extends BaseComponent {

    render() {
        return (
            <Card profile>
                <CardAvatar profile>
                    <img src={this.props.avatar} style={Styles.avatar} alt="..."/>
                </CardAvatar>
                <CardBody profile>
                    <ImagePicker
                        extensions={['jpg', 'jpeg', 'png']}
                        accept={"image/*"}
                        dims={ImageUtils.config()}
                        onChange={async (base64: string) => { this.props.onChange(base64); }}
                        onError={(errMsg: string) => { this.props.onError(errMsg); }}
                    >
                        <Button color="primary" round>{Translation.find("update-avatar")}</Button>
                    </ImagePicker>
                </CardBody>
            </Card>
        );
    }

}
