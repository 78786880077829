import React from "react";
import EtiqPageCollapse from "../subviews/EtiqPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class Rustic extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <EtiqPageCollapse title={"Rustic"}>
                    <li>A party table decorated with rustic style and earthy means, and serves food and drink appropriate to the area. Can also be linked to an era (medieval, early 20th century, etc.)</li>
                </EtiqPageCollapse>
            );
        }

        return (
            <EtiqPageCollapse title={"Rustikaalne"}>
                <li>Maalähedaste vahenditega dekoreeritud peolaud (ei pruugi olla lihtne ja vähese kaunistusega, kuid võib), millel pakutakse piirkonnale vastavat toitu ja jooki. Võib siduda ka endale sobiva ajastuga (keskaeg, 20.sajandi algus vms)</li>
            </EtiqPageCollapse>
        );
    }
}
