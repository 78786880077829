
import React from "react";

import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardIcon from "../../components/Card/CardIcon";
import CardHeader from "../../components/Card/CardHeader";

import {Styles} from "../../styles/Styles";
import {BaseComponent} from "../_subviews/BaseComponent";

import {Routing} from "../../services/Routing";
import {Language} from "../../core/model/enum/Language";
import {LocalStorage} from "../../services/LocalStorage";
import {ViewUtils} from "../../utils/ViewUtils";
import HelpPageContent from "./HelpPageContent";

export default class HelpPage extends BaseComponent {

    render() {
        const param: Language = Routing.instance.getUrlParameter("lang")!;
        const english = (param === Language.En) ? true : LocalStorage.instance.settings.english();

        return (
            <div className={"help-ol"} style={{marginTop: "20px", marginBottom: "20px"}}>

                <GridContainer justifyContent="center">
                    <GridItem xs={12} sm={12} md={8}>
                        <Card className={"z-margin"}>
                            <CardHeader color="default">
                                <CardIcon color={"info"}>
                                    {ViewUtils.createIcon("settings")}
                                </CardIcon>
                                <h4 style={Styles.cardIconTitle}>{english ? "Help" : "Abi"}</h4>
                            </CardHeader>
                        </Card>
                        <HelpPageContent en={english}/>
                    </GridItem>
                </GridContainer>

            </div>
        );
    }
}

