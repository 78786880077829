
import React, {CSSProperties} from 'react';
import {withTranslation} from "react-i18next";
import ReactGA from 'react-ga4'

import "./assets/css/material-dashboard-pro-react.css";
import {drawerMiniWidth, drawerWidth, transition} from "./assets/jss/material-dashboard-pro-react";

import AdminNavbar from "./components/Navbars/AdminNavbar";
import Sidebar from "./components/Sidebar/Sidebar.js";
import Footer from "./components/Footer/Footer";

import ACNotifier from "./views/_subviews/ac-components/ACNotifier";
import BetaLabel from "./views/_subviews/BetaLabel";
import {BaseComponent} from "./views/_subviews/BaseComponent";

import LandingPage from "./views/landing-page/LandingPage";
import RequestsPage from "./views/requests/RequestsPage";
import ProfilePage from "./views/profile/ProfilePage";
import SettingsPage from "./views/settings/SettingsPage";
import OfferListPage from "./views/offer-list/OfferListPage";
import InvoiceListPage from "./views/invoice-list/InvoiceListPage";
import MenuPage from "./views/menu/MenuPage";
import DashboardPage from "./views/dashboard/DashboardPage";
import FeedbackPage from "./views/feedback/FeedbackPage";
import EventCreatePage from "./views/event-create/EventCreatePage";
import OfferHistoryPage from "./views/offer-history/OfferHistoryPage";
import OfferPage from "./views/offer/OfferPage";
import EventHistoryPage from "./views/event-history/EventHistoryPage";
import InvoicePage from "./views/invoice/InvoicePage";
import RatingPage from "./views/rating/RatingPage";
import VerificationPage from "./views/verify/VerificationPage";
import ResetPasswordPage from "./views/reset/ResetPasswordPage";
import PaymentRedirectPage from "./views/payment/redirect/PaymentRedirectPage";
import AdminPage from "./views/admin/AdminPage";
import TermsPage from "./views/terms/TermsPage";
import HelpPage from "./views/help/HelpPage";

import CateringRegion from "./core/model/enum/CateringRegion";
import {Networking} from "./services/Networking";
import {LocalStorage} from "./services/LocalStorage";
import {CurrentUser} from "./services/dto/CurrentUser";
import {RouteEnumeration, Routing} from "./services/Routing";
import {Translation} from "./core/service/Translation";
import LocalData from "./services/state/LocalData";
import {ViewUtils} from "./utils/ViewUtils";
import PaymentResultPage from './views/payment/result/PaymentResultPage';

import i18n from "i18next";
import CatererAgreementPage from './views/terms/CatererAgreementPage';
import ReleaseNotesPage from './views/release-notes/ReleaseNotesPage';

class App extends BaseComponent {

    constructor(props: any) {
        super(props);
        this.state = {
            mobileOpen: false,
            miniActive: false,
        };
        
        Translation.initialize(this.props.t);
    }

    componentDidMount = async () => {

        ReactGA.initialize("G-X5R51XS1S9");//, [options]);
        ReactGA.send("pageview");

        await this.loadData();
        window.onhashchange = () => {
            this.forceUpdate();
        };
        CateringRegion.load();
    };

    async loadData() {
        if (Routing.instance.isAtVerificationPage()) {
            return;
        }
        const userResponse = await Networking.instance.fetchUserInfo();
        if (userResponse.error) {
            LocalStorage.instance.clear();
        }

        const language = Routing.instance.getUrlParameter("lang");
        if (language) {
            LocalStorage.instance.updateLanguage(language);
            await i18n.changeLanguage(language);
        }
        
        CurrentUser.instance.set(userResponse);
        this.reload();
    }


    reload() {
        Routing.instance.calculate(this.props);
        this.forceUpdate();
    }

    handleDrawerToggle() {
        this.setState({mobileOpen: !this.state.mobileOpen});
    }

    sidebarMinimize() {
        this.setState({miniActive: !this.state.miniActive});
    }

    panelStyle(): CSSProperties {
        return {
            transitionProperty: "top, bottom, width",
            transitionDuration: ".2s, .2s, .35s",
            transitionTimingFunction: "linear, linear, ease",
            width: this.panelWidth(),
            overflow: "auto",
            position: "relative",
            float: "right",
            ...transition,
            maxHeight: "100%",
            // @ts-ignore
            overflowScrolling: "touch"
        }
    }
    panelWidth() {
        if (ViewUtils.isMobile()) {
            return "100%";
        }
        if (this.state.miniActive) {
            return `calc(100% - ${drawerMiniWidth}px)`;
        }
        return `calc(100% - ${drawerWidth}px)`;
    }

    render() {

        if (CurrentUser.instance.exists() && CurrentUser.instance.isCaterer() && !LocalStorage.instance.hasCatererAgreed()) {
            return <CatererAgreementPage agreed={() => {
                this.reload();
            }}/>
        }    
        
        if (Routing.instance.isAtHelpPage()) {
            return <HelpPage/>
        }
        if (Routing.instance.isAtTermsPage()) {
            return <TermsPage/>
        }
        if (Routing.instance.isAtPaymentRedirectPage()) {
            return <PaymentRedirectPage/>
        }
        if (Routing.instance.isAtResetPasswordPage()) {
            return <ResetPasswordPage reset={async (title: string, content: string) => {
                this.showNotification(title, content);
                Routing.instance.to(Routing.Enumeration.Dashboard);
                await Networking.instance.refreshToken();
                await this.loadData();
            }}/>
        }

        if (Routing.instance.isAtVerificationPage()) {
            return <VerificationPage verified={async () => {
                Routing.instance.to(Routing.Enumeration.Dashboard + Routing.Enumeration.Profile);
                await Networking.instance.refreshToken();
                await this.loadData();
            }}/>
        }

        if (!LocalStorage.instance.isLoggedIn()) {
            return (<LandingPage parent={this} reload={async () => {
                    await this.loadData();
                }
            }/>);
        }

        return (
            <div>
                <BetaLabel/>
                <ACNotifier titleKey={this.state.titleKey} contentKey={this.state.contentKey} confirmed={() => {
                    this.showNotification(undefined, undefined);
                }}/>
                <Sidebar
                    routes={Routing.instance.calculate(this.props)}
                    logoText={"Allicater"}
                    handleDrawerToggle={this.handleDrawerToggle.bind(this)}
                    open={this.state.mobileOpen}
                    miniActive={this.state.miniActive}
                    user={CurrentUser.instance.get()}
                    onRedirect={this.onRedirect.bind(this)}
                    onClick={(action: string) => {
                        if (action === "log-out") {
                            this.logOut();
                        }
                    }}
                />
                <div style={this.panelStyle()}>
                    <AdminNavbar
                        sidebarMinimize={this.sidebarMinimize.bind(this)}
                        miniActive={this.state.miniActive}
                        brandText={"Dashboard"}
                        handleDrawerToggle={this.handleDrawerToggle.bind(this)}
                        dashboardClick={this.showDashboard.bind(this)}
                        profileClick={this.showProfile.bind(this)}
                        settingsClick={this.showSettings.bind(this)}
                        logoutClick={this.logOut.bind(this)}
                    />
                    <div style={{marginTop: "70px", padding: "30px 15px", minHeight: "calc(100vh - 123px)"}}>
                        <div style={{maxWidth: "1480px", margin: "auto"}}>
                            {this.decideContent()}
                        </div>
                    </div>
                    <Footer languageUpdate={() => {
                        this.reload();
                    }}/>
                </div>
            </div>
        );
    }

    decideContent() {
        if (Routing.instance.isAt(Routing.Enumeration.ReleaseNotes)) {
            return <ReleaseNotesPage />
        }
        if (Routing.instance.isAt(Routing.Enumeration.Admin)) {
            return <AdminPage viewDetails={this.viewInvoice.bind(this)}/>
        }
        if (Routing.instance.isAt(Routing.Enumeration.Profile)) {
            return <ProfilePage alert={this.onShowAlert.bind(this)}/>
        }
        if (Routing.instance.isAt(Routing.Enumeration.Requests)) {
            return <RequestsPage viewOffer={this.viewOffer.bind(this)} makeOffer={this.makeOffer.bind(this)}/>
        }
        if (Routing.instance.isAt(Routing.Enumeration.EventHistory)) {
            return <EventHistoryPage viewOffer={this.viewOffer.bind(this)}/>
        }
        if (Routing.instance.isAt(Routing.Enumeration.Settings)) {
            return <SettingsPage languageUpdate={() => {this.reload();}}/>
        }
        if (Routing.instance.isAt(Routing.Enumeration.Offer)) {
            return <OfferPage alert={this.onShowAlert.bind(this)}/>
        }
        if (Routing.instance.isAt(Routing.Enumeration.OfferList)) {
            return <OfferListPage viewDetails={this.viewOffer.bind(this)}/>
        }
        if (Routing.instance.isAt(Routing.Enumeration.OfferHistory)) {
            return <OfferHistoryPage viewOffer={this.viewOffer.bind(this)}/>
        }

        if (Routing.instance.isAt(Routing.Enumeration.Dashboard)) {
            return (
                <DashboardPage
                    editEvent={this.editEvent.bind(this)}
                    viewOffer={this.viewOffer.bind(this)}
                    makeOffer={this.makeOffer.bind(this)}
                    viewInvoice={this.viewInvoice.bind(this)}
                    giveReview={this.giveRating.bind(this)}/>
            );
        }
        if (Routing.instance.isAt(Routing.Enumeration.Menu)) {
            return <MenuPage alert={this.onShowAlert.bind(this)}/>
        }
        if (Routing.instance.isAt(Routing.Enumeration.Invoice)) {
            return <InvoicePage/>
        }
        if (Routing.instance.isAt(Routing.Enumeration.InvoiceList)) {
            return <InvoiceListPage viewDetails={this.viewInvoice.bind(this)}/>
        }
        if (Routing.instance.isAt(Routing.Enumeration.Feedback)) {
            return <FeedbackPage alert={this.onShowAlert.bind(this)}/>
        }
        if (Routing.instance.isAt(Routing.Enumeration.EventCreate)) {
            return <EventCreatePage alert={this.onShowAlert.bind(this)}/>
        }
        if (Routing.instance.isAt(Routing.Enumeration.Rate)) {
            return <RatingPage alert={this.onShowAlert.bind(this)}/>
        }
        if (Routing.instance.isAt(Routing.Enumeration.PaymentResult)) {
            return <PaymentResultPage/>
        }
        
        return null;
    }

    onRedirect(path: string) {
        if (path === RouteEnumeration.Dashboard) {
            Routing.instance.to(Routing.Enumeration.Dashboard);
        } else {
            Routing.instance.to(Routing.Enumeration.Dashboard + path);
        }
        
        this.setState({mobileOpen: false});
    }

    showDashboard() {
        Routing.instance.to(Routing.Enumeration.Dashboard + Routing.Enumeration.Dashboard);
        this.setState({});
    }

    showProfile() {
        Routing.instance.to(Routing.Enumeration.Dashboard + Routing.Enumeration.Profile);
        this.setState({});
    }

    showSettings() {
        Routing.instance.to(Routing.Enumeration.Dashboard + Routing.Enumeration.Settings);
        this.setState({});
    }

    logOut() {
        Routing.instance.clear();
        LocalStorage.instance.clear();
        LocalData.clear();
        this.forceUpdate();
    }

    editEvent(event: any) {
        const params = Routing.Enumeration.ParamStart + "eventId=" + event.id;
        Routing.instance.to(Routing.Enumeration.Dashboard + Routing.Enumeration.EventCreate + params);
        this.setState({});
    }

    viewInvoice(invoice: any) {
        const params = "id=" + invoice.id;
        Routing.instance.to(Routing.Enumeration.Dashboard + Routing.Enumeration.Invoice + params);
        this.setState({});
    }

    giveRating(rating: any) {
        const eventId = rating.offer?.event?.id;
        const params = "eventId=" + eventId + "&recipientId=" + rating.recipient?.id + "&offerId=" + rating.offer?.id;
        Routing.instance.to(Routing.Enumeration.Dashboard + Routing.Enumeration.Rate + params);
        this.setState({});
    }

    viewOffer(event: any, offer: any) {
        const params = "eventId=" + event.id + "&offerId=" + offer.id;
        Routing.instance.to(Routing.Enumeration.Dashboard + Routing.Enumeration.Offer + params);
        this.setState({});
    }

    makeOffer(event: any) {
        const params = "eventId=" + event.id;
        Routing.instance.to(Routing.Enumeration.Dashboard + Routing.Enumeration.Offer + params);
        this.setState({});
    }

    onShowAlert(title: string, content: string, redirect: string) {
        this.showNotification(title, content);
        if (redirect) { Routing.instance.to(redirect); }
    }
    showNotification(titleKey?: string, contentKey?: string) {
        this.setState({ titleKey: titleKey, contentKey: contentKey });
    }
}

export default withTranslation()(App);

