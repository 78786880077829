
import React from "react";

import { BaseComponent } from "../_subviews/BaseComponent";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";

import Button from "../../components/CustomButtons/Button";
import { Translation } from "../../core/service/Translation";
import { LocalStorage } from "../../services/LocalStorage";

export default class CatererAgreementPage extends BaseComponent {

    FILENAME = "/docs/catereri-vahendusleping.pdf";

    constructor(props: any) {
        super(props);
        this.state = {
            pages: {
                current: 1,
                total: 0
            }
        }
    }

    render() {
        return (
            <GridContainer justifyContent="center">
                <GridItem xs={12} sm={12} md={8} style={{marginTop: "20px"}}>
                <p><span >ALLICATER.COM VAHENDUSLEPING</span></p>
<ol>
<li  aria-level={1}><span >LEPINGU EESMÄRK</span>
<ol>
<li  aria-level={2}><span >Lepingu eesmärk on tõsta teenusepakkuja teenuste silmapaistvust ja kättesaadavust läbi elektroonilise müügisüsteemi allicater.com. Selle eesmärgi täitmiseks on vahendajal õigus vahendada teenusepakkuja teenuseid vastavalt vahendaja platvormist allicater.com kinnitatud tellimustele ja hankida teenusepakkujale kliente (edaspidi </span><b>„tellija“</b><span >) käesolevas lepingus määratud tasu eest.</span></li>
<li  aria-level={2}><span >Vahendaja pakub tellijatele teenusepakkuja poolt teostatavat cateringiteenust elektroonilise müügisüsteemi kaudu allicater.com . </span></li>
<li  aria-level={2}><span >Teenusepakkuja vastutab tervikuna enda poolt pakutavate teenuste eest. Vahendaja ise teenuseid ei osuta, mistõttu vahendaja ei vastuta mitte mingil moel enda poolt vahendatava teenuse eest. </span></li>
<li  aria-level={2}><span >Vahendaja ei vastuta mitte mingis osas kahju eest, mis teenusepakkujale, Tellijale või mõnele kolmandale osapoolele teenust osutades kas hooletusest, tahtlikult või muul viisil tekkida võib. Tekkinud kahjunõuded kohustuvad teenusepakkuja ja Tellija lahendama ilma vahendaja osaluseta.</span></li>
<li  aria-level={2}><span >Vahendaja vastutus on piiratud allicater.com müügisüsteemi tegevusega.</span></li>
<li  aria-level={2}><span >Teenusepakkuja vastutab Tellijate kaebuste menetlemise eest. </span></li>
<li  aria-level={2}><span >Vahendajal on õigus vahendada oma müügisüsteemis teiste teenusepakkujate cateringi – ja toitlustamisteenuseid. Antud leping ei ole mitte mingil moel seotud konkurentsikeeluga kolmandate osapoolte suhtes.</span></li>
<li  aria-level={2}><span >Teenusepakkuja vastutab vahendajale või Tellijale esitatud andmete õigsuse eest ning võimalike süsteemis olevate andmete muudatuste eest</span></li>
</ol>
</li>
<li  aria-level={1}><span >VAHENDATAVAD TEENUSED, NENDE EEST TASUMINE JA VAHENDUSTASU</span>
<ol>
<li  aria-level={2}><span >Teenusepakkuja poolt pakutatavad tooted on kirjeldatud müügisüsteemis allicater.com ning teenusepakkujal on õigus oma tooteid ja nende kirjeldusi müügisüsttemis muuta, kustutada või lisada talle sobival ajal ilma vahendajat sellest teavitamata. Allicater.com poolt tekkinud tehnilise tõrke korral on vahendajal kohustus tõrge eemaldada mitte hiljem kui 48 tundi pärast tõrke ilmnemist. Teenusepakkuja peab tõrke ilmnemisest vahendajat teavitama e-kirja teel </span><a href="mailto:support@allicater.com"><span >support@allicater.com</span></a><span >.</span></li>
<li  aria-level={2}><span >Vahendaja poolt loodud elektrooniline müügisüsteem võimaldab automaatselt vastavalt Tellija poolt sisestatud toitlustusteenuse parameetritele välja valida kolm kuni viis teenusepakkujat. Automaatselt väljavalitud teenusepakkujad saavad e-posti teel teate selle kohta, et Tellija poolt sisestatud parameetrite alustel oodatakse teenusepakkujalt cateringi pakkumist. Teenusepakkuja kohustub Tellijale vastama kolme päeva jooksul, vastasel juhul langeb teenusepakkuja Tellijale saadetud valimist välja.</span></li>
<li  aria-level={2}><span >Vahendaja esitab Tellijale elektroonilise ettemaksuarve mahus 20% kogu kinnitatud tellimusest pärast seda Post Innovations OÜ poolt, kui Tellija on kinnitanud teenusepakkuja poolt koostatud lõpliku pakkumise. Ettemaksuarve maksetähtaeg on 7 päeva. Lõplik osa arvest (80% kogutellimusest) esitatakse Vahendaja poolt pärast ürituse toimumist maksetähtajaga 7 päeva.  </span></li>
<li  aria-level={2}><span >Vahendaja koostab Teenusepakkuja poolt arve, mille maksja on Post Innovations OÜ ning arve maksetähtaeg on 10 päeva. Post Innovations OÜ poolt edastatakse arve automaatselt Teenusepakkujale e-kirja teel.</span></li>
<li  aria-level={2}><span >Vahendustasu on 3 &#8211; 9% ja arvutatakse kord kvartalis algoritmiga, mis võtab muuhulgas arvesse teenusepakkuja käibe, kliendi tagasiside, cateringide arvu jm. </span></li>
<li  aria-level={2}><span >Kui Tellija esitab reklamatsiooni talle osutatud teenuse eest, siis tekkinud vaidlused lahendatakse Tellija ja Teenusepakkuja vahel. Teenusepakkuja on kohustatud antud lahendist teatama Vahendajat, kui peaks olema vajadus muuta ümber tehtud töö eest esitatud arve Tellijale. Arve muutmise korral kuulub samas ulatuses korrigeerimisele ka Teenusepakkuja poolt Vahendajale esitatud arve. </span></li>
<li  aria-level={2}><span >Teenusepakkuja poolt Vahendajale esitatud arvel arvestatakse maha Teenusepakkuja ja Vahendaja vahel kokkulepitud vahendustasu.</span></li>
<li  aria-level={2}><span >Lepinguosalised on kokku leppinud, et Vahendaja poolt vahendatud teenus loetakse Teenusepakkuja poolt teostatuks, kui Tellija on müügisüsteemis teinud märke teenuse kättesaamise / teostamise kohta.</span></li>
<li  aria-level={2}><span >Kõikide lisateenuste – ja toodete osas, mida teenusepakkuja läbi Vahendaja Tellijale osutab, teostatakse makse samuti läbi Vahendaja. Teenusepakkujal ei ole Vahendajalt õigus saada tasu lisateenuste – ja toodete eest, mis ei sisaldu müügisüsteemi kaudu tehtud pakkumises Tellijale.</span></li>
<li  aria-level={2}><span >Vahendaja kogub Tellijate käest makseid Teenusepakkuja toodete eest Allicater teenuse kaudu Teenusepakkuja nimel ja Allicateri kontol. Selguse huvides &#8211;  Allicater ei tegutse makseteenuse pakkujana Teenusepakkuja ja Tellijate vahel. Peale seda kui Tellija on vahendaja kaudu tellitud ja Teenusepakkuja poolt teostatud tööde / toodete eest tasunud Vahendaja poolt esitatud arve alusel Vahendaja pangakontole, on Teenusepakkujal võimalik tasu võtta ainult Vahendajalt, mitte Tellijalt.</span></li>
<li  aria-level={2}><span >Kõik arveldused esitatakse arvel ilma käibemaksuta ning lõplik summa esitatakse koos käibemaksuga, mis on arvetel eraldi välja toodud. </span></li>
</ol>
</li>
<li  aria-level={1}><span >TEENUSEPAKKUJA KOHUSTUSED JA ÕIGUSED </span>
<ol>
<li  aria-level={2}><span >Teenusepakkuja kohustused on:</span>
<ol>
<li  aria-level={3}><span >Pakkuda Tellijale kokkulepitud teenuseid ja tooteid vastavalt kokkulepitud kuupäevale ja kellaajale. Kui ilmaoludest või muul vääramatu jõuga seotud tingimustest ei ole võimalik tellitud töid teostada, tuleb Teenusepakkujal Vahendajat antud asjaoludest koheselt teavitada. </span></li>
<li  aria-level={3}><span > Hoolitseda selle eest, et pakutavad teenused / tooted oleksid ohutud ja vastaksid Veterinaar – ja toiduameti poolt kehtestatud nõuetele, Eesti Vabariigis kehtestatud seadustele, muudele õigusaktidele ja ettekirjutustele. Osutatavad teenused peavad vastama üldkehtivale tasemele ja standarditele. Teenusepakkuja vastutab täiel määral antud nõuete mittetäitmise eest.</span></li>
<li  aria-level={3}><span >Tagada, et teenusepakkuja poolt vahendajale esitatud arved vastaksid nendele hindadele, mis on tellija poolt kinnitatud pakkumisel kirjas.</span></li>
<li  aria-level={3}><span >Kindlustada, et Tellija poolt kinnitatud hinnapakkumise hinnad kaubaartiklite lõikes ei muutu. Tellitud koguste muutmisel muutub lõpphind tänu koguse muutmisele, kuid toote tükihind ei tohi muutuda. Teenusepakkuja on kohustatud esitama Allicater müügisüsteemis toodete hinnad koos käibemaksuga.</span></li>
<li  aria-level={3}><span >Olukordades, kus Teenusepakkuja ei suuda kokkulepitud teenust osutada, teavitada elektroonilisel teel vahendajat ja tellijat mitte hiljem kui 7 päeva enne tellitud ürituse toimumist. Teenusepakkuja peab olema valmis hüvitama sellest Vahendajale ja tellijale tekkiva otsese kahju. Teenusepakkuja peab tasuma Vahendajale kokkulepitud teenustasu vastavalt Vahendaja poolt esitatud arvele.</span></li>
<li  aria-level={3}><span >Lisada müügikeskkonda oma toodete fotod, mis peavad vastama tegelikkusele. Toodete fotod teeb, töötleb ja lisab müügikeskkonda Teenusepakkuja ise ning Teenusepakkujale jäävad fotode autoriõigused 100%liselt. Vahendaja ei tohi antud fotosid kasutada mujal kui ainult müügisüsteemis ja elektroonilistes turunduskanalites (Facebook, Instagram jms).</span></li>
<li  aria-level={3}><span >Kaitsta, hüvitada ja vabastada vahendaja mis tahes nõuete, toimingute või nõudmist eest, sealhulgas piiramata mõistlikud õigusabikulud, mis tulenevad kõigist teenusepakkuja toodetest või teenusepakkuja lepingu rikkumisest.</span></li>
</ol>
</li>
<li  aria-level={2}><span >Teenusepakkuja õigused on:</span>
<ol>
<li  aria-level={3}><span >Müüa oma tooteid ja teenuseid läbi teiste vahendajate ning ka iseseisvalt käesolevast lepingust sõltumata.</span></li>
<li  aria-level={3}><span >Otsustada kinnitatud tellimuste täideviimise viiside ja vahendite üle iseseisvalt, kui need vastavad Eesti Vabariigis kohaldatavate seadustele, määrustele ja ametkondlikele nõuetele, mis reguleerivad teenusepakkuja tegevust ja vastavad tegevusalal üldiselt valitsevale heale tavale ja standarditele.</span></li>
</ol>
</li>
</ol>
</li>
<li  aria-level={1}><span >VAHENDAJA KOHUSTUSED JA ÕIGUSED</span>
<ol>
<li  aria-level={2}><span >Vahendaja kohustused on:</span>
<ol>
<li  aria-level={3}><span >Vahendada tooteid / teenuseid teenusepakkuja poolt kehtestatud hindadega. Vahendaja ei tohi iseseisvalt muuta müügisüsteemis teenusepakkuja poolt kehtestatud hindasid.</span></li>
<li  aria-level={3}><span >Kanda teenusepakkujale üle tema poolt teostatud ja tellija poolt kinnitatud toodete ja teenuste eest kokkulepitud tasu vastavalt teenusepakkuja poolt esitatud arvele mitte hiljem kui 10 päeva pärast arve kättesaamist. Teenusepakkuja poolt esitatud arvel peab olema kajastatud maksmisele kuuluvast summast maha arvestatav kokkulepitud vahendustasu. Vahendaja kannab tasu üle käesolevas lepingus kokkulepitud teenusepakkuja pangakontole.</span></li>
<li  aria-level={3}><span >Selgitada läbi müügisüsteemis kajastatud kasutustingimuste tellijale ühemõtteliselt asjaolust, et tellitud toodete ja teenuste kvaliteedi ja ohutuse eest vastutab teenusepakkuja.</span></li>
<li  aria-level={3}><span >Esindada teenusepakkujat müügisüsteemis professionaalselt ja pakkuda vahendusteenust vastavalt kehtivatele seadusaktidele ning tegevusalal valitsevale heale tasemele ja standarditele.</span></li>
<li  aria-level={3}><span >Teatada teenusepakkujale teenuste tingimuste muutustest vähemalt 30 päeva ette.</span></li>
</ol>
</li>
<li  aria-level={2}><span >Vahendaja õigused on:</span>
<ol>
<li  aria-level={3}><span >Vahendada muid teenusepakkuja toodetega samalaadseid tooteid ja teenuseid läbi vahendaja müügisüsteemi.</span></li>
<li  aria-level={3}><span >Levitada läbi elektrooniliste turunduskanalite ja oma müügimeeskonna teenusepakkuja poolt pakutavaid tooteid ning teenuseid. Vahendaja jätab endale õiguse kasutada teenusepakkuja nime või brändi vahendaja teenuse turunduse viitena.</span></li>
</ol>
</li>
</ol>
</li>
<li  aria-level={1}><span >KONFIDENTSIAALSUS</span>
<ol>
<li  aria-level={2}><span >Lepinguosalised kohustuvad käesoleva lepingu kehtivusajal ja ka pärast selle lõppemist lepingutingimusi mitte avaldama kolmandatele osapooltele. Lepingutingimused on konfidentsiaalsed.</span></li>
<li  aria-level={2}><span >Lepingu osapooled ei tohi mitte mingil juhul avaldada kolmandale osapoolele teenuste / toodete tellijate teavet.</span></li>
<li  aria-level={2}><span >Antud punktis käsitletud konfidentsiaalsustingimuste rikkuja vastutab kõigi rikkumistest  tulenevate otseste kahjude eest. Nimetatud vastutus ei kõrvalda vastutust muude võimalike kahjude eest.</span></li>
</ol>
</li>
<li  aria-level={1}><span >LEPINGU KEHTIVUS, MUUTMINE JA LÕPETAMINE</span>
<ol>
<li  aria-level={2}><span >Leping loetakse sõlmituks, kui teenusepakkuja aktsepteerib müügisüsteemis konto loomisel lepingu tingimusi, klõpsates nuppu „Nõustun lepingutingimustega“. Leping kehtib tähtajatult.</span></li>
<li  aria-level={2}><span >Mõlemad osapooled on kohustatud viivitamatult üksteisele teatama kontaktandmete, allkirjaõigusliku liikme ja kontaktisiku liikme muutumisest.</span></li>
<li  aria-level={2}><span >Mõlemad lepinguosalised võivad lepingu lõpetada ühepoolselt, teavitades sellest teist osapoolt vähemalt 60 (kuuskümmend) päeva ette.</span></li>
<li  aria-level={2}><span >Mõlemad lepinguosalised võivad lepingu ühepoolselt lõpetada ilma etteteatamistähtajata, kui teine lepinguosaline on rikkunud oluliselt lepingus kehtestatud tingimusi. Oluliseks rikkumiseks loetakse muuhulgas igasugune rikkumine, mille kõrvaldamiseks on antud poolele edutult täiendav mõistlik tähtaeg.</span></li>
<li  aria-level={2}><span >Lepingu lõppemine ei vabasta pooli enne lepingu lõppemist kinnitatud tellimuste täitmisest ja selliste tellimustega seotud maksekohustustest. </span></li>
<li  aria-level={2}><span >Lepingu võib lõpetada mistahes ajal mõlema lepinguosalise nõusolekul.</span></li>
<li  aria-level={2}><span >Lepinguosaline, kes käesoleva lepingu lõpetab või üles ütleb muul viisil, kui siinkohal kokkulepitud moel, on kohustatud hüvitama sellest tekkivad teise lepinguosapoole otsesed ja kaudsed kulud.</span></li>
<li  aria-level={2}><span >Käesolevat lepingut kohaldatakse ka kõikidele müügisüsteemi lisatavatele uutele funktsioonidele.</span></li>
</ol>
</li>
<li  aria-level={1}><span >KOHALDATAV ÕIGUS JA ERIMEELSUSTE LAHENDAMINE</span>
<ol>
<li  aria-level={2}><span >Lepingu suhtes kohaldatakse Eesti Vabariigis kehtivaid seadusi, määrusi ja nõudeid.</span></li>
<li  aria-level={2}><span >Lepinguosalised proovivad lahendada lepingu täitmisega seotud vaidlused läbirääkimiste teel. </span></li>
</ol>
</li>
</ol>
<p>&nbsp;</p>
                    
                </GridItem>
                <GridItem xs={12} sm={12} md={8} style={{marginTop: "20px", marginBottom: "20px"}}>
                    <Button color={"success"} onClick={this.onAgree.bind(this)}>{Translation.find("i-agree")}</Button>
                </GridItem>
            </GridContainer>
        );
    }

    enumeratePageCount(): any {
        const mapped = Array.apply(null, Array(this.state.pages.total)).map((x, i) => i + 1);
        return <div style={{display: "flex", paddingLeft: "5px", marginTop: "10px"}}>{mapped.map(number => <p style={{marginRight: "10px", cursor: "pointer", textDecoration: "underline"}} onClick={() => {
            this.setState({
                pages: {...this.state.pages, current: number}
            });
        }}>{number}</p>)}</div>;
    }

    onLoad(pdf: any) {
        this.setState({
            pages: {...this.state.pages, total: pdf._pdfInfo.numPages}
        });
    }

    onAgree() {
        LocalStorage.instance.setCatererAgreed();
        this.props.agreed();
    }
}