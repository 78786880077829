import React from "react";

import {BaseComponent} from "../BaseComponent";

export class TermsPopupContentET extends BaseComponent {

    render() {
        return (
            <div>
                <p><strong>Kasutaja Kasutustingimused Allicater.com</strong></p>
                <p>Allicater’i kasutajakonto loomisega ja teenuste kasutamisega nõustute
                    alljärgnevate kasutustingimustega (edaspidi <strong>„Kasutustingimused“</strong>).</p>
                <p>Palun lugege Kasutustingimused tähelepanelikult läbi enne, kui asute kasutama Allicater’i teenuseid.
                    Kui Te ei peaks Kasutustingimustega nõustuma, ei ole Teil võimalik Allicater’i teenuseid kasutada.
                    Kasutustingimused kohalduvad kõigile Allicater’i vahendusplatvormi kaudu tellitud teenustele.
                    Allicater’i platvormil Tellimuse esitamise ja kinnitamise kaudu sõlmite Te Tellimuse kinnitanud
                    Teenusepakkujaga Teenuselepingu ning sellisel juhul on Kasutustingimused Tellija (Teie)
                    ja Teenusepakkuja vahel sõlmitud Teenuselepingu lahutamatuks osaks.</p>
                <p>Kasutajakonto loomisega ja Allicater’i teenuste kasutamisega kinnitate ühtlasi,
                    et Te olete täisealine teovõimeline isik ning Teil on õigus Kasutustingimustes nimetatud teenuseid
                    kasutada ja tellida. Juriidilise isiku nimel kasutajakonto loomisega kinnitate Te lisaks,
                    et Teil on kehtivad volitused ja vajalikud nõusolekud Kasutustingimustes nimetatud teenuste
                    kasutamiseks ja tellimiseks vastava juriidilise isiku nimel. </p>
                <p>Kui olete tarbija, siis palun arvestage, et tulenevalt Euroopa parlamendi ja nõukogu direktiivi
                    (EL) 2011/83 artikkel 16 punktist (L) puudub Teil sõlmitud Teenuselepingu osas taganemise õigus.</p>
                <p><strong>1. Mõisted</strong></p>
                <p><strong>„Allicater“</strong> on<strong> Post Innovations OÜ</strong> (registrikood: 14331746; asukoht:
                    Riia 181a, Tartu, 51004, Eesti; e-posti aadress: support@allicater.com), kes vahendab Tellijatele
                    Teenusepakkujate Teenuseid tema poolt hallataval platvormil, mis on kasutajatele kättesaadav
                    veebilehel allicater.com ja mobiilirakenduses (Allicater’i äpp).</p>
                <p><strong>„Vahendusteenus“</strong> on Allicater’i poolt teostatav vahendustegevus
                    Teenusepakkujate Teenuste vahendamisel Tellijatele.</p>
                <p><strong> „Teenusepakkuja“</strong> tähendab toitlustusasutust (restoran, pubi, baar,
                    cateringiettevõte, toiduauto, toidu õpitubade korraldaja, baariteenuste pakkuja vms),
                    kes on sõlminud Allicater’iga lepingu Teenusepakkuja Teenuste vahendamiseks Tellijatele.</p>
                <p><strong>„Tellija“</strong> tähendab täisealist füüsilist isikut või õigusvõimelist juriidilist
                    isikut,
                    kes kasutab Allicater’i platvormi eesmärgiga saada Teenusepakkujatelt
                    Hinnapakkumisi ning sõlmida sobiva Teenusepakkujaga Teenuseleping.</p>
                <p><strong>„Teenuseleping“</strong> on leping, mis sõlmitakse Tellija ja Teenusepakkuja vahel,
                    kasutades Allicater’i platvormi, ning mille sisuks on Teenusepakkuja poolt kinnitatud Tellimuses
                    nimetatud Teenuse osutamine Tellijale. Teenuseleping koosneb kinnitatud Tellimusest,
                    Kasutustingimustest ning Tellija ja Teenusepakkuja vahelistest võimalikest täiendavatest
                    kokkulepetest.</p>
                <p><strong>„Teenus“</strong> on Teenusepakkuja poolt Tellijale osutatav cateringteenus, mis hõlmab
                    Tellija poolt
                    määratud üritusel ja asukohas erinevate tellija poolt soovitud toitude ja/või jookide müüki ja/või
                    serveerimist.</p>
                <p><strong>„Hinnapakkumine“</strong> on Allicater’i portaalis automaatselt
                    genereeritav esialgne pakkumine vastuseks Tellija poolt esitatud päringule.</p>
                <p><strong>„Tellimus“</strong> tähendab Tellija poolt kinnitatud Hinnapakkumist,
                    mis edastatakse Teenusepakkujale ülevaatamiseks ja kinnitamiseks.</p>
                <p><strong>2. Teenuse tellimine</strong></p>
                <p>2.1 Tellija täidab Allicater’i platvormil päringuvormi, märkides selgelt järgmised andmed
                    planeeritava ürituse ja selle tarbeks soovitud Teenuste osas:</p>
                <ul>
                    <li>ürituse toimumise aeg ja kestvus,</li>
                    <li>ürituse toimumise koht,</li>
                    <li>planeeritud külastajate arv,</li>
                    <li>teenuse jaoks planeeritud orienteeruv eelarve,</li>
                    <li>info huvipakkuvate toodete ja teenuste osas, kasutades selleks platvormil etteantud valikuid.
                    </li>
                </ul>
                <p>2.2. Päringu tegemine on Tellijale tasuta ning sellest ei teki Tellijale kohustust päringus nimetatud
                    Teenuseid tellida. </p>
                <p>2.3. Allicater’i platvormi automatiseeritud süsteem genereerib päringu andmete alusel
                    automaatselt esialgsed Hinnapakkumised nende Teenusepakkujate Teenuste kohta,
                    kelle poolt pakutavad tingimused vastavad kõige enam Tellija päringus kirjeldatud
                    kriteeriumitele.</p>
                <p>2.4. Tellija päringu vastuseks genereeritud Hinnapakkumised on Tellijale nähtavad Tellija
                    kasutajakontol.
                    Tellija vaatab esitatud Hinnapakkumised üle ning otsustab, kas ta soovib mõne Hinnapakkumise
                    kinnitada või mitte. Tellija saab talle esitatud Hinnapakkumiste hulgast valida ja kinnitada
                    vaid ühe Hinnapakkumise. Tellijal on aega Hinnapakkumiste seast sobiva valiku tegemiseks …
                    tööpäeva.</p>
                <p>2.5. Hinnapakkumise kinnitamine loetakse Tellija poolt siduva Tellimuse esitamiseks vastavale
                    Teenusepakkujale.</p>
                <p>2.6. Allicater’i platvormi automatiseeritud süsteem edastab Tellimuse Teenusepakkujale
                    ülevaatamiseks.
                    Teenusepakkujal on õigus Tellimus kinnitada või sellest keelduda. Kui Teenusepakkuja ei ole
                    Tellimust kahe (2) tööpäeva jooksul kinnitanud, kaotab Tellija poolt esitatud Tellimus kehtivuse
                    ning Tellijal on õigus valida Allicater’i süsteemi poolt esitatud Hinnapakkumiste hulgast
                    kinnitamiseks
                    teine Hinnapakkumine ja esitada selle kinnitamisega uus siduv Tellimus teisele
                    Teenusepakkujale. </p>
                <p>2.7. Tellimuse kinnitamisega Teenusepakkuja poolt loetakse Teenuseleping Tellija ja Teenusepakkuja
                    vahel sõlmituks ning Tellija ja Teenusepakkuja kohustuvad seda täitma täies mahus. Tellija
                    saab Teenusepakkuja poolt kinnitatud Tellimust tühistada üksnes vastavalt
                    Teenusepakkuja poolt kindlaksmääratud tühistamistingimustele. </p>
                <p>2.8. Tellija poolt väljavalitud Teenusepakkuja on kohustatud osutama kinnitatud Tellimuses
                    nimetatud Teenuse vastavalt Tellimuses sätestatud tingimustele. </p>
                <p>2.9. Tellija on kohustatud maksma kinnitatud Tellimuses sätestatud tasu ettemaksuna
                    Allicater’i vahendusel vastavalt Kasutustingimuste peatükis 3 sätestatud maksetingimustele. </p>
                <p>2.10. Tellimuse täitmise üksikasjad lepivad Tellija ja Teenusepakkuja kokku omavahel ilma Allicater’i
                    vahenduseta. </p>
                <p><strong>3. Maksed ja tagastused</strong></p>
                <p>3.1. Allicater esitab Tellijale arve ettemaksu tasumiseks 1-7 päeva jooksul pärast Tellimuse
                    kinnitamist Teenusepakkuja poolt. Ettemaks moodustab 20% Tellimuses kinnitatud Teenuse tasust. </p>
                <p>3.2. Tellija kohustub punktis 3.1. nimetatud arve tasuma Allicater’i pangakontole,
                    kasutades Allicater’i platvormil pakutavaid makselahendusi (pangalink, krediitkaardi makse,
                    pangaülekanne vms). Arve maksetähtaeg on 1-7 päeva arve esitamisest. Arve tasumisega viivitamise
                    korral on Tellija kohustatud tasuma viivist 0,06% tähtaegselt tasumata summast päevas. </p>
                <p>3.3. Kuni ettemaksu täieliku tasumiseni Allicater’ile on Teenusepakkujal õigus keelduda Tellijale
                    Teenuse osutamisest ning selleks ettevalmistuste tegemisest. Kui ettemaks on laekumata ka
                    veel 1-7 päeva enne Tellimuses nimetatud ürituse algust, on Teenusepakkujal õigus Teenuseleping
                    erakorraliselt üles öelda ning jätta Teenus Tellijale osutamata. Teenusepakkuja teatab
                    Teenuselepingu erakorralisest ülesütlemisest Allicater’ile ja Tellijale e-kirja teel aadressil 
                    <a href="mailto:support@allicater.com">support@allicater.com</a>. </p>
                <p>3.4. Allicater maksab Tellija poolt ettemaksuna saadud tasu Teenusepakkujale välja pärast seda,
                    kui Teenusepakkuja on Teenuse osutamise lõpule viinud ning Tellija on Allicater’i
                    platvormil kinnitanud, et Teenus on osutatud nõuetekohaselt vastavalt Tellimusele. </p>
                <p>3.5. Kui Tellija on Allicater’i poolt esitatud arve alusel Teenuse eest tasunud ning andnud pärast
                    Teenuse osutamist punktis 3.4. nimetatud kinnituse, loetakse Tellija kohustused Allicater ja
                    Teenusepakkuja ees täidetuks.</p>
                <p>3.6. Tellija ja Teenusepakkuja vahelised vaidlused lahendatakse ilma Allicater’ita ning vaidluse
                    tulemused antakse teada Allicater’ile, kui on vajadus Teenuse eest esitatud arvet muuta.
                    Vaidluse lahendused esitatakse Allicater’ile nii Tellija kui ka Teenusepakkuja poolt
                    e-posti aadressile support@allicater.com. Enne vaidluse lõplikku lahendamist ei edasta
                    Allicater Tellijalt ettemaksuna saadud tasu Teenusepakkujale ega tagasta ettemaksu ka Tellijale.</p>
                <p>3.7. Juhul kui Teenusepakkuja on Allicater’ile teatanud, et ta ei suuda Tellimust täita,
                    kohustub Allicater teavitama Tellijat sellest viivitamatult. Kui Tellija on sooritanud sellise
                    Tellimuse eest ettemaksu Allicater’i kontole, on Allicater kohustatud
                    ettemaksu Tellijale tagastama seitsme (7) päeva jooksul.</p>
                <p><strong>4. Tellija õigused, kohustused ja vastutus</strong></p>
                <p>4.1. Tellija kohustub esitama Allicater’ile kasutajakonto loomisel täielikud ja õiged isikuandmed,
                    mis on vajalikud kasutajakonto loomiseks ja Vahendusteenuste kasutamiseks (Tellija täielik nimi
                    ja kehtivad kontaktandmed, sh e-posti aadress, telefoninumber, postiaadress).
                    Allicater tagab, et talle usaldatud Tellija andmeid kasutatakse ainult eelpool nimetatud eesmärgil.
                    Need andmed avaldab Allicater üksnes sellele Teenusepakkujale, kelle Hinnapakkumise on Tellija
                    kinnitanud.</p>
                <p>4.2. Tellija ei tohi edastada kellelegi teisele oma kasutajakonto salasõna ega tohi kasutada
                    Allicater’i platvormi ebaseaduslikeks tehinguteks ega pettusteks.</p>
                <p>4.3. Tellija kohustub tasuma kinnitatud Tellimuse alusel Allicater’i poolt talle esitatud
                    arve täies mahus Allicater’i pangakontole vastavalt peatükis 3 sätestatud maksetingimustele.
                    Tellija ei vastuta tema poolt Allicater’ile makstud tasu edastamise eest Teenusepakkujale.
                    Tasu edastamine toimub vastavalt Allicater’i ja Teenusepakkuja poolt sõlmitud vahenduslepingu
                    tingimustele.</p>
                <p>4.4. Kui Tellija esitab libatellimusi ning ei tasu kinnitatud Tellimuste alusel esitatud arveid,
                    on Allicater’il õigus teha kindlaks Tellija isik läbi IP-aadressi tuvastuse ning käsitleda
                    libatellimusi pettusena, mis on seadusega karistatav tegu. Pettuse, valeandmete esitamise
                    või libatellimuste korral on Allicater’il õigus sellise Tellija kasutajakonto
                    sulgeda ning mitte lubada tal uut kasutajakontot luua.</p>
                <p>4.5. Kui Tellija tegevusest või tegevusetusest tulenevalt osutub kinnitatud Tellimuse
                    täitmine võimatuks, ei vabasta see teda kinnitatud Tellimuses sätestatud tasu maksmise
                    kohustusest vastavalt Allicater’i poolt Tellijale esitatud arvele.
                    Kui Tellija on punktis 3.1 nimetatud ettemaksu juba tasunud, kuid Tellija tegevuse või
                    tegevusetuse tõttu ei ole võimalik Teenust osutada, siis ei ole Tellijal õigus nõuda ettemaksu
                    tagastamist.
                    Tellija tegevuseks või tegevusetuseks peetakse muuhulgas ürituse ära jätmisest/ära jäämisest
                    mitteteatamist Allicater’ile, ürituse asukoha muutusest mitteteatamist ja muid asjaolusid,
                    mille tõttu ei saa Teenusepakkuja täita kinnitatud Tellimust.</p>
                <p>4.6. Tellija ei tohi:</p>
                <p>4.6.1. kopeerida, muuta ega paljundada Allicater’i poolt osutatavaid teenuseid ega sellega seotud
                    tehnoloogiat;</p>
                <p>4.6.2. eemaldada autoriõiguse, kaubamärgi või teiste omandiõiguste märget Allicater’i platvormil;</p>
                <p>4.6.3. kasutada talle mittekuuluvat Allicater’i kasutajakontot või maksevahendeid;</p>
                <p>4.6.4. eemaldada, kopeerida, kinni katta või varjata Allicater’i platvormil kuvatavaid ja
                    kasutatavaid
                    reklaame, embleeme, logosid ja muid Teenusepakkuja või Allicater’i poolt platvormile lisatud
                    kujutisi;</p>
                <p>4.6.5. siseneda Allicater’i platvormile muude võimaluste teel, kui ainult Allicater äppi või
                    Allicater’i portaali kaudu;</p>
                <p>4.6.6. koguda, kasutada, kopeerida ning edastada Allicater’i teenuses sisalduvat teavet ilma
                    Allicater’i kirjaliku nõusolekuta.</p>
                <p><strong>5. Intellektuaalne omand ja selle õigused</strong></p>
                <p>5.1. Intellektuaalse omandi õigused on käesoleval juhul autoriõigused (kataloogi, piltide
                    ja andmebaasi kasutamise õigused), patendid, kasulike mudelite õigused, kaubamärgid, ärinimed,
                    ärisaladused, oskusteabe ja teiste registreeritud ning registreerimata intellektuaalsed õigused,
                    mille osad ja eksemplarid kuuluvad Allicater’ile ning Allicater’il on nende kasutamiseks
                    ainuõigus.</p>
                <p>5.2. Käesolevad Kasutustingimused ei anna Tellijale mitte mingisuguseid
                    Intellektuaalse omandi õigusi, mis on seotud Allicater tegevusega.</p>
                <p><strong>6. Allicater’i õigused, kohustused ja vastutus</strong></p>
                <p>6.1. Teenuseleping sõlmitakse Allicater’i vahendusel, kuid Teenuselepingu poolteks on Tellija
                    ja Teenusepakkuja. Allicater ei vastuta Tellija ees Teenusepakkuja poolt osutatava Teenus
                    kvaliteedi ega Teenusepakkuja muude lepinguliste kohustuste täitmise eest,
                    muuhulgas ei vastuta Allicater Teenusepakkuja poolt pakutava toidu ja joogi kvaliteedi,
                    koguste ega muude Allicater’i vahendusel tellitud toodete ja teenuste kvaliteedi eest.
                    Allicater ei vastuta ühelgi juhul Teenusepakkuja poolt Tellijale osutamata jäänud või
                    mittenõuetekohaselt osutatud Teenuse eest. Allicater ei garanteeri ega võta omale vastutust
                    Teenusepakkuja poolt Teenuse osutamisel tekkinud ajalise viivituse,
                    Teenuse kvaliteedi, kvantiteedi ega muu eest.</p>
                <p>6.2. Allicater ei ole Teenusepakkuja ja Tellija vaheliste kaebuste lahendaja.
                    Kaebused kinnitatud hinnapakkumiste ning selle alusel osutatud või osutamata jäänud Teenuste
                    eest esitab Tellija otse Teenusepakkujale ning omavahelised vaidlused lahendatakse ilma Allicater’i
                    osaluseta. </p>
                <p>6.3. Allicater ei ole makseasutus ega osuta makseteenuseid Euroopa Parlamendi
                    ja nõukogu direktiivi (EL) 2015/2366 tähenduses. Allicater kogub Tellijatelt ettemakseid
                    vastavalt Teenusepakkujate poolt kinnitatud Tellimustele ning edastab saadud ettemaksed
                    Teenusepakkujatele vastavalt Kasutustingimustes sätestatule.</p>
                <p>6.4. Allicater täiendab ja parandab pidevalt oma teenuseid ning omab täielikku õigust
                    teha muudatusi või eemaldada erinevaid osiseid oma platvormilt ilma sellest Tellijat
                    teavitamata. </p>
                <p>6.5. Allicater poolt hallatava platvormi ajutise rikke korral teeb Allicater meeskond
                    kõik endast oleneva, et taastada eelnev olukord. Arvestades, et Vahendusteenust
                    osutatakse Tellijale tasuta ning on mõeldud kasutamiseks „nii nagu on“, siis ei võta
                    Allicater Tellija ees vastutust platvormi ajutise rikke, ajutise katkestuse või sulgemise
                    korral.</p>
                <p>6.6. Allicater ei kontrolli ega vastuta Teenuseosutajate poolt Allicater platvormile
                    lisatud informatsiooni, kujutiste ega piltide õigsuse eest. Samuti ei kontrolli ega vastuta
                    Allicater platvormile lisatud kolmandate osapoolte sisulinkidelt avaneva teabe eest. </p>
                <p>6.7. Allicater’il on õigus oma õigused ja kohustused täiemahuliselt või osaliselt üle anda
                    oma õigusjärglasele või Allicater’i vara ja õiguste ostjale ilma Tellija nõusolekuta.</p>
                <p><strong>7. Lõppsätted</strong></p>
                <p>7.1. Käesolevad Kasutustingimused on Allicater’i ja Tellija vahel siduvad seni, kuni
                    Tellija ei ole avaldanud soovi kasutajakonto sulgemiseks ja kustutamiseks.</p>
                <p>7.2. Tellijal on õigus igal ajal oma kasutajakonto sulgeda või kustutada. Samuti on
                    Allicater’il õigus igal ajal kas ajutiselt katkestada või igaveseks oma tegevus lõpetada,
                    andmata sellest Tellijatele eraldi teada või selleks põhjusi avaldamata.</p>
                <p>7.3. Allicater võib käesolevaid Kasutustingimusi muuta, teavitades Tellijaid kas
                    e-kirja teel või teavitades Kasutustingimuste muutmisest oma platvormil.
                    Kui Tellija ei ole nõus Kasutustingimuste muudatustega,
                    on tal õigus oma kasutajakonto sulgeda ja kustutada.</p>
            </div>
        );
    }
}
