import React from "react";
import EtiqPageCollapse from "../subviews/EtiqPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class Dishes extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <EtiqPageCollapse title={"Dishes etc"}>
                    <li>If the customer wishes to order different means for consuming drinks or food (cake) purchased for an additional fee</li>
                </EtiqPageCollapse>
            );
        }

        return (
            <EtiqPageCollapse title={"Nõud jm"}>
                <li>Kui kliendil on soov tellida lisatasu eest omalt poolt soetatud jookide või toitude (tort) tarbimiseks erinevaid vahendeid</li>
            </EtiqPageCollapse>
        );
    }
}