import React from "react";
import {ViewUtils} from "../../utils/ViewUtils";
import ReactTable from "../../components/ReactTable/ReactTable";
import {CurrentUser} from "../../services/dto/CurrentUser";
import {DateUtils} from "../../core/utils/DateUtils";
import EventDetailPopup from "../_subviews/EventDetailPopup";
import LocalData from "../../services/state/LocalData";
import Rating from "../../model/Rating";
import Offer from "../../model/Offer";
import {BaseComponent} from "../_subviews/BaseComponent";
import {Translation} from "../../core/service/Translation";

export default class EventHistoryPage extends BaseComponent {

    constructor(props: any) {
        super(props);

        this.state = {
            events: [],
            selectedEvent: undefined
        }
    }

    componentDidMount = async () => {
        if (CurrentUser.instance.exists()) {
            this.loadData();
        } else {
            CurrentUser.instance.loaded = () => {
                this.loadData();
            }
        }
    };

    async loadData() {
        const data = await LocalData.eventHistory.load((state: any) => {
            this.setState(state);
        });
        this.setState(data);
    }

    parseData() {
        const result = this.state.events;

        result.forEach((event: any) => {
            event.humanReadableDate = DateUtils.msToLongDateString(event.date);
            event.humanReadableBudget = event.budget + "€ / " + event.attendees;
            event.payment = Offer.calculatePrice(event?.offer);

            const action = () => this.setState({ selectedEvent: event });
            event.action = ViewUtils.listActionButton(Translation.find("view-details"), action);

            const user = CurrentUser.instance.isCaterer() ? event.creator : event.offer?.caterer;
            const name = user ? user.name : " - ";
            const score = Rating.calculateScore(user?.ratings);
            event.humanReadableRating = name + " / " + score;
        });

        return result;
    }

    render() {
        return ViewUtils.createCardTemplate("past-gigs", Translation.find("past-gigs"), this.createTable());
    }

    createTable() {
        return (
            <div>
                <EventDetailPopup
                    event={this.state.selectedEvent}
                    onClose={this.onClosePopup.bind(this)}
                    viewOfferClick={(event: any, offer: any) => {
                        this.props.viewOffer(event, offer);
                    }}
                />
                <ReactTable columns={this.columns()} data={this.parseData()}/>
            </div>
        );
    }

    onClosePopup() {
        this.setState({selectedEvent: undefined})
    }

    columns() {
        const result = [];
        if (!ViewUtils.isMobile()) {
            result.push(ViewUtils.createColumnHeader("#", "id"));
            result.push(ViewUtils.createColumnHeader(Translation.find("client-rating"), "humanReadableRating"));
        }
        result.push(ViewUtils.createColumnHeader(Translation.find("date"), "humanReadableDate"));
        if (!ViewUtils.isMobile()) {
            result.push(ViewUtils.createColumnHeader(Translation.find("budget"), "humanReadableBudget"));
        }
        result.push(ViewUtils.createColumnHeader(Translation.find("location"), "location"));
        if (!ViewUtils.isMobile()) {
            result.push(ViewUtils.createColumnHeader(Translation.find("payment"), "payment"));
        }
        result.push(ViewUtils.createColumnHeader(Translation.find("action"), "action"));

        return result;
    }
}
