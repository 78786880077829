
import React from "react";

import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";

import {BaseComponent} from "../_subviews/BaseComponent";

import ProfileInputFields from "./ProfileInputFields";
import ProfileAvatarComponent from "./ProfileAvatarComponent";
import ProfileCateringTypesComponent from "./ProfileCateringTypesComponent";

import {ImageUtils} from "../../utils/ImageUtils";

import {CurrentUser} from "../../services/dto/CurrentUser";
import {Networking} from "../../services/Networking";
import {NewEvent} from "../../services/NewEvent";
import {CateringEnums} from "../../model/constants/CateringEnums";
import {LocalStorage} from "../../services/LocalStorage";
import {Translation} from "../../core/service/Translation";

export default class ProfilePage extends BaseComponent {

    constructor(props: any) {
        super(props);
        this.state = {
            avatarBase64: undefined
        };
    }

    render() {
        const user = CurrentUser.instance.get();
        if (!user) {
            return null;
        }

        CateringEnums.clear();

        const options = CurrentUser.instance.findCateringOptions();
        CateringEnums.updateSelection(options);

        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={8}>
                        <ProfileInputFields user={user} onSave={this.onUpdatePersonalInfo.bind(this)}/>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <ProfileAvatarComponent
                            avatar={this.findAvatarToDisplay()}
                            onChange={this.onAvatarUpdate.bind(this)}
                            onError={this.onAvatarError.bind(this)}
                        />
                    </GridItem>
                    <ProfileCateringTypesComponent
                        isVisible={CurrentUser.instance.isCaterer()}
                        onSave={this.onUpdateCateringOptions.bind(this)}
                    />
                </GridContainer>
            </div>
        );
    }

    async onUpdatePersonalInfo(data: any) {
        data.language = LocalStorage.instance.settings.language;
        const result = await Networking.instance.updateProfile(data);
        if (result.error) {
            this.props.alert(Translation.find("profile-update-error"), result.error);
        } else {
            this.alertSuccess(Translation.find("profile-update-success-content"));
        }
    }

    async onAvatarUpdate(base64: string) {
        const data = {avatarBase64: base64};
        this.setState(data);
        await Networking.instance.updateProfile(data);
        this.alertSuccess(Translation.find("profile-update-success-content"));
    }

    async onAvatarError(message: string) {
        if (message.includes("The uploaded image is too large.")) {
            this.props.alert(
                Translation.find("profile-update-error"),
                Translation.find("image-too-large") + " " + ImageUtils.MAX_SIZE + "px");
        } else {
            this.props.alert(Translation.find("profile-update-error"), message);
        }
    }
    async onUpdateCateringOptions() {
        const data = NewEvent.instance.findCateringInfo();
        data["multi_category"] = CateringEnums.checkedBoxesEnum();
        CurrentUser.instance.updateCateringOptions(data);
        const result = await Networking.instance.updateProfile(data);
        if (result.error) {
            this.props.alert(Translation.find("profile-update-error"), result.error);
        } else {
            this.alertSuccess(Translation.find("profile-update-success-content"));
        }
    }

    findAvatarToDisplay() {
        return this.state.avatarBase64 ? this.state.avatarBase64 : CurrentUser.instance.avatarUrl();
    }
}
