import React from "react";

import {BaseComponent} from "../BaseComponent";

import {withStyles} from "@mui/styles";
import style from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import MenuCategoryCounter from "../../MenuCategoryCounter";

class ACDropdown extends BaseComponent {

    itemStyle() {
        return {root: this.props.classes.selectMenuItem, selected: this.props.classes.selectMenuItemSelected}
    }

    render() {
        const {hidden, label, value, options, onChange} = this.props;
        if (hidden) {
            return null;
        }
        return (
            <FormControl fullWidth className={this.props.classes.selectFormControl}>
                <InputLabel htmlFor="simple-select" className={this.props.classes.selectLabel} style={{fontFamily: '"Open Sans"'}}>
                    {label}
                </InputLabel>
                <Select
                    style={{fontFamily: '"Open Sans"'}}
                    MenuProps={{className: this.props.classes.selectMenu}}
                    classes={{select: this.props.classes.select}}
                    value={value}
                    onChange={onChange}
                    inputProps={{name: "simpleSelect", id: "simple-select"}}
                >
                    {options.map((option, index) => {
                        return <MenuItem key={index} classes={this.itemStyle()} value={option.id}>
                            <div>{option.text}{<MenuCategoryCounter count={option.count}/>}</div>
                        </MenuItem>
                    })}
                </Select>
            </FormControl>
        );
    }
}

//@ts-ignore
export default withStyles(style)(ACDropdown);
