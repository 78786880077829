
import React from "react";
import ACDropdown from "./ac-components/ACDropdown";

import {MenuCategories} from "../../model/constants/MenuCategories";
import LocalData from "../../services/state/LocalData";
import {Translation} from "../../core/service/Translation";
import {BaseComponent} from "./BaseComponent";

export default class MenuCategorySelect extends BaseComponent {

    constructor(props: any) {
        super(props);
        this.state = {
            category: 0
        };
    }

    render() {
        return (
            <ACDropdown
                label={Translation.find("menu-category")}
                value={this.state.category}
                options={MenuCategories.list.items.map(item => {
                    return {
                        id: item.id,
                        text: Translation.find(item.tx),
                        count: LocalData.offer.countForCategory(item.id)
                    }})
                }
                onChange={(e: any) => {
                    this.setState({category: e.target.value});
                    this.props.onCategoryChange(e);
                }}
            />
        );
    }
}
