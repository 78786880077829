
import React from "react";
import HelpPageCollapse from "../subviews/HelpPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class FinishProfileCustomer extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <HelpPageCollapse title={"Finishing profile (customer)"}>
                    <li> The user's profile is divided into two parts:
                        <ol>
                            <li> Contact Information </li>
                            <li> Profile Image </li>
                        </ol>
                    </li>
                    <li> For catering <strong> outsourcing </strong> companies, it makes sense to
                        use a business address when the majority of orders are for office events </li>
                </HelpPageCollapse>
            );
        }

        return (
            <HelpPageCollapse title={"Profiili lõpetamine (cateringi tellija)"}>
                <li>Kasutaja profiil on jagatud kaheks osaks:
                    <ol>
                        <li>Kontaktandmed</li>
                        <li>Profiilipilt</li>
                    </ol>
                </li>
                <li>Cateringi <strong>tellivate</strong> ettevõtete puhul on mõistlik kasutada
                    tegevusaadressi kui suurem osa tellimustest on kontoris toimuvate ürituste jaoks</li>
            </HelpPageCollapse>
        );
    }
}
