
import React, {CSSProperties} from "react";
import { withTranslation } from 'react-i18next';

import {BaseComponent} from "../_subviews/BaseComponent";

import "../../assets/css/landing.css";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import {ViewUtils} from "../../utils/ViewUtils";
import Button from "../../components/CustomButtons/Button";
import {StringUtils} from "../../utils/StringUtils";
import GridContainer from "../../components/Grid/GridContainer";
import {Networking} from "../../services/Networking";
import {LocalStorage} from "../../services/LocalStorage";
import {Routing} from "../../services/Routing";
const buffet = require('../../assets/img/allicater/foodz.png');

class ResetPasswordPage extends BaseComponent {

    constructor(props: any) {
        super(props);
        this.state = {
            id: undefined,
            password: undefined,
            repeat: undefined,
            error: false,
            disabled: true
        };
    }

    headerStyle: CSSProperties = {
        width: "94%",
        margin: "2%",
        textAlign: "center",
        paddingTop: "15px"
    };

    componentDidMount = async () => {
        this.setState({id: Routing.instance.getUrlParameter("id")});
    };

    render() {
        const inputProps = {
            onChange: (e: any) => {
                const disabled = StringUtils.isAnyNullOrEmpty([this.state.password, this.state.repeat]);
                const state = {disabled: disabled, error: false, password: this.state.password, repeat: this.state.repeat};
                if (e.target.id === "new-password-repeat") {
                    state.repeat = e.target.value;
                } else {
                    state.password = e.target.value;
                }
                this.setState(state);
            }
        };
        return (
            <div>
                <div className={"container"}>
                    <img src={buffet} className={"img-responsive rounded about-sec"} alt={"."}
                         style={{marginBottom: 0, marginTop: "30px"}}/>
                    <GridContainer justifyContent="center">
                        <GridItem xs={12} sm={12} md={7}>
                            <Card>
                                <h5 style={this.headerStyle}>{this.translate("update-password")}</h5>
                                {this.state.error &&
                                <h6 style={{...this.headerStyle, color: "red"}}>{this.translate("password-no-match-title")}</h6>}
                                <div style={{width: "94%", margin: "2%", marginTop: 0}}>
                                    {ViewUtils.createGridItem(this, "new-password", 12, inputProps)}
                                    {ViewUtils.createGridItem(this, "new-password-repeat", 12, inputProps)}
                                    <Button
                                        color={"primary"}
                                        onClick={this.onSavePress.bind(this)}
                                        disabled={this.state.disabled}
                                        style={{marginTop: "15px"}}
                                    >
                                        {this.props.t("save")}
                                    </Button>
                                </div>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        );
    }

    async onSavePress() {
        console.log(this.state.password, this.state.repeat);
        if (this.state.password !== this.state.repeat) {
            this.setState({error: true});
            return;
        }
        this.setState({error: false});
        const result = await Networking.instance.updatePassword(this.state.id, this.state.password);

        if (!result.error) {
            LocalStorage.instance.setSessionToken(result.sessionId);
            Networking.instance.refreshToken();
            this.props.reset(
                this.translate("pw-reset-success-header"),
                this.translate("pw-reset-success-content")
            );
        }
    }
}


export default withTranslation()(ResetPasswordPage);
