
import React from "react";
import {BaseComponent} from "../../_subviews/BaseComponent";
import BasePopup from "../../_subviews/_base/BasePopup";
import EtiqPageContent from "../EtiqPageContent";
import {LocalStorage} from "../../../services/LocalStorage";

export default class EtiqPopup extends BaseComponent {

    render() {
        const english = LocalStorage.instance.settings.language === "en";
        return (
            <BasePopup isOpen={this.props.isOpen} title={""} onClose={this.props.onClose}>
                <EtiqPageContent en={english}/>
            </BasePopup>
        );
    }
}
