import React from "react";
import EtiqPageCollapse from "../subviews/EtiqPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class Short extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <EtiqPageCollapse title={"Seperate Tables"}>
                    <li>Client wants to make the guests sit at different tables divided into group</li>
                </EtiqPageCollapse>
            );
        }

        return (
            <EtiqPageCollapse title={"Eraldi lauad"}>
                <li>Klient soovib oma külalised panna istuma laudkonniti. Selleks lepitakse toitlustajaga eraldi kokku, mitmesed laudkonnad luuakse</li>
            </EtiqPageCollapse>
        );
    }
}