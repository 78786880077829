
import React from "react";

import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";

import {BaseComponent} from "../_subviews/BaseComponent";
import LoginBox from "./boxes/LoginBox";
import RegistrationBox from "./boxes/RegistrationBox";
import PasswordReset from "./boxes/PasswordResetBox";

import {Networking} from "../../services/Networking";
import {LocalStorage} from "../../services/LocalStorage";
import {Routing} from "../../services/Routing";

import {Translation} from "../../core/service/Translation";
import {StringUtils} from "../../utils/StringUtils";
import ACNotifier from "../_subviews/ac-components/ACNotifier";

const logo = require('../../assets/img/allicater/allicater_logo_alt.svg')


export default class LandingPage extends BaseComponent {


    constructor(props: any) {
        super(props);

        const isSignup = Routing.instance.getHash("sign-up");
        
        this.state = {
            isVisible: {
                login: !isSignup,
                register: isSignup,
                reset: false
            },
            backgroundNotification: undefined,
            titleKey: undefined,
            contentKey: undefined,
            invalidRegistration: {
                name: false,
                email: false,
                password: false,
                regNumber: false
            }
        }
    }

    render() {
        return (
            <GridContainer container className={"login-flex-children"}>
                <ACNotifier
                    titleKey={this.state.titleKey} 
                    contentKey={this.state.contentKey} 
                    confirmed={() => {
                        this.showNotification(undefined, undefined);
                    }
                }/>
                <GridItem xs={12} sm={12} md={6} className={"login-left"}>                    
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <img src={logo} alt="Allicater logo" className={"login-logo"} />
                    <LoginBox
                        isVisible={this.state.isVisible.login}
                        onLogin={this.onLoginClick.bind(this)}
                        onPasswordForget={() => {
                            this.setState({isVisible: {reset: true}})
                        }}
                        onShowRegister={() => {
                            this.setState({isVisible: {register: true}})
                        }}
                    />
                    <RegistrationBox
                        isVisible={this.state.isVisible.register}
                        error={this.state.invalidRegistration}
                        onRegisterClick={this.onRegisterClick.bind(this)}
                        showLogin={() => {
                            this.setState({isVisible: {login: true}})
                        }}
                    />
                    <PasswordReset 
                        visible={this.state.isVisible.reset} 
                        onSubmit={this.onPasswordReset.bind(this)}
                        showLogin={() => {
                            this.setState({isVisible: {login: true}})
                        }}
                    />
                </GridItem>
            </GridContainer>
        );
    }

    async onLoginClick(email: string, password: string) {
        const result = await Networking.instance.logIn(email, password);
        
        if (!result.error) {
            LocalStorage.instance.setSessionToken(result.sessionId);
            LocalStorage.instance.updateLanguage(result.language);
            Networking.instance.refreshToken();
            Routing.instance.to(Routing.Enumeration.Dashboard);
            this.props.reload()
        } else {
            this.showNotification(Translation.find("login-error"), Translation.error(result.error.code));
        }
    }

    async onRegisterClick(name: string, email: string, password: string, regNumber: string) {
        
        const inputs = [name, email, password];
        if (regNumber) {
            // regNumber will be a rendered html element only if caterer checkbox is checked,
            // then we also need to add it to the validator. Else ignore.
            inputs.push(regNumber);
        }

        const isEmpty = StringUtils.isAnyNullOrEmpty(inputs);
        const isValidEmail = StringUtils.isValidEmail(email);
        if (isEmpty || !isValidEmail) {
            this.setState({
                invalidRegistration: {
                    name: StringUtils.isNullOrEmpty(name),
                    email: StringUtils.isNullOrEmpty(email) || !isValidEmail,
                    password: StringUtils.isNullOrEmpty(password),
                    regNumber: StringUtils.isNullOrEmpty(regNumber)
                }
            });
            return;
        }

        const result = await Networking.instance.register(name, email, password, regNumber);

        if (result.error) {
            this.showNotification(Translation.find("registration-error"), Translation.error(result.error.code));
        } else {
            this.showNotification(Translation.find("almost-done"), Translation.find("check-email"));
            this.setState({isVisible: {login: true}});
        }
    }

    async onPasswordReset(email: string) {
        await Networking.instance.resetPassword(email);
        this.setState({isVisible: {login: true}});
        this.showNotification(Translation.find("reset-email-sent-header"), Translation.find("reset-email-sent-content"));
    }

    showNotification(titleKey?: string, contentKey?: string) {
        this.setState({ titleKey: titleKey, contentKey: contentKey });
    }

}
