import {InvoiceType} from "../core/model/enum/InvoiceType";

export default class Invoice {

    public id: number = -1;
    
    public customerId: number = -1;
    public catererId: number = -1;
    public offerId: number = -1;

    public createDate: string = "";
    public eventDate: string = "";
    public dueDate: string = "";
    public paidDate: string = "";

    public isPaid: boolean = false;

    public stripePaymentId: string = "";
    public swedPaymentId: string = "";

    public menuItemJson: string = "";
    public userJson: string = "";

    public isPrepayment: boolean = false;

    public type: InvoiceType = InvoiceType.Undefined;

    public menuItems?: {};
    public caterer?: any;

    public prepaidAmount: number = 0;
    
    /* Client-side property to temporary store drawn html in order to generate PDF */
    public html?: any;

    public static isPrepayment(invoice: Invoice) {
        return invoice.type === InvoiceType.Prepayment;
    }

    static findType(item: Invoice): InvoiceType {

        if (item.isPrepayment) {
            return InvoiceType.Prepayment;
        }
        if (!item.type) {
            return InvoiceType.Undefined;
        }

        return item.type;
    }

    static typeToString(type: InvoiceType) {
        return InvoiceType[type];
    }

    static releaseDate(invoice: Invoice) {
        if (invoice.type === InvoiceType.Prepayment) {
            return invoice.createDate;
        }
        return invoice.eventDate;
    }

    static findFeePercentage(invoice: any) {
        if (!invoice.caterer.tier) {
            return 0;
        }
        return invoice.caterer.tier;
    }

}
