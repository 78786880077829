import {OfferState} from "../core/model/enum/OfferState";
import {CurrentUser} from "../services/dto/CurrentUser";
import {DateUtils} from "../core/utils/DateUtils";

export default class Offer {

    public id: number = -1;
    
    public eventId: number = -1;

    public catererId: number = -1;

    public menuItems: string = "";

    public accepted: boolean = false;

    public rejected: boolean = false;

    public createDate: string = "";

    public acceptDate: string = "";

    public status: OfferState = OfferState.Pending;

    /**
     * Static helper functions that store no instance data
     */
    static calculatePrice(offer: any) {

        if (!offer || !offer.menuItems || typeof offer.menuItems === "string") {
            return "...";
        }
        let total = 0;

        offer.menuItems.forEach((item: any) => {
            if (item.data) {
                total += item.count * item.data.price;
            }
        });
        return total + "€";
    }

    static isPending(offer: any): boolean {
        if (!offer) { return false; }
        return (!offer.accepted && !offer.rejected) || offer.state === OfferState.Pending;
    }

    static findAcceptedOfferForEvent(event: any) {

        if (!event.offers) {
            return undefined;
        }
        
        for (let i = 0; i < event.offers.length; i++) {
            const offer = event.offers[i];
            if (this.isAccepted(offer)) {
                return offer;
            }
        }
        return undefined;
    }

    static isAccepted(offer: any): boolean {
        if (!offer) { return false; }
        return offer.status === OfferState.Accepted;
    }
    static isRejected(offer: any): boolean {
        if (!offer) { return false; }
        return offer.status === OfferState.Rejected;
    }

    static isEventAccepted(event: any) {
        if (!event.offers || event.offers.length === 0) {
            return false;
        }
        
        if (this.findAcceptedOfferForEvent(event) !== undefined) {
            return true;
        }
        
        return false;
    }

    static isMine(offer: any): boolean {
        return CurrentUser.instance.getId() === offer.catererId;
    }

    static isAnyMine(event: any): boolean {
        for (let i = 0; i < event.offers.length; i++) {
            const offer = event.offers[i];
            if (this.isMine(offer)) {
                return true;
            }
        }
        return false;
    }

    static isMineRejected(event: any): boolean {
        if (!event.offers || event.offers.length === 0) {
            return false;
        }
        for (let i = 0; i < event.offers.length; i++) {
            const offer = event.offers[i];
            if (this.isMine(offer) && Offer.isRejected(offer)) {
                return true;
            }
        }
        return false;
    }

    static toUpcoming(offers: any) {
        const result: any[] = [];

        if (!offers) {
            return result;
        }
        offers.forEach((offer: any) => {
            if (DateUtils.isUpcoming(offer.event?.date, true)) {
                result.push(offer);
            }
        });
        return result;
    }

    static statusString(status: OfferState) {
        return OfferState[status].toLowerCase();
    }

    static toEventIds(offers: any) {
        return offers.map((offer: any) => offer.eventId);
    }
}









