import {Language} from "../core/model/enum/Language";

export class Settings {

    static DEFAULT_LANGUAGE: Language = Language.Et;

    language: string | undefined;

    constructor(json: any) {
        Object.assign(this, JSON.parse(json));

        if (!this.language) {
            this.language = Settings.DEFAULT_LANGUAGE;
        }
    }

    english() {
      return this.language === Language.En;
    }
}
