import React from "react";

import {BaseComponent} from "../BaseComponent";

export class TermsPopupContentEN extends BaseComponent {

    render() {
        return (
            <div>
                <p><strong>Allicater’s Terms of Use</strong></p>
                <p>By creating an Allicater user account and using the provided services, you agree to the following terms of use (hereinafter <strong>Terms of Use</strong>).</p>
                <p>Please carefully read these Terms of Use before using Allicater’s services. If You disagree with these Terms of Use, you are not able to use Allicater’s services. These Terms of Use are applicable to all services ordered via the Allicater intermediary platform. By submitting and approving an Order on Allicater’s platform, You are concluding a Service Contract with the Service Provider that confirmed the Order, and, in this case, these Terms of Use become an integral part of the Service Contract concluded between the Customer (You) and the Service Provider.</p>
                <p>By creating a user account and using Allicater’s services, You also confirm that You are an adult with active legal capacity, and You have the right to use and order the services described in these Terms of Use. When creating a user account in the name of a legal person, You additionally confirm that You have the valid authorisations and approvals required to use and order the services described in these Terms of Use in the name of that legal person. </p>
                <p>If You are a consumer, please bear in mind that under Article 16(l) of Directive 2011/83/EU of the European Parliament and of the Council, You do not have a right of withdrawal with respect to the Service Contract You conclude.</p>
                <p><strong>1. Definitions</strong></p>
                <p><strong>Allicater</strong> means <strong>Post Innovations OÜ</strong> (registry code: 14331746, registered office at: Riia 181a, Tartu, 51004, Estonia; e-mail address: support@allicater.com), who acts as an intermediary between Customers and Service Providers by facilitating the provision of the Service Providers’ Services on its platform, which Customers can access on the website allicater.com and via a mobile application (the Allicater app);</p>
                <p><strong>Intermediation Service</strong> means intermediation activity carried out by Allicater in facilitating Service Providers’ Services to the Customers;</p>
                <p><strong> Service Provider</strong> means a catering company (restaurant, pub, bar, catering undertaking, food truck, organiser of cooking workshops, provider of bar services etc.) that has concluded a contract with Allicater for the facilitation of its Services to Customers;</p>
                <p><strong>Customer</strong> means an adult natural person or a legal person with passive legal capacity, who uses Allicater’s platform to receive Price Offers from Service Providers and to conclude a Service Contract with a suitable Service Provider;</p>
                <p><strong>Service Contract</strong> means a contract, which is concluded between a Customer and a Service Provider while using Allicater’s platform, that has, as its object, the provision of a Service to a Customer based on an Order confirmed by a Service Provider. The Service Contract comprises a confirmed Order, these Terms of Use and any additional agreements concluded between the Customer and the Service Provider;</p>
                <p><strong>Service</strong> means the catering service provided to the Customer by the Service Provider, which consists in serving and/or sale of foods and/or drinks at an event and location determined by the Customer;</p>
                <p><strong>Price Offer</strong> means an initial offer that is automatically generated in Allicater’s portal in response to a query submitted by the Customer;</p>
                <p><strong>Order</strong> means a Price Offer that the Customer has approved, which is forwarded to the Service Provider for review and confirmation.</p>
                <p><strong>2. Ordering services</strong></p>
                <p>2.1 The Customer fills in a query form on Allicater’s platform by clearly entering the following data about a planned event and the Services required for that event:</p>
                <ul><li>the time and duration of the event;</li>
                    <li>the location of the event;</li>
                    <li>planned number of guests;</li>
                    <li>estimated budget regarding the Services;</li>
                    <li>any information about preferred products and services by using the options provided on the platform.</li>
                </ul>
                <p>2.2. Submitting a query is free of charge for the Customer, and by submitting the query, the Customer is not entering into an obligation to purchase the Services listed in the query.</p>
                <p>2.3.  Based on the data in the query, the automated system of Allicater’s platform automatically generates initial Price Offers for those Service Providers’ Services that have the closest match with the criteria set by the Customer in their submitted query.</p>
                <p>2.4. The Price Offers generated in response to the Customer’s query are displayed to the Customer in their user account. The Customer reviews the provided Price Offers and makes a decision on whether they want to approve any of the Price Offers. The Customer can only choose and approve one of the displayed Price Offers. The Customer has … business days to choose their preferred Price Offer.</p>
                <p>2.5. Approval of a Price Offer is deemed as a binding Order submitted by the Customer to the chosen Service Provider.</p>
                <p>2.6. The automated system of Allicater’s platform forwards the Order to the Service Provider for review. The Service Provide can either confirm or reject the Order. In case the Service Provider has not confirmed the Order within two (2) business days, the validity of Order submitted by the Customer expires and the Customer has the right to choose a different Price Offer from the list displayed by Allicater’s system, and to submit a new Order to a different Service Provider by approving their chosen Price Offer. </p>
                <p>2.7. When a Service Provider confirms an Order, a Service Contract between the Customer and the Service Provider is deemed as concluded, and the Customer and the Service Provider are obligated to perform the contract as required. The Customer can terminate an Order that has already been confirmed by a Service Provider only in accordance with the conditions established by the Service Provider with respect to terminations. </p>
                <p>2.8. The Customer’s chose Service Provider is obligated to provide the Service, which is described in the confirmed Order, in accordance with the conditions set out in the Order. </p>
                <p>2.9. The Customer must pay the fee set out in the confirmed Order as an advance payment via Allicater in accordance with the payment conditions established under clause 3 of these Terms of Use. </p>
                <p>2.10. The details of fulfilling the Order are agreed on by the Customer and the Service Provider without any facilitation from Allicater. </p>
                <p><strong>3. Payments and returns</strong></p>
                <p>3.1. Allicater submits to the Customer an invoice for the advance payment within 1-7 days starting from the confirmation of the Order by the Service Provider. The advance payment covers 20% of the fee charged for the Service confirmed in the Order. </p>
                <p>3.2. The Customer must pay the invoice set out under clause 3.1. to Allicater’s bank account by using the payment options listed on Allicater’s platform (bank link, credit card payment, bank transfer or another listed option). The invoice must be paid within 1-7 days starting from the submission of the invoice. If the invoice is not paid in a timely manner, the Customer must pay late interest at the rate of 0.06% of the overdue sum per day. </p>
                <p>3.3. Until the advance payment is paid in full to Allicater, the Service Provider has the right to refuse to provide the Service to the Customer, and to make preparations for the provision of said Service. If the advance payment has still not been received 1-7 days days before the start of the event mentioned in the Order, the Service Provider can terminate the Service Contract without prior notice and refuse to provide the Service to the Customer. The Service Provider must inform Allicater and the Customer about the Service Contract’s termination by submitting an e-mail to: <a href="mailto:support@allicater.com">support@allicater.com</a>.</p>
                <p>3.4. Allicater pays the advance payment received from the Customer to the Service Provider after the Service Provider has concluded providing the Service and the Customer has provided a confirmation on Allicater’s platform that the Service was provided in accordance with the conditions set out in the Order. </p>
                <p>3.5. If the Customer has paid for the Service on the basis of the invoice submitted by Allicater and, following the provision of the Service, provided the confirmation set out under clause 3.4., the Customer’s obligations toward Allicater and the Service Provider are deemed as performed.</p>
                <p>3.6. Any disputes arising between the Customer and the Service Provider are resolved without involving Allicater, and Allicater is notified of the outcomes of the dispute if there is a need to make changes to the invoice submitted for the Service. The outcomes of the dispute are submitted to Allicater by both the Customer and the Service Provider via e-mail at: support@allicater.com. Allicater does not pay the advance payment received from the Customer to the Service Provider, and does not return the advance payment to the Customer before a final resolution is reached in the dispute between the two parties.</p>
                <p>3.7. In case the Service Provider notifies Allicater of not being able to fulfil the Order, Allicater must inform the Customer about this without delay. If the Customer has made an advance payment for the aforementioned Order to Allicater’s bank account, Allicater must return the advance payment to the Customer within seven (7) days.</p>
                <p><strong>4. Rights, obligations and liability of the Customer</strong></p>
                <p>4.1. When creating a user account, the Customer must provide to Allicater personal data that are complete and accurate, as these are required for creating the user account and using the Intermediation Service (Customer’s full name and valid contact information, including an e-mail address, phone number and postal address). Allicater ensures that the personal data of the Customer are used solely for the abovementioned purposes. Allicater discloses these personal data only to the Service Provider whose Price Offer the Customer has approved.</p>
                <p>4.2. The Customer must not provide the password of their user account to any third persons and cannot use Allicater’s platform to conclude unlawful transactions or commit fraud.</p>
                <p>4.3. On the basis of a confirmed Order, the Customer must pay the invoice received from Allicater in full to Allicater’s bank account in accordance with the payment conditions set out under clause 3 of these Terms of Use. The Customer is not responsible for forwarding the fee, which they paid to Allicater, to the Service Provider. The paid fee is forwarded from Allicater to the Service Provider in accordance with the conditions set out under the intermediation contract concluded between Allicater and the Service Provider.</p>
                <p>4.4. If the Customer submits bogus orders and does pay the invoices submitted on the basis of confirmed Orders, Allicater has the right to identify the Customer’s person via IP address identification and to consider the aforementioned bogus orders as fraud, which is an offence punishable under law. In case of fraud, the submission of false information or bogus orders, Allicater has the right to close the Customer’s user account and prevent the person from creating a new account.</p>
                <p>4.5. If fulfilling a confirmed Order becomes impossible due to the acts or omissions of the Customer, the Customer is not released from its obligation to pay the fee set out in the confirmed Order on the basis of the invoice Allicater submitted to the Customer. If the Customer has already made the advance payment set out under clause 3.1., but the Service cannot be provided due to the acts or omissions of the Customer, the Customer cannot demand that the advance payment be returned. The acts or omissions of the Customer include, among other things, cancelling the event/failure to notify Allicater of the event’s cancellation, failure to notify of a change in the event’s location and other circumstances, which prevent the Service Provider from fulfilling a confirmed Order.</p>
                <p>4.6. The Customer must not:</p>
                <p>4.6.1. copy, alter or duplicate the services provided by Allicater or the technology used in the provision of such services;</p>
                <p>4.6.2. remove copyright, trademark or other ownership marks found on Allicater’s platform;</p>
                <p>4.6.3. use an Allicater user account or payment methods that do not belong to the Customer;</p>
                <p>4.6.4. remove, make copies of, cover up or hide the advertisements, symbols, logos or other designs added to the platform by a Service Provider or Allicater, which are displayed and used on Allicater’s platform;</p>
                <p>4.6.5. gain access to Allicater’s platform by means other than the use of Allicater’s app or portal;</p>
                <p>4.6.6. collect, use, make copies of or forward any information contained in Allicater’s service without the prior written consent of Allicater.</p>
                <p><strong>5. Intellectual property and intellectual property rights</strong></p>
                <p>5.1. Under these Terms of Use, intellectual property rights include copyright (use rights concerning the catalogue, images and database), patents, rights relating to utility models, trademarks, business names, business secrets, know-how, and third parties’ registered and unregistered intellectual property rights the parts and copies of which belong to Allicater, and Allicater has an exclusive right to use them.</p>
                <p>5.2. These Terms of Use do not entitle the Customer to any intellectual property rights in relation to Allicater’s activities.</p>
                <p><strong>6. Rights, obligations and liability of Allicater</strong></p>
                <p>6.1. Allicater facilitates the conclusion of the Service Contract, but only the Customer and the Service Provider are parties to the Service Contract. Allicater is not liable to the Customer regarding the quality of the Service provided by the Service Provider or with respect to the performance of any other contractual obligations of the Service Provider, including the quality or quantity of the food and drinks provided by the Service Provider or the quality of any other products and services ordered through Allicater’s intermediation service. In any case, Allicater is not liable if the Service Provider fails to provide Services to the Customer or provides the Services improperly. Allicater makes no commitments and provides no guarantees with respect to any time delays in the Service Provider’s provision of the Services, the quality, quantity or other aspects relating to the Services.</p>
                <p>6.2. Allicater is not a mediator for any disputes arising between the Service Provider and the Customer. The Customer must submit any complaints relating to confirmed Price Offers, and the Services provided or not provided on the basis of these confirmed Price Offers, directly to the Service Provider and any disputes between the Customer and the Service Provider cannot involve Allicater.</p>
                <p>6.3. Allicater is not a payment institution and is not providing payment services within the meaning provided for under Directive (EU) 2015/2366 of the European Parliament and of the Council. Allicater collects advance payments from the Customers based on Orders confirmed by Service Providers, and forwards the received advance payments to Service Providers in accordance with these Terms of Use.</p>
                <p>6.4. Allicater continuously improves and amends its services and has any and all rights to make changes to or remove certain elements from its platform without notifying the Customer beforehand.</p>
                <p>6.5. In case of a temporary failure occurring in Allicater’s platform, Allicater’s team takes all necessary steps to restore the platform to its previous status. Given that the Intermediation Service is provided to the Customer free of charge and the Intermediation Service is meant for use “as is”, Allicater is not liable to the Customer for any temporary failures or interruptions occurring in or temporary down-time of the platform.</p>
                <p>6.6.  Allicater has no control over and takes no responsibility for the accuracy of the information, designs or images that Service Providers upload to Allicater’s platform. Furthermore, Allicater has no control over and takes no responsibility for any information accessible via third parties’ content links. </p>
                <p>6.7. Allicater can fully or partly transfer its rights and obligations to a legal successor, or to the person acquiring Allicater’s assets and rights, without the prior consent of the Customer.</p>
                <p><strong>7. Final provisions</strong></p>
                <p>7.1. These Terms of Use remain legally binding for Allicater and the Customer until the Customer requests that their user account be closed or deleted.</p>
                <p>7.2. The Customer can close or delete its user account at any time. Allicater can temporarily suspend or permanently discontinue its activities without providing any reasons or prior individual notices to the Customers.</p>
                <p>7.3. Allicater retains the right to amend these Terms of Use by providing a relevant notification to the Customers by e-mail, or announcing any amendments to these Terms of Use on its platform. If the Customer disagrees with any amendments to these Terms of Use, the Customer has the right to close or delete their user account.</p>
            </div>
        );
    }
}
