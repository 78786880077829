
import React from "react";

import GridContainer from "../../components/Grid/GridContainer.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardIcon from "../../components/Card/CardIcon.js";

import styles from "../../assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import {Styles} from "../../styles/Styles";

import {CurrentUser} from "../../services/dto/CurrentUser";
import {ViewUtils} from "../../utils/ViewUtils";
import {BaseComponent} from "../_subviews/BaseComponent";
import {Translation} from "../../core/service/Translation";
import ACCheckbox from "../_subviews/ac-components/ACCheckbox";

export default class ProfileInputFields extends BaseComponent {

    inputs: any[] = [];

    constructor(props: any) {
        super(props);

        this.state = {
            representsCompany: null
        }
    }

    onUpdate() {
        const data: any = {};
        this.inputs.forEach((id: string) => {
            let key = id;

            // Remnant of legacy v1 frontend
            if (id === "area-of-service") {
                key = "serviceArea";
            } else if (id === "edit-business-name") {
                key = "officialBusinessName";
            } else if (id === "edit-company-name") {
                key = "companyName";
            } else if (id === "maximum-attendance-of-catering") {
                key = "maxAttendance";
            } else if (id === "minimum-attendance-of-catering") {
                key = "minAttendance";
            } else if (id === "minimum-price-of-catering") {
                key = "minPrice";
            } else if (id === "vat-number") {
                key = "vatNumber";
            } else if (id === "phone-number") {
                key = "phone";
            }

            data[key] = ViewUtils.getInputValueById(id);
        });
        data.representsCompany = this.representsCompany;
        this.props.onSave(data);
    }

    get representsCompany(): boolean {
        if (this.state.representsCompany === null) {
            return this.props.user.representsCompany === 1;
        }

        return this.state.representsCompany;
    }

    render() {

        const user = this.props.user;
        this.inputs = [];

        return (
            <Card>
                <CardHeader color="info" icon>
                    <CardIcon color="info">
                        {ViewUtils.createIcon("profile")}
                    </CardIcon>
                    <h4 style={Styles.cardIconTitle}>
                        {Translation.find("profile")} - <small>
                        {Translation.find(CurrentUser.instance.isCaterer() ? "edit-business" : "edit-personal")}
                    </small>
                    </h4>
                </CardHeader>
                <CardBody>
                    {CurrentUser.instance.isCaterer() && <GridContainer>
                        {this.createGridItem("edit-business-name", user.officialBusinessName, 6)}
                        {this.createGridItem("edit-company-name", user.companyName, 6)}
                    </GridContainer>}
                    <GridContainer>
                        {this.createGridItem("phone-number", user.phone, 6)}
                        {this.createGridItem("email", user.email, 6, {disabled: true})}
                    </GridContainer>
                    <GridContainer>
                        {this.createGridItem("city", user.city, 6)}
                        {!CurrentUser.instance.isCaterer() && this.createGridItem("address", user.address, 6)}
                        {CurrentUser.instance.isCaterer() && this.createGridItem("area-of-service", user.serviceArea, 6)}
                    </GridContainer>
                    {CurrentUser.instance.isCaterer() && <GridContainer>
                        {this.createGridItem("minimum-attendance-of-catering", user.minAttendance, 4)}
                        {this.createGridItem("maximum-attendance-of-catering", user.maxAttendance, 4)}
                        {this.createGridItem("minimum-price-of-catering", user.minPrice, 4)}
                    </GridContainer>}
                    {CurrentUser.instance.isCaterer() && <GridContainer>
                        {this.createGridItem("regNumber", user.regNumber, 3)}
                        {this.createGridItem("vat-number", user.vatNumber, 3)}
                        {this.createGridItem("iban", user.iban, 6)}
                    </GridContainer>}
                    <GridContainer>
                        {this.createGridItem("description", user.description, 12, {
                            multiline: true, rows: 5
                        })}
                    </GridContainer>
                    {!CurrentUser.instance.isCaterer() && <GridContainer justifyContent="center" style={{marginTop: "20px"}}>
                        <ACCheckbox
                            label={Translation.find("i-represent-company")}
                            checked={this.representsCompany}
                            onChange={this.onCompanyCheckbox.bind(this)}
                        />
                    </GridContainer>}
                    {!user.isCaterer && this.representsCompany && <GridContainer>
                        {this.createGridItem("regNumber", user.regNumber, 6)}
                        {this.createGridItem("edit-company-name", user.companyName, 6)}
                    </GridContainer>}
                    <Button
                        color="primary"
                        style={styles.updateProfileButton}
                        type={"submit"}
                        onClick={this.onUpdate.bind(this)}>
                        {Translation.find("save")}
                    </Button>

                </CardBody>
            </Card>
        );
    }

    onCompanyCheckbox(event: any) {
        this.setState({representsCompany: event.target.checked});
    }

    createGridItem(id: string, text: string, width: number, inputProps?: any) {
        if (!inputProps) {
            inputProps = { defaultValue: text ? text : "" };
        } else {
            inputProps.defaultValue = text ? text : "";
        }
        this.inputs.push(id);
        return ViewUtils.createGridItem(this, id, width, inputProps);
    }
}
