
import React from "react";

import PropTypes from "prop-types";
import cx from "classnames";

import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";

import AdminNavbarLinks from "../Navbars/AdminNavbarLinks.js";
import SidebarUserTab from "./SidebarUserTab";
import SidebarTabs from "./SidebarTabs";

import sidebarStyle from "../../assets/jss/material-dashboard-pro-react/components/sidebarStyle.js";
import withStyles from "@mui/styles/withStyles";
import SidebarHeader from "./SidebarHeader";

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            miniActive: true
        };
    }

    // verifies if routeName is the one active (in browser input)
    activeRoute = routeName => {
        return window.location.href.indexOf(routeName) > -1 ? "active" : "";
    };

    render() {
        const { classes, logo, logoText, bgColor } = this.props;

        const drawerPaper = classes.drawerPaper + " " + cx({
            [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive
        });
        const sidebarWrapper = classes.sidebarWrapper + " " + cx({
            [classes.drawerPaperMini]:
                this.props.miniActive && this.state.miniActive,
            [classes.sidebarWrapperWithPerfectScrollbar]:
                navigator.platform.indexOf("Win") > -1
        });
        const miniActive = this.props.miniActive && this.state.miniActive;

        return (
            <div>
                <Hidden mdUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={"right"}
                        open={this.props.open}
                        classes={{ paper: drawerPaper + " " + classes[bgColor + "Background"] }}
                        onClose={this.props.handleDrawerToggle}
                        // Better open performance on mobile.
                        ModalProps={{ keepMounted: true }}
                    >
                        <SidebarHeader logo={logo} logoText={logoText} bgColor={bgColor} miniActive={miniActive} />

                        <div className={sidebarWrapper}>
                            <SidebarUserTab
                                onClick={this.props.onClick}
                                onRedirect={this.props.onRedirect}
                                miniActive={miniActive}
                                routes={this.props.routes}
                                bgColor={this.props.bgColor}
                            />
                            <AdminNavbarLinks />
                            <SidebarTabs
                                color={this.props.color}
                                routes={this.props.routes}
                                onRedirect={this.props.onRedirect}
                                miniActive={miniActive}
                            />

                        </div>
                    </Drawer>
                </Hidden>
                <Hidden mdDown implementation="css">
                    <Drawer
                        onMouseOver={() => this.setState({ miniActive: false })}
                        onMouseOut={() => this.setState({ miniActive: true })}
                        anchor={"left"}
                        variant="permanent"
                        open
                        classes={{ paper: drawerPaper + " " + classes[bgColor + "Background"] }}
                    >
                        <SidebarHeader logo={logo} logoText={logoText} bgColor={bgColor} miniActive={miniActive} className={"header-logo-text"} />
                        <div className={sidebarWrapper}>
                            <SidebarUserTab
                                onClick={this.props.onClick}
                                onRedirect={this.props.onRedirect}
                                miniActive={miniActive}
                                routes={this.props.routes}
                                bgColor={this.props.bgColor}
                            />
                            <SidebarTabs
                                color={this.props.color}
                                routes={this.props.routes}
                                onRedirect={this.props.onRedirect}
                                miniActive={miniActive}
                            />
                        </div>
                    </Drawer>
                </Hidden>
            </div>
        );
    }
}

Sidebar.defaultProps = {
    color: "primary",
    bgColor: "white",
    fixedClasses: "dropdown",
    logo: require("../../assets/img/allicater/allicater_logo_alt.svg")
};

Sidebar.propTypes = {
    classes: PropTypes.object.isRequired,
    bgColor: PropTypes.oneOf(["white", "black", "blue"]),
    rtlActive: PropTypes.bool,
    color: PropTypes.oneOf([
        "white",
        "red",
        "orange",
        "green",
        "blue",
        "purple",
        "rose",
        "primary"
    ]),
    logo: PropTypes.string,
    logoText: PropTypes.string,
    image: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object),
    miniActive: PropTypes.bool,
    open: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    onRedirect: PropTypes.func,
    onClick: PropTypes.func,
    user: PropTypes.object
};

export default withStyles(sidebarStyle)(Sidebar);
