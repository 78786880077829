
import React, {CSSProperties} from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import {defaultFont, grayColor} from "../../assets/jss/material-dashboard-pro-react";

import {BaseComponent} from "../../views/_subviews/BaseComponent";
import FooterCopyright from "./FooterCopyright";

import TermsPopup from "../../views/_subviews/agreements/TermsPopup";
import PrivacyPopup from "../../views/_subviews/agreements/PrivacyPopup";
import HelpPopup from "../../views/help/popup/HelpPopup";
import EtiqPopup from "../../views/etiquette/popup/EtiqPopup";

import {ViewUtils} from "../../utils/ViewUtils";
import {Language} from "../../core/model/enum/Language";
import {LocalStorage} from "../../services/LocalStorage";
import {Translation} from "../../core/service/Translation";

import i18n from "i18next";

export default class Footer extends BaseComponent {

    constructor(props: any) {
        super(props);

        this.state = {
            showTerms: false,
            showPrivacy: false,
            showHelp: false,
            showEtiq: false
        }
    }

    render() {
        return (
            <footer style={this.style.footer}>
                <TermsPopup isOpen={this.state.showTerms} onClose={() => {
                    this.setState({showTerms: false})
                }}/>
                <PrivacyPopup isOpen={this.state.showPrivacy} onClose={() => {
                    this.setState({showPrivacy: false})
                }}/>
                <HelpPopup isOpen={this.state.showHelp} onClose={() => {
                    this.setState({showHelp: false})
                }}/>
                <EtiqPopup isOpen={this.state.showEtiq} onClose={() => {
                    this.setState({showEtiq: false})
                }}/>
                <div>
                    <div style={this.style.left}>
                        <List style={this.style.list}>
                            {this.createListItem("privacy", () => {
                                this.setState({showPrivacy: true})
                            })}
                            {this.createListItem("terms", () => {
                                this.setState({showTerms: true})
                            })}
                            {this.createListItem("help", () => {
                                this.setState({showHelp: true})
                            })}
                            {this.createListItem("etiq", () => {
                                this.setState({showEtiq: true})
                            })}
                            {this.createLanguageTab()}
                        </List>
                    </div>
                    <FooterCopyright/>
                </div>
            </footer>
        );
    }

    get style() {
        return {
            footer: {
                bottom: "0", borderTop: "1px solid " + grayColor[15], padding: "15px 0", defaultFont, zIndex: 4
            },
            left: {
                float: "left", display: "block"
            } as CSSProperties,
            inlineBlock: {
                display: "inline-block", padding: "0", width: "auto"
            },
            list: {
                marginBottom: "0", padding: "0", marginTop: "0"
            },
            listItem: {
                cursor: "pointer", paddingLeft: "16px", paddingRight: "16px", paddingTop: "8px"
            },
            languageListItem: {
                paddingLeft: "16px"
            },
            languageButton: {
                cursor: "pointer"
            },
            divider: {
                paddingLeft: "3px", paddingRight: "3px"
            }
        }
    }

    createListItem(text: string, action: any) {
        const style = {...this.style.inlineBlock, ...this.style.listItem};
        return <ListItem style={style} onClick={action}>{Translation.find(text)}</ListItem>
    }

    createLanguageTab() {
        const english = LocalStorage.instance.settings.english();
        return (
            <ListItem style={{...this.style.inlineBlock, ...this.style.languageListItem}}>
                <div style={{...this.style.inlineBlock, ...this.style.languageButton}} onClick={async () => {
                    await this.updateLanguage(Language.En);
                }}>{ViewUtils.strong("EN", english)}</div>
                <div style={{...this.style.inlineBlock, ...this.style.divider}}>/</div>
                <div style={{...this.style.inlineBlock, ...this.style.languageButton}} onClick={async () => {
                    await this.updateLanguage(Language.Et);
                }}>{ViewUtils.strong("ET", !english)}</div>
            </ListItem>
        );
    }

    async updateLanguage(language: Language) {
        LocalStorage.instance.updateLanguage(language);
        await i18n.changeLanguage(language);
        this.props.languageUpdate();
    }
}
