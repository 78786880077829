
import {BaseComponent} from "../../views/_subviews/BaseComponent";
import React from "react";
import Button from "../CustomButtons/Button";

export default class WizardFooter  extends BaseComponent {

    render() {
        return (
            <div style={{padding: "0 15px"}}>
                <div style={{float: "left"}}>
                    {this.createButton(this.props.previous)}
                </div>
                <div style={{float: "right"}}>
                    {this.createButton(this.props.next)}
                    {this.createButton(this.props.finish)}
                </div>
            </div>
        );
    }

    createButton(props: any) {
        if (!props.visible) {
            return null;
        }
        return (<Button color="primary" onClick={props.onClick}>{props.text}</Button>);
    }
}
