
import React, {CSSProperties} from "react";

import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardText from "../../../components/Card/CardText";
import CardBody from "../../../components/Card/CardBody";
import Table from "../../../components/Table/Table";
import GridItem from "../../../components/Grid/GridItem";

import {BaseComponent} from "../../_subviews/BaseComponent";
import Offer from "../../../model/Offer";

import {DateUtils} from "../../../core/utils/DateUtils";
import {ViewUtils} from "../../../utils/ViewUtils";

import {Translation} from "../../../core/service/Translation";

const styles = {
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0"
    },
    cardCategoryWhite: {
        margin: "0",
        color: "rgba(255, 255, 255, 0.8)",
        fontSize: ".875rem"
    },
};

export default class EventOfferTable extends BaseComponent {

    render() {
        const title = Translation.find("event") + " #" + this.props.event.id;
        const subtitle = DateUtils.msToLongDateString(this.props.event.date) + " / " + this.props.event.budget + "€";
        return (
            <GridItem xs={12} sm={12} md={6}>
                <Card>
                    <CardHeader color="primary" text>
                        <CardText color="primary">
                            <h4 style={styles.cardTitleWhite}>{title}</h4>
                            <h4 style={styles.cardCategoryWhite}>{subtitle}</h4>
                        </CardText>
                    </CardHeader>
                    <CardBody>
                        {this.createTable()}
                    </CardBody>
                </Card>
            </GridItem>
        );
    }

    labelStyle(): CSSProperties {
        return {width: "100%", textAlign: "center", paddingBottom: "10px", fontSize: "1em"};
    }

    createTable() {
        if (this.props.event.offers?.length === 0) {
            return <div style={this.labelStyle()}>{Translation.find("event-has-no-offers")}</div>;
        }
        //@ts-ignore
        return <Table hover tableHead={this.head()} tableData={this.data()}/>
    }

    head() {
        return Translation.findList(["ID", "caterer", "price", "action"]);
    }
    data() {
        const {event} = this.props;
        if (!event.offers) {
            return [];
        }

        const filtered = event.offers.filter((offer: any) => !offer.rejected);
        return filtered.map((offer: any) => {
            const action = () => this.props.viewDetails(event, offer);
            return [
                "#" + offer.id,
                offer.caterer ? offer.caterer.officialBusinessName : "...",
                // Translation.find("hidden"),
                Offer.calculatePrice(offer),
                ViewUtils.listActionButton(Translation.find("view-offer"), action)
            ];
        });
    }
}
