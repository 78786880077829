import React from "react";
import EtiqPageCollapse from "../subviews/EtiqPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class Presentation extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <EtiqPageCollapse title={"Presentation"}>
                    <li>Items like projector, screen, TV, whiteboard with markers, computer, pointer</li>
                </EtiqPageCollapse>
            );
        }

        return (
            <EtiqPageCollapse title={"Esitlustehnika"}>
                <li>Erinevad visuaalvahendid nagu projektor, ekraan, TV, tahvel koos markeritega, arvuti, pointer</li>
            </EtiqPageCollapse>
        );
    }
}