import {BaseComponent} from "./_subviews/BaseComponent";
import React from "react";

export default class MenuCategoryCounter extends BaseComponent {

    render() {
        if (!this.props.count) {
            return null;
        }
        return <span style={{
            float: "right",
            marginRight: "10px",
            backgroundColor: "#72caaf",
            width: "20px",
            height: "20px",
            color: "white",
            textAlign: "center",
            lineHeight: "20px",
            borderRadius: "10px"

        }}>{this.props.count}</span>;
    }
}
