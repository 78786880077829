import {Networking} from "../../Networking";
import {CurrentUser} from "../../dto/CurrentUser";
import {JSONUtils} from "../../../core/utils/JSONUtils";
import MenuItem from "../../../model/MenuItem";
import Invoice from "../../../model/Invoice";
import {InvoiceType} from "../../../core/model/enum/InvoiceType";
import {DateUtils} from "../../../core/utils/DateUtils";

export default class InvoiceData {

    data?: {invoices: any[]};

    async load(callback?: any): Promise<any> {

        if (this.data) {
            return this.data;
        }

        const invoices = await InvoiceData.fetch();

        this.data = {invoices: invoices};
        return this.data;
    }

    public isEmpty(): boolean {
        return !this.data || this.data.invoices.length === 0;
    }

    public async findById(id: number): Promise<any> {
        if (!this.data) {
            const data = { invoiceId: id };
            const response = await Networking.instance.fetchInvoices(data);
            if (response.invoices.length === 1) {
                return response.invoices[0];
            }
            return undefined;
        }
        return this.data.invoices.find((invoice: any) => invoice.id === id);
    }
    public findCatererIds(): number[] {
        return this.mapToProperty("catererId");
    }

    public findOfferIds(): number[] {
        return this.mapToProperty("offerId");
    }

    public mapToProperty(name: string) {
        const result: any[] = [];
        if (!this.data) { return result; }
        this.data.invoices.forEach((invoice: any) => {
            if (result.indexOf(invoice[name]) === -1) {
                const property = invoice[name];
                if (property) {
                    result.push(property);
                }
            }
        });
        return result;
    }

    static async fetch() {
        let data = CurrentUser.instance.isCaterer() ? { catererId: CurrentUser.instance.getId() } : {};
        let response = await Networking.instance.fetchInvoices(data);
        return response.invoices;
    }

    unpackJsonData() {
        if (this.isEmpty()) {
            return;
        }
        this.data?.invoices.forEach((invoice: any) => {
            InvoiceData.unpackJsonDataFor(invoice);
        });
    }

    static calculatePrice(invoice: any, vat: boolean) {
        if (!invoice) {
            return 0;
        }
        if (!invoice.menuItems) {
            invoice.menuItems = JSON.parse(JSONUtils.escape(invoice.menuItemJson));
        }
        let total: any = MenuItem.calculatePrice(invoice.menuItems, vat, Invoice.isPrepayment(invoice)).total;
        return total;
    }
    
    static unpackJsonDataForAll(invoices: any[]) {
        invoices.forEach((invoice: any) => {
            this.unpackJsonDataFor(invoice);
        });
    }

    static unpackJsonDataFor(invoice: any) {
        const users = JSONUtils.safeParse(invoice.userJson);
        if (users && users.caterer) {
            invoice.caterer = users.caterer;
        }
        invoice.menuItems = JSON.parse(JSONUtils.escape(invoice.menuItemJson));
    }

    static splitAndUnpack(invoiceData?: any[]) {
        const paid: any[] = [];
        const unpaid: any[] = [];

        if (!invoiceData) {
            return { paid: paid, unpaid: unpaid };
        }
        for (let i = 0; i < invoiceData.length; i++) {
            const item = invoiceData[i];
            if (CurrentUser.instance.isCaterer() && item.type !== InvoiceType.Allicater) {
                // For the caterer, only show invoices sent out to Allicater, else skip
                continue;
            }
            this.unpackJsonDataFor(item);

            if (!CurrentUser.instance.isCaterer() && item.type === InvoiceType.Allicater) {
                continue;
            }

            if (item.isPaid) {
                paid.push(item);
            } else {
                unpaid.push(item);
            }
        }
        return { paid: paid, unpaid: unpaid };
    }

    static filterOutFuture(invoices?: any[]) {
        if (!invoices) {
            return [];
        }
        return invoices.filter((invoice: any) => {
            if (Invoice.isPrepayment(invoice)) {
                return true;
            }
            return !DateUtils.isUpcoming(invoice.eventDate, true);
        });
    }
}
