
//@ts-ignore
import convert from 'image-file-resize';

export class ImageUtils {

    static MIN_SIZE = 20;
    static MAX_SIZE = 1000;

    static config(): any {
        return { minWidth: this.MIN_SIZE, minHeight: this.MIN_SIZE, maxWidth: this.MAX_SIZE, maxHeight: this.MAX_SIZE }
    }

    static toMB(bytes: number) {
        return (bytes / (1024*1024)).toFixed(2);
    }

    static downscale(file: any, ratio?: number): Promise<any> {
        return new Promise((resolve, reject) => {
            const size = 600;
            ratio = ratio ?? 0;
            convert({
                file: file,
                width: size * ratio,
                height: size,
                type: 'jpeg'
            }).then((resp: any) => {
                resolve(resp);
                // Response contain compressed and resized file
            }).catch((error: any) => {
                console.log("error downscaling image:", error);
            })
        });
    }

    static toBase64(file: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
}
