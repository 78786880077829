
import React from "react";
import HelpPageCollapse from "../subviews/HelpPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class Payment extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <HelpPageCollapse title={"Paying"}>
                    <li> Payment is in two parts </li>
                    <li> When you create an event, a prepayment invoice of 10% of the event price will be generated </li>
                    <li> After the event, the remaining 90% of the invoice will be generated </li>
                    <li> Invoices are sent to the user's email address and are also accessible from the dashboard and the Invoices menu </li>
                </HelpPageCollapse>
            );
        }

        return (
            <HelpPageCollapse title={"Maksmine"}>
                <li>Maksmine toimub kahes osas</li>
                <li>Ürituse loomisel genereeritakse ettemaksuarve, mis on 10% ürituse hinnnast</li>
                <li>Pärast ürituse toimumist genereeritakse arve järelejäänud 90%</li>
                <li>Arved saadetakse kasutaja e-posti aadressile ning on ligipääsetavad ka töölaualt ning menüüst Arved</li>
            </HelpPageCollapse>
        );
    }
}
