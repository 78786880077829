import {Networking} from "../../Networking";
import Event from "../../../model/Event";
import MenuItem from "../../../model/MenuItem";
import { JSONUtils } from "../../../core/utils/JSONUtils";

export default class OfferListData {

    data?: {events: any[]};

    async load(callback?: any): Promise<any> {

        // if (this.data) {
        //     return this.data;
        // }

        this.data = await Networking.instance.fetchEvents({});

        if (!this.data) {
            console.error("Unable to fetch events. Try refreshing the page");
            return;
        }

        let events = this.data.events;
        events = Event.filterUpcoming(events, true);
        callback({events: events});

        const offerResponse = await Networking.instance.fetchOffers({"eventIds": this.ids()});
        this.data.events.forEach((event: any) => {
            event.offers = offerResponse.offers.filter((offer: any) => offer.eventId === event.id);
        });
        callback({events: events});

        const catererIds: number[] = [];

        const menuItems: any[] = [];
        this.data.events.forEach((event: any) => {
            event.offers.forEach((offer: any) => {
                if (catererIds.indexOf(offer.catererId) === -1 && !isNaN(offer.catererId)) {
                    catererIds.push(offer.catererId);
                }
                offer.menuItems = JSON.parse(JSONUtils.escape(offer.menuItems));
                offer.menuItems.forEach((item: any) => {
                    menuItems.push(item);
                });
            });
        });
        const userResponse = await Networking.instance.fetchUsers({"userIds": catererIds});

        const ratingResponse = await Networking.instance.fetchRatings({"recipientIds": catererIds});
        ratingResponse.ratings.forEach((rating: any) => {
            // LOL. Has this always been empty?
        });

        userResponse.users.forEach((user: any) => {
            user.ratings = ratingResponse.ratings.filter((rating: any) => rating.recipientId === user.id);
            if (!this.data) {
                console.error("Unable to fetch events. Try refreshing the page");
                return;
            }
            this.data.events.forEach(event => {
                const offer = event.offers.find((offer: any) => offer.catererId === user.id);
                if (offer) {
                    offer.caterer = user;
                }
            });
        });

        const items = await MenuItem.parseOfferDataAndDownload(menuItems);

        callback({events: events});

        this.data.events.forEach(event => {
            event.offers.forEach((offer: any) => {
                offer.menuItems.forEach((item: any) => {
                    const downloaded = items.find((existing: any) => existing.id === item.id);
                    if (downloaded) {
                        item.data = downloaded;
                    }
                });
            });
        });

        this.data = {events: events};
        return this.data;
    }

    public ids(): number[] {
        if (!this.data) {
            return [];
        }
        return this.data.events.map((event: any) => event.id);
    }

}
