import React from "react";
import EtiqPageCollapse from "../subviews/EtiqPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class BeerTable extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <EtiqPageCollapse title={"Beertable"}>
                    <li>A variety of beers and schnapps are available. The food is usually products made in the oven or in a deep fryer / frying pan, such as schnitzel, bratwurst, miniburgers, sauerkraut, garlic bread, chicken wings, etc</li>
                </EtiqPageCollapse>
            );
        }

        return (
            <EtiqPageCollapse title={"Õllelaud"}>
                <li>Pakutakse erinevat sorti õllesid ning šnapse. Toiduks on reeglina ahjus või fritüüris / pannil valmistatud tooted, mille näitena võib tuua šnitsli, bratwursti, miniburgerid, hapukapsa, küüslauguleivad, kanatiivad jne. </li>
            </EtiqPageCollapse>
        );
    }
}