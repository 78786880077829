
import React from "react";

import GridItem from "../../../components/Grid/GridItem";
import Button from "../../../components/CustomButtons/Button";
import CustomInput from "../../../components/CustomInput/CustomInput";

import {BaseComponent} from "../../_subviews/BaseComponent";
import Table from "../../_subviews/ac-components/ACTable";
import OfferIcon from "../../../assets/img/allicater/icon/my-offers.svg";

import Offer from "../../../model/Offer";
import {MenuCategories} from "../../../model/constants/MenuCategories";

import {ViewUtils} from "../../../utils/ViewUtils";
import {DateUtils} from "../../../core/utils/DateUtils";
import {FuckingRandomUtils} from "../../../utils/FuckingRandomUtils";
import {Translation} from "../../../core/service/Translation";
import { MathUtils } from "../../../core/utils/MathUtils";

export default class OfferBox extends BaseComponent {

    mapOfferedItems() {
        const list = this.props.offeredItems.map((item: any, index: number) => {
            const category = Translation.find(MenuCategories.textById(item.categoryId));
            return [index + 1, " ", item.name, category, this.countColumn(item), 
                    item.price, MathUtils.round(item.price * item.count)
                ];
        });

        list.push({total: true, colspan: "5", amount: this.calculateTotal()});
        return list;
    }

    mapDescriptions() {
        return this.props.offeredItems.map((item: any, index: number) => {
            return item.description;
        });
    }

    countColumn(item: any) {
        const id = FuckingRandomUtils.generateId();
        const props = {
            type: "number", min: "1", defaultValue: item.count, disabled: !this.props.canEdit, onChange: (e: any) => {
                const count = parseInt(e.target.value, 0);
                if (!isNaN(count)) {
                    this.props.countChanged(item, count);
                }
            }
        };

        // @ts-ignore
        return <CustomInput id={item.id.toString()} key={id} inputProps={props}/>;
    }

    calculateTotal() {
        let result = 0;
        this.props.offeredItems.forEach((item: any) => {
            result += (item.count * item.price);
        });
        return MathUtils.round(result);
    }

    render() {
        const icon = <img src={OfferIcon} className={"squery"} alt={"."} />;
        const title = Translation.find("offer-for-event") + " " + (this.props.event?.id ?? "");
        return (
            ViewUtils.createCard(icon, title,
                <div>
                    <Table
                        head={Translation.findList(["#", " ", "item", "type", "quantity", "item-price", "total"])}
                        rows={this.mapOfferedItems()}
                        rowHints={this.mapDescriptions()}
                    />
                    <GridItem xs={12} sm={6} md={5} lg={12} style={{margin: "10px"}}>
                        {this.createFooter()}
                    </GridItem>
                </div>
            )
        );
    }


    createFooter() {
        if (Offer.isAccepted(this.props.offer) && this.props.isAcceptPage) {
            return <h5>{Translation.find("offer-accepted")}</h5>
        }

        if (Offer.isRejected(this.props.offer)) {
            return <h5>{Translation.find("offer-rejected")}</h5>
        }

        if (DateUtils.isPast(this.props.event?.date)) {
            return <h5>{Translation.find("offer-expired")}</h5>
        }
        
        return (
            <div>
                {!this.props.isAcceptPage && Offer.isAccepted(this.props.offer) 
                    && <h5>{Translation.find("offer-accepted")}</h5>}
                {this.createButton("success", this.positiveButtonText(),
                    () => this.props.positiveClick(), this.isUpdateDisabled())}
                {this.props.isAcceptPage && this.createButton("danger", Translation.find("reject-offer"),
                    () => this.props.negativeClick())}
            </div>
        );
    }

    isUpdateDisabled(): boolean {
        if (!this.props.canEdit) {
            return true;
        }
        return this.props.offeredItems?.length === 0;
    }

    positiveButtonText() {
        if (this.props.isAcceptPage) {
            return this.translate("accept-offer");
        }

        if (this.props.offer) {
            return this.translate("update-offer");
        }
        return this.translate("make-offer");
    }

    createButton(color: string, text: string, action: any, disabled?: boolean) {
        return <Button color={color} disabled={disabled} onClick={action}>{text}</Button>;
    }
}
