import {Networking} from "../../Networking";
import {CurrentUser} from "../../dto/CurrentUser";
import Message from "../../../model/Message";
import MenuItem from "../../../model/MenuItem";
import {Translation} from "../../../core/service/Translation";
import Rating from "../../../model/Rating";
import { OfferState } from "../../../core/model/enum/OfferState";
import Offer from "../../../model/Offer";
import User from "../../../model/User";

export class OfferParams {

    eventId: number;
    offerId: number;
    isAcceptPage: boolean;

    constructor(eventId: number, offerId: number, isAcceptPage: boolean) {
        this.eventId = eventId;
        this.offerId = offerId;
        this.isAcceptPage = isAcceptPage
    }
}

export default class OfferData {

    params?: OfferParams;

    data?: {events: any[]};

    items?: any[];

    ratings?: any[];

    messages?: any[];

    offer?: Offer;

    event?: any;

    async load(params: OfferParams, callback?: any): Promise<any> {

        // if (this.event && this.offer && this.event?.id === params.eventId && this.offer?.id === params.offerId) {
        //     callback({offer: this.offer});
        //     callback({offeredItems: this.items});
        //     callback({messages: this.messages});
        //     return this.data;
        // } else {
        //     this.offer = undefined;
        // }

        const eventResponse = await Networking.instance.fetchEvents({"eventId": params.eventId});
        if (eventResponse.events.length > 0) {
            this.event = eventResponse.events[0];
        }

        const data = params.isAcceptPage ? {"offerId": params.offerId} : {eventId: params.eventId, filterByMe: true};
        const offerResponse = await Networking.instance.fetchOffers(data);

        if (offerResponse.offers.length > 0) {
            this.offer = offerResponse.offers[0];
        } else {
            this.offer = undefined;
        }

        if (params.isAcceptPage) {
            await this.downloadMenuOf(this.offer?.catererId);
        } else {
            await this.download();
        }

        callback({offer: this.offer});

        if (params.isAcceptPage) {
            this.items = this.findOfferedItemsOfCaterer(this.offer?.menuItems)
        } else if (!this.isNew()) {
            this.items = this.findOfferedItems(this.offer?.menuItems)
        } else if (this.isNew()) {
            this.items = [];
        }

        if (!this.items) {
            this.items = [];
        }
        callback({offeredItems: this.items});

        if (params.isAcceptPage || !this.isNew()) {
            const result = await Networking.instance.fetchMessages({offerId: this.offer?.id});

            let creator = undefined;
            let caterer: User | undefined;

            const ids = [this.event.creatorId, this.offer?.catererId];
            const users = (await Networking.instance.fetchUsers({userIds: ids})).users;
            if (users) {
                creator = users.find((user: any) => user.id === this.event.creatorId);
                caterer = users.find((user: any) => user.id === this.offer?.catererId);
            }
            console.log("users", users);

            if (result.messages) {
                const data = {isCaterer: CurrentUser.instance.isCaterer()};
                let messages = Message.initial(data, (tx: string) => Translation.find(tx));
                messages = [...messages, ...Message.createAll(result.messages, creator)];

                if (this.offer && caterer && this.offer.status === OfferState.Accepted) {
                    messages.push(Message.catererInfo(this.offer, caterer, (tx: string) => Translation.find(tx)));
                }
                
                this.messages = messages;

                callback({messages: this.messages});
            }
        }

        const recipientIds = [];
        if (CurrentUser.instance.isCaterer()) {
            recipientIds.push(this.event?.creatorId);
        } else {
            recipientIds.push(this.offer?.catererId);
        }

        const ratingResponse = await Networking.instance.fetchRatings({recipientIds: recipientIds});
        this.ratings = Rating.filterComplete(ratingResponse);
        callback({ratings: this.ratings});

        return this.data;
    }

    clear() {
        this.event = undefined;
        this.offer = undefined;
        this.data = undefined;
        this.myMenu = undefined;
        this.catererMenu = undefined;
        this.items = undefined;
        this.messages = undefined;
    }

    isNew() {
        return this.offer === null || this.offer === undefined;
    }

    // Data downloaded without arguments provided: the menu of the caterer
    private myMenu: any;
    // The menu of a specific caterer. For now, one instance exists at a time
    private catererMenu: any;

    public async download() {
        const response = await Networking.instance.fetchMenu({});
        this.myMenu = response.items;
    }

    public async downloadMenuOf(catererId?: number) {
        const response = await Networking.instance.fetchMenu({ catererId: catererId});
        this.catererMenu = response.items;
    }

    public findMenuItemsFor(categoryId: number, isOwnMenu = true) {
        const data = isOwnMenu ? this.myMenu : this.catererMenu;
        if (!data) {
            return [];
        }
        return data.filter((item: any) => item.categoryId === categoryId);
    }

    public findOfferedItems(json?: string) {
        return MenuItem.findOfferedItemsOfData(this.myMenu, json);
    }

    public findOfferedItemsOfCaterer(json?: string) {
        return MenuItem.findOfferedItemsOfData(this.catererMenu, json);
    }

    public countForCategory(id: number): number {
        const menu = this.myMenu ?? this.catererMenu;
        const existing = menu?.filter((item: any) => item.categoryId === id);
        if (existing) {
            return existing.length;
        }
        return 0;
    }

}
