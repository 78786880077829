
import React from "react";
import GridContainer from "../../components/Grid/GridContainer";

import ACCheckbox from "./ac-components/ACCheckbox";
import ACMultiSelect from "./ac-components/ACMultiSelect";

import {CateringEnums} from "../../model/constants/CateringEnums";
import {Translation} from "../../core/service/Translation";
import {BaseComponent} from "./BaseComponent";

export default class CateringTypeSelector extends BaseComponent {

    handleToggle() {
        this.forceUpdate();
    }

    onSelectChange(select: any, e: any) {
        select.selectedValues = e.target.value;
        this.callback();
        this.forceUpdate();
    }

    onCheckboxSelected(box: any, e: any) {
        box.isChecked = e.target.checked;
        this.callback();
        this.forceUpdate();
    }

    callback() {
        if (this.props.callback) {
            this.props.callback({type: "save", tab: "catering"});
        }
    }

    render() {
        return (
            <GridContainer justifyContent="center">
                <GridContainer justifyContent="center">
                    {
                        CateringEnums.checkboxes.map((box, index) => {
                            return <ACCheckbox
                                key={index}
                                index={index}
                                label={Translation.find(box.id)}
                                checked={box.isChecked}
                                onChange={(e: any) => this.onCheckboxSelected(box, e)}
                                
                            />
                        })
                    }
                </GridContainer>

                <GridContainer justifyContent="center">
                    {
                        CateringEnums.subtypes.map((select, index) => {
                            return <ACMultiSelect
                                key={index}
                                hidden={CateringEnums.hideSelect(select)}
                                id={select.id}
                                index={index}
                                text={Translation.find(select.tx)}
                                options={select.items.map(item => {
                                    return {id: item.id, text: Translation.find(item.tx_class)};
                                })}
                                selectedOptions={select.selectedValues}
                                optionSelected={(e: any) => this.onSelectChange(select, e)}
                                multiple={true}
                            />
                        })
                    }
                </GridContainer>
            </GridContainer>
        );
    }
}
