
import React from "react";

import Button from "../../components/CustomButtons/Button";
import {BaseComponent} from "../_subviews/BaseComponent";

import InvoiceList from "./subviews/InvoiceList";

import {CurrentUser} from "../../services/dto/CurrentUser";
import LocalData from "../../services/state/LocalData";
import InvoiceData from "../../services/state/sub/InvoiceData";
import {Translation} from "../../core/service/Translation";

export default class InvoiceListPage extends BaseComponent {

    constructor(props: any) {
        super(props);
        this.state = {
            invoices: [],
            unpaid: [],
            paid: []
        }
    }

    componentDidMount = async () => {
        if (CurrentUser.instance.exists()) {
            this.loadData();
        } else {
            CurrentUser.instance.loaded = () => {
                this.loadData();
            }
        }
    };

    async loadData() {
        const data = await LocalData.invoices.load();
        this.setState(data);

        let parsed = this.parseData();
        this.setState({ unpaid: parsed.unpaid, paid: parsed.paid });

        if (LocalData.invoices.isEmpty()) {
            console.log("No invoices to display");
        }
    }

    parseData() {
        return InvoiceData.splitAndUnpack(this.state.invoices);
    }

    render() {
        if (!CurrentUser.instance.exists()) {
            return null;
        }
        return (
            <div>
                <InvoiceList
                    title={CurrentUser.instance.isCaterer() ? "sent-invoices-unpaid" : "unpaid-invoices"}
                    invoices={this.state.unpaid}
                    actions={this.actions.bind(this)}
                />
                <InvoiceList
                    title={CurrentUser.instance.isCaterer() ? "sent-invoices-paid" : "paid-invoices"}
                    invoices={this.state.paid}
                    actions={this.actions.bind(this)}
                />
            </div>
        );
    }

    actions(item: any) {
        return <Button size="sm" color={this.mustPay(item) ? "warning" : "success"} onClick={() => {
            this.props.viewDetails(item);
        }}>{Translation.find(this.mustPay(item) ? "pay" : "view-details")}</Button>
    }

    mustPay(invoice: any) {
        if (CurrentUser.instance?.isCaterer()) {
            return false;
        }
        return !invoice.isPaid;
    }
}
