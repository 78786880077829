
import React from "react";
import {BaseComponent} from "../_subviews/BaseComponent";

import Registration from "./content/Registration";
import FinishProfileCustomer from "./content/FinishProfileCustomer";
import FinishProfileCaterer from "./content/FinishProfileCaterer";
import ManageMenu from "./content/ManageMenu";
import OfferMake from "./content/OfferMake";
import OfferEdit from "./content/OfferEdit";
import OfferHistory from "./content/OfferHistory";
import Invoices from "./content/Invoices";
import EventHistory from "./content/EventHistory";
import Reviews from "./content/Reviews";
import EventCreate from "./content/EventCreate";
import OfferView from "./content/OfferView";
import Payment from "./content/Payment";
import Dashboard from "./content/Dashboard";

export default class HelpPageContent extends BaseComponent {
    render() {
        const english = this.props.en;
        return (
            <div>
                <Registration en={english}/>
                <FinishProfileCustomer en={english}/>
                <FinishProfileCaterer en={english}/>
                <ManageMenu en={english}/>
                <OfferMake en={english}/>
                <OfferEdit en={english}/>
                <OfferHistory en={english}/>
                <Invoices en={english}/>
                <EventHistory en={english}/>
                <Reviews en={english}/>
                <EventCreate en={english}/>
                <OfferView en={english}/>
                <Payment en={english}/>
                <Dashboard en={english}/>
            </div>
        );
    }
}
