import React from "react";

import {BaseComponent} from "../BaseComponent";

import Checkbox from "@mui/material/Checkbox";
import Check from "@mui/icons-material/Check";
import FormControlLabel from "@mui/material/FormControlLabel";

import {blackColor, hexToRgb} from "../../../assets/jss/material-dashboard-pro-react";
import {withStyles} from "@mui/styles";
import checkbox from "../../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
const style = {
    ...checkbox,
    checkboxLabelControl: {
        margin: "0"
    },
    checkboxLabel: {
        fontSize: "0.875rem",
        marginLeft: "6px",
        color: "rgba(" + hexToRgb(blackColor) + ", 0.75)",
        fontFamily: '"Open Sans"'
    }
};

class ACCheckbox extends BaseComponent {
    render() {
        const {classes, checked, index} = this.props;
        return (
            <FormControlLabel
                key={index ?? 0}
                classes={{root: classes.checkboxLabelControl, label: classes.checkboxLabel}}
                style={{fontFamily: '"Open Sans"'}}
                control={
                    <Checkbox
                        key={index ?? 0}
                        checked={checked}
                        onChange={this.props.onChange}
                        checkedIcon={<Check className={classes.checkedIcon}/>}
                        icon={<Check className={classes.uncheckedIcon}/>}
                        classes={{checked: classes.checked, root: classes.checkRoot}}
                    />}
                label={this.props.label}
            />);
    }
}

//@ts-ignore
export default withStyles(style)(ACCheckbox);
