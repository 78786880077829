
import React from "react";
import { withTranslation } from 'react-i18next';

import {BaseComponent} from "../../_subviews/BaseComponent";

import "../../../assets/css/landing.css";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import GridContainer from "../../../components/Grid/GridContainer";
import SwedPaymentForm from "./subviews/SwedPaymentForm";
const buffet = require('../../../assets/img/allicater/foodz.png');

class PaymentRedirectPage extends BaseComponent {

    componentDidMount = async () => {
        const form = document.getElementById("swedbank738252") as HTMLFormElement;
        form.submit();
    };

    render() {

        return (
            <div>
                <div className={"container"}>
                    <img src={buffet} className={"img-responsive rounded about-sec"} alt={"."}
                         style={{marginBottom: 0, marginTop: "30px"}}/>
                    <GridContainer justifyContent="center">
                        <GridItem xs={12} sm={12} md={7}>
                            <Card>
                                <SwedPaymentForm/>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        );
    }

}


export default withTranslation()(PaymentRedirectPage);
