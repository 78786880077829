import React from "react";
import EtiqPageCollapse from "../subviews/EtiqPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class Tray extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <EtiqPageCollapse title={"Service on Tray"}>
                    <li>Laudadel on suupisted ja külmad eelroad, kuid teenindajad serveerivad sooja toidu vaagnatel</li>
                </EtiqPageCollapse>
            );
        }

        return (
            <EtiqPageCollapse title={"Kandikul teenindus"}>
                <li>Laudadel on suupisted ja külmad eelroad, kuid teenindajad serveerivad sooja toidu vaagnatel</li>
            </EtiqPageCollapse>
        );
    }
}