import React from "react";
import {BaseComponent} from "../_subviews/BaseComponent";
import {Translation} from "../../core/service/Translation";

import Restaurant from "./content/Restaurant";
import Catering from "./content/Catering";
import Bar from "./content/Bar";
import Foodtruck from "./content/Foodtruck";
import Buffee from "./content/Buffee";
import Standing from "./content/Standing";
import Full from "./content/Full";
import Tray from "./content/Tray";
import Business from "./content/Business";
import Coffee from "./content/Coffee";
import Opening from "./content/Opening";
import Dish from "./content/Dish";
import Presentation from "./content/Presentation";
import Sound from "./content/Sound";
import Long from "./content/Long";
import Short from "./content/Short";
import Estonian from "./content/Estonian";
import Fine from "./content/Fine";
import Vegan from "./content/Vegan";
import Italian from "./content/Italian";
import Asian from "./content/Asian";
import Japanese from "./content/Japanese";
import Chinese from "./content/Chinese";
import Thai from "./content/Thai";
import Mexican from "./content/Mexican";
import BeerTable from "./content/BeerTable";
import Rustic from "./content/Rustic";
import Arab from "./content/Arab";


export default class EtiqPageContent extends BaseComponent {
    render() {
        const english = this.props.en;
        return (
            <div className={"etiq-headers"}>
                <h4>{Translation.find("catering-type")}</h4>
                <Restaurant en={english}/>
                <Catering en={english}/>
                <Bar en={english}/>
                <Foodtruck en={english}/>
                <h4>{Translation.find("service-type")}</h4>
                <Buffee en={english}/>
                <Standing en={english}/>
                <Full en={english}/>
                <Tray en={english}/>
                <Business en={english}/>
                <Coffee en={english}/>
                <Opening en={english}/>
                <h4>{Translation.find("extra-items")}</h4>
                <Dish en={english}/>
                <Presentation en={english}/>
                <Sound en={english}/>
                <h4>{Translation.find("seating-type")}</h4>
                <Long en={english}/>
                <Short en={english}/>
                <h4>{Translation.find("cuisines")}</h4>
                <Estonian en={english}/>
                <Fine en={english}/>
                <Vegan en={english}/>
                <Italian en={english}/>
                <Asian en={english}/>
                <Japanese en={english}/>
                <Chinese en={english}/>
                <Thai en={english}/>
                <Mexican en={english}/>
                <BeerTable en={english}/>
                <Rustic en={english}/>
                <Arab en={english}/>
            </div>
        );
    }
}
