import { MathUtils } from "../utils/MathUtils";

export class InvoicePrice {

    static readonly VAT_PERCENTAGE = 20;
    static readonly PREPAYMENT_PERCENTAGE = 20;

    public total = 0;
    public vat = 0;
    public withoutVat = 0;

    public static fromPrepaidAmount(amount: number, vat: boolean): InvoicePrice {
        const result = new InvoicePrice();
        result.total = amount;
        result.calculateVat(vat);
        return result;
    }

    public add(item: any) {
        this.total += item.count * item.data.price;
    }

    public toPrepayment() {
        this.total = this.total * InvoicePrice.PREPAYMENT_PERCENTAGE / 100;
    }

    public calculateVat(vat: boolean) {
        const percentage = vat ? InvoicePrice.VAT_PERCENTAGE : 0;
        const noVat = this.total / (1 + percentage / 100);
        this.vat = this.total - noVat;
        this.withoutVat = noVat;
    }

    public subtractPrepayment(prepaid: number, vat: boolean) {
        this.total -= prepaid;
        this.calculateVat(vat);
    }

    round() {
        this.total = MathUtils.round(this.total);
        this.vat = MathUtils.round(this.vat);
        this.withoutVat = MathUtils.round(this.withoutVat);
    }
}
