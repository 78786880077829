
import React from "react";
import { withTranslation } from 'react-i18next';

import {BaseComponent} from "../../../_subviews/BaseComponent";

import GridItem from "../../../../components/Grid/GridItem";
import GridContainer from "../../../../components/Grid/GridContainer";

class SwedPaymentForm extends BaseComponent {

    componentDidMount = async () => {
        if (this.props.payment?.redirect) {
            const form = document.getElementById("swedbank738252") as HTMLFormElement;
            form.submit();
        }
    };

    render() {
        const data = this.props.payment?.data;
        return (
            <div>
                <div className={"container"}>
                    <GridContainer justifyContent="center">
                        <GridItem xs={12} sm={12} md={7}>
                            <form action="https://www.swedbank.ee/banklink" method="post" id="swedbank738252">
                                <input type="hidden" name="VK_SERVICE" value="1002"/>
                                <input type="hidden" name="VK_VERSION" value="008"/>
                                <input type="hidden" name="VK_SND_ID" value="POSTION"/>
                                <input type="hidden" name="VK_STAMP" value="10"/>
                                <input type="hidden" name="VK_AMOUNT" value={data.VK_AMOUNT}/>
                                <input type="hidden" name="VK_CURR" value="EUR"/>
                                <input type="hidden" name="VK_REF" value=""/>
                                <input type="hidden" name="VK_MSG" value={data.VK_MSG}/>
                                <input type="hidden" name="VK_MAC" value={data.VK_MAC}/>
                                <input type="hidden" name="VK_RETURN" value={data.VK_RETURN}/>
                                <input type="hidden" name="VK_LANG" value="ENG"/>
                                <input type="hidden" name="VK_ENCODING" value="UTF-8"/>
                            </form>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        );
    }

}


export default withTranslation()(SwedPaymentForm);
