import React from "react";
import EtiqPageCollapse from "../subviews/EtiqPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class Sound extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <EtiqPageCollapse title={"Sound Systems"}>
                    <li>Speakers, remote control, sound players, microphones, main microphones, necessary wires</li>
                </EtiqPageCollapse>
            );
        }

        return (
            <EtiqPageCollapse title={"Helitehnika"}>
                <li>Kõlarid, pult, helimängijad, mikrofonid, peamikrofonid, vajalikud juhtmed</li>
            </EtiqPageCollapse>
        );
    }
}