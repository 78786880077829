
import React from "react";
import CollapseBlock from "./CollapseBlock";
import {BaseComponent} from "../../_subviews/BaseComponent";
import {Typography} from "@mui/material";

export default class EtiqPageCollapse extends BaseComponent {

    render() {
        return (
            <CollapseBlock
                title={this.props.title}
                content={
                    <Typography paragraph>
                        <ul style={{fontFamily: "'Open Sans"}}>
                            {this.props.children}
                        </ul>
                    </Typography>
                }
            />
        );
    }
}

