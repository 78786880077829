import React from "react";
import EtiqPageCollapse from "../subviews/EtiqPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class Estonian extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <EtiqPageCollapse title={"Estonian"}>
                    <li>Traditional simpler food made from local ingredients, which today refers to German-influenced dishes such as potato salad, sauerkraut, pork roast, mashed potatoes, various simpler pies such as apple pie, etc</li>
                </EtiqPageCollapse>
            );
        }

        return (
            <EtiqPageCollapse title={"Eesti"}>
                <li>Kohalikust toorainest valmistatud traditsiooniline lihtsam toit, mille all peetakse tänapäeval silmas pigem saksa mõjutustega sööke nagu kartulisalat, hapukapsas, seapraad, mulgipuder, erinevad lihtsamad koogid nagu õunakook jms</li>
            </EtiqPageCollapse>
        );
    }
}