import React from "react";

import {BaseComponent} from "../_subviews/BaseComponent";

import PaymentActions from "./subviews/PaymentActions";
import InvoiceActions from "./subviews/InvoiceActions";
import SwedPaymentForm from "../payment/redirect/subviews/SwedPaymentForm";

import {Routing} from "../../services/Routing";
import InvoiceData from "../../services/state/sub/InvoiceData";
import LocalData from "../../services/state/LocalData";
import {Networking} from "../../services/Networking";
import {CurrentUser} from "../../services/dto/CurrentUser";

import {InvoicePDFView} from "./InvoicePDFView";
import { toast, ToastContainer } from "react-toastify";
import { Translation } from "../../core/service/Translation";

export default class InvoicePage extends BaseComponent {

    constructor(props: any) {
        super(props);
        this.state = {
            invoice: "",
            showStripeForm: false,
            compact: false,
            payment: undefined,
            isCurrentUserClient: false,
            disableEmailButton: false
        }
    }

    componentDidMount = async () => {
        if (CurrentUser.instance.exists()) {
            await this.loadData();
        } else {
            CurrentUser.instance.loaded = async() => {
                await this.loadData();
            }
        }
    };

    async loadData() {
        const id = Routing.instance.getUrlNumberParameter("id");
        const invoice = await LocalData.invoices.findById(id!);

        if (!invoice) {
            return;
        }
        
        this.setState({
            invoice: invoice,
            isCurrentUserClient: invoice.customerId === CurrentUser.instance.getId()
        });
    };

    render() {
        const invoice = this.state.invoice;
        if (!invoice) {
            return null;
        }
        return (
            <div>
                <ToastContainer/>
                {this.state.payment && <SwedPaymentForm payment={this.state.payment}/>}
                <InvoicePDFView invoice={invoice} compact={this.state.compact} />
                <InvoiceActions
                    invoice={invoice}
                    printPdf={() => this.printPdf()}
                    emailPdf={() => this.emailPdf()}
                    disableEmailButton={this.state.disableEmailButton}
                    updateViewMode={(compact: boolean) => this.updateViewMode(compact)}
                />
                <PaymentActions
                    isVisible={this.state.isCurrentUserClient && invoice.isPaid === 0}
                    style={{marginTop: "100px !important"}}
                    pay={{
                        swed: () => this.onSwedButtonClick(),
                        seb: () => this.onSebButtonClick(),
                        stripe: () => this.onStripeButtonClick(),
                    }}/>
            </div>
        );
    }

    async printPdf() {

    }

    async emailPdf() {
        this.setState({disableEmailButton: true}, () => {
            Networking.instance.emailPdf(this.state.invoice.id, this.state.compact);
            toast(Translation.find("email-invoice-sent"), {
                position: "top-center",
                autoClose: 5000,
                type: "success",
                hideProgressBar: true,
                }
            );
            this.setState({disableEmailButton: false});
        })
    }

    updateViewMode(compact: boolean) {
        this.setState({compact: compact});
    }

    async onSwedButtonClick() {
        let amount = InvoiceData.calculatePrice(this.state.invoice, true);
        if (this.state.invoice && this.state.invoice.calculatedPrice) {
            if (this.state.invoice.calculatedPrice.total) {
                amount = this.state.invoice.calculatedPrice.total;
            }
        }
        const data = {invoiceId: this.state.invoice.id, amount: amount};
        const response = await Networking.instance.payment.fetchSwed(data);
        this.setState({payment: {redirect: true, data: response.json}});
    }

    onSebButtonClick() {
        console.log("seb");
    }

    async onStripeButtonClick() {
        console.log("stripe");
        this.setState({showStripeForm: true});
    }

}
