
import React from "react";

import Datetime from "react-datetime";

import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";

import {NewEvent} from "../../services/NewEvent";
import {FuckingRandomUtils} from "../../utils/FuckingRandomUtils";
import {ViewUtils} from "../../utils/ViewUtils";

import "moment/locale/et";
import "moment/locale/en-gb";
import {Translation} from "../../core/service/Translation";
import {BaseComponent} from "./BaseComponent";
import {DateUtils} from "../../core/utils/DateUtils";
import InputLabel from "@mui/material/InputLabel";
import moment from "moment";


export default class EventBasicInfoFields extends BaseComponent {

    fields = [
        {id: "location", type: "text", width: 3, isValid: true},
        {id: "budget", type: "number", width: 3, isValid: true},
        {id: "people-attending", type: "number", width: 3, isValid: true},
        {id: "how-many-children", type: "number", width: 3, isValid: true},

        {id: "date", type: "number", width: 4, isValid: true, format: { date: "DD/MM/yyyy"}, value: this.minDateForEvent().add(1, "day")},
        {id: "time", type: "number", width: 4, isValid: true, format: { time: "HH:mm"}, value: "18:00"},
        {id: "duration", type: "number", width: 4, isValid: true},



        {id: "cleaningTime", type: "number", width: 6, isValid: true, format: { time: "HH:mm"}, value: "23:00"}
    ];

    validate() {
        const info = NewEvent.instance.findBasicInfo();
        const keys = Object.keys(info);

        let result = true;

        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const field = this.fields.find(field => field.id === NewEvent.unmapSpecialCases(key));
            if (!info[key]) {
                field!.isValid = false;
                result = false;
            } else {
                field!.isValid = true;
            }
        }
        this.forceUpdate();
        return result;
    }

    minDateForEvent() {
        return moment().add(2, 'day');
    }

    render() {
        NewEvent.instance.basicInfoFieldIds = [];
        return (
            <GridContainer justifyContent="center">
                {this.createGridItem(this.fields[0])}
                {this.createGridItem(this.fields[1])}
                {this.createGridItem(this.fields[2])}
                {this.createGridItem(this.fields[3])}

                {this.createDatetimeGridItem(this.fields[4])}
                {this.createDatetimeGridItem(this.fields[5])}
                {this.createGridItem(this.fields[6])}



                {this.createDatetimeGridItem(this.fields[7])}
            </GridContainer>
        );
    }

    createGridItem(field: any) {
        NewEvent.instance.basicInfoFieldIds.push(field.id);
        const existingValue = NewEvent.instance.findStoredValueForId(field.id);
        const inputProps = {
            key: FuckingRandomUtils.generateId(),
            type: field.type,
            defaultValue: existingValue,
            onChange: (e: any) => {
                if (this.props.callback) {
                    this.props.callback({type: "save", tab: "basic", field: field, data: e.target.value});
                }
            }
        };
        return ViewUtils.createGridItem(this, field.id, field.width, inputProps, !field.isValid)
    }

    createDatetimeGridItem(field: any) {
        NewEvent.instance.basicInfoFieldIds.push(field.id);
        let existingValue;
        let existingDate;

        if (field.id === "cleaningTime") {
            existingValue = NewEvent.instance.findStoredValueForId("cleaningTime");
            // Convert hours and minutes only to a full-fledged date so it's not affected by DST.
            const splitNumeric = DateUtils.mapDateField(existingValue ?? field.value, ":");
            const hours = splitNumeric[0];
            const minutes = splitNumeric[1];
            const fullDate = new Date();
            fullDate.setHours(hours, minutes, 0, 0);
            existingDate = fullDate;
        } else {
            existingValue = NewEvent.instance.findStoredValueForId("date");
            existingDate = existingValue ? new Date(parseInt(existingValue)) : null;
        }
        
        return (
            <GridItem xs={12} sm={12} md={4} style={{marginTop: "23px"}}>
                <InputLabel style={{
                    color: "#888 !important",
                    fontSize: "10.5px",
                    fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
                    fontWeight: 400
                }}>
                    {Translation.find(field.id)}
                </InputLabel>
                <Datetime
                    // locale={moment.locale(LocalStorage.instance.settings.english() ? "en-gb" : "et")}
                    locale={"et"}
                    key={FuckingRandomUtils.generateId()}
                    timeFormat={field.format.time ?? false}
                    dateFormat={field.format.date ?? false}
                    initialValue={existingDate ?? field.value}
                    
                    onChange={(e: any) => {
                        setTimeout(() => {
                            // I absolutely hate this, but at the time of rendering, 
                            // the new value has not been applied, so it quite literally just 
                            // returns the previous value. Just give it a second.
                            if (this.props.callback) {
                                this.props.callback({type: "save", tab: "basic", field: field, dataType: "date"});
                            }
                        }, 300);
                        
                        
                    }}
                    timeConstraints={{ minutes: {min: 0, max: 59, step: 15}}}
                    isValidDate={(current: any) => {
                        // This is somewhat counter-intuitive. This will loop through all dates
                        return !current.isBefore(this.minDateForEvent());
                    }}
                    inputProps={{
                        id: field.id,
                        placeholder: Translation.find(field.id),
                        key: FuckingRandomUtils.generateId(),
                        autoComplete: "off",
                        readOnly: true
                    }}
                />
            </GridItem>
        );
    }
}
