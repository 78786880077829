
export class MathUtils {

    static guid(len: number) {
        const buf = [],
            chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
            charlen = chars.length,
            length = len || 32;

        for (let i = 0; i < length; i++) {
            buf[i] = chars.charAt(Math.floor(Math.random() * charlen));
        }

        return buf.join('');
    }

    static round(amount: number): number {
        const fixed = amount.toFixed(2);
        
        const result = parseFloat(fixed);
        return result;
    }
}
