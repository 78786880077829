import {Settings} from "./Settings";
import {Language} from "../core/model/enum/Language";

export class LocalStorage {

    public static instance = new LocalStorage();
    

    constructor() {
        this.settings = new Settings(this.get(this.SETTINGS_KEY));
    }
    public isLoggedIn() {
        return !!this.getSessionToken();
    }

    TOKEN_KEY = "allicater-global-session-id";
    getSessionToken() {
        return this.get(this.TOKEN_KEY);
    }
    setSessionToken(value: string) {
        this.set(this.TOKEN_KEY, value);
    }
    deleteSessionToken() {
        this.remove(this.TOKEN_KEY);
    }

    public set(key: string, value: string) {
        localStorage.setItem(key, value);
    }
    public get(key: string): any {
        return localStorage.getItem(key);
    }
    public remove(key: string) {
        localStorage.removeItem(key);
    }

    public clear() {
        this.deleteSessionToken();
        this.remove(this.EVENT_KEY);
    }

    SETTINGS_KEY = "allicater-settings";
    updateLanguage(language: Language) {
        this.settings.language = language ?? Settings.DEFAULT_LANGUAGE;
        this.set(this.SETTINGS_KEY, JSON.stringify(this.settings));
    }
    settings: Settings;

    EVENT_KEY = "allicater-new-event";
    updateEventData(data: any) {
        if (!data) {
            localStorage.removeItem(this.EVENT_KEY);
        } else {
            this.set(this.EVENT_KEY, JSON.stringify(data));
        }
    }
    loadEventData() {
        return localStorage.getItem(this.EVENT_KEY);
    }

    CATERER_AGREEMENT_KEY = "caterer-has-agreed";
    public hasCatererAgreed(): boolean {
        return this.get(this.CATERER_AGREEMENT_KEY) === "true";
    }
    public setCatererAgreed() {
        this.set(this.CATERER_AGREEMENT_KEY, "true");
    }
}
