
import React from "react";
import HelpPageCollapse from "../subviews/HelpPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class OfferHistory extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <HelpPageCollapse title={"Offer history"}>
                    <li>Under History, you can see the offers that have been made by you and additional information about them</li>
                </HelpPageCollapse>
            );
        }

        return (
            <HelpPageCollapse title={"Pakkumiste ajalugu"}>
                <li>Pakkumiste ajaloo all näeb sooritatud pakkumisi ning nende kohta käivat lisainfot</li>
            </HelpPageCollapse>
        );
    }
}
