
import React from "react";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Wizard from "../../components/Wizard/Wizard";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Button from "../../components/CustomButtons/Button";
import Card from "../../components/Card/Card";

import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";

import { BaseComponent } from "../_subviews/BaseComponent";
import ACNotifier from "../_subviews/ac-components/ACNotifier";
import PreviousEventList from "./PreviousEventList"

import { CateringEnums } from "../../model/constants/CateringEnums";

import EtiqPopup from "../../views/etiquette/popup/EtiqPopup";

import { NewEvent } from "../../services/NewEvent";
import { Networking } from "../../services/Networking";
import { Routing } from "../../services/Routing";
import { Translation } from "../../core/service/Translation";
import { DateUtils } from "../../core/utils/DateUtils";

export default class EventCreatePage extends BaseComponent {

    wizard: any;

    constructor(props: any) {
        super(props);

        this.state = {
            existing: undefined,
            delete: false,
            hardReload: false,
            showEtiq: false,
            showPreviousEvents: false
        };
    }

    async submitEvent(e: any) {

        let result;
        if (this.isEditPage()) {
            const data = NewEvent.instance.findData();
            data.id = this.existingEvent().id;
            result = await Networking.instance.updateEvent(data);
        } else {
            result = await Networking.instance.createEvent(NewEvent.instance.findData());
        }

        NewEvent.instance.clearData();
        if (result.error) {

        } else {
            const message = this.isEditPage() ? "event-update-success-content" : "event-create-success-content";
            this.props.alert(Translation.find("success-header"), Translation.find(message), "#/");
        }
    }

    onNextClick(e: any) {
        if (e.isComplete()) {
            this.wizard.moveNext();
        }
    }

    componentDidMount = async () => {
        let event = this.props.existing;
        const id = Routing.instance.getUrlNumberParameter("eventId");
        if (id) {
            const result = await Networking.instance.fetchEvents({ eventId: id });
            if (result.events && result.events.length > 0) {
                event = result.events[0];
                NewEvent.instance.saveData(event);
                this.setState({ existing: event });
            }
        }
    };

    isEditPage() {
        return this.existingEvent() !== undefined;
    }

    existingEvent() {
        return this.state.existing;
    }

    render() {
        CateringEnums.clear();
        CateringEnums.fill(NewEvent.instance.findStoredCateringInfo());

        return (
            <div>
                <ToastContainer />
                {this.deleteNotifier()}
                <GridContainer justifyContent="center" style={{marginBottom: "10px"}}>
                    <GridItem xs={12} sm={10}>
                        <Button color="primary" onClick={this.showPreviousEvents.bind(this)}>{Translation.find("previous-events")}</Button>
                        <PreviousEventList visible={this.state.showPreviousEvents}/>
                    </GridItem> 
                    
                </GridContainer>
                <GridContainer justifyContent="center">
                    <GridItem xs={12} sm={10}>
                        {this.createExtraButton("etiq-help", () => {
                            this.setState({ showEtiq: true })
                        })}
                    </GridItem>
                </GridContainer>
                <GridContainer justifyContent="center">
                    {this.eventDeleteBox()}
                    <GridItem xs={12} sm={10}>
                        <Wizard
                            ref={(node: any) => (this.wizard = node)}
                            validate
                            steps={[
                                this.createStep("event-basic-info", Step1),
                                this.createStep("event-type-info", Step2),
                                this.createStep("event-overview-info", Step3)
                            ]}
                            color={"primary"}
                            title={Translation.find(this.isEditPage() ? "edit-event-title" : "create-event-title")}
                            subtitle={Translation.find("create-event-subtitle")}
                            previousButtonText={Translation.find("previous")}
                            nextButtonText={Translation.find("next")}
                            finishButtonText={Translation.find("done")}
                            finishButtonClick={(e: any) => this.submitEvent(e)}
                            nextButtonClick={(e: any) => this.onNextClick(e)}
                        />
                    </GridItem>
                </GridContainer>
                <EtiqPopup isOpen={this.state.showEtiq} onClose={() => {
                    this.setState({ showEtiq: false })
                }} />
            </div>

        );
    }

    showPreviousEvents() {
        this.setState({showPreviousEvents: !this.state.showPreviousEvents});
    }

    createExtraButton(text: string, action: any) {
        return <button onClick={action} style={{ cursor: "pointer" }} color={"danger"}>{Translation.find(text)}</button>
    }

    deleteNotifier() {
        if (!this.state.delete) {
            return null;
        }
        return <ACNotifier
            //@ts-ignore
            titleKey={Translation.find("are-you-sure")}
            contentKey={Translation.find("delete-event")}
            confirmText={Translation.find("delete-yes")}
            cancelText={Translation.find("delete-no")}
            confirmed={async () => {
                const data = { eventId: this.existingEvent().id };
                await Networking.instance.deleteEvent(data);
                this.setState({ delete: false }, () => {
                    this.alertSuccess(Translation.find("event-delete-success-content"), "#/");
                });
            }}
            cancelled={() => {
                this.setState({ delete: false })
            }}
        />
    }

    eventDeleteBox() {
        if (!this.isEditPage()) {
            return null;
        }
        return (
            <GridItem xs={12} sm={12} md={7}>
                <Card>
                    <h4 style={{ marginLeft: "10px", width: "100%", textAlign: "center" }}>
                        {Translation.find("delete-event") + "?"}
                    </h4>
                    <Button style={{ margin: "10px" }} color={"danger"} onClick={() => {
                        this.setState({ delete: true })
                    }}>
                        {Translation.find("delete")}
                    </Button>
                </Card>
            </GridItem>
        );
    }

    onStepEvent(data: any) {
        NewEvent.instance.saveData();
        if (data.field?.id === "date") {
            const selectedMS = NewEvent.instance.getDateFieldValue();
            if (selectedMS < DateUtils.getDaysFromNow(5)) {
                toast(Translation.find("event-too-close-warning"), {
                    position: "top-center",
                    autoClose: 5000,
                    type: "warning",
                    hideProgressBar: true,
                }
                );
            }
        }
        if (data.tab === "additional") {
            this.setState({});
        }
    }

    createStep(id: string, step: any) {
        return {
            id: id,
            name: Translation.find(id),
            component: step,
            callback: this.onStepEvent.bind(this),
            editing: this.isEditPage()
        };
    }
}
