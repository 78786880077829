import React from "react";

import {BaseComponent} from "../BaseComponent";

export class PrivacyPopupContentEN extends BaseComponent {

    render() {
        return (
            <div>
                <p><strong>Privacy Policy for Allicater</strong></p>
                <p><strong>“Allicater”</strong> is
                    <strong>Post Innovations OÜ</strong>, Riia 181a, Tartu, 51004, Estonia. Reg nr 14331746, VAT ID EE102248980. Email address: support@allicater.com</p>
                <p>Post Innovation OÜ (“Allicater”, “we”) process personal data of their users of Allicater’s catering ordering services (“Allicater App”) and the visitors of the website Allicater.com (“Website”).
                    In this Privacy Statement, the word “Allicater Services” refers jointly to the Website and the Allicater App. In this Privacy Statement, the word “User” or “you” refers jointly to our customers, representatives and other authorized users of our customer organizations, potential customers and the users of the Allicater Services.</p>
                <p>Our Privacy Statement explains the types of personal data we process, how we process the personal data and how you may exercise your rights as a data subject.
                    Some of our services might be subject to a separate privacy policy. If a separate privacy policy applies to a particular service, we will post it in connection with the service in question.</p>
                <p>This Privacy Statement may be updated from time to time in order to reflect the changes in data processing practices or otherwise. You can find the current version on the Website. We will not make substantial changes to this Privacy Statement or reduce the rights of the Users under this Privacy Statement without providing a notice thereof.</p>
                <p><strong>Personal Data</strong></p>
                <p>User Data</p>
                <p>User Data is personal data collected directly from you or from our customer organization on behalf of which you are using the Allicater Services (“Customer Organization”), as the case may be. We may collect User Data from our Users and Customer Organizations in a variety of ways, including, after conclusion of a service agreement with the Customer Organization or when Users register to the Allicater Services, subscribe to a newsletter or fill out a form. Further, please note that we also collect details of any transactions and payments you carry out through the Allicater Services.</p>
                <p>The following personal data collected and processed by us is necessary in order for a proper performance of the contract between you and us as well as for our legitimate interest whilst fulfilling our contractual obligations towards our Customer Organizations and for the compliance with our legal obligations.
                    After conclusion of the service agreement between us and the Customer Organization, the Customer Organization provides us with your full name and email address.
                    When you register to the Allicater Services and create a user account, you need to provide us with the following information:</p>
                <ul>
                    <li>Full Name</li>
                    <li>Email Address</li>
                    <li>Telephone Number</li>
                    <li>Address</li>
                </ul>
                <p>Your user or customer experience may be enhanced by providing us with the following information: </p>
                <ul>
                    <li>Location Data</li>
                    <li>Picture</li>
                    <li>other information you provide either when creating a user account or later when modifying your account profile</li>
                </ul>
                <p>We may also process other information provided by you voluntarily such as:
                </p>
                <ul>
                    <li>information you provide when rating our services</li>
                    <li>marketing opt-ins and opt-outs</li>
                    <li>information you provide by phone or in email or chat correspondence with us, including call recordings of your calls with our customer service </li>
                </ul>
                <p>In addition to User Data collected from you and the Customer Organization, we process certain personal data third party service providers provide about you.
                    If you connect or login to your account with Facebook, Facebook shares with us personal information about you such as your profile picture, a sample of your Facebook friends and your Facebook ID.
                </p>
                <p><strong>Log Files</strong></p>
                <p>Allicater follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>
                <p><strong>Cookies and Web Beacons</strong></p>
                <p>Cookies are small text files sent and saved on your device that allows us to identify visitors of the Allicater Services and facilitate the use of the Allicater Services and to create aggregate information of our visitors. This helps us to improve the Allicater Services and better serve our Users. The cookies will not harm your device or files. We use cookies to tailor the Allicater Services and the information we provide in accordance with the individual interests of our Users.  The Users may choose to set their web browser to refuse cookies, or to alert when cookies are being sent. </p>
                <p>Please note that some parts of the Allicater Services may not function properly if use of cookies is refused.</p>
                <p>A web beacon is a technology that allows identifying readers of websites and emails e.g. for identifying whether an email has been read. </p>

                <p><strong>Storage period</strong></p>
                <p>Allicater does not store your personal data longer than is legally permitted and necessary for the purposes of providing the Allicater Services or the relevant parts thereof. The storage period depends on the nature of the information and on the purposes of processing. The maximum period may therefore vary per use. Most personal data relating to a User’s user account with the Allicater Services will be deleted after a period of 90 days have lapsed after the User has deleted its user account with the Allicater Services. Thereafter, a part of the personal data relating to a User’s user account with the Allicater Services may be stored only as long as such processing is required by law or is reasonably necessary for our legal obligations or legitimate interests such as claims handling, bookkeeping, internal reporting and reconciliation purposes. All personal data relating to a User’s user account with the Allicater Services will be deleted within a period of 10 years after the User has deleted its user account with the Allicater Services, with the exception of personal data required in certain rare situations such as legal proceedings. We will store Analytics Data of Users that do not have an account with the Allicater Services for a period of 90 days.
                </p>
                <p><strong>Information Security</strong></p>
                <p>We use administrative, organizational, technical, and physical safeguards to protect the personal data we collect and process. Measures include for example, where appropriate, encryption, pseudonymization, firewalls, secure facilities and access right systems. Our security controls are designed to maintain an appropriate level of data confidentiality, integrity, availability, resilience and ability to restore the data. We regularly test the Allicater Services, systems, and other assets for security vulnerabilities.
                    Should despite of the security measures, a security breach occur that is likely to have negative effects to the privacy of the Users, we will inform the relevant Users and other affected parties, as well as relevant authorities when required by applicable data protection laws, about the breach as soon as possible.</p>
            </div>
        );
    }
}
