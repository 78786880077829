import React from "react";

import {Delete, Edit} from "@mui/icons-material";
import Button from "../../../components/CustomButtons/Button";

import {BaseComponent} from "../../_subviews/BaseComponent";
import MenuItem from "../../../model/MenuItem";

export default class MenuItemView extends BaseComponent {

    constructor(props: any) {
        super(props);
        this.state = {
            error: {
                image: false
            }
        }
    }

    render() {
        const {item} = this.props;
        const image = this.state.error.image ? MenuItem.defaultImage() : MenuItem.avatar(item);
        const isDefault = (image?.includes("no-food-image"));

        return (
            <div key={item.id} style={{display: "flex", borderBottom: "1px solid lightgray", minHeight: "120px"}}>
                <div style={{width: "120px", maxHeight: "120px", overflow: "hidden", display: "block"}}>
                    <img
                        onError={(e) => {
                            this.setState({error: {image: true}})
                        }}
                        src={image}
                        style={{ marginTop: "10%", height: "80%", width: "100%", objectFit: isDefault ? "contain" : "cover"}}
                        alt={"..."}/>
                </div>
                <span style={{marginTop: "1em", marginLeft: "1em"}}>
                    <h5 style={{fontWeight: "bold"}}>{item.name + " (" + (item.price ?? 0) + "€)"}</h5>
                    <small>{item.description}</small>
                </span>
                <div style={{ display: "flex", flexDirection: "column", position: "absolute", right: "5px" }}>
                    <Button justIcon onClick={() => this.props.onEditItemClick(item)} color="success">
                        <Edit/>
                    </Button>
                    <Button justIcon onClick={() => this.props.onDeleteItemClick(item)} color="danger">
                        <Delete/>
                    </Button>
                </div>

            </div>
        );
    }
}
