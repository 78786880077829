
import React, {CSSProperties} from "react";

import EventBasicInfoFields from "../../_subviews/EventBasicInfoFields";

import {Translation} from "../../../core/service/Translation";
import {BaseComponent} from "../../_subviews/BaseComponent";

const style = {
    infoText: {
        margin: "10px 0 30px",
        textAlign: "center"
    } as CSSProperties
};

export default class Step1 extends BaseComponent {

    fields?: any;

    isComplete() {
        return this.fields.validate();
    }

    onChange(e: any) {
        if (this.props.callback) {
            this.props.callback({type: "save", tab: "basic", data: e.target.value});
        }
    }

    render() {
        return (
            <div>
                <h4 style={style.infoText}>{Translation.find("event-basic-info-subheader")}</h4>
                <EventBasicInfoFields
                    ref={(node: any) => (this.fields = node)}
                    disabled={false}
                    callback={this.props.callback}
                />
            </div>
        );
    }
}
