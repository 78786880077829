
export class MenuCategories {

    static list = {
        tx: "categories",
        items: [
            { id: 0, text: "", tx: "group-menus", icon: "group-menu" },
            { id: 1, text: "", tx: "party-menus", icon: "party-menu" },
            { id: 2, text: "", tx: "warm-snacks", icon: "warm-snack" },
            { id: 3, text: "", tx: "cold-snacks", icon: "cold-snack" },
            { id: 4, name: "", tx: "mains-plate", icon: "mains-on-plate" },
            { id: 5, text: "", tx: "mains-tray", icon: "mains-on-tray" },
            { id: 9, text: "", tx: "street-food", icon: "street-food" },
            { id: 6, text: "", tx: "sweets", icon: "sweets" },
            { id: 7, text: "", tx: "drinks", icon: "drink" },
            { id: 8, text: "", tx: "other", icon: "other" }
        ]
    };

    static textById(id: number) {
        const found = this.list.items.find(item => item.id === id);
        if (found) {
            return found.tx;
        }
        return "";
    }

}
