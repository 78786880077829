import React from "react";
import EtiqPageCollapse from "../subviews/EtiqPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class Restaurant extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <EtiqPageCollapse title={"Restaurant or pub"}>
                    <li>Option to use the existing premises of a functioning catering company for the event</li>
                </EtiqPageCollapse>
            );
        }

        return (
            <EtiqPageCollapse title={"Restoran, pubi või muu ruum"}>
                <li>Võimalus ürituseks kasutada olemasolevat toimiva toitlustusettevõtte ruume</li>
            </EtiqPageCollapse>
        );
    }
}
