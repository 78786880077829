import {DateUtils} from "../core/utils/DateUtils";
import Offer from "./Offer";
import User from "./User";

export default class Event {

    public creatorId: number = -1;

    public latitude: number = -1;
    public longitude: number = -1;

    public location: string = "";
    public budget: string = "";
    public attendees: string = "";
    public children: string = "";
    public date: string = "";
    public duration: string = "";

    public multi_category: string = "";

    public multi_event: string = "";
    public multi_service: string = "";

    public multi_drink: string = "";
    public multi_cuisine: string = "";

    public multi_seating: string = "";
    public multi_rentals: string = "";
    public multi_other: string = "";

    public additionalInfo: string = "";
    public cleaningTime: string = "";

    private static isEvaluated(multi_category: any) {
        return multi_category !== null && multi_category !== undefined && multi_category.category !== "";
    }

    private static fitsCatererCategory(event: Event, user: User): boolean {
        const uSplit = user.multi_category.split((","));
        const eSplit = event.multi_category.split(",");

        for (let i = 0; i < uSplit.length; i++) {
            const uCategory = uSplit[i];
            const match = eSplit.find(eCategory => eCategory === uCategory);
            if (match) {
                return true;
            }
        }
        return false;
    }

    public static filterByCategory(events: Event[], user: User) {
        let result: Event[] = [];

        if (user.email !== User.CATERER_VEIN_JA_VINE) {
            // For now: wine-workshop event category is reserved for Vein & Vine
            // So just filter it out all events that contain that category
            events = events.filter(event => {
                if (!event.multi_event) {
                    return event;
                }
                return event.multi_event.split(",").indexOf("9") !== 0;
            });
        }

        if (!this.isEvaluated(user.multi_category)) {
            // If user has not selected any categories, return everything
            result = events
        } else {
            events.forEach((event: Event) => {
                if (!this.isEvaluated(event.multi_category)) {
                    // If event creator hasn't selected any categories, return it for everyone
                    result.push(event);
                } else if (this.fitsCatererCategory(event, user)) {
                    result.push(event);
                }
            });
        }

        return result;
    }

    public static filterUpcoming(events: Event[], immediate?: boolean) {
        return events.filter((event: Event) => DateUtils.isUpcoming(event.date, immediate));
    }

    public static filterOutAccepted(events: Event[]) {
        return events.filter((event: Event) => !Offer.isEventAccepted(event));
    }

    public static mapToPastEvents(events: any[]): any[] {
        const result: number[] = [];
        events.forEach(event => {
            if (DateUtils.isPast(event.date)) {
                result.push(event);
            }
        });
        return result;
    }

    public static mapToUniqueEventIds(events?: any[]): number[] {
        const result: number[] = [];
        events?.forEach(event => {
            if (!result.includes(event.id)) {
                result.push(event.id);
            }
        });
        return result;
    }

    public static mapOffersToEvents(events: any[], offers: any[]) {
        if (!events || !offers) {
            return;
        }
        events.forEach((event: any) => {
            event.offers = offers.filter((offer: any) => offer.eventId === event.id);
        });
    }

    static categorizeByDate(events: any): { active: any[], past: any[]} {
        const active: any[] = [];
        const past: any[] = [];
        events?.forEach((event: any) => {
            
            if (DateUtils.isUpcoming(event.date, true)) {
                active.push(event);
            } else {
                past.push(event);
            }
        });

        return {active: active, past: past};
    }

    static toActiveRequests(events?: any[]) {
        const result: any[] = [];

        events?.forEach((event: any) => {
            if (!Offer.isEventAccepted(event) && !Offer.isAnyMine(event) && !Offer.isMineRejected(event)) {
                result.push(event);
            }
        });
        return result;
    }

    static filterOutRejected(events: any[]) {
        const result: any[] = [];

        events.forEach((event: any) => {
            if (!Offer.isMineRejected(event)) {
                result.push(event);
            }
        });
        return result;
    }
}
