
import React from "react";

import { DateUtils } from "../../core/utils/DateUtils";
import { BaseComponent } from "../_subviews/BaseComponent";

export default class ReleaseNoteComponent extends BaseComponent {

    render() {
        const content = this.props.content;
        return (
            <div>
                <h3>{`v${content.version} (${DateUtils.dateToShortFormString(content.date)})`}</h3>
                {content.bullets.map((bullet: any) => <p>{`• ${bullet}`}</p>)}
            </div>
        );
    }
}