import {BaseComponent} from "../../_subviews/BaseComponent";
import React from "react";

// @ts-ignore
import StarRatings from "react-star-ratings/build";

export default class RatingView extends BaseComponent {

    render() {
        return (
            <div style={{
                display: 'flex',
                flexDirection: "column",
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: "30px"
            }}>
                <h5 style={{marginBottom: "20px"}}>{this.props.heading}</h5>
                <StarRatings
                    rating={this.props.stars}
                    starRatedColor="primary"
                    changeRating={(stars: number) => this.props.onChange(this.props.type, stars)}
                    numberOfStars={5}
                    name='rating'
                />
            </div>
        );
    }
}
