
import PDFMenuItemData from "./PDFMenuItemData";

import { InvoiceType } from "../../model/enum/InvoiceType";
import { InvoicePrice } from "../../model/InvoicePrice";

import { JSONUtils } from "../../utils/JSONUtils";

import Invoice from "../../../model/Invoice";
import MenuItem from "../../../model/MenuItem";
import User from "../../../model/User";

export default class PDFInvoiceData {

    id!: number;
    type!: InvoiceType;
    dueDate!: string;
    releaseDate!: string;

    recipient!: User;
    sender!: User;

    price!: InvoicePrice;
    hasVatNumber!: boolean;

    menuItems!: PDFMenuItemData[];

    prepaid!: InvoicePrice;

    feePercentage!: number;
    feeAmount!: number;

    compactMode: boolean = false;

    public static parse(invoice: any, compactMode: boolean): PDFInvoiceData {

        const parsed = new PDFInvoiceData();
        
        parsed.compactMode = compactMode;
        
        parsed.id = invoice.id;
        parsed.type = invoice.type;
        parsed.dueDate = invoice.dueDate;
        parsed.releaseDate = Invoice.releaseDate(invoice);

        parsed.menuItems = JSONUtils.safeParse(invoice.menuItemJson);

        const users = JSONUtils.safeParse(invoice.userJson);
        invoice.caterer = users.caterer;
        invoice.client = users.client;

        parsed.recipient = users.client;
        parsed.sender = users.caterer;
        if (invoice.type === InvoiceType.Prepayment || invoice.type === InvoiceType.Full) {
            parsed.sender = User.allicater();
        }
        
        parsed.hasVatNumber = users.caterer && users.caterer.vatNumber;
        parsed.price = MenuItem.calculatePrice(parsed.menuItems, parsed.hasVatNumber, invoice.type === InvoiceType.Prepayment);
        
        parsed.prepaid = InvoicePrice.fromPrepaidAmount(invoice.prepaidAmount, parsed.hasVatNumber);
        if (parsed.type === InvoiceType.Full) {
            parsed.price.subtractPrepayment(parsed.prepaid.total, parsed.hasVatNumber);
        }

        if (parsed.type === InvoiceType.Allicater) {
            parsed.feePercentage = Invoice.findFeePercentage(invoice);
            parsed.feeAmount = parsed.price.total * (parsed.feePercentage / 100);
            parsed.price.subtractPrepayment(parsed.feeAmount, true);
        }

        parsed.price.round();
        
        return parsed;
    }
}