import React from "react";

import GridContainer from "../../../components/Grid/GridContainer";
import Button from "../../../components/CustomButtons/Button";

import {BaseComponent} from "../../_subviews/BaseComponent";
import {Translation} from "../../../core/service/Translation";

export default class InvoiceActions extends BaseComponent {

    constructor(props: any) {
        super(props);
        this.state = {
            compactView: false
        }
    }

    onPdfClick() {
        this.props.printPdf();
    }

    onViewModeClick() {
        this.setState({ compactView: !this.state.compactView}, () => {
            this.props.updateViewMode(this.state.compactView);
        });
    }

    render() {
        return (
            <GridContainer justifyContent="center">
                <Button disabled={this.props.disableEmailButton} onClick={() => this.props.emailPdf()} color="warning">
                    {Translation.find("email-pdf")}
                </Button>
                <Button onClick={this.onViewModeClick.bind(this)} color="success">
                    {Translation.find(this.state.compactView ? "detailed-invoice" : "compact-invoice")}
                </Button>
            </GridContainer>
        );
    }
}
