
import React from "react";
import cx from "classnames";

import {BaseComponent} from "../../views/_subviews/BaseComponent";

import withStyles from "@mui/styles/withStyles";
import sidebarStyle from "../../assets/jss/material-dashboard-pro-react/components/sidebarStyle";

class SidebarHeader extends BaseComponent {

    render() {
        const {classes, logo, logoText, bgColor} = this.props;
        const logoNormal = classes.logoNormal + " " + cx({
            [classes.logoNormalSidebarMini]: this.props.miniActive
        });
        const logoMini = classes.logoMini;
        const logoClasses = classes.logo + " " + cx({[classes.whiteAfter]: bgColor === "white"});

        return (
            <div className={logoClasses}>
                <a href="/" className={logoMini}><img src={logo} alt="logo" className={classes.img}/></a>
                <a href="/" className={logoNormal} style={{
                    textDecoration: 'none',
                    fontSize: '13px',
                    fontWeight: '600',
                    color: '#37b48f'
                }}>{logoText}</a>
            </div>
        );
    }
}

export default withStyles(sidebarStyle)(SidebarHeader);
