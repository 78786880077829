
import React from "react";

import GridContainer from "../../components/Grid/GridContainer";

import DashboardTable from "./table/DashboardTable";
import EventDetailPopup from "../_subviews/EventDetailPopup";
import {BaseComponent} from "../_subviews/BaseComponent";

import {ViewUtils} from "../../utils/ViewUtils";

import {CurrentUser} from "../../services/dto/CurrentUser";
import LocalData from "../../services/state/LocalData";
import {Translation} from "../../core/service/Translation";

export default class DashboardPage extends BaseComponent {

    constructor(props: any) {
        super(props);
        this.state = {
            events: {
                active: undefined,
                past: undefined,
                requests: undefined
            },
            offers: {
                mine: undefined
            },
            reviews: {
                pending: undefined
            },
            invoices: {
                unpaid: undefined
            }
        }
    }

    componentDidMount = async () => {
        if (CurrentUser.instance.exists()) {
            this.loadData();
        } else {
            CurrentUser.instance.loaded = () => {
                this.loadData();
            }
        }
    };

    async loadData() {
        const data = await LocalData.dashboard.load();
        this.setState(data);
    };

    rowClicked(id: any, type: string) {

        if (type === "invoice") {
            const invoice = this.state.invoices?.unpaid?.find((invoice: any) => invoice.id === id);
            if (invoice) {
                this.props.viewInvoice(invoice);
            }
            return;
        }

        if (type === "offer") {
            const offer = this.state.offers?.mine?.find((offer: any) => offer.id === id);
            if (offer) {
                this.props.viewOffer(offer.event, offer);
            }
            return;
        }

        if (type === "review") {
            const rating = this.state.reviews.pending.find((rating: any) => rating.id === id);
            if (rating) {
                console.log("rating", rating);
                this.props.giveReview(rating);
            }
            return;
        }
        let event = this.state.events?.active?.find((event: any) => event.id === id);
        if (!event) {
            event = this.state.events?.past?.find((event: any) => event.id === id);
        }
        if (!event) {
            event = this.state.events?.requests?.find((event: any) => event.id === id);
        }
        if (!event) {
            event = this.state.events?.offers?.find((event: any) => event.id === id);
        }
        if (event) {
            this.setState({selectedEvent: event});
        }
    }

    render() {
        return (
            <div>
                <EventDetailPopup
                    event={this.state.selectedEvent}
                    editClick={(event: any) => {
                        this.props.editEvent(event);
                    }}
                    viewOfferClick={(event: any, offer: any) => {
                        this.props.viewOffer(event, offer);
                    }}
                    makeOfferClick={(event: any) => {
                        this.props.makeOffer(event);
                    }}
                    onClose={() => {
                        this.setState({selectedEvent: undefined})
                    }}
                />
                {ViewUtils.createCardTemplate("dashboard", Translation.find("dashboard"))}
                {this.renderContent()}
            </div>
        );
    }

    renderContent() {
        return (
            <div>
                <GridContainer>
                    {this.createTable("requests", this.state.events.requests, "event", "party")}
                    {this.createTable("my-offers", this.state.offers.mine, "offer", "my-offers")}
                    {this.createTable("active-events", this.state.events.active, "event")}
                    {this.createTable("invoices", this.state.invoices.unpaid, "invoice", "invoice")}
                    {this.createTable("past-gigs", this.state.events.past, "event", "past-gigs")}
                    {this.createTable("review-pending", this.state.reviews.pending, "review", "reviews")}
                </GridContainer>
            </div>
        );
    }

    createTable(title: string, data: any[], type: string, icon?: string) {
        if (!data) {
            return;
        }
        return <DashboardTable
            icon={icon}
            type={type}
            title={Translation.find(title)}
            data={data}
            onRowClick={this.rowClicked.bind(this)}
        />
    }
}
