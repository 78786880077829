import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "../../../components/CustomButtons/Button";
import {BaseComponent} from "../BaseComponent";
import {Translation} from "../../../core/service/Translation";

export default class BasePopup extends BaseComponent {

    render() {
        return (
            <div>
                <Dialog
                    open={!!this.props.isOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        {this.props.children}
                    </DialogContent>
                    <DialogActions>
                        {this.props.extraAction && this.extraButton(this.props.extraAction)}
                        <Button onClick={this.props.onClose} color="success">{Translation.find("close")}</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    extraButton(options: any) {
        return <Button onClick={options.onClick} color={options.color}>{options.text}</Button>
    }
}
