
import React from "react";

import {ViewUtils} from "../../utils/ViewUtils";
import ReactTable from "../../components/ReactTable/ReactTable";
import {DateUtils} from "../../core/utils/DateUtils";
import EventDetailPopup from "../_subviews/EventDetailPopup";
import LocalData from "../../services/state/LocalData";
import {Translation} from "../../core/service/Translation";
import {BaseComponent} from "../_subviews/BaseComponent";

export default class OfferHistoryPage extends BaseComponent {

    constructor(props: any) {
        super(props);
        this.state = {
            offers: []
        };
    }

    componentDidMount = async () => {
        const data = await LocalData.offerHistory.load((state: any) => {
            this.setState(state);
        });
        this.setState(data);
    };

    parseData() {
        const result: any[] = [];

        this.state.offers.forEach((offer: any) => {
            const parsed: any = {};
            const offerPrice = LocalData.offerHistory.findPriceForOfferedItems(offer.menuItems);

            parsed.id = offer.id;
            parsed.budget = offer.event?.budget ? (offer.event.budget + "€") : "-";
            parsed.offerPrice = (offerPrice === -1) ? "..." : offerPrice + "€";
            parsed.status = ViewUtils.statusForOffer(offer);
            parsed.humanReadableDate = DateUtils.msToLongDateString(offer.createDate);
            parsed.rating = offer?.event?.creator ? offer.event.creator.name : "-";
            parsed.location = offer.event?.location ? offer.event.location : "-";

            const action = () => this.setState({selectedEvent: offer.event});
            parsed.action = ViewUtils.listActionButton(Translation.find("view-details"), action);

            result.push(parsed);
        });
        return result;
    }

    render() {
        return ViewUtils.createCardTemplate(
            "offer-history",
            Translation.find("offer-history"),
            this.createTable()
        );
    }

    createColumnHeader(header: string, accessor: string) {
        return { Header: header, accessor: accessor };
    }

    createTable() {
        return (
            <div>
                <EventDetailPopup
                    event={this.state.selectedEvent}
                    viewOfferClick={this.props.viewOffer}
                    onClose={() => {
                        this.setState({selectedEvent: undefined})
                    }}/>
                <ReactTable columns={this.columns()} data={this.parseData()}/>
            </div>
        );
    }

    columns() {
        const result = [];
        if (!ViewUtils.isMobile()) {
            result.push(this.createColumnHeader("#", "id"));
            //result.push(this.createColumnHeader(Translation.find("review"), "name"));
        }

        result.push(this.createColumnHeader(Translation.find("offer-date"), "humanReadableDate"));

        if (!ViewUtils.isMobile()) {
            result.push(this.createColumnHeader(Translation.find("budget"), "budget"));
            result.push(this.createColumnHeader(Translation.find("offer"), "offerPrice"));
            result.push(this.createColumnHeader(Translation.find("location"), "location"));
        }

        result.push(this.createColumnHeader(Translation.find("status"), "status"));
        result.push(this.createColumnHeader(Translation.find("action"), "action"));

        return result;
    }
}
