import {CurrentUser} from "../../dto/CurrentUser";
import {Networking} from "../../Networking";
import Event from "../../../model/Event";
import Offer from "../../../model/Offer";
import InvoiceData from "./InvoiceData";
import Rating from "../../../model/Rating";

export default class DashboardData {

    data?: {
        events: {
            active: any[],
            past: any[],
            requests?: any[]
        },
        offers: {
            mine?: any[]
        },
        invoices: {
            unpaid?: any[],
            paid?: any[]
        },
        reviews: {
            pending?: any[]
        }
    };

    async load(callback?: any): Promise<any> {
        // if (this.data) {
        //     return this.data;
        // }

        const id = CurrentUser.instance.getId();
        const data = CurrentUser.instance.isCaterer() ? {catererId: id} : {};
        const events = (await Networking.instance.fetchEvents(data)).events;

        const sorted = Event.categorizeByDate(events);

        let requests: { active?: any[]; past?: any[] } = {};
        let offers: { mine?: any[] } = {};

        if (CurrentUser.instance.isCaterer()) {
            requests = (await Networking.instance.fetchEvents()).events;
            requests = Event.categorizeByDate(requests);
            const eventIds = Event.mapToUniqueEventIds(requests.active);
            const offers = (await Networking.instance.fetchOffers({eventIds: eventIds})).offers;
            requests.active?.forEach(event => {
                event.offers = offers.filter((offer: any) => offer.eventId === event.id);
            });
            requests.active = Event.toActiveRequests(requests.active);
            requests.active = Event.filterByCategory(requests.active, CurrentUser.instance.get());
        }

        const offerResponse = await Networking.instance.fetchOffers({"eventIds": Event.mapToUniqueEventIds(events)});

        if (CurrentUser.instance.isCaterer()) {
            Event.mapOffersToEvents(events, offerResponse.offers);
        } else {
            offerResponse.offers?.forEach((offer: any) => {
                offer.event = events.find((event: any) => event.id === offer.eventId);
            });
            offers.mine = Offer.toUpcoming(offerResponse.offers);
        }

        const invoiceData  = await InvoiceData.fetch();

        const ratings = (await Networking.instance.fetchRatings({pending: true})).ratings;

        let recipientIds = ratings.map((rating: Rating) => rating.recipientId);
        recipientIds = recipientIds.filter((id: any) => id !== "" && id !== undefined && id !== null);

        if (recipientIds && recipientIds.length > 0) {
            const users = (await Networking.instance.fetchUsers({userIds: recipientIds})).users;
            ratings?.forEach((rating: any) => {
                rating.recipient = users.find((user: any) => rating.recipientId === user.id);
            });

            const ratingOffers = (await Networking.instance.fetchOffers({offerIds: Rating.toOfferIds(ratings)})).offers;
            const ratingEvents = (await Networking.instance.fetchEvents({eventIds: Offer.toEventIds(ratingOffers)})).events;
            ratingOffers?.forEach((offer: any) => {
                offer.event = ratingEvents?.find((event: any) => event.id === offer.eventId);
            });
            ratings?.forEach((rating: any) => {
               rating.offer = ratingOffers?.find((offer: any) => offer.id === rating.offerId);
            });

        }

        const parsed = InvoiceData.splitAndUnpack(invoiceData);
        const invoices = InvoiceData.filterOutFuture(parsed.unpaid);

        this.data = {
            events: {
                active: Event.filterOutRejected(sorted.active),
                past: Event.filterOutRejected(sorted.past),
                requests: requests.active
            },
            offers: offers,
            invoices: {
                unpaid: invoices
            },
            reviews: {
                pending: Rating.filterPending(ratings, CurrentUser.instance.isCaterer())
            }
        };

        return this.data;
    }

    async findOffersBasedOnEvents(events: any, mine: boolean) {
        const eventIds = Event.mapToUniqueEventIds(events);
        return (await Networking.instance.fetchOffers({eventIds: eventIds, mine: mine})).offers;
    }

}
