import React from "react";

import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";

import {BaseComponent} from "../_subviews/BaseComponent";
import MenuItemBox from "./subviews/MenuItemBox";
import Message from "../../model/Message";
import OfferBox from "./subviews/OfferBox";
import EventInfoBox from "./subviews/EventInfoBox";
import RatingBox from "./subviews/RatingBox";
import ChatWindow from "./subviews/ChatWindow";

import {Routing} from "../../services/Routing";
import {OfferParams} from "../../services/state/sub/OfferData";
import LocalData from "../../services/state/LocalData";
import {Networking} from "../../services/Networking";
import {Translation} from "../../core/service/Translation";
import {CurrentUser} from "../../services/dto/CurrentUser";
import OfferRejectPopup from "./subviews/OfferRejectPopup";
import Offer from "../../model/Offer";
import { DateUtils } from "../../core/utils/DateUtils";

export default class OfferPage extends BaseComponent {

    eventId?: number;
    offerId?: number;

    event?: any;

    constructor(props: any) {
        super(props);
        this.eventId = Routing.instance.getUrlNumberParameter("eventId");
        this.offerId = Routing.instance.getUrlNumberParameter("offerId");
        this.state = {
            category: 0,
            offeredItems: [],
            isAcceptPage: false,
            messages: [],
            reject: false
        };
    }

    componentDidMount = async () => {
        if (CurrentUser.instance.exists()) {
            await this.loadData();
        } else {
            CurrentUser.instance.loaded = async() => {
                await this.loadData();
            }
        }
    };

    async loadData() {

        // If offerId exists, it's an offer-accept page. If not, it's an offer-make page
        this.setState({ isAcceptPage: !CurrentUser.instance.isCaterer() });

        const params = new OfferParams(this.eventId!, this.offerId!, !CurrentUser.instance.isCaterer());
        await LocalData.offer.load(params, (state: any) => {
            this.event = LocalData.offer.event;
            this.setState(state);
        });
    };

    onItemAdded(item: any) {
        const items = this.state.offeredItems;
        const found = items.find((existing: any) => existing.id === item.id);

        if (found) {
            found.count++;
        } else {
            item.count = 1;
            items.push(item);
        }
        this.setState({ offeredItems: items });
    }

    onItemRemoved(item: any) {
        const items = this.state.offeredItems;
        const found = items.find((existing: any) => existing.id === item.id);

        if (found) {
            found.count--;
            if (found.count === 0) {
                items.splice(items.indexOf(found), 1);
            }
        }
        this.setState({ offeredItems: items });
    }

    handleItemCountChange(item: any, count: number) {
        const items = this.state.offeredItems;
        const found = items.find((existing: any) => existing.id === item.id);
        found.count = count;
        this.setState({ offeredItems: items }, () => {
            const element = document.getElementById(item.id.toString());
            if (element) {
                element.focus();
            }
        });
    }

    async handlePositiveButtonClick() {
        const data: any = {eventId: this.event.id, client: this.event.creatorId};
        data.menuItems = this.parseMenuItems();

        if (this.state.isAcceptPage) {
            data.offerId = this.state.offer.id;
            data.catererId = this.state.offer.catererId;
            data.eventDate = this.event.date;
            await Networking.instance.acceptOffer(data);
            this.alert(Translation.find("offer-accept-success-content"));
        } else if (this.state.offer) {
            data.offerId = this.state.offer.id;
            data.message = this.translate("caterer-made-change");
            await Networking.instance.updateOffer(data);
            this.alert(Translation.find("offer-update-success-content"));
        } else {
            await Networking.instance.makeOffer(data);
            this.alert(Translation.find("offer-make-success-content"));
        }
    }

    async handleNegativeButtonClick() {
        this.setState({reject: true});
    }

    alert(message: string) {
        this.props.alert(Translation.find("success-header"), message, "#/");
    }

    async onRejectConfirmed() {
        const data = {offerId : this.state.offer.id, catererId: this.state.offer.catererId,};
        await Networking.instance.rejectOffer(data);
        this.setState({reject: false}, () => {
            this.alert(Translation.find("offer-reject-success-content"));
        });
    }

    canEdit(): boolean {
        
        if (!this.event) {
            return false;
        }
        if (Offer.isRejected(this.state.offer)) {
            return false;
        }
        if (!Offer.isAccepted(this.state.offer) && this.state.isAcceptPage) {
            return true;
        }
        
        const date = parseInt(this.event.date);
        if (date <= DateUtils.get3DaysFromNow()) {
            return false;
        }
        
        return true;
    }

    render() {
        return <div>
            <OfferRejectPopup
                visible={this.state.reject}
                confirmed={this.onRejectConfirmed.bind(this)}
                cancelled={() => {
                    this.setState({reject: false})
                }}
            />
            <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                    <RatingBox ratings={this.state.ratings}/>
                </GridItem>
                <GridItem xs={12} sm={12} md={9}>
                    <EventInfoBox event={this.event}/>
                </GridItem>
            </GridContainer>

            <GridContainer>
                <GridItem xs={12} sm={12} md={5}>
                    <MenuItemBox
                        event={this.event}
                        isAcceptPage={this.state.isAcceptPage}
                        offer={this.state.offer}
                        canEdit={this.canEdit()}
                        onItemAdded={this.onItemAdded.bind(this)}
                        onItemRemoved={this.onItemRemoved.bind(this)}/>
                </GridItem>
                <GridItem xs={12} sm={12} md={7}>
                    <OfferBox
                        //@ts-ignore
                        event={this.event}
                        offeredItems={this.state.offeredItems}
                        offer={this.state.offer}
                        isAcceptPage={this.state.isAcceptPage}
                        canEdit={this.canEdit()}
                        countChanged={this.handleItemCountChange.bind(this)}
                        positiveClick={this.handlePositiveButtonClick.bind(this)}
                        negativeClick={this.handleNegativeButtonClick.bind(this)}/>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <ChatWindow
                        offer={this.state.offer}
                        messages={this.state.messages}
                        onMessageSend={this.onMessageSend.bind(this)}
                    />
                </GridItem>
            </GridContainer>
        </div>;
    }

    async onMessageSend(e: any) {
        const data = {
            text:  e,
            catererId: this.state.offer?.catererId,
            offerId: this.offerId,
            eventId: this.eventId,
            isSentByCustomer: true,
            timestamp: new Date().getTime(),
        };

        await Networking.instance.sendMessage(data);
        const user = CurrentUser.instance.get();
        this.setState({
            messages: this.state.messages.concat(Message.create({text: e, isSentByCustomer: true}, user))
        });
    }

    parseMenuItems() {
        return this.state.offeredItems.map((item: any) => {
            return { id: item.id, count: item.count, data: item }
        });
    }
}
