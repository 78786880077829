import {DateUtils} from "../core/utils/DateUtils";

export default class Rating {

    public score: number = -1;

    public cleanliness: number = -1;
    public service: number = -1;
    public food: number = -1;

    public recipientId: number = -1;

    public raterId: number = -1;

    public offerId: number = -1;

    public comment: string = "";


    static calculateScore(ratings: any[]): any {
        if (!ratings) {
            return " - ";
        }
        if (ratings.length === 0) {
            return " – ";
        }
        let total = 0;
        ratings.forEach(rating => {
            total += rating.score;
        });
        return (total / ratings.length).toFixed(1);
    }

    static filterPending(ratings: any, caterer: boolean): any[] {
        const result: any[] = [];

        ratings.forEach((rating: any) => {
           if (!DateUtils.isUpcoming(rating.offer?.event?.date, true)) {

               // TODO: as is, only legacy "score" -1 ratings are requested.
               //  if user is not caterer, filter out caterer scores
               if (caterer) {
                   result.push(rating);
               } else {
                   if (rating.cleanliness === -1 || rating.cleanliness === null) {
                       result.push(rating);
                   }
               }
           }
        });
        return result;
    }

    static toOfferIds(ratings: Rating[]) {
        return ratings.map((rating: Rating) => rating.offerId);
    }

    static filterComplete(response: any) {
        const all = response.ratings;
        const filtered: any[] = [];

        all.forEach((rating: Rating) => {
            if (rating.score !== -1 || rating.cleanliness !== -1 || rating.service !== -1 || rating.food !== -1) {
                filtered.push(rating);
            }
        });
        return filtered;
    }

    static KEYS = ["cleanliness", "service", "food"];
    static isValid(rating: any): boolean {
        for (let i = 0; i < this.KEYS.length; i++) {
            const sub = rating[this.KEYS[i]];
            if (sub === null || sub === -1) {
                return false;
            }
        }
        return true;
    }
}
