
import React from "react";
import HelpPageCollapse from "../subviews/HelpPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class Registration extends BaseComponent {

    render() {
        if (this.props.en) {
            return (
                <HelpPageCollapse title={"Create Account"}>
                    <li>Choose<strong>Get Started</strong> from main page
                        <ol>
                            <li> Enter your name, email, and password in the window that opens</li>
                            <li> If you're a caterer, select "I'm a Caterer" and enter your business registry code</li>
                            <li> Please read and agree to the Terms of Service</li>
                        </ol>
                    </li>
                    <li> Allicater will send an account verification link to the email address you entered.
                        Go to your inbox and click the link to activate your account.
                        You will be automatically logged in when you click the link.
                    </li>
                    <li> You can log out by selecting Log Off from the profile icon in the upper right corner.
                        If you are logged out and want to log in again but can't remember your password again,
                        you can reset it by clicking the Forgot your password? Link
                    </li>
                </HelpPageCollapse>
            );
        }

        return (
            <HelpPageCollapse title={"Konto Loomine"}>
                <li>Vajutage avalehel <strong>Liitu</strong>
                    <ol>
                        <li>Sisestage avanenud aknas oma nimi, e-post ja salasõna</li>
                        <li>Kui olete caterer, siis valige lisaks "Olen Caterer" ja sisestage ettevõtte registrikood
                        </li>
                        <li>Lugege läbi kasutajatingimused ja nendega nõustudes tehke vastav märge</li>
                    </ol>
                </li>
                <li>Allicater saadab sisestatud e-posti aadressile konto verifitseerimise lingi.
                    Mine oma postkasti ja vajuta lingil, et konto aktiveerida.
                    Teid logitakse lingile vajutadaes automaatselt keskkonda sisse.
                </li>
                <li>Süsteemist saab välja logida paremal üleval nurgas olevast profiili ikoonist valides Logi Väljas.
                    Kui olete süsteemist välja loginud ja soovite uuesti siseneda, aga ei mäleta enam salasõna,
                    saab selle uuesti seadistada vajutades linki Unustasid Salasõna?
                </li>
            </HelpPageCollapse>
        );
    }
}
