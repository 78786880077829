
import React from "react";
import HelpPageCollapse from "../subviews/HelpPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class ManageMenu extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <HelpPageCollapse title={"Menu"}>
                    <li> From the menu, the caterer can enter the food, drinks, and other products it offers </li>
                    <li> There are different product categories on the left and products entered in the category on the right </li>
                    <li> Products can be edited and deleted in the menu.
                        It's important to note here that if you have an active bid and change
                        the price of your products in the menu, etc., it will also change in the bid. </li>
                    <li> Add a product:
                        <ol>
                            <li> Choose which category the product belongs to </li>
                            <li> Enter a product name </li>
                            <li> Quantity </li>
                            <li> Which unit is the product being measured (for example: pcs, g, kg, ml, l, etc.) </li>
                            <li> Product Description </li>
                            <li> Add an image and more information </li>
                        </ol>
                    </li>
                </HelpPageCollapse>
            );
        }

        return (
            <HelpPageCollapse title={"Menüü haldamine"}>
                <li>Menüü alt saab caterer sisestada oma pakutavaid toite, jooke ja muid tooteid</li>
                <li>Vasakul on erinevad toote kategooriad ja paremal kategooriasse sisestatud tooted</li>
                <li>Tooteid saab menüüs muuta ja kustutada. Siin on oluline tähele panna,
                    et kui teil on tehtud aktiivne pakkumine ja muudate menüüs oma toodete hinda vms,
                    siis muutub see ka pakkumises.</li>
                <li>Toote lisamine:
                    <ol>
                        <li>Valige kuhu kategooriasse toode kuulub</li>
                        <li>Sisestage toote nimi</li>
                        <li>Kogus</li>
                        <li>Millise ühikuga toodet mõõdetakse (näiteks: tk, g, kg, ml, l vms)</li>
                        <li>Toote kirjeldus</li>
                        <li>Lisada pilt ja lisainfo</li>
                    </ol>
                </li>
            </HelpPageCollapse>
        );
    }
}
