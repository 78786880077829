import React from "react";
import EtiqPageCollapse from "../subviews/EtiqPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class Standing extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <EtiqPageCollapse title={"Standing Event"}>
                    <li>Food is served either on the bowls or on separate tables. It is important that the food is either a snack or only small pieces of food that can only be eaten with a fork (or fingers)</li>
                </EtiqPageCollapse>
            );
        }

        return (
            <EtiqPageCollapse title={"Püstijala üritus"}>
                <li>Toite pakutakse kas vaagnatel või asuvad need eraldi laudadel, kuid oluline on, et toitude puhul on tegemist kas suupistete või ainult sellised väikesed toidupalad, mida saab ainult kahvliga süüa</li>
            </EtiqPageCollapse>
        );
    }
}