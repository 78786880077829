import DashboardData from "./sub/DashboardData";
import RequestsData from "./sub/RequestsData";
import MenuData from "./sub/MenuData";
import OfferData from "./sub/OfferData";
import EventHistoryData from "./sub/EventHistoryData";
import OfferHistoryData from "./sub/OfferHistoryData";
import InvoiceData from "./sub/InvoiceData";
import OfferListData from "./sub/OfferListData";
import AdminData from "./sub/AdminData";

export default class LocalData {

    static dashboard: DashboardData = new DashboardData();

    static menu: MenuData = new MenuData();

    static requests: RequestsData = new RequestsData();

    static offer: OfferData = new OfferData();

    static eventHistory: EventHistoryData = new EventHistoryData();

    static offerHistory: OfferHistoryData = new OfferHistoryData();

    static offerList: OfferListData = new OfferListData();

    static invoices: InvoiceData = new InvoiceData();

    static admin: AdminData = new AdminData();

    static clear() {
        this.dashboard = new DashboardData();
        this.menu = new MenuData();
        this.requests = new RequestsData();
        this.offer = new OfferData();
        this.eventHistory = new EventHistoryData();
        this.offerHistory = new OfferHistoryData();
        this.offerList = new OfferListData();
        this.invoices = new InvoiceData();
        this.admin = new AdminData();
    }
}
