
import React from "react";

import CardHeader from "../../../components/Card/CardHeader";
import CardIcon from "../../../components/Card/CardIcon";
import CardBody from "../../../components/Card/CardBody";
import Card from "../../../components/Card/Card";
import Button from "../../../components/CustomButtons/Button";

import MenuItemView from "./MenuItemView";
import {BaseComponent} from "../../_subviews/BaseComponent";
import {Styles} from "../../../styles/Styles";

import {ViewUtils} from "../../../utils/ViewUtils";
import LocalData from "../../../services/state/LocalData";

import {Translation} from "../../../core/service/Translation";

export default class MenuItemContainer extends BaseComponent {

    render() {
        return (
            <Card>
                <CardHeader color="info" icon>
                    <CardIcon color="info">
                    {ViewUtils.createIcon(this.props.activeCategory.icon)}
                    </CardIcon>
                    <h4 style={Styles.cardIconTitle}>
                        {Translation.find(this.props.activeCategory.tx)}
                        <Button style={{float: "right"}} size="sm" color={"primary"} onClick={this.props.onAddClick}>
                            {Translation.find("add-menu-item")}
                        </Button>
                    </h4>
                </CardHeader>
                <CardBody>
                    {LocalData.menu.findMenuItemsFor(this.props.activeCategory.id).map((item, index) => {
                        return <MenuItemView
                            key={index}
                            item={item}
                            onEditItemClick={this.props.onEditItemClick}
                            onDeleteItemClick={this.props.onDeleteItemClick}
                        />
                    })}
                </CardBody>
            </Card>
        );
    }
}
