
export default class Banking {

    static readonly POST_INNOVATIONS_MAIN_SWED = "EE482200221067688794";

    static ACCOUNTS: any[] = [
        {name: "Coop",     iban: "EE824204278617994405"},
        {name: "LHV",      iban: "EE597700771004777613"},
        {name: "Swedbank", iban: Banking.POST_INNOVATIONS_MAIN_SWED},
        // {name: "SEB",      iban: "EE241010220290397225"}
    ];

    static BANK_CODES: { [code:string]: string } = {
        "42": "Coop",
        "16": "Eesti Pank",
        "10": "SEB",
        "22": "Swedbank",
        "96": "Luminor",
        "17": "Luminor",
        "12": "Citadele",
        "83": "Svenska Handelsbanken",
        "00": "TBB",
        "51": "OB Corporate Bank",
        "77": "LHV",
        "75": "Bigbank"
    }

}
