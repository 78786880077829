import React from "react";
import EtiqPageCollapse from "../subviews/EtiqPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class Buffee extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <EtiqPageCollapse title={"Buffet"}>
                    <li>Rich dining experience with a variety of meals served by the guests themselves</li>
                </EtiqPageCollapse>
            );
        }

        return (
            <EtiqPageCollapse title={"Buffe"}>
                <li>Rikkalik toidulaud erinevate söökidega, mida serveerivad külalised endale ise</li>
            </EtiqPageCollapse>
        );
    }
}
