
import React from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import cx from "classnames";

import {BaseComponent} from "../../views/_subviews/BaseComponent";
import {CurrentUser} from "../../services/dto/CurrentUser";
import {Styles} from "../../styles/Styles";

import withStyles from "@mui/styles/withStyles";
import sidebarStyle from "../../assets/jss/material-dashboard-pro-react/components/sidebarStyle";

import {Translation} from "../../core/service/Translation";

class SidebarUserTab extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            openAvatar: false,
            miniActive: true,
            ...this.getCollapseStates(props.routes)
        };
    }

    // this creates the intial state of this component based on the collapse routes
    // that it gets through this.props.routes
    getCollapseStates = routes => {
        let initialState = {};
        // eslint-disable-next-line no-unused-expressions
        routes?.map(prop => {
            if (prop.collapse) {
                initialState = {
                    [prop.state]: this.getCollapseInitialState(prop.views),
                    ...this.getCollapseStates(prop.views),
                    ...initialState
                };
            }
            return null;
        });
        return initialState;
    };

    // this verifies if any of the collapses should be default opened on a rerender of this component
    // for example, on the refresh of the page,
    // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
    getCollapseInitialState(routes) {
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
                return true;
            } else if (window.location.href.indexOf(routes[i].path) !== -1) {
                return true;
            }
        }
        return false;
    }

    openCollapse(collapse) {
        const st = {};
        st[collapse] = !this.state[collapse];
        this.setState(st);
    }

    render() {
        const { classes, bgColor} = this.props;
        const itemText = classes.itemText + " " + cx({
            [classes.itemTextMini]: this.props.miniActive && this.state.miniActive
        });
        const userWrapperClass = classes.user + " " + cx({ [classes.whiteAfter]: bgColor === "white" });
        return (
            <div className={userWrapperClass}>
                <div className={classes.photo}>
                    <img src={CurrentUser.instance.avatarUrl()} className={classes.avatarImg} style={Styles.avatar} alt="..."/>
                </div>
                <List className={classes.list}>
                    <ListItem className={classes.item + " " + classes.userItem}>
                        <div
                            style={{cursor: "pointer"}}
                            className={classes.itemLink + " " + classes.userCollapseButton}
                            onClick={() => this.openCollapse("openAvatar")}
                        >
                            <ListItemText
                                primary={CurrentUser.instance.displayName()}
                                secondary={
                                    <b
                                        className={classes.caret + " " + classes.userCaret + " " +
                                        (this.state.openAvatar ? classes.caretActive : "")
                                        }
                                    />
                                }
                                disableTypography={true}
                                className={itemText + " " + classes.userItemText}
                            />
                        </div>
                        <Collapse in={this.state.openAvatar} unmountOnExit>
                            <List className={classes.list + " " + classes.collapseList}>
                                {this.createProfileCollapseTab("profile")}
                                {this.createProfileCollapseTab("settings")}
                                {this.createProfileCollapseTab("log-out", false)}
                            </List>
                        </Collapse>
                    </ListItem>
                </List>
            </div>
        );
    }

    createProfileCollapseTab(text, isLink = true) {
        const classes = this.props.classes;
        const collapseItemText = classes.collapseItemText + " " + cx({
            [classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive
        });
        const translated = Translation.find(text);

        return (
            <ListItem className={classes.collapseItem}>
                <div
                    style={{cursor: "pointer"}}
                    className={classes.itemLink + " " + classes.userCollapseLinks}
                    onClick={e => {
                        if (isLink) {
                            this.props.onRedirect(text.toLowerCase());
                        } else {
                            e.preventDefault();
                            this.props.onClick(text.toLowerCase());
                        }
                    }}>
                    <span className={classes.collapseItemMini}>{translated[0].toUpperCase()}</span>
                    <ListItemText primary={translated} disableTypography={true} className={collapseItemText}/>
                </div>
            </ListItem>
        );
    }
}

export default withStyles(sidebarStyle)(SidebarUserTab);
