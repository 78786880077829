import React from "react";

import GridItem from "../../components/Grid/GridItem";
import {DateUtils} from "../../core/utils/DateUtils";
import {ViewUtils} from "../../utils/ViewUtils";
import {CateringEnums} from "../../model/constants/CateringEnums";
import {BaseComponent} from "./BaseComponent";
import {Translation} from "../../core/service/Translation";

export default class EventDetailContainer extends BaseComponent {

    render() {
        if (!this.props.event) {
            return null;
        }
        const event = this.props.event;
        const time = DateUtils.msToLongDateString(event.date) + " " + DateUtils.msToHoursMinutes(event.date);
        return (
            <div>
                <GridItem xs={12} lg={12}>
                    {this.createEventDetailRow("location", event.location)}
                    {this.createEventDetailRow("date-and-time", time)}
                    {this.createEventDetailRow("people-attending", event.attendees)}
                    {this.createEventDetailRow("price", event.budget + "€")}
                    {this.createEventDetailRow("cleaningTime", event.cleaningTime)}
                </GridItem>
                <GridItem xs={12} lg={12}>{ViewUtils.divider()}</GridItem>
                <GridItem xs={12} lg={12}>
                    {this.createCategoryEnumerationRow("type", event.multi_category)}
                </GridItem>
                <GridItem xs={12} lg={12}>{ViewUtils.divider()}</GridItem>
                <GridItem xs={12} lg={12}>
                    {this.createSubtypeEnumerationRow("multi_event", event.multi_event)}
                    {this.createSubtypeEnumerationRow("multi_cuisine", event.multi_cuisine)}
                    {this.createSubtypeEnumerationRow("multi_service", event.multi_service)}
                    {this.createSubtypeEnumerationRow("multi_drink", event.multi_drink)}

                </GridItem>
                <GridItem xs={12} lg={12}>{ViewUtils.divider()}</GridItem>
                <GridItem xs={12} lg={12}>
                    {this.createSubtypeEnumerationRow("multi_rentals", event.multi_rentals)}
                    {this.createSubtypeEnumerationRow("multi_seating", event.multi_seating)}
                    {this.createSubtypeEnumerationRow("multi_other", event.multi_other)}
                </GridItem>
                <GridItem xs={12} lg={12}>{ViewUtils.divider()}</GridItem>
                <GridItem xs={12} lg={12}>
                    {this.createEventDetailRow("additional-info", event.additionalInfo)}
                </GridItem>
            </div>

        );
    }

    createEventDetailRow(title: string, body: string) {
        return <div style={{marginTop: "5px"}}><span>{Translation.find(title) + ": "}</span><strong>{body}</strong></div>
    }

    createCategoryEnumerationRow(title: string, categories: any) {
        return <div><span>{Translation.find(title) + ": "}</span>{this.mapCategoryEnumeration(categories)}</div>
    }

    mapCategoryEnumeration(list: string) {
        if (!list) {
            return "-";
        }
        const split = list.split(",");
        return split.map((index: any) => {
            return this.createColoredBox(Translation.find(CateringEnums.textByIndex(index)), index);
        });
    }

    createSubtypeEnumerationRow(key: string, list: any) {
        const subtype = CateringEnums.findSubtypeByKey(key);
        return <div style={{marginTop: "5px"}}>
            <span>{Translation.find(subtype.tx) + ": "}</span>
            {this.mapSubtypeEnumeration(subtype, list)}
        </div>
    }

    mapSubtypeEnumeration(subtype: any, list: string) {
        if (!list) {
            return "-";
        }
        const split = list.split(",");
        return split.map((index: any) => {
            const text = Translation.find(CateringEnums.findItemTextBySubtype(subtype, index));
            return this.createColoredBox(text, index);
        });
    }

    createColoredBox(text: string, index: any) {
        const style = {
            backgroundColor: "#F8E90F",
            borderRadius: "5px",
            paddingLeft: "5px",
            paddingRight: "5px",
            marginRight: "5px"
        };
        return <span key={index} style={style}>{text}</span>;
    }
}
