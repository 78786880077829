
import React from "react";

import {BaseComponent} from "../_subviews/BaseComponent";
import InvoiceList from "../invoice-list/subviews/InvoiceList";
import {CurrentUser} from "../../services/dto/CurrentUser";
import LocalData from "../../services/state/LocalData";
import Button from "../../components/CustomButtons/Button";
import {Translation} from "../../core/service/Translation";
import UserList from "./subviews/UserList";
import ACNotifier from "../_subviews/ac-components/ACNotifier";
import User from "../../model/User";
import {LocalStorage} from "../../services/LocalStorage";
import {Routing} from "../../services/Routing";

export default class AdminPage extends BaseComponent {

    constructor(props: any) {
        super(props);
        this.state = {
            invoices: [],
            users: []
        }
    }
    componentDidMount = async () => {
        if (CurrentUser.instance.exists()) {
            this.loadData();
        } else {
            CurrentUser.instance.loaded = () => {
                this.loadData();
            }
        }
    };

    async loadData() {
        const data = await LocalData.admin.load();
        this.setState(data);
        console.log(data);
    }

    onHijack(user: User) {
        this.setState({hijack: user});
    }
    onConfirmHijack() {
        LocalStorage.instance.setSessionToken(this.state.hijack.sessionId);
        Routing.redirectTo(Routing.Enumeration.Dashboard);
        window.location.reload();
    }

    onCancelHijack() {
        this.setState({hijack: undefined});
    }

    render() {
        return (
            <div>
                {this.state.hijack && <ACNotifier
                    //@ts-ignore
                    titleKey={Translation.find("hijack-user")}
                    contentKey={Translation.find("hijack-user-warning")}
                    confirmText={Translation.find("ok")}
                    cancelText={Translation.find("cancel")}
                    confirmed={this.onConfirmHijack.bind(this)}
                    cancelled={this.onCancelHijack.bind(this)}
                />}
                <InvoiceList
                    title={"invoices"}
                    invoices={this.state.invoices}
                    showType={true}
                    isAdminPage={true}
                    actions={this.actions.bind(this)}
                />
                <UserList
                    title={"users"}
                    data={this.state.users}
                    hijack={this.onHijack.bind(this)}
                />
            </div>
        );
    }

    actions(item: any) {
        return <Button size="sm" color={"success"} onClick={() => {
            this.props.viewDetails(item);
        }}>{Translation.find("view-details")}</Button>
    }
}
