import React from "react";
import EtiqPageCollapse from "../subviews/EtiqPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class Asian extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <EtiqPageCollapse title={"Asian"}>
                    <li>Indian, Nepalese, Indonesian cuisine, where the seasoning is rather spicy and a lot of different components are combined in the preparation of dishes</li>
                </EtiqPageCollapse>
            );
        }

        return (
            <EtiqPageCollapse title={"Aasia"}>
                <li>India, Nepaali, Indoneesia köök, kus on maitsestatus pigem vürtsisem ning roogade valmistamisel kombineeritakse ohtralt erinevaid komponente</li>
            </EtiqPageCollapse>
        );
    }
}