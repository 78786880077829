import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// core components
import Button from "../CustomButtons/Button.js";

import defaultImage from "../../assets/img/allicater/icon/no-food-image.svg";
import {ImageUtils} from "../../utils/ImageUtils";

export default class ImageUpload extends React.Component {

    static TOO_LARGE = 1;

    constructor(props) {
        super(props);
        this.state = {
            file: null,
            imagePreviewUrl: defaultImage
        };
    }

    handleImageChange = e => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = async (e) => {

            const size = ImageUtils.toMB(file.size);
            if (size > 1) {
                console.log("picked large image - automatically resizing");

                const image = new Image();

                image.onload = async () => {
                    const ratio = image.width / image.height;
                    const resized = await ImageUtils.downscale(file, ratio);
                    const base64 = await ImageUtils.toBase64(resized);
                    this.setState({file: resized, imagePreviewUrl: base64});
                    this.props.imagePicked(resized, base64);
                };
                image.src = reader.result;

            } else {
                this.setState({file: file, imagePreviewUrl: reader.result});
                this.props.imagePicked(file, reader.result);
            }
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };
    // eslint-disable-next-line
    handleSubmit = e => {
        e.preventDefault();
        // file is the file/image uploaded
        // in this function you can save the image (file) on form submit
        // you have to call it yourself
    };
    handleClick = () => {
        this.fileInput.click();
    };
    handleRemove = () => {
        this.setState({
            file: null,
            imagePreviewUrl: defaultImage
        });
        this.fileInput.value = null;
    };


    render() {
        let {addButtonProps, changeButtonProps, removeButtonProps} = this.props;
        return (
            <div className="fileinput text-center" style={this.props.style}>
                <input type="file" onChange={this.handleImageChange} ref={ref => this.fileInput = ref}/>
                <div className={"thumbnail"}>
                    <img src={this.state.imagePreviewUrl} alt="..."/>
                </div>
                <div>
                    {this.state.file === null ? (
                        <Button {...addButtonProps}
                                onClick={() => this.handleClick()}>{addButtonProps.text}</Button>) : (
                        <span>
                        <Button {...changeButtonProps} onClick={() => this.handleClick()}>
                          {changeButtonProps.text}
                        </Button>
                        <Button {...removeButtonProps} onClick={() => this.handleRemove()}>
                        <i className="fas fa-times"/>{removeButtonProps.text}</Button>
                    </span>
                    )}
                </div>
            </div>
        );
    }
}

ImageUpload.propTypes = {
    avatar: PropTypes.bool,
    addButtonProps: PropTypes.object,
    changeButtonProps: PropTypes.object,
    removeButtonProps: PropTypes.object,
    imagePicked: PropTypes.any
};
