import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @mui/material components
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Input from "@mui/material/Input";

import styles from "../../assets/jss/material-dashboard-pro-react/components/customInputStyle.js";
import {withTranslation} from "react-i18next";
import withStyles from "@mui/styles/withStyles";

class CustomInput extends React.Component {

    render() {
        const {
            classes,
            formControlProps,
            labelText,
            id,
            labelProps,
            inputProps,
            error,
            white,
            inputRootCustomClasses,
            success,
            helperText
        } = this.props;

        const labelClasses = classNames({
            [" " + classes.labelRootError]: error,
            [" " + classes.labelRootSuccess]: success && !error
        });
        const underlineClasses = classNames({
            [classes.underlineError]: error,
            [classes.underlineSuccess]: success && !error,
            [classes.underline]: true,
            [classes.whiteUnderline]: white
        });
        const marginTop = classNames({[inputRootCustomClasses]: inputRootCustomClasses !== undefined});
        const inputClasses = classNames({[classes.input]: true, [classes.whiteInput]: white});

        let formControlClasses;
        if (formControlProps !== undefined) {
            formControlClasses = classNames(formControlProps.className, classes.formControl);
        } else {
            formControlClasses = classes.formControl;
        }
        const helpTextClasses = classNames({
            [classes.labelRootError]: error,
            [classes.labelRootSuccess]: success && !error
        });
        let newInputProps = {maxLength: inputProps?.maxLength, minLength: inputProps?.minLength};

        return (
            <FormControl {...formControlProps} className={formControlClasses}>
                {labelText !== undefined ? (
                    <InputLabel className={classes.labelRoot + " " + labelClasses} htmlFor={id} {...labelProps} >
                        {labelText}
                    </InputLabel>
                ) : null}
                <Input
                    classes={{
                        input: inputClasses,
                        root: marginTop,
                        disabled: classes.disabled,
                        underline: underlineClasses,
                    }}
                    id={id}
                    {...inputProps}
                    inputProps={newInputProps}
                />
                {helperText !== undefined ? (
                    <FormHelperText id={id + "-text"} className={helpTextClasses}>{helperText}</FormHelperText>
                ) : null}
            </FormControl>
        );
    }
}

export default withTranslation()(withStyles(styles)(CustomInput));

CustomInput.propTypes = {
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    inputRootCustomClasses: PropTypes.string,
    error: PropTypes.bool,
    success: PropTypes.bool,
    white: PropTypes.bool,
    helperText: PropTypes.node
};
