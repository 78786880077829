
import React from "react";

import ACNotifier from "../../_subviews/ac-components/ACNotifier";
import {BaseComponent} from "../../_subviews/BaseComponent";

import {Translation} from "../../../core/service/Translation";

export default class MenuItemDeletePopup extends BaseComponent {

    render() {
        if (!this.props.item) {
            return null;
        }
        return (
            <ACNotifier
                //@ts-ignore
                titleKey={Translation.find("are-you-sure")}
                contentKey={Translation.find("del-warning") + " " + this.props.item.name + "?"}
                confirmText={Translation.find("del-yes")}
                cancelText={Translation.find("del-no")}
                confirmed={this.props.confirmed}
                cancelled={this.props.cancelled}
            />
        );
    }
}
