
export class JSONUtils {

    public static escape(str: string)  {
        if (!str) {
            console.log("Unable to parse json. This may be a legacy invoice");
            return str;
        }
        return str
            .replace(/\n/g, "\\\\n")
            .replace(/\r/g, "\\\\r")
            .replace(/\t/g, "\\\\t");
    }

    public static safeParse(str: string) {
        return JSON.parse(JSONUtils.escape(str));
    }
}
