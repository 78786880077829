
import React from "react";
import HelpPageCollapse from "../subviews/HelpPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class Invoices extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <HelpPageCollapse title={"Invoices"}>
                    <li> Under Invoices, the caterer sees invoices for its past events and their status </li>
                </HelpPageCollapse>
            );
        }

        return (
            <HelpPageCollapse title={"Arved"}>
                <li>Arvete all näeb caterer oma toimunud ürituste kohta käivaid arveid ning nende staatust</li>
            </HelpPageCollapse>
        );
    }
}
