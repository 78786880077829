
export enum DateEnumeration {
    January,
    February,
    March,
    April,
    May,
    June,
    July,
    August,
    September,
    October,
    November,
    December
}
export class DateUtils {

    static construct(day: number, month: DateEnumeration, year: number) {
        return new Date(year, month, day);
    }

    static msToDateWithTime(ms: any) {
        return DateUtils.msToLongDateString(ms) + " " + DateUtils.msToHoursMinutes(ms);
    }

    static locale() {
        const language = "et";
        return language + "-" + language.toUpperCase();
    }
    static msToLongDateString(ms: any) {
        if (ms === undefined || ms === null || ms === "") {
            return "-";
        }

        
        return DateUtils.MSToDate(ms).toLocaleDateString(this.locale());
    }

    static dateToShortFormString(date: Date) {
        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        //@ts-ignore
        return date.toLocaleDateString(this.locale(), options);
    }

    static msToHoursMinutes(ms: any) {
        const date = this.MSToDate(ms);
        const minutes = date.getMinutes();
        return date.getHours() + ":" + (minutes < 10 ? "0" + minutes : minutes);
    }

    static MSToDate(ms: any) {
        if (typeof ms === "string") {
            ms = parseInt(ms);
        }
        return new Date(ms);
    }

    static parseDateFieldValue(value: string) {
        const split = this.mapDateField(value, "/");
        // Months start at 0
        return new Date(split[2], split[1] - 1, split[0], 0, 0).getTime();
    }

    static parseTimeFieldValue(value: string) {
        const split = this.mapDateField(value, ":");
        const minutes = (split[0] * 60 + split[1]);
        return minutes * 60 * 1000;
    }

    public static mapDateField(value: string, separator: string): number[] {
        return value.split(separator).map(item => Number(item));
    }

    public static isPast(ms: any) {
        if (!ms) {
            return false;
        }
        if (typeof ms === "string") {
            ms = parseInt(ms);
        }
        return ms < new Date().getTime();
    }

    public static isUpcoming(ms: any, immediate?: boolean) {
        if (!ms) {
            return false;
        }
        if (typeof ms === "string") {
            ms = parseInt(ms);
        }
        
        const minimum = immediate ? DateUtils.getCurrentDayInMS() : DateUtils.get3DaysFromNow();
        return minimum <= ms;
    }

    public static currentYear() {
        return new Date().getFullYear();
    }

    public static getOneDayInMS() {
        return 1000 * 60 * 60 * 24;
    }
    public static getDaysInMS(numberOfDays: number) {
        return DateUtils.getOneDayInMS() * numberOfDays;
    }

    public static getCurrentDayInMS(): number {
        return new Date().getTime();
    }

    public static get3DaysFromNow() {
        return DateUtils.getCurrentDayInMS() + DateUtils.getDaysInMS(3);
    }
    public static getDaysFromNow(days: number) {
        return DateUtils.getCurrentDayInMS() + DateUtils.getDaysInMS(days);
    }
}
