
import React from "react";
import HelpPageCollapse from "../subviews/HelpPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class OfferEdit extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <HelpPageCollapse title={"Edit offers"}>
                    <li> The customer can accept, reject, or request changes to the offer </li>
                    <li> To make changes, go back to the offer (from the dashboard or email link) and select edit offer. </li>
                    <li> Once the changes have been made, the system will send an automatic notification to the customer </li>
                </HelpPageCollapse>
            );
        }

        return (
            <HelpPageCollapse title={"Pakkumise muutmine"}>
                <li>Klient saab pakkumise vastu võtta, tagasi lükata või soovib ta pakkumises muudatusi</li>
                <li>Muudatuste tegemiseks minge tagasi pakkumisse (töölaualt või e-posti lingilt) ja valige muuda pakkumist.</li>
                <li>Kui muudatused on tehtud saadab süsteem kliendile automaatse teate</li>
            </HelpPageCollapse>
        );
    }
}
