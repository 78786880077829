import React from "react";
import EtiqPageCollapse from "../subviews/EtiqPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class Arab extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <EtiqPageCollapse title={"Arab"}>
                    <li></li>
                </EtiqPageCollapse>
            );
        }

        return (
            <EtiqPageCollapse title={"Araabia"}>
                <li></li>
            </EtiqPageCollapse>
        );
    }
}