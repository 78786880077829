import React from "react";
import EtiqPageCollapse from "../subviews/EtiqPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class Mexican extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <EtiqPageCollapse title={"Mexican"}>
                    <li>Usually  different parts of pork, beef or poultry are used. It is richly seasoned and rice, vegetables and corn are served as sides. Corn flour products are widely used</li>
                </EtiqPageCollapse>
            );
        }

        return (
            <EtiqPageCollapse title={"Mehhiko"}>
                <li>Kasutatakse reeglina koduloomade erinevaid osasid ja ande. Maitsestatakse rikkalikult ning põhitoiduse kõrvasena pakutakse riisi, juurvilju ja maisi. Ohtralt kasutatakse maisijahu tooteid. </li>
            </EtiqPageCollapse>
        );
    }
}