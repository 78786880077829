import React from "react";
import EtiqPageCollapse from "../subviews/EtiqPageCollapse";
import {BaseComponent} from "../../_subviews/BaseComponent";

export default class Japanese extends BaseComponent {
    render() {
        if (this.props.en) {
            return (
                <EtiqPageCollapse title={"Japanese"}>
                    <li>Dishes made from local ingredients, the sides are usually rice, rice noodles, etc. The main ingredients are a wide choice of seafood. Vegetables are either cooked in broth or fried. And of course sushi!</li>
                </EtiqPageCollapse>
            );
        }

        return (
            <EtiqPageCollapse title={"Jaapani"}>
                <li>Reeglina kohalikust toorainest valmistatud road, mille kõrvane on tavapäraselt riis, riisinuudlid jms. Põhitooraine – mereannid, aedviljad on kas puljongis keedetud või fritüüritud. Ja muidugi sushi! </li>
            </EtiqPageCollapse>
        );
    }
}