
import { PDFDocument, PDFFont, PDFImage, PDFPage, PDFPageDrawLineOptions, RGB, rgb, StandardFonts } from "pdf-lib";
import Coordinate from "../../model/Coordinate";

export enum ACPDFTextAlignment {
    Left,
    Right
}

export class ACPDFOptions {
    
    x?: number; 
    y?: number;
    
    width?: number;
    height?: number;

    size?: number; 
    font?: PDFFont;

    alignment?: ACPDFTextAlignment = ACPDFTextAlignment.Left;
}

/**
 * Wrapper class for pdf-lib to unify and simplify document and page generation
 */
export default class ACPDFDocument {

    private document!: PDFDocument;
    public internal(): PDFDocument {
        return this.document;
    }

    private _page!: PDFPage;
    public page(): PDFPage {
        return this._page;
    }

    private _font!: {normal: PDFFont, bold: PDFFont, size: {small: number, normal: number, large: number}};
    public font() {
        return this._font;
    }

    public size() {
        return this._page.getSize();
    }

    static async create(): Promise<ACPDFDocument> {
        const result = new ACPDFDocument();
        
        result.document = await PDFDocument.create();
        result._page = result.document.addPage();
        result._font = {
            normal: await result.document?.embedFont(StandardFonts.Helvetica),
            bold: await result.document?.embedFont(StandardFonts.HelveticaBold),
            size: {small: 14, normal: 15, large: 20}
        };

        return result;
    }

    draw(input: string | PDFImage, options: ACPDFOptions) {
        
        options.y = this.toDescendingCoordinate(options.y ?? 0);

        if (typeof input === "string") {
            if (!options.size) {
                options.size = this._font.size.normal;
            }
            if (!options.font) {
                options.font = this._font.normal;
            }
            if (options.alignment === ACPDFTextAlignment.Right) {
                const textWidth = options.font.widthOfTextAtSize(input, options.size);
                const space = options.width! - textWidth;
                options.x = options.x! + space;
            }
            
            this._page.drawText(input, options);
        } else {
            if (options.width) {
                options.y -= options.width;
            }
            this._page.drawImage(input, options);
        }
    }

    line(start: Coordinate, end: Coordinate) {
        const options: PDFPageDrawLineOptions = {
            start: start.yToDescending(this.size().height),
            end: end.yToDescending(this.size().height),
            thickness: 1,
            color: this.color(200, 200, 200)
        };
        this._page.drawLine(options);
    }

    color(red: number, green: number, blue: number): RGB {
        return rgb(red / 255, green / 255, blue / 255);
    }

    toDescendingCoordinate(source: number): number {
        const total = this.size().height;
        const translated = total - source;
        return translated;
    }
}
