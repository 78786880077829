import {BaseComponent} from "../../_subviews/BaseComponent";
import {ViewUtils} from "../../../utils/ViewUtils";
import ReactTable from "../../../components/ReactTable/ReactTable";
import React from "react";
import {DateUtils} from "../../../core/utils/DateUtils";
import InvoiceData from "../../../services/state/sub/InvoiceData";
import {withTranslation} from "react-i18next";
import Invoice from "../../../model/Invoice";
import {InvoiceType} from "../../../core/model/enum/InvoiceType";
import { InvoicePrice } from "../../../core/model/InvoicePrice";
import { MathUtils } from "../../../core/utils/MathUtils";

class InvoiceList extends BaseComponent {

    columns(): any[] {
        const result = [];

        if (!ViewUtils.isMobile()) {
            result.push(ViewUtils.createColumnHeader("#", "id"));
            result.push(ViewUtils.createColumnHeader(this.props.t("caterer"), "humanReadableCaterer"));
            result.push(ViewUtils.createColumnHeader(this.props.t("date"), "humanReadableCreateDate"));
        }

        result.push(ViewUtils.createColumnHeader(this.props.t("bill-due"), "humanReadableDueDate"));
        result.push(ViewUtils.createColumnHeader(this.props.t("sum"), "sum"));

        if (this.props.showType) {
            result.push(ViewUtils.createColumnHeader(this.props.t("type"), "readableType"));
        }
        result.push(ViewUtils.createColumnHeader(this.props.t("action"), "actions"));

        return result;
    }

    parseData() {
        const result = [];

        for (let i = 0; i < this.props.invoices.length; i++) {
            const item = this.props.invoices[i];

            if (!Invoice.isPrepayment(item) && DateUtils.isUpcoming(item.eventDate, true) && !this.props.isAdminPage) {
                // Skip invoices for events that haven't happened yet, unless viewed from the admin page
                continue;
            }
            item.humanReadableCreateDate = DateUtils.msToLongDateString(Invoice.releaseDate(item));
            item.humanReadableDueDate = DateUtils.msToLongDateString(item.dueDate);

            if (item.caterer) {
                item.humanReadableCaterer = item.caterer.name;
            }
            item.sum = this.findAmount(item);
            item.readableType = this.parseType(item);

            item.actions = <div className="actions-right">{this.props.actions(item)}</div>;

            result.push(item);
        }
        return result;
    }

    findAmount(item: any) {
        const vat = !!item.caterer?.vatNumber;

        let prepaidAmount = 0;

        if (!Invoice.isPrepayment(item) && item.type !== InvoiceType.Allicater) {
            prepaidAmount = InvoicePrice.fromPrepaidAmount(item.prepaidAmount, vat).total;
        }
        let sum = InvoiceData.calculatePrice(item, vat) - prepaidAmount;

        if (item.type === InvoiceType.Allicater) {
            const percentage = Invoice.findFeePercentage(item);
            sum -= sum * (percentage / 100);
        }

        if (sum !== undefined) {
            return MathUtils.round(sum) + "€";
        }

        return "- €";
    }

    parseType(item: Invoice) {
        const type = Invoice.findType(item);
        return <div style={{color: this.typeToColor(type)}}>{Invoice.typeToString(type)}</div>
    }

    typeToColor(type: InvoiceType) {
        if (type === InvoiceType.Prepayment) {
            return "blue";
        }
        if (type === InvoiceType.Full) {
            return "red";
        }
        if (type === InvoiceType.Allicater) {
            return "green";
        }
        return "gray";
    }

    render() {
        return ViewUtils.createCardTemplate(
            "invoice",
            this.translate(this.props.title),
            <ReactTable columns={this.columns()} data={this.parseData()}/>,
            this.props.color
        );
    }
}

export default withTranslation()(InvoiceList);
