import React from "react";

import {BaseComponent} from "../../_subviews/BaseComponent";
import Button from "../../../components/CustomButtons/Button";
import Badge from "@mui/material/Badge";
import {Translation} from "../../../core/service/Translation";
import {ViewUtils} from "../../../utils/ViewUtils";

export default class MenuCategoryButton extends BaseComponent {

    render() {
        const {item, badge} = this.props;

        return <Button
            key={item.id}
            fullWidth
            color="white"
            onClick={() => this.props.onClick(item)}
            className = {"force-cat-color"}
        >
            <span>{Translation.find(item.tx)}</span>
            <Badge
                className="badge-circle badge-floating border-white bubble-killer"
                color="default"
                size="md"
                badgeContent={badge}
                showZero={false}
                style={{position: "absolute", right: "20px", color: "#555"}}
            >
            </Badge>
            {ViewUtils.menuIcon(item.icon)}
        </Button>
    }
}
