
import React, {CSSProperties} from "react";
import Button from "../../../components/CustomButtons/Button";
import MenuCategorySelect from "../../_subviews/MenuCategorySelect";

import MenuItem from "../../../model/MenuItem";

import LocalData from "../../../services/state/LocalData";
import {Translation} from "../../../core/service/Translation";

import {ViewUtils} from "../../../utils/ViewUtils";
import {BaseComponent} from "../../_subviews/BaseComponent";
import MenuIcon from "../../../assets/img/allicater/icon/my-menu.svg";
import { Tooltip } from "@mui/material";

export default class MenuItemBox extends BaseComponent {

    constructor(props: any) {
        super(props);
        this.state = {
            category: 0
        };
    }

    handleCategoryChange(e: any) {
        this.setState({category: e.target.value});
    }

    render() {
        const isOwnMenu = !this.props.isAcceptPage;
        const title = isOwnMenu ? Translation.find("your-menu") : Translation.find("browse-caterer-menu");
        return (
            ViewUtils.createCard(<img src={MenuIcon} className={"squery"} alt={"."} />, title,
                <div>
                    <MenuCategorySelect
                        //@ts-ignore
                        onCategoryChange={this.handleCategoryChange.bind(this)}
                    />
                    <div>
                        {LocalData.offer.findMenuItemsFor(this.state.category, isOwnMenu).map((item: any) => {
                            return this.createCategoryItem(item);
                        })}
                    </div>
                </div>
            )
        );
    }

    createCategoryItem(item: any) {
        return (
            <Tooltip key={item.id} title={MenuItem.findDescription(item)}>
                <div style={{display: "flex", borderBottom: "1px solid lightgray", minHeight: "40px"}}>
                    <div style={{width: "40px", maxHeight: "40px", overflow: "hidden", display: "block"}}>
                        <img
                            src={MenuItem.avatar(item)}
                            style={{ marginTop: "10%", height: "80%"}}
                            alt={"..."}/>
                    </div>
                    <div style={{ lineHeight: "40px", marginLeft: "10px" }}>{item.name}</div>
                    <div style={{ lineHeight: "40px", marginLeft: "10px" }}>{"(" + item.price + "€)"}</div>
                    {this.createButtons(item)}
                </div>
            </Tooltip>
        );
    }

    createButtons(item: any) {
        if (!this.props.canEdit || this.props.isAcceptPage) {
            return null;
        }
        const countBoxStyle: CSSProperties = {
            display: "flex",
            flexDirection: "row",
            position: "absolute",
            right: "15px",
            marginTop: "5px"
        };
        return <div style={countBoxStyle}>
            {this.createButton("danger", "-", () => { this.props.onItemRemoved(item); })}
            {this.createButton("success", "+", () => { this.props.onItemAdded(item); })}
        </div>
    }

    createButton(color: string, text: string, action: any) {
        const style = { minWidth: "20px", width: "20px", height: "20px" };
        return <Button justIcon onClick={action} color={color} style={style}>{text}</Button>;
    }
}
