import React from "react";

import {BaseComponent} from "../../_subviews/BaseComponent";
import ACNotifier from "../../_subviews/ac-components/ACNotifier";
import {Translation} from "../../../core/service/Translation";

export default class OfferRejectPopup extends BaseComponent {

    render() {
        if (!this.props.visible) {
            return null;
        }

        return (
            <ACNotifier
                //@ts-ignore
                titleKey={Translation.find("are-you-sure")}
                contentKey={Translation.find("reject-offer")}
                confirmText={Translation.find("reject-yes")}
                cancelText={Translation.find("reject-no")}
                confirmed={this.props.confirmed}
                cancelled={this.props.cancelled}
            />
        );
    }
}
