
import React from "react";

import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import Button from "../../components/CustomButtons/Button";

import {BaseComponent} from "../_subviews/BaseComponent";
import ACCard from "../_subviews/_base/ACCard";

import {Networking} from "../../services/Networking";
import {Routing} from "../../services/Routing";
import {Translation} from "../../core/service/Translation";
import RatingView from "./subviews/RatingView";
import EventInfoBox from "../offer/subviews/EventInfoBox";
import {CurrentUser} from "../../services/dto/CurrentUser";

export default class RatingPage extends BaseComponent {

    constructor(props: any) {
        super(props);

        this.state = {
            ids: {
                event: Routing.instance.getUrlNumberParameter("eventId"),
                offer: Routing.instance.getUrlNumberParameter("offerId"),
                recipient: Routing.instance.getUrlNumberParameter("recipientId")
            },
            score: {
              customer: -1,
              cleanliness: -1,
              service: -1,
              food: -1
            },
            event: undefined
        }
    }

    componentDidMount = async () => {
        if (CurrentUser.instance.exists()) {
            await this.loadData();
        } else {
            CurrentUser.instance.loaded = async() => {
                await this.loadData();
            }
        }
    };

    async loadData() {
        const response = await Networking.instance.fetchEvents({eventId: this.state.ids.event});
        if (response.events?.length > 0) {
            this.setState({event: response.events[0]});
        }
    }

    async giveRating() {
        const data = {
            ...this.state.score,
            "recipientId": this.state.ids.recipient,
            "offerId": this.state.ids.offer,
        };
        const response = await Networking.instance.giveRating(data);

        if (response.error) {
            this.props.alert(Translation.find("rating-give-error"), response.error);
        } else {
            this.props.alert(Translation.find("success-header"), Translation.find("rating-give-success-content"), "#/");
        }
    }

    changeRating(type: string, stars: number) {
        const score = this.state.score;
        score[type] = stars;
        this.setState({score: score});
    }

    render() {
        const event = this.state.event;
        if (!event) {
            return null;
        }

        return (
            <div>
                <ACCard title={Translation.find("review")} icon={"feedback"}>
                    <GridContainer justifyContent="center">
                        <GridItem xs={12} sm={12} md={12}>
                            <EventInfoBox event={event}/>
                                {this.content()}
                        </GridItem>
                    </GridContainer>
                </ACCard>
                <Button color={"primary"} onClick={this.giveRating.bind(this)} disabled={this.disabled()}>
                    {Translation.find("give")}
                </Button>
            </div>
        );
    }

    disabled() {
        const score = this.state.score;
        if (CurrentUser.instance.isCaterer()) {
            return this.state.score.customer === -1;
        }

        return score.cleanliness === -1 || score.service === -1 || score.food === -1;
    }

    content() {
        console.log(this.state.score);
        if (CurrentUser.instance.isCaterer()) {
            return <RatingView
                type={"customer"}
                heading={Translation.find("score-for-customer")}
                stars={this.state.score.customer}
                onChange={this.changeRating.bind(this)}
            />
        }

        return (
            <div>
                <RatingView
                    type={"cleanliness"}
                    heading={Translation.find("cleanliness-score")}
                    stars={this.state.score.cleanliness}
                    onChange={this.changeRating.bind(this)}
                />
                <RatingView
                    type={"service"}
                    heading={Translation.find("service-score")}
                    stars={this.state.score.service}
                    onChange={this.changeRating.bind(this)}
                />
                <RatingView
                    type={"food"}
                    heading={Translation.find("food-score")}
                    stars={this.state.score.food}
                    onChange={this.changeRating.bind(this)}
                />
            </div>
        );
    }

}
