import React from "react";

import {BaseComponent} from "../BaseComponent";

export class PrivacyPopupContentET extends BaseComponent {

    render() {
        return (
            <div>
                <p><strong>Allicater privaatsustingimused</strong></p>
                <p><strong>“Allicater”</strong> on<strong> Post Innovations OÜ</strong>, Riia 181a, Tartu, 51004, Eesti. Reg nr 14331746, KM ID EE102248980. E-post: support@allicater.com</p>
                <p>Post Innovations OÜ töötleb oma külastajate isikuandmeid.
                    Käesolevates Privaatsuspõhimõtetes viitab sõna „Allicater Teenused” nii Veebilehele kui ka Allicater mobiiliäpileile. Käesolevates Privaatsustingimustes viitab sõna „Kasutaja” või „teie” nii meie ettevõtte klientidele, esindajatele ja teistele meie Kliendi Organisatsiooni volitatud Kasutajatele, potentsiaalsetele klientidele ja Allicater Teenuste Kasutajatele.</p>
                <p>Meie Privaatsustingimustes selgitatakse milliseid isikuandmeid me töötleme, kuidas isikuandmeid töödeldakse ja kuidas saate andmesubjektina oma õigusi kasutada (nt. õigus esitada vastuväiteid, õigus tutvuda andmetega).
                    Mõnede meie teenuste suhtes võidakse kohaldada eraldi privaatsuspõhimõtteid. Kui konkreetse teenuse suhtes kohaldatakse eraldi privaatsuspõhimõtteid, teavitame sellest kõnealuse teenuse juures.</p>
                <p>Käesolevaid Privaatsustingimusi võidakse aeg-ajalt uuendada, et kajastada meie andmete töötlemise tavasid või muud muudatusi. Me ei tee käesolevates Privaatsustingimustes olulisi muudatusi ega vähenda nende Kasutajate õigusi, ilma et teid sellest teavitaksime.</p>
                <p><strong>Isikuandmed</strong></p>
                <p>Kasutajaandmed</p>
                <p>Kasutajaandmed on isikuandmed, mis kogutakse vahetult Teie või Kliendi Organisatsiooni käest, kelle nimel te Allicater Teenuseid kasutate („Kliendi Organisatsioon”). Me võime koguda Kasutajatelt ja Kliendi Organisatsioonidelt Kasutajaandmeid erineval viisil, sealhulgas siis, kui sõlmitakse ärikliendiga teenusleping või kui Kasutajad registreerivad end Allicater Teenuste Kasutajaks, tellivad uudiskirja või täidavad vormi. Lisaks võtke palun arvesse, et me kogume mh andmeid mistahes tehingu ja makse kohta, mida te Allicater Teenuste kaudu sooritate.</p>
                <p>Järgnevad isikuandmed, mis on meie poolt kogutud ja mida me töötleme, on vajalikud selleks, et nõuetekohaselt täita lepingut, mis on sõlmitud teie ja ka meie vahel ja meie õigustatud huvi jaoks kui me täidame oma lepingulisi kohustusi äriklientide ees ning meie seaduslike kohustuste täitmiseks.
                    Pärast meie ja Kliendi Organisatsiooni vahelise teenuslepingu sõlmimist esitab Kliendi Organisatsioon meile teie ees- ja perekonnanime ja meiliaadressi.
                    Kui registreerite Allicater Teenuste Kasutajaks ja loote Kasutajakonto, peate andma meile järgmise teabe:</p>
                <ul>
                    <li>ees- ja perekonnanimi</li>
                    <li>e-posti aadress</li>
                    <li>telefoninumber</li>
                    <li>aadress</li>
                </ul>
                <p>Teie kasutaja- või kliendikogemuse parandamiseks võite meile anda järgmise teabe:  </p>
                <ul>
                    <li>asukohaandmed (kui nõustute oma asukohaandmete töötlemisega)</li>
                    <li>foto</li>
                    <li>muu teave, mille esitate kas kasutajakonto loomisel või hiljem oma kontoprofiili muutmisel  </li>
                </ul>
                <p>Muu teave. Me võime samuti töödelda muud teie poolt vabatahtlikult esitatud teavet, näiteks:
                </p>
                <ul>
                    <li>teave, mida annate meie teenuste hindamisel</li>
                    <li>turunduseesmärkidel nõustumised ja keeldumised</li>
                    <li>teave, mida annate suheldes meiega telefoni teel või meilisõnumiga või vestlussõnumiga, sealhulgas teie poolt meie klienditeenindusse tehtud kõnede salvestused</li>
                </ul>
                <p>Lisaks teilt ja Kliendi Organisatsioonilt kogutud Kasutajaandmetele, töötleme ka teatud isikuandmeid, mida kolmandast isikust teenusepakkujad teie kohta annavad.
                    Kui logite sisse oma Facebooki konto kaudu, jagab Facebook meiega teie isikuandmeid, näiteks teie profiilipilti, näidisvalikut teie Facebooki sõpradest ja teie Facebooki IDd.
                </p>
                <p><strong>Logifailid</strong></p>
                <p>
                    Allicater järgib logifailide kasutamise standardset protseduuri. Nimetatud failid talletavad külastused veebisaitide külastamisel. Kõik hostinguettevõtted teevad seda ja see on osa hostimisteenuste analüüsist. Logifailide kogutud teave sisaldab Interneti-protokolli (IP) aadresse, brauseri tüüpi, Interneti-teenuse pakkujat (ISP), kuupäeva ja kellaaega, viidavaid / väljumislehti ja võimalusel klikkide arvu. Neid ei seostata ühegi isikut tuvastava teabega. Teabe eesmärk on suundumuste analüüsimine, saidi haldamine, kasutajate liikumise jälgimine veebisaidil ja demograafilise teabe kogumine.</p>
                <p><strong>Küpsised ja veebimajakad</strong></p>
                <p>Küpsised on teie seadmesse saadetud ja salvestatud väikesemahulised tekstifailid, mis võimaldavad meil tuvastada Allicateri Teenuste Kasutajaid ning hõlbustada Allicateri Teenuste kasutamist ja saada koondteavet meie külastajate kohta. See aitab meil tõhustada Allicateri Teenuseid ja pakkuda meie Kasutajatele paremat klienditeenindust. Küpsised ei kahjusta teie seadet ega faile. Me kasutame küpsiseid, et kohandada Allicateri Teenuseid ja meie poolt pakutavat teavet vastavalt meie Kasutajate konkreetsetele huvidele. </p>
                <p>Kasutajad võivad oma veebibrauseris küpsised keelata või seadistada see nii, et Kasutajat küpsiste saatmise ajal hoiatatakse. </p>
                <p>Pange tähele, et mõned Allicateri Teenuste osad ei pruugi korralikult töötada, kui küpsiste kasutamine on keelatud.</p>
                <p>Veebimajakas (ingl web beacon) on tehnoloogia, mis võimaldab tuvastada veebisaitide ja meilide lugejaid, näiteks selleks, et tuvastada, kas meili on vaadatud. </p>

                <p><strong>Säilitamisperiood</strong></p>
                <p>Allicater ei säilita teie isikuandmeid kauem kui seaduslikult lubatud ja vajalik Allicater Teenuste või nende asjaomaste osade osutamiseks. Säilitamisperiood sõltub teabe olemusest ja töötlemise eesmärgist. Seetõttu võib maksimaalne säilitamisperiood sõltuvalt kasutusotstarbest olla erinev. Enamik isikuandmeid, mida säilitatakse Allicater Teenustega seotud Kasutajakontol, kustutatakse 90. päeva möödumisel pärast seda, kui Kasutaja kustutab oma Allicater Teenustega seotud Kasutajakonto. Seejärel võib osa Allicater Teenustega ja Kasutajakontoga seotud isikuandmetest säilitada ainult niikaua, kui selline töötlemine on seadusega ette nähtud või kui see on mõistlikult vajalik meie juriidiliste kohustuste või õigustatud huvide teostamise eesmärgil, näiteks nõuete lahendamise, raamatupidamise, ettevõttesisese aruandluse ja vaidluste lahendamise eesmärgil. Kõik Allicater Teenustega seotud Kasutajakontol olevad isikuandmed kustutatakse 10. aasta jooksul pärast seda, kui Kasutaja on Allicater Teenustega seotud Kasutajakonto kustutanud, välja arvatud isikuandmed, mida vajatakse teatud harvadel juhtudel, nt. kohtumenetluses. Nende Kasutajate Analüütikaandmed, kellel ei ole Allicater Teenustega seotud Kasutajakontot, säilitame 90 päeva.
                </p>
                <p><strong>Andmete turvalisus</strong></p>
                <p>Me kasutame meie kogutud ja töödeldavate isikuandmete kaitsmiseks halduslikke, organisatsioonilisi, tehnilisi ja füüsilisi meetmeid. Meetmed hõlmavad vajadusel näiteks krüptimist, pseudonüümimist, tulemüüre, turvalisi süsteeme ja juurdepääsuõigusega süsteeme. Meie turvakontrolli eesmärk on säilitada asjakohane andmete konfidentsiaalsus, terviklikkus, kättesaadavus, vastupidavus ja andmete taastamine. Turvaohtude tuvastamiseks kontrollime regulaarselt Allicateri Teenuseid, süsteeme ja muid varasid.
                    Kui turvameetmetest hoolimata esineb turvarikkumine, millel on tõenäoliselt negatiivne mõju Kasutajate privaatsusele, teavitame rikkumisest võimalikult kiiresti vastavaid Kasutajaid ja teisi asjassepuutuvaid isikuid ning asjakohaseid asutusi, kui see on nõutav kohalduva</p>
            </div>
        );
    }
}
