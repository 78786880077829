import Banking from "../core/service/Banking";
import {Language} from "../core/model/enum/Language";

export default class User {

    static CATERER_VEIN_JA_VINE = "assar@veinjavine.ee";

    public id: number = -1;

    public name: string = "";
    public email: string = "";
    public password: string = "";
    public companyName: string = "";

    public isCaterer: boolean = false;
    public isAdmin: boolean = false;

    public facebookId: string = "";

    public address: string = "";
    public city: string = "";
    public description: string = "";

    public multi_category: string = "";
    public multi_cuisine: string = "";
    public multi_drink: string = "";
    public multi_event: string = "";
    public multi_other: string = "";
    public multi_rentals: string = "";
    public multi_seating: string = "";
    public multi_service: string = "";
    public officialBusinessName: string = "";
    public phone: string = "";
    public serviceArea: string = "";

    public minAttendance: number = -1;
    public maxAttendance: number = -1;
    public minPrice: number = -1;

    public vatNumber: string = "";
    public regNumber: string = "";
    public iban: string = "";

    public language: Language = Language.Et;

    public avatarUrl: string = "";

    public isVerified: boolean = false;
    public sessionId: string = "";

    static allicater() {
        const user = new User();
        user.name = "Allicater";
        user.email = "invoices@allicater.com";
        user.officialBusinessName = "Post Innovations OÜ";
        user.regNumber = "14331746";
        user.vatNumber = "EE102248980";
        user.iban = Banking.POST_INNOVATIONS_MAIN_SWED;
        
        return user;
    }

    static displayName(user: User): string {
        if (!user) {
            return "";
        }
        return user.isCaterer ? user.officialBusinessName : user.name;
    }
}
