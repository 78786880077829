import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import cx from "classnames";

// @mui/material components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Hidden from "@mui/material/Hidden";

// material-ui icons
import Menu from "@mui/icons-material/Menu";
import MoreVert from "@mui/icons-material/MoreVert";
import ViewList from "@mui/icons-material/ViewList";

// core components
import AdminNavbarLinks from "./AdminNavbarLinks";
import Button from "../../components/CustomButtons/Button.js";

import { withStyles } from "@mui/styles";
import styles from "../../assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";

class AdminNavbar extends React.Component {

    constructor(props) {
        super(props);

        this.classes = props.classes;

    }

    render() {
        const {color} = this.props;
        const appBarClasses = cx({[" " + this.classes[color]]: color});
        const sidebarMinimize = this.classes.sidebarMinimize;
        const classes = this.classes;

        return (
            <AppBar className={classes.appBar + appBarClasses}>
                <Toolbar className={classes.container}>
                    <Hidden mdDown implementation="css">
                        <div className={sidebarMinimize}>
                            {this.props.miniActive ? (
                                <Button justIcon round color="white" onClick={this.props.sidebarMinimize}>
                                    <ViewList className={classes.sidebarMiniIcon}/>
                                </Button>
                            ) : (
                                <Button justIcon round color="white" onClick={this.props.sidebarMinimize}>
                                    <MoreVert className={classes.sidebarMiniIcon}/>
                                </Button>
                            )}
                        </div>
                    </Hidden>
                    <div className={classes.flex}>
                        {/* Here we create navbar brand, based on route name */}
                        {/*<Button href="#" className={classes.title} color="transparent">{brandText}</Button>*/}
                    </div>
                    <Hidden mdDown implementation="css">
                        <AdminNavbarLinks
                            dashboardClick={this.props.dashboardClick}
                            profileClick={this.props.profileClick}
                            settingsClick={this.props.settingsClick}
                            logoutClick={this.props.logoutClick}/>
                    </Hidden>
                    <Hidden mdUp implementation="css">
                        <Button
                            className={classes.appResponsive}
                            color="transparent"
                            justIcon
                            aria-label="open drawer"
                            onClick={this.props.handleDrawerToggle}
                        >
                            <Menu/>
                        </Button>
                    </Hidden>
                </Toolbar>
            </AppBar>
        );
    }
}

export default withStyles(styles)(AdminNavbar);

AdminNavbar.propTypes = {
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
    rtlActive: PropTypes.bool,
    brandText: PropTypes.string,
    miniActive: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    sidebarMinimize: PropTypes.func,
    dashboardClick: PropTypes.func,
    profileClick: PropTypes.func,
    settingsClick: PropTypes.func,
    logoutClick: PropTypes.func
};
